/* eslint quote-props: 0 */
import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import fileDownload from 'js-file-download';
import FolderIcon from '@material-ui/icons/Folder';
import DownloadIcon from '@material-ui/icons/GetApp';
import { withStyles } from '@material-ui/core/styles';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { unstable_Box as Box } from '@material-ui/core/Box';
import { Document } from '@aps-management/primapp-common';
import {
  Grid,
  Paper,
  IconButton,
  Typography,
  Button,
  Select,
  Input,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  CircularProgress,
} from '@material-ui/core';

import i18n from '_utils/i18n';
import master from '_utils/master';
import { Screen } from '_components/core';
import { FileIcon } from '_components/elements';
import apolloClient from '_utils/masterClient';

// Types de document
const documentTypes = {
  'invoice': 'Factures / tickets',
  'receipt': 'Reçus',
  'statement': 'Relevés',
  'subscription': 'Cotisations',
  'payment_schedule': 'Échéanciers',
  'misc': 'Divers',
};

/* */
const styles = (theme) => ({
  title: { marginBottom: theme.spacing.unit * 2 },
  iconColumn: { width: 75 },
  dateColumn: { width: 200 },
  actionColumn: { width: 75 },
});

/* */
const DocumentList = ({
  account,
  golf,
  classes,
  history,
}) => {
  /* */
  const [downloading, setDownloading] = useState(null);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const [documents, setDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);

  const [periods, setPeriods] = useState([]);
  const [activePeriod, setActivePeriod] = useState(null);

  const [activeTypes, setActiveTypes] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  /* */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  /* */
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(Number(e.target.value));
    setPage(0);
  };

  /* */
  const loadPeriods = () => Document.api.getSharedPeriodFilters(apolloClient, {
    ownerId: golf.id,
    readerId: account.id,
  })
    .then((data) => {
      setPeriods(data);
      setActivePeriod(0);
    });

  /* */
  const loadDocuments = useCallback(() => {
    if (periods.length === 0) return null;

    setRefreshing(true);
    const period = periods[activePeriod];

    return Document.api.getSharedDocuments(apolloClient, {
      ownerId: golf.id,
      readerId: account.id,
      from: period.from,
      to: period.to,
    })
      .then((data) => {
        const docs = data.map((d) => ({
          ...d,
          type: documentTypes[d.type] ? d.type : 'misc',
        }));
        setDocuments(docs);
        setFilteredDocuments(docs);
        setTimeout(() => setRefreshing(false), 1000);
      })
      .catch(setError);
  }, [periods, activePeriod, account, golf]);

  /* */
  const load = () => {
    setLoading(true);
    loadPeriods()
      .catch(setError)
      .finally(() => setLoading(false));
  };

  /* */
  React.useEffect(load, []);

  /* */
  React.useEffect(() => {
    if (documents.length === 0) return;

    if (activeTypes.length === 0) {
      setFilteredDocuments(documents);
    } else {
      setFilteredDocuments(documents.filter((d) => activeTypes.includes(d.type)));
    }
    setPage(0);
  }, [activeTypes, documents]);

  /* */
  React.useEffect(() => {
    if (activePeriod === null) return;

    loadDocuments();
  }, [activePeriod, loadDocuments]);

  /* */
  const handleTypeToggle = (type) => () => {
    const idx = activeTypes.indexOf(type);
    const newActiveTypes = [...activeTypes];

    if (idx === -1) {
      newActiveTypes.push(type);
    } else {
      newActiveTypes.splice(idx, 1);
    }

    setActiveTypes(newActiveTypes);
  };

  /* */
  const downloadFile = (d) => {
    setDownloading(d.id);
    master.blob(`document/${d.id}`)
      .then((data) => fileDownload(data, d.filename))
      .catch(setError)
      .finally(() => setDownloading(null));
  };

  /* */
  const renderTypeFilter = () => (
    <Box mb={1} mt={2}>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="baseline"
        justifyContent="space-between"
      >
        <Typography component="span" variant="body1">
          {'Filtrer par'}
        </Typography>
        {activeTypes.length > 0 && (
          <Button
            color="primary"
            size="small"
            onClick={() => setActiveTypes([])}
          >
            {'Afficher tout'}
          </Button>
        )}
      </Box>
      <FormGroup row>
        {Object.keys(documentTypes).map((type, idx) => (
          <FormControlLabel
            key={idx}
            control={
              <Checkbox
                style={{ paddingBottom: 6, paddingTop: 6 }}
                value={type}
                icon={<FolderOpenIcon />}
                checkedIcon={<FolderIcon />}
                onChange={handleTypeToggle(type)}
                checked={activeTypes.includes(type)}
              />
            }
            label={documentTypes[type]}
          />
        ))}
      </FormGroup>
    </Box>
  );

  /* */
  const handleChangePeriod = (e) => {
    const value = Number(e.target.value);
    setActivePeriod(value);
  };

  /* */
  const renderPeriodFilter = () => {
    if (!periods.length) return null;

    return (
      <Select
        native
        label='Type'
        value={activePeriod}
        onChange={handleChangePeriod}
        input={<Input name="type" id="select-type" />}>
        {periods.map((opt, idx) => (
          <option key={idx} value={idx}>{opt.label}</option>
        ))}
      </Select>
    );
  };

  /* */
  const renderDocuments = () => {
    if (refreshing) {
      return (
        <Box display="flex" justifyContent="center" py={1}>
          <CircularProgress
            size={24}
            thickness={4}
            color="secondary"
          />
        </Box>
      );
    }

    if (documents.length === 0) {
      return (
        <Box py={1}>
          <Typography variant="body1" align="center" color="primary">
            {'Aucun fichier partagé sur la période.'}
          </Typography>
        </Box>
      );
    }

    return (
      <Paper>
        <Table padding="dense">
          <TableHead>
            <TableRow>
              <TableCell className={classes.iconColumn}>
                {''}
              </TableCell>
              <TableCell>
                {'Nom'}
              </TableCell>
              <TableCell className={classes.dateColumn}>
                {'Date'}
              </TableCell>
              <TableCell className={classes.actionColumn}>
                {''}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody key="tbody">
            {filteredDocuments
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((d, key) => (
                <TableRow key={key}>
                  <TableCell>
                    <FileIcon mimetype={d.mimetype} />
                  </TableCell>
                  <TableCell>
                    {d.name}
                  </TableCell>
                  <TableCell>
                    {i18n.l('date.formats.short_y', new Date(d.createdAt))}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton aria-label="Télécharger" onClick={() => downloadFile(d)}>
                      {(downloading === d.id)
                        ? <CircularProgress color="secondary" size={24} />
                        : <DownloadIcon />
                      }
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
          }
          {filteredDocuments.length === 0 && (
            <TableRow>
              <TableCell colSpan={4} align="center">
                {'Aucun fichier correspondant.'}
              </TableCell>
            </TableRow>
          )}
          </TableBody>
        </Table>
        {filteredDocuments.length > 0 && (
          <TablePagination
            page={page}
            component="div"
            count={filteredDocuments.length}
            rowsPerPage={rowsPerPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={[25, 50, 100]}
            labelRowsPerPage="Lignes par page"
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
          />
        )}
      </Paper>
    );
  };

  return (
    <Screen
      error={error}
      loading={loading}
      title="Documents partagés"
      onBackPress={() => history.goBack()}>
      <Grid container spacing={40}>
        <Grid item lg={12} md={6} xs={12}>
          <Typography variant="h6" className={classes.title}>
            {'Partagés avec moi'}
          </Typography>
          {renderPeriodFilter()}
          {renderTypeFilter()}
          {renderDocuments()}
        </Grid>
      </Grid>
    </Screen>
  );
};

const mapStateToProps = ({ app: { account, golf } }) => ({ account, golf });

const StyledComponent = withStyles(styles)(DocumentList);

export default connect(mapStateToProps)(StyledComponent);
