import React from 'react';
import PhotoIcon from '@material-ui/icons/Photo';
import AudioIcon from '@material-ui/icons/MusicNote';
import VideoIcon from '@material-ui/icons/MovieFilter';
import DescriptionIcon from '@material-ui/icons/Description';

/* */
export default ({ mimetype }) => {
  if (mimetype.match(/image\/*/i)) return <PhotoIcon />;
  if (mimetype.match(/video\/*/i)) return <VideoIcon />;
  if (mimetype.match(/audio\/*/i)) return <AudioIcon />;

  return <DescriptionIcon />;
};
