import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FileSelectArea } from 'material-ui-file-dropzone';
import {
  colors,
  Chip,
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import UploadIcon from '@material-ui/icons/CloudUpload';
import DescriptionIcon from '@material-ui/icons/Description';

import {
  helpers,
  DOCUMENT_MAX_SIZE,
  DOCUMENT_MAX_FILES,
} from '@aps-management/primapp-common';

/* */
const styles = theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
  dropzone: {
    width: '100%',
    minWidth: 300,
    minHeight: 300,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  uploadIcon: {
    width: 96,
    height: 96,
    opacity: 0.3,
  },
  chip: {
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  error: {
    fontWeight: 600,
    color: colors.red[500],
  },
});

/* */
const Upload = ({
  open,
  classes,
  handleClose,
  handleUpload,
}) => {
  const [files, setFiles] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [uploading, setUploading] = React.useState(false);

  /* */
  const handleSelectFiles = (filelist) => {
    const list = Array.from(filelist);
    if (list.length > DOCUMENT_MAX_FILES) {
      setError(`Seulement ${DOCUMENT_MAX_FILES} fichiers à la fois`);
      return false;
    }
    const errors = [];
    const uploads = [];
    list.forEach((file) => {
      if (file.size > DOCUMENT_MAX_SIZE) {
        errors.push(`${file.name} trop volumineux (${helpers.string.humanFileSize(file.size)} max)`);
      } else {
        uploads.push(file);
      }
    });
    if (errors.length) {
      errors.push(`Taille max. acceptée : ${helpers.string.humanFileSize(DOCUMENT_MAX_SIZE)}`);
      setError(errors.map((err, i) => (
        <React.Fragment key={i}>
          {err}<br />
        </React.Fragment>
      )));
      return false;
    }
    setFiles(uploads);
    setError(null);
    return true;
  };

  /* */
  const handleDelete = () => {
    setFiles([]);
  };

  /* */
  const handleSubmit = () => {
    if (files?.length) {
      setUploading(true);
      handleUpload(files)
        .finally(() => setUploading(false));
    }
  };

  /* */
  const renderContent = () => {
    if (files?.length) {
      return files.map((file, i) => (
        <Chip
          key={i}
          color="secondary"
          onDelete={handleDelete}
          className={classes.chip}
          label={`${files[0].name} (${helpers.string.humanFileSize(files[0].size)})`}
          icon={<DescriptionIcon />}
        />
      ));
    }

    return (
      <FileSelectArea
        multiple
        className={classes.dropzone}
        onSelectFiles={handleSelectFiles}
      >
        <UploadIcon className={classes.uploadIcon} />
        <Typography align='center'>
          {'Cliquez ou faites glisser le fichier dans cette zone'}
        </Typography>
      </FileSelectArea>
    );
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        {'Ajouter un document'}
        <IconButton
          color="inherit"
          aria-label="Fermer"
          onClick={handleClose}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography align='center' className={classes.error}>
          {error}
        </Typography>
        {renderContent()}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}>
          {'Annuler'}
        </Button>
        <Button
          color="secondary"
          variant="contained"
          disabled={uploading}
          onClick={handleSubmit}>
          {uploading
            ? <CircularProgress color="secondary" size={24} />
            : 'Envoyer'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(Upload);
