import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { App, Account } from '@aps-management/primapp-common';
import {
  Grid,
  Button,
  Checkbox,
  Typography,
  FormControl,
  CircularProgress,
  FormControlLabel,
} from '@material-ui/core';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';

/* */
class ChangeInterests extends React.Component {
  /* */
  state = {
    // Screen
    error: null,
    loading: true,
    success: null,
    // Data
    interests: [],
    selectedIds: [],
  };

  /* */
  componentDidMount() {
    this.load();
  }

  /* */
  load = () => {
    const { golf } = this.props;

    Promise.all([
      App.api.getGolfInterests(apolloClient, { id: golf.id }),
      Account.api.getInterests(apolloClient, { golfId: golf.id }),
    ])
      .then(([interests, selected]) => {
        this.setState({
          interests,
          selectedIds: selected.map(s => s.id),
        });
      })
      .catch(e => this.setState({ error: e.message }))
      .finally(() => this.setState({ loading: false }));
  }


  /* */
  onSubmit = (values, { setSubmitting }) => {
    const { golf } = this.props;

    this.setState({
      error: null,
      success: null,
    });
    Account.api.setInterests(apolloClient, {
      golfId: golf.id,
      interestIds: Object.keys(values).filter(k => values[k]),
    })
      .then(() => this.setState({ success: "Vos centres d'intérêts ont été enregistrés." }))
      .catch(error => this.setState({ error: error.message }))
      .finally(() => setSubmitting(false));
  };

  /* */
  renderForm() {
    const { interests, selectedIds } = this.state;

    if (interests.length === 0) {
      return (
        <Typography variant='h5' align="center">
          {"Aucun centre d'intérêt."}
        </Typography>
      );
    }

    const initialValues = interests.reduce((acc, i) => {
      acc[i.id] = selectedIds.includes(i.id);
      return acc;
    }, {});

    const checkboxes = [[], []];
    interests.forEach((interest, index) => {
      checkboxes[(index % 2)].push(interest);
    });

    return (
      <Formik
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        render={({
          values,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={40}>
              {checkboxes.map((inte, k) => (
                <Grid item key={k} md={6} xs={12}>
                  <FormControl
                    fullWidth
                    component="fieldset">
                    {inte.map(i => (
                      <FormControlLabel
                        key={i.id}
                        label={
                          <Typography variant="body1">
                            {i.name}
                          </Typography>
                        }
                        control={
                          <Checkbox
                            name={String(i.id)}
                            onChange={handleChange}
                            checked={values[i.id]} />
                        }
                      />
                    ))}
                  </FormControl>
                </Grid>
              ))}
            </Grid>
            <br />
            <Button
              fullWidth
              size="large"
              color="secondary"
              variant="contained"
              disabled={isSubmitting}
              onClick={handleSubmit}>
              {isSubmitting
                ? <CircularProgress color="inherit" size={24} />
                : 'Sauvegarder'}
             </Button>
          </form>
        )} />
    );
  }

  /* */
  render() {
    const { error, loading, success } = this.state;

    return (
      <Screen
        error={error}
        loading={loading}
        success={success}
        title="Mes centres d'intérêt"
        onBackPress={() => this.props.history.goBack()}>
        {this.renderForm()}
      </Screen>
    );
  }
}

const mapStateToProps = ({ app }) => ({ golf: app.golf });

export default connect(mapStateToProps)(ChangeInterests);
