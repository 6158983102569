import React, { Fragment } from 'react';
import moment from 'moment';
import Icon from '@mdi/react';
import { connect } from 'react-redux';
import { mdiCardAccountDetails } from '@mdi/js';
import { App, Primaccess } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  Tab,
  Grid,
  Tabs,
  List,
  Paper,
  Button,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
/* */
import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import { getAuthorization } from '_utils/security';
/* */
import SearchMoves from './SearchMoves';
import ChooseProduct from './ChooseProduct';
import SearchOperations from './SearchOperations';

/* */
const styles = theme => ({
  balancePaper: {
    padding: 16,
    marginBottom: 12,
  },
  dialogPaper: {
    backgroundColor: theme.palette.background.default,
  },
});

const ROWS_PER_PAGE = 16;

/* */
class PrimaccessView extends React.Component {
  /* */
  state = {
    // Screen
    error: null,
    loading: true,
    // Data
    tabIndex: 0,
    modal: false,
    client: null,
    balance: null,
    movesTabFocus: false,
  };

  /* */
  componentDidMount() {
    this.load();
  }

  /* */
  load = () => {
    const { golf, account } = this.props;
    const match = App.functions.match(account, golf);

    if (match) {
      Primaccess.api.getClient(apolloClient, { golfId: golf.id })
        .then((res) => {
          if (res) {
            const { balance, ...client } = res;
            this.setState({ client, balance });
            this.props.update({ clientId: client.id });
          }
        })
        .catch(error => this.setState({ error }))
        .finally(() => this.setState({ loading: false }));
    } else {
      this.setState({ loading: false });
    }
  };

  /* */
  isAuthorized(path) {
    const { golf, account } = this.props;
    const { isAuthorized } = getAuthorization(path, { golf, account });

    return isAuthorized;
  }

  /* */
  handleSwitchModal = () => {
    this.setState(prevState => ({ modal: !prevState.modal }));
  };

  /* */
  handleTabChange = (e, tabIndex) => {
    const newState = { tabIndex };
    if (tabIndex === 1) { newState.movesTabFocus = true; }
    this.setState(newState);
  }

  /* */
  refreshBalance = balance => this.setState({ balance });

  /* */
  renderTabs() {
    const { tabIndex, movesTabFocus } = this.state;

    const movesTabDisplay = tabIndex === 1 ? 'block' : 'none';
    const operationsTabDisplay = tabIndex === 0 ? 'block' : 'none';

    return (
      <Grid item md={8} sm={12} xs={10}>
        <Tabs
          centered
          value={tabIndex}
          textColor="primary"
          indicatorColor="primary"
          onChange={this.handleTabChange}>
          <Tab label="Transactions" />
          <Tab label="Passages" />
        </Tabs>
        <br />
        <Box style={{ display: operationsTabDisplay }}>
          <SearchOperations
            rowsPerPage={ROWS_PER_PAGE}
            onRefresh={this.refreshBalance} />
        </Box>
        <Box style={{ display: movesTabDisplay }}>
          <SearchMoves
            isFocused={movesTabFocus}
            rowsPerPage={ROWS_PER_PAGE} />
        </Box>
      </Grid>
    );
  }

  /* */
  renderBalance() {
    const { balance } = this.state;
    const { classes, history } = this.props;

    if (balance === null) return null;

    return (
      <Fragment>
        <Typography
          gutterBottom
          variant="h6"
          component='h2'>
          {'Mon solde'}
        </Typography>
        <Paper className={classes.balancePaper}>
          <Typography
            variant="h4"
            align="center"
            component='span'>
            {i18n.l('number', balance)}
          </Typography>
        </Paper>
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          onClick={this.handleSwitchModal}>
          {'Recharger'}
        </Button>
        {this.isAuthorized('/primaccess/autorefill') && (
          <Button
            fullWidth
            color="secondary"
            variant="outlined"
            style={{ marginTop: 12 }}
            onClick={() => history.push('/primaccess/autorefill')}>
            {'Rechargement automatique'}
          </Button>
        )}
        <br />
      </Fragment>
    );
  }

  /* */
  renderBadges() {
    const { client } = this.state;

    return (
      <Fragment>
        <br />
        <Typography
          gutterBottom
          variant="h6"
          component='h2'>
          {'Mes badges'}
        </Typography>
        {client.badges.length > 0 && (
          <Paper>
            <List disablePadding>
              {client.badges.map(b => (
                <ListItem divider key={b.id}>
                  <ListItemIcon>
                    <Icon path={mdiCardAccountDetails} size={1} />
                  </ListItemIcon>
                  <ListItemText
                    primary={`#${b.id}`}
                    secondary={`Attribué le ${moment(b.startDate).format('L')}`} />
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
        {client.badges.length === 0
          && <Typography>{'Aucun badge attribué.'}</Typography>}
        <br />
        {client.isSU && (
          <Typography
            gutterBottom
            component="span"
            variant="h6">
            {'Super Utilisateur'}
          </Typography>
        )}
        {client.expiredAt && (
          <Typography
            gutterBottom
            component="span"
            variant="h6">
            {`Expire le ${moment(client.expiredAt).format('L')}`}
          </Typography>
        )}
      </Fragment>
    );
  }

  /* */
  renderClientData() {
    const { error, client } = this.state;

    if (!client && error) return null;

    if (!client && !error) {
      return (
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column">
          <Icon path={mdiCardAccountDetails} size={6} />
          <Typography variant="subtitle1">
            {"Vous n'utilisez pas ce service actuellement."}
          </Typography>
          <Typography variant="subtitle1">
            {'Veuillez vous rapprocher de votre golf.'}
          </Typography>
        </Box>
      );
    }

    return (
      <Grid container justify="center" spacing={40}>
        <Grid item md={4} sm={8} xs={10}>
          {this.renderBalance()}
          {this.renderBadges()}
        </Grid>
        {this.renderTabs()}
      </Grid>
    );
  }

  /* */
  renderProductsModal() {
    const { client, modal } = this.state;
    const { history } = this.props; // follow router-dom

    if (!client) return null;

    return (
      <ChooseProduct
        history={history}
        open={modal}
        handleSwitchModal={this.handleSwitchModal} />
    );
  }

  /* */
  render() {
    const { error, loading } = this.state;

    return (
      <Screen
        error={error}
        loading={loading}
        title="Practice">
        {this.renderClientData()}
        {this.renderProductsModal()}
      </Screen>
    );
  }
}

const mapStateToProps = ({ app: { golf, account } }) => ({ golf, account });

const StyledComponent = withStyles(styles)(PrimaccessView);

export default connect(
  mapStateToProps,
  Primaccess.actions,
)(StyledComponent);
