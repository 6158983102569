import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Typography } from "@material-ui/core";
import i18n from "_utils/i18n";
import { useContextSelector } from "@fluentui/react-context-selector";
import PublicBookingContext from "./services/publicBookingService";
import { unstable_Box as Box } from "@material-ui/core/Box";

/* */
const styles = (theme) => ({
  headerTitle: {
    position: "relative",
    gridArea: "title",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: -theme.spacing.unit * 2,
  },
  headerTitleTypography: {
    textAlign: "center",
    fontWeight: 500,
    fontSize: "calc(16px + 0.390625vw)",
  },
  tabsRoot: {
    gridArea: "header",
    display: "flex",
    minHeight: "35px",
    alignItems: "flex-end",
    marginBottom: -theme.spacing.unit * 2,
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    height: "100%",
  },
  tabsIndicator: {
    backgroundColor: theme.palette.primary.white,
    opacity: 0.3,
  },
  tabsFlexContainer: {
    maxHeight: "35px",
  },
  tabLabel: {
    padding: "0 24px",
    fontWeight: 500,
  },
  tabSelected: {
    position: "relative",
  },
  tabsScroller: {
    maxHeight: "35px",
    overflowY: "hidden",
  },
  tabRoot: {
    display: "flex",
    backgroundColor: theme.palette.common.white,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    paddingTop: 0,
    textTransform: "initial",
    minWidth: 72,
    minHeight: "35px",
    maxHeight: "35px",
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing.unit,
    fontSize: "calc(6px + 0.390625vw)",
    opacity: 0.8,
    color: "#1e1e1e",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#000000",
      opacity: 1,
    },
    "&$tabSelected": {
      color: "#000000",
      fontWeight: theme.typography.fontWeightMedium,
      opacity: 1,
    },
    "&:focus": {
      color: "#000000",
      opacity: 1,
    },
  },
});

const PublicBookingHeader = ({ golf, classes }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const {
    data: { courses },
    setPlayers,
    setPlayerCount,
    setSelectedCourse,
  } = useContextSelector(PublicBookingContext, (c) => c);
  return (
    <>
      <Box className={classes.headerTitle}>
        <Typography
          variant="h6"
          color="primary"
          className={classes.headerTitleTypography}
        >
          {golf && golf.name}
        </Typography>
      </Box>
      <Tabs
        value={tabIndex}
        style={{ width: "100%" }}
        classes={{
          root: classes.tabsRoot,
          indicator: classes.tabsIndicator,
          scroller: classes.tabsScroller,
          flexContainer: classes.tabsFlexContainer,
        }}
        onChange={(e, value) => {
          setTabIndex(value);
          setPlayerCount(1);
          setPlayers(["UNKNOWN"]);
          setSelectedCourse(courses[value]);
        }}
      >
        {courses &&
          courses?.map((p, i) => (
            <Tab
              key={`parcours-${p.id}-${p.type}`}
              disableRipple
              classes={{
                root: classes.tabRoot,
                labelContainer: classes.tabLabel,
                selected: classes.tabSelected,
              }}
              label={`${p.name} (${i18n.t(`terms.course_type_${p.type}`)})`}
            />
          ))}
      </Tabs>
    </>
  );
};
/* */
const mapStateToProps = ({ app: { golf } }) => ({
  golf,
});

export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(PublicBookingHeader))
);
