
import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
    Paper,
    Grid,
    Checkbox,
    Typography,
    FormControlLabel,
} from "@material-ui/core";
import { unstable_Box as Box } from "@material-ui/core/Box";
import { useScaling } from "_utils/scalingUtil";
import PublicBookingDateSelector from "./PublicBookingDateSelector";
import PublicBookingPlayerSelector from "./PublicBookingPlayerSelector";
import PublicBookingList from "./PublicBookingList";

/* */
const styles = (theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      gridArea: "main",
      maxHeight: `calc(100% - ${theme.spacing.unit * 2}px)`,
    },
    zoneOptions: {
      gridArea: "main",
      width: `calc(100% - ${theme.spacing.unit * 4}px)`,
      height: `calc(100% - ${theme.spacing.unit * 4}px)`,
      margin: "auto",
      marginTop: theme.spacing.unit * 2,
      display: "grid",
      gridTemplateColumns: "1fr",
      gridTemplateRows: "auto 1fr",
      gridTemplateAreas: `
        "options"
        "content"`,
    },
    label: {
      margin: 0,
      fontSize: "calc(8px + 0.390625vw)",
    },
    formControl: {
      maxHeight: "35px",
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
    },
  });

const PublicBookingOptions = ({ classes }) => {
  const { isMobileView } = useScaling();
  const [discountOnly, setDiscountOnly] = useState(false);
    return (
        <Paper className={classes.paper} style={{ marginLeft: isMobileView ? 0 : 32, marginRight: isMobileView ? 0 : 32 }}>
          <Grid className={classes.zoneOptions}>
              <Grid
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                  gridTemplateRows: "repeat(auto-fill, 1fr)repeat(auto-fill, 1fr)",
                  gridAutoFlow: "dense",
                }}
              >
                <Box>
                  <PublicBookingDateSelector />
                </Box>
                <Box>
                  <PublicBookingPlayerSelector />
                </Box>
                <Box>
                <Box
                  style={{
                    gridArea: "options",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "0 16px 16px 16px",
                }}
                >
                  <Typography
                  color="secondary"
                  variant="body2"
                  style={{ marginBottom: 8, fontSize: "calc(8px + 0.390625vw)", fontWeight: 500 }}
                  paragraph
                  >
                  {"Affichage :"}
                  </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={discountOnly}
                      onChange={(e) => setDiscountOnly(e.target.checked)}
                      value="checkedG"
                      classes={{
                        root: classes.root,
                      }}
                    />
                  }
                  classes={{ label: classes.label, root: classes.formControl }}
                  label="Départs remisés seulement"
                />
                </Box>
                </Box>
              </Grid>
            <Grid
              style={{
                gridArea: "content",
                borderRadius: 5,
                marginLeft: -12,
                marginRight: -12,
                marginBottom: -8,
                position: "relative",
              }}
            >
              <PublicBookingList discountOnly={discountOnly} />
            </Grid>
          </Grid>
        </Paper>
    );
}

export default withStyles(styles)(PublicBookingOptions);
