import React from 'react';
import { connect } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
//
import * as Screens from '_screens';
import defaultTheme from '_config/defaultTheme.json';
//
import Bootstrap from './Bootstrap';

// Create a client
const queryClient = new QueryClient();
/* */
class App extends React.Component {
  /* */
  render() {
    const { options } = this.props;

    const theme = (options && options.themeMUI) || defaultTheme;
    if (window.innerWidth <= 480) {
      theme.typography.fontSize = (theme.typography.fontSize || 14) * 0.8;
    }

    const muiTheme = createMuiTheme({
      ...theme,
      typography: {
        useNextVariants: true,
        fontFamily: "'Montserrat', system-ui, sans-serif",
      },
    });
    // muiTheme.breakpoints.values.md = 840; // VT Design iFrame maxWidth (Saint-Cloud)

    return (
      <QueryClientProvider client={queryClient}>
        <MuiThemeProvider theme={muiTheme}>
          <SnackbarProvider maxSnack={3}>
            <BrowserRouter>
              <Switch>
                <Route exact path="/privacy-policy" component={Screens.Public.PrivacyPolicy} />
                <Route exact path="/terms-conditions" component={Screens.Public.TermsAndConditions} />
                <Route exact path="/reset-password/:hash" component={Screens.Public.ResetPassword} />
                <Route exact path="/registration/:hash" component={Screens.Public.ResetPassword} />
                <Route exact path="/activation/:hash" component={Screens.Public.Activation} />
                <Route exact path="/membership/confirm" component={Screens.Public.Membership} />
                <Route exact path="/membership/confirm/:reference" component={Screens.Public.ConfirmMembership} />
                <Route exact path="/membership/pay/:reference" component={Screens.Public.PayMembership} />
                <Route exact path="/membership/renew" component={Screens.Public.RenewMembership} />
                <Route exact path="/primaccess/refill" component={Screens.Public.AutomaticRefill} />
                <Route exact path="/credit-card/update" component={Screens.Public.ChangeCreditCard} />
                <Route exact path="/3ds" component={Screens.ThreeDS.SubmitForm} />
                <Route exact path="/3ds/response" component={Screens.ThreeDS.Response} />
                <Route path='/:slug' component={Bootstrap} />
                <Route component={Screens.Public.NotFound} />
              </Switch>
              <ReactQueryDevtools initialIsOpen={false} />
            </BrowserRouter>
          </SnackbarProvider>
        </MuiThemeProvider>
      </QueryClientProvider>
    );
  }
}

const mapStateToProps = ({ app: { golf } }) => ({ ...golf });

export default connect(mapStateToProps)(App);
