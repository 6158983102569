import React from 'react';
import * as qs from 'query-string';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { App, Account } from '@aps-management/primapp-common';
/* */
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';

/* */
class CreateGuest extends React.PureComponent {
  /* */
  constructor(props) {
    super(props);

    this.state = {
      // Screen
      error: null,
      loading: true,
    };

    const { redirect = null } = qs.parse(props.location.search);
    this.redirectRoute = redirect || '/home';

    const { golf, account } = props;
    const match = App.functions.match(account, golf);

    // check redirect
    this.redirect = false;
    if (match && match.reference) {
      this.redirect = true;
    }
  }

  /* */
  componentDidMount = () => {
    const { golf, account } = this.props;

    if (this.redirect) return;

    this.setState({ error: null });

    Account.api.matchInGolf(apolloClient, { golfId: golf.id })
      .then(() => this.props.loadAccount(apolloClient, { id: account.id }))
      .then(() => this.props.history.replace(this.redirectRoute))
      .catch(e => this.setState({ error: e.message, loading: false }));
  }

  /* */
  render() {
    if (this.redirect) { return <Redirect to={this.redirectRoute} />; }

    const { golf } = this.props;
    const { error, loading } = this.state;

    return (
      <Screen
        error={error}
        loading={loading}
        title={golf.name}>
      </Screen>
    );
  }
}

const mapStateToProps = ({ app }) => ({
  golf: app.golf,
  account: app.account,
});

export default connect(
  mapStateToProps,
  App.actions,
)(CreateGuest);
