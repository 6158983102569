/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect } from 'react';
import {
  Redirect,
  useParams,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import {
  Academy,
  App,
  Event,
  Directory,
} from '@aps-management/primapp-common';
import masterClient from '_utils/masterClient';
import { useQuery } from '@tanstack/react-query';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Typography,
} from '@material-ui/core';
import { unstable_Box as Box } from "@material-ui/core/Box";
import { Screen } from '_components/core';
import AcademyChoosePartners from '_components/AcademyChoosePartners';
import { Alert, Incrementer } from '_components/elements';
/* */
import Header from '../_Header';
import RenderChip from './RenderChip';

/* */
const styles = (theme) => ({
  chip: {
    marginTop: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 1,
  },
  labelMore: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing.unit * 2,
  },
});

/* */
const SetPlayers = (props) => {
  const commentInput = React.useRef();
  const history = useHistory();
  const {
    golf,
    account,
    enter,
    classes,
  } = props;
  const { entrants, lesson: lessonData } = enter;
  const [isLoading, setIsLoading] = React.useState(true);
  const [openPartners, setOpenPartners] = React.useState(false);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [slotId, setSlotId] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [listExceededQuotaPlayers, setListExceededQuotaPlayers] = React.useState([]);
  const { id } = useParams();
  const matchRoute = useRouteMatch('/academy/:id/individual');

  useEffect(() => {
    setListExceededQuotaPlayers((prev) => prev.filter((p) => entrants.map(e => e.id).includes(p.id)));
  }, [entrants, setListExceededQuotaPlayers]);
  /* */
  const goToFees = () => {
    props.enter.status.push('entrants');
    history.push(`/academy/${id}/fees`);
  };

  const number = entrants.length;
  const { maxPlayers } = {
    ...golf?.options?.academy?.onlineRestrictions?.global,
    ...matchRoute
      ? golf?.options?.academy?.onlineRestrictions?.individual
      : golf?.options?.academy?.onlineRestrictions?.group,
  } || 1;
  const { isLoading: lessonLoading, data: lesson } = useQuery(
    ['academy-lesson', id],
    matchRoute ? () => lessonData : () => Academy.api.getLesson(masterClient, {
      id,
    }),
    {
      enabled: !!id,
      retry: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const {
          id: accountId,
          ffg,
          email,
          phone,
          lastname,
          firstname,
        } = account;
        const match = App.functions.match(account, golf) || { isMember: false, reference: 'UNKNOWN' };
        props.initialize({ lesson: data, status: ['init'] });
        props.addEntrant({
          id: accountId,
          email,
          phone,
          lastname,
          firstname,
          dateOfBirth: null,
          gender: null,
          type: 'owner',
          isMember: match.isMember,
          reference: match.reference,
          licenseNumber: ffg.license || null,
        });
        setIsLoading(false);
        return data;
      },
      initialData: () => {},
      onError: (err) => {
        if (err.message.includes('data is undefined')) {
          return history.push('/academy');
        }
        return setError(err);
      },
    },
  );
  // Check tournament is opened
  if (!matchRoute && lesson) {
    const isOpened = Academy.functions
      .isOpened({ ...lesson, openOnline: true }, golf?.options);
    if (!isOpened) {
      return <Redirect to={'/academy'} />;
    }
  }

  /* */
  const handleIncrease = () => {
    props.addEntrant({
      id: null,
      email: null,
      phone: null,
      lastname: null,
      firstname: null,
      isMember: false,
      type: 'anonymous',
      reference: 'UNKNOWN',
    });
  };

  /* */
  const handleDecrease = () => {
    props.adjustEntrants(entrants.length - 1);
  };

  /* */
  const handleDeleteEntrant = (i) => {
    props.replaceEntrant({
      id: null,
      email: null,
      phone: null,
      lastname: null,
      firstname: null,
      isMember: false,
      type: 'anonymous',
      reference: 'UNKNOWN',
    }, i);
  };
  /* */
  const renderMorePlayers = () => entrants.map((e, i) => <RenderChip index={i} classes={classes} key={i} entrant={e} handleDeleteEntrant={handleDeleteEntrant} setListExceededQuotaPlayers={setListExceededQuotaPlayers} setOpenPartners={setOpenPartners} matchRoute={matchRoute} />);

  /* */
  const renderPlayers = () => {
    let alertMessage;

    if (maxPlayers === number) {
      alertMessage = 'Vous avez atteint le nombre de participants maximum autorisé.';
    }
    return (
      <Fragment>
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={6}>
            <Typography variant="body1">
              {'Combien serez-vous ?'}
            </Typography>
            <br />
            <Incrementer
              minValue={1}
              value={number}
              maxValue={maxPlayers}
              onDecrease={handleDecrease}
              onIncrease={handleIncrease} />
            <br />
            {renderMorePlayers()}
          </Grid>
          <Grid item xs={12} md={12} sm={12}>
            {alertMessage && (
              <Alert variant="info">{alertMessage}</Alert>
            )}
            {listExceededQuotaPlayers.length > 0 && (
              <Alert variant="warning" as={Box}>
                <Box>
                  {listExceededQuotaPlayers?.map((entrant, i) => entrant.id === account.id ? (
                    <Typography style={{ color: 'white' }} key={i}>{'Vous avez dépassé le nombre de réservations autorisées.'}</Typography>
                  ) : (
                    <Typography style={{ color: 'white' }} key={i}>{`${entrant.firstname} ${entrant.lastname} a dépassé le nombre de réservations autorisées.`}</Typography>
                  ))}
                </Box>
              </Alert>
            )}
          </Grid>
        </Grid>
        <br />
      </Fragment>
    );
  };
  /* */
  const renderPartnersDialog = () => (
      <AcademyChoosePartners
        partners={entrants}
        open={openPartners}
        onClose={() => setOpenPartners(false)}
        replacePartner={props.replaceEntrant} />
  );

  /* */
  const onNext = () => {
    if (entrants.some((e) => e.type === 'anonymous')) {
      setError('Veuillez renseigner les informations des participants anonymes');
      return setTimeout(() => {
        setError(null);
      }, 300);
    }
    // hydrate entrants
    if (slotId) {
      const slot = lesson.slots.find((s) => String(s.id) === slotId);
      entrants.forEach((e) => {
        e.timeslot = slot;
      });
    }

    return goToFees();
  };

  /* */
  const renderNextButton = () => {
    return (
      <Button
        fullWidth
        size="large"
        color="secondary"
        variant="contained"
        onClick={onNext}
        disabled={listExceededQuotaPlayers.length > 0 || buttonDisabled}>
        {'Continuer'}
      </Button>
    );
  }
  const loading = lessonLoading || isLoading;
  return (
    <Screen
      error={error}
      loading={loading}
      title="Académie - Choix des inscrits">
      {lesson && (
        <Fragment>
          <Header lesson={lesson} golf={golf} />
          {renderPlayers()}
          {renderNextButton()}
          {renderPartnersDialog()}
        </Fragment>
      )}
    </Screen>
  );
};

const mapStateToProps = ({ app, eventEnter }) => ({
  golf: app.golf,
  enter: eventEnter,
  account: app.account,
});
const mapDispatchToProps = { ...App.actions, ...Event.actions, ...Directory.actions };

const styledComponent = withStyles(styles)(SetPlayers);

export default connect(mapStateToProps, mapDispatchToProps)(styledComponent);
