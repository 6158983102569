import React, { Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { App, Event } from '@aps-management/primapp-common';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  List,
  Paper,
  Button,
  ListItem,
  Typography,
  ListItemText,
  CircularProgress,
} from '@material-ui/core';
/* */
import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import PaymentMethods from '_components/PaymentMethods';
/* */
import Header from '../_Header';

/* */
const styles = theme => ({
  section: {
    margin: `${theme.spacing.unit * 1}px 0`,
  },
  subtotal: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
});

/* */
class TournamentCart extends React.Component {
  /* */
  constructor(props) {
    super(props);

    const {
      account,
      enter,
      golf,
      match: { params },
    } = props;

    this.id = params.id;
    this.redirect = false;

    if (!enter.status.includes('init')) {
      this.redirect = true;
    }

    // payment methods
    this.paymentMethods = App.functions.getPaymentMethods(account, golf, 'event');
    const paymentMethod = this.paymentMethods[0];

    this.state = {
      // UI
      error: null,
      loading: true,
      // Moyens de paiment / CGV
      paymentMethod,
      paymentMethodOpts: null,
      areAcceptedTerms: false,
      // Data
      waiting: false,
      getEntryFees: false,
    };
  }

  /* */
  componentDidMount() {
    if (!this.redirect) this.load();
  }

  /* */
  goToPayment = () => {
    this.propagatePaymentMethod();
    this.props.enter.status.push('cart');
    this.props.history.push(`/tournament/${this.id}/payment`);
  }

  /* */
  goToEvent = index => () => this.props.history.push(`/tournament/${this.id}/event/${index}`);

  /* */
  goToVoucher = () => {
    this.props.enter.status.push('payment');
    this.props.history.push(`/tournament/${this.id}/voucher`);
  }

  /* */
  propagatePaymentMethod() {
    const { paymentMethod } = this.state;
    const { enter: { entrants } } = this.props;

    // set payment method
    entrants.forEach((e, i) => {
      entrants[i].tournament.type = paymentMethod;
    });
  }

  /* */
  onConfirm = () => {
    const { enter, golf } = this.props;
    const { paymentMethod, paymentMethodOpts } = this.state;

    this.setState({ error: null, waiting: true });

    this.propagatePaymentMethod();

    const transaction = {
      amount: enter.total.tournament,
      mode: paymentMethod,
      ...(paymentMethod === 2 && { walletId: paymentMethodOpts.wallet.id }),
    };

    return Event.functions.asyncSetEntry(apolloClient, {
      enter,
      golf,
      transaction,
    })
      .then(() => {
        enter.transaction = transaction;
        this.goToVoucher();
      })
      .catch((err) => {
        this.setState({ error: err.message, waiting: false });
      });
  }

  /* */
  load() {
    const { enter, golf } = this.props;
    const { entrants, tournament } = enter;

    // Reset state
    this.setState({
      error: null,
      loading: true,
      getEntryFees: false,
    });

    const params = {
      golfId: golf.id,
      players: entrants.map(e => e.reference),
      tournamentId: tournament.id,
    };

    Event.api.getEntryFees(apolloClient, params)
      .then(({ fees }) => {
        // critical error
        if (!fees || !fees.length) throw new Error('errors.internal');

        // build fees object by player ref
        const feesByRef = fees.reduce((acc, { player: reference, ...f }) => {
          if (typeof acc[reference] === 'undefined') {
            acc[reference] = f;
          }
          return acc;
        }, {});

        // list events with options
        enter.events = fees[0].events.map(({ options, ...event }) => ({
          ...event,
          options: options.map(o => ({
            fees: [],
            id: o.id,
            name: o.name,
          })),
          hasManyOptions: options.length > 1,
        }));

        let tournamentFees = 0;

        // hydrate entrants
        entrants.forEach((e) => {
          const { entryFee, greenFee, events } = feesByRef[e.reference];

          // hydrate events with options player fees
          events.forEach((ev, i) => {
            enter.events[i].options.forEach((opt, j) => opt.fees.push(ev.options[j].amount));
          });

          e.events = []; // no event options selected yet
          e.tournament = {
            type: 0, // payment method (0: immediate, 1: deferred)
            entryFee,
            greenFee,
            slot: (e.timeslot && {
              id: e.timeslot.id,
              name: e.timeslot.name,
            }) || null, // timeslot selected
            id: tournament.id,
            name: tournament.name,
            cancelableUntil: tournament.cancelableUntil,
          };

          tournamentFees += entryFee + greenFee;
        });

        enter.total = { tournament: tournamentFees, events: 0 };

        this.setState({ getEntryFees: true });
      })
      .catch(e => this.setState({ error: e.message }))
      .finally(() => this.setState({ loading: false }));
  }

  /* */
  handleChangePaymentMethod = (type, opts) => {
    this.setState({
      paymentMethod: type,
      paymentMethodOpts: opts,
    });
  }

  /* */
  handleChangeAcceptTerms = checked => this.setState({ areAcceptedTerms: checked });

  /* */
  renderPaymentMethod() {
    const { classes, enter: { total, tournament } } = this.props;
    const { waiting, paymentMethod, areAcceptedTerms } = this.state;

    const totalAmount = total.tournament + total.events;
    const buttonTxt = `${(paymentMethod === 1 ? 'Valider' : 'Payer')}`
      + ` ${i18n.l('currency', totalAmount / 100)}`;
    const cancelableUntil = tournament.cancelableUntil
      && moment(tournament.cancelableUntil, 'YYYY-MM-DD HH:mm');

    return (
      <Grid
        container
        spacing={24}
        justify="center">
        <Grid
          item md={6} xs={12}
          className={classes.section}>
          <Typography
            paragraph
            component="h3"
            variant="h6">
            {'Votre moyen de paiement'}
          </Typography>
          <PaymentMethods
            amount={totalAmount}
            selected={paymentMethod}
            methods={this.paymentMethods}
            onChange={this.handleChangePaymentMethod}
            onChangeAcceptTerms={this.handleChangeAcceptTerms}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          className={classes.section}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}>
          {cancelableUntil && (
            <Typography gutterBottom variant="caption" align="center">
              {`L'inscription est annulable jusqu'au ${cancelableUntil.format('DD/MM/YYYY')} à ${cancelableUntil.format('HH:mm')}.`}
            </Typography>
          )}
          <Button
            fullWidth
            size="large"
            color="secondary"
            variant="contained"
            disabled={waiting || !areAcceptedTerms}
            onClick={paymentMethod === 0 ? this.goToPayment : this.onConfirm}>
            {waiting ? <CircularProgress color="inherit" size={24} /> : buttonTxt}
          </Button>
        </Grid>
      </Grid>
    );
  }

  /* */
  renderNextButton() {
    const { getEntryFees, waiting } = this.state;
    const { enter: { events, total } } = this.props;

    if (!getEntryFees) return null;

    let label;
    let onClick;
    const amount = total.tournament;
    const hasEvents = events.length;

    if (!hasEvents && amount > 0) return this.renderPaymentMethod();

    if (hasEvents) {
      label = 'Continuer';
      onClick = this.goToEvent(0);
    } else {
      label = 'Confirmer';
      onClick = this.onConfirm;
    }

    return (
      <Button
        fullWidth
        size="large"
        color="secondary"
        onClick={onClick}
        disabled={waiting}
        variant="contained">
        {waiting ? <CircularProgress color="inherit" size={24} /> : label}
      </Button>
    );
  }

  /* */
  render() {
    if (this.redirect) {
      return <Redirect to={`/tournament/${this.id}`} />;
    }

    const { error, loading, getEntryFees } = this.state;
    const {
      classes,
      enter: {
        total,
        events,
        entrants,
        tournament,
      },
    } = this.props;

    const hasEvents = events.length;
    const timeslot = (entrants && entrants[0] && entrants[0].timeslot) || null;

    return (
      <Screen
        error={error}
        loading={loading}
        title="Compétitions - S'inscrire"
        onBackPress={() => this.props.history.goBack()}>
        <Header tournament={tournament} />
        {timeslot && (
          <Fragment>
            <Typography variant="body1">
              {'Informations'}
            </Typography>
            <br />
            <Paper>
              <List disablePadding>
                <ListItem>
                  <ListItemText>
                    {'Créneau horaire'}
                  </ListItemText>
                  <ListItemText primaryTypographyProps={{ align: 'right' }}>
                    {timeslot.name}
                  </ListItemText>
                </ListItem>
              </List>
            </Paper>
            <br /><br/>
          </Fragment>
        )}
        {getEntryFees && (
          <Fragment>
            <Typography variant="h6">
              {'Frais d\'inscription'}
            </Typography>
            <br />
            <Paper>
              <List disablePadding>
                {entrants.map(({ firstname, lastname, tournament: fees }, i) => {
                  const subtotal = fees.greenFee + fees.entryFee;

                  return (
                    <ListItem key={i} divider>
                      <ListItemText
                        primary={`${firstname} ${lastname}`}
                        secondary={
                          <Fragment>
                            {'- Droit de jeu'}<br />
                            {'- Green-fee'}
                          </Fragment>
                        } />
                      <ListItemText
                        primary={subtotal === 0
                          ? i18n.t('terms.included')
                          : i18n.l('currency', subtotal / 100)}
                        secondary={
                          <Fragment>
                            {fees.entryFee === 0
                              ? i18n.t('terms.included')
                              : i18n.l('currency', fees.entryFee / 100)}
                            <br />
                              {fees.greenFee === 0
                                ? i18n.t('terms.included')
                                : i18n.l('currency', fees.greenFee / 100)}
                          </Fragment>
                        }
                        primaryTypographyProps={{ align: 'right' }}
                        secondaryTypographyProps={{ align: 'right' }} />
                    </ListItem>
                  );
                })}
                <ListItem className={classes.subtotal}>
                  <ListItemText
                    primary={hasEvents ? 'Sous-total' : 'Total'}
                    primaryTypographyProps={{ color: 'inherit' }} />
                  <Typography
                    variant="subtitle1"
                    color="inherit">
                    {i18n.l('currency', total.tournament / 100)}
                  </Typography>
                </ListItem>
              </List>
            </Paper>
            <br />
            <br />
            {this.renderNextButton()}
          </Fragment>
        )}
      </Screen>
    );
  }
}

const mapStateToProps = ({
  app: {
    account,
    golf,
  },
  eventEnter: enter,
}) => ({ account, golf, enter });

const StyledComponent = withStyles(styles)(TournamentCart);

export default connect(
  mapStateToProps,
  null,
)(StyledComponent);
