/* eslint global-require: 0 */
import _ from 'lodash/fp/object';
import config from './env/config.json';

/* */
function envConfig() {
  if (process.env.NODE_ENV === 'development') {
    return require('./env/config_dev.json');
  }
  return {};
}

const conf = _.defaultsDeep(config, envConfig());
global.firebase = conf.firebase;
global.apiBasePath = conf.apiBasePath;
global.masterBasePath = conf.masterBasePath;
global.booker = 'WEB';
// global.origin = 'golf-slug';
global.apiVersion = 1.0;

export default conf;
