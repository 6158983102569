import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  withWidth,
} from '@material-ui/core';
import {
  App,
  HELP_URL,
} from '@aps-management/primapp-common';

/* */
import { Screen } from '_components/core';
import { AcademySelector } from '_components/elements';

import IndividualLesson from './IndividualLesson';
import GroupLesson from './GroupLesson';


/* */
const styles = theme => ({
  titleButton: {
    marginLeft: theme.spacing.unit * 2,
  },
  optionContainer: {
    alignItems: 'center',
    color: theme.palette.grey[500],
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
  },
  optionContent: {
    flex: 1,
    marginRight: theme.spacing.unit * 2,
    overflow: 'hidden',
  },
  optionValue: {
    color: theme.palette.text.secondary,
    lineHeight: 'inherit',
  },
});

/* */
class BookingSearch extends React.Component {
  /* */
  constructor(props) {
    super(props);

    const {
      account,
      golf,
    } = this.props;

    this.state = {
      error: null,
      loading: true,
      activeOption: 0,
      activity: 0,
    };

    this.match = App.functions.match(account, golf)
      || { isMember: false, reference: 'UNKNOWN' };
  }

  /* */
  componentDidMount() {
    this.load();
  }

  /* */
  load = () => {
    this.setState({ loading: false, activeOption: 0 });
  }

  /* */
  changeActivity = activity => {
    this.setState({ activity }, this.load);
  };

  /* */
  renderActivities() {
    const { activity: currentActivity } = this.state;

    return (
      <AcademySelector
        data={[{
          "name": "Cours Individuel",
          "mdIcon": "individual",
          "allowAccessories": false,
        }]}
        value={currentActivity}
        onChange={this.changeActivity}
      />
    );
  }

  /* */
  render() {
    const {
      error,
      loading,
      activity: currentActivity,
    } = this.state;
    const renderComponent = () => {
      switch (currentActivity) {
        case 1:
          return <IndividualLesson />;
        default:
          return <GroupLesson />;
      }
    }
    return (
      <Screen
        error={error}
        title="Académie"
        loading={loading}
        helpURL={`${HELP_URL}/academy`}
        onRetry={this.load}>
        {this.renderActivities()}
        {!loading && renderComponent()}
      </Screen>
    );
  }
}

/* */
const mapStateToProps = ({
  app,
}) => ({
  golf: app.golf,
  account: app.account,
});

const StyledComponent = withStyles(styles)(BookingSearch);

export default connect(
  mapStateToProps,
)(withWidth()(StyledComponent));
