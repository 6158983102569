import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { App, helpers, Primaccess } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  Paper,
  Button,
  Typography,
  CircularProgress,
  DialogTitle,
  Dialog,
  DialogContent,
} from '@material-ui/core';
/* */
import i18n from '_utils/i18n';
import apolloClient from '_utils/apolloClient';
import PaymentMethods from '_components/PaymentMethods';

/* */
const styles = theme => ({
  product: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing.unit * 1.5,
    marginBottom: theme.spacing.unit * 2,
  },
  productSelected: {
    boxShadow: '0px 1px 5px 1px rgba(0, 109, 183, 1)',
  },
  dialogPaper: {
    backgroundColor: theme.palette.background.default,
  },
});

/* */
class ChooseProduct extends React.Component {
  constructor(props) {
    super(props);
    const { account, golf } = props;

    // CB & compte client
    this.paymentMethods = App.functions.getPaymentMethods(account, golf, 'primaccess');
    const paymentMethod = this.paymentMethods[0];

    this.state = {
      // UI
      loading: false,
      selected: null,
      waiting: false,
      // Moyens de paiment / CGV
      paymentMethod,
      paymentMethodOpts: null,
      areAcceptedTerms: false,
    };
  }

  /* */
  componentDidMount() { this.load(); }

  /* */
  handleChangeAcceptTerms = checked => this.setState({ areAcceptedTerms: checked });

  /* */
  handleChangePaymentMethod = (type, opts) => this.setState({
    paymentMethod: type,
    paymentMethodOpts: opts,
  });

  /* */
  load = () => {
    const { golfId, products } = this.props;

    // cache products list in reducer
    if (!products || products.length === 0) {
      this.setState({ loading: true });

      Primaccess.api.getProducts(apolloClient, { golfId })
        .then((list) => {
          list.sort(({ price: a }, { price: b }) => a - b);
          this.props.update({ products: list });
        })
        .finally(() => this.setState({ loading: false }));
    }
    // reset cart
    this.props.update({ cart: [] });
  };

  /* */
  selectProduct = p => () => this.setState({ selected: p });

  /* */
  renderProduct = (p, k) => {
    const { classes } = this.props;
    const { selected } = this.state;

    const className = classNames(classes.product, {
      [classes.productSelected]: (selected === k),
    });

    return (
      <Paper
        key={k}
        className={className}
        onClick={this.selectProduct(k)}>
        <Typography>
          {p.name.toUpperCase()}
        </Typography>
        <Typography
          variant="h6"
          color="textSecondary">
          {i18n.l('currency', p.price / 100)}
        </Typography>
      </Paper>
    );
  }

  /* */
  renderPayButton() {
    const {
      selected,
      paymentMethod,
      areAcceptedTerms,
      waiting,
    } = this.state;
    const { products } = this.props;

    if (!products[selected]) return null;

    const buttonTxt = `Payer ${i18n.l('currency', products[selected].price / 100)}`;

    return (
      <>
        {this.renderProduct(products[selected], selected)}
        <PaymentMethods
          selected={paymentMethod}
          amount={products[selected].price}
          methods={this.paymentMethods}
          onChange={this.handleChangePaymentMethod}
          onChangeAcceptTerms={this.handleChangeAcceptTerms}
        />
        <Button
          fullWidth
          size="large"
          color="secondary"
          disabled={waiting || !areAcceptedTerms}
          variant="contained"
          onClick={this.goToNext}
        >
          {waiting ? <CircularProgress color="inherit" size={24} /> : buttonTxt}
        </Button>
      </>
    );
  }

  /* */
  goToNext = () => {
    const { paymentMethod } = this.state;

    // si paiment sur place ou par compte client
    if (paymentMethod === 2) {
      this.doPrimaccessRequest();
    } else {
      this.goToPayment();
    }
  }

  /* */
  doPrimaccessRequest = () => {
    const { selected, paymentMethodOpts } = this.state;
    const { golf, products, primaccess: { clientId } } = this.props;

    this.setState({ waiting: true });

    const transaction = {
      amount: products[selected].price,
      currency: golf.currency,
      walletId: paymentMethodOpts.wallet.id,
      mode: 2,
    };

    this.props.addProductToCart(products[selected]);

    return Primaccess.api.createOperation(apolloClient, {
      clientId,
      transaction,
      golfId: golf.id,
      product: helpers.object.filterByKeys(products[selected], ['id', 'name', 'value', 'price']),
    })
      .then(() => {
        this.props.update({ transaction });
        this.props.history.push('/primaccess/voucher');
      })
      .catch((err) => {
        this.setState({
          waiting: false,
          error: err.message,
        });
      });
  };

  /* */
  goToPayment = () => {
    const { selected } = this.state;
    const { products } = this.props;

    this.props.addProductToCart(products[selected]);
    this.props.history.push('/primaccess/payment');
  }

  /* */
  renderHeader = () => {
    const { selected } = this.state;
    const { products } = this.props;

    return (
      <Box
        display="flex"
        justifyContent="space-between">
        <Box>
          {'Sélectionnez un produit'}
        </Box>
        {(products[selected]) && (
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={() => this.setState({ selected: null, areAcceptedTerms: false })}>
            {'Modifier'}
          </Button>
        )}
      </Box>
    );
  }

  /* */
  renderList() {
    const { loading, selected } = this.state;
    const { products } = this.props;

    if (loading) {
      return (
        <Box
          display="flex"
          justifyContent="center">
          <CircularProgress size={24} color="secondary" />
        </Box>
      );
    }

    if (products && products.length === 0) {
      return (
        <Typography align="center">
          {'Aucun produit disponible.'}
        </Typography>
      );
    }

    if (products[selected]) return null;

    return products.map(this.renderProduct);
  }

  /* */
  render() {
    const { open, handleSwitchModal, classes } = this.props;

    return (
      <Dialog
        open={open}
        scroll="body"
        classes={{ paper: classes.dialogPaper }}
        onClose={handleSwitchModal}>
        <DialogTitle>
          {this.renderHeader()}
        </DialogTitle>
        <DialogContent>
          <br />
          {this.renderList()}
          {this.renderPayButton()}
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ app: { golf, account }, primaccess }) => ({
  account,
  golf,
  golfId: golf.id,
  products: primaccess.products,
  primaccess,
});

const StyledComponent = withStyles(styles)(ChooseProduct);

export default connect(
  mapStateToProps,
  Primaccess.actions,
)(StyledComponent);
