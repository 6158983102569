import React from 'react';
import {
  Button,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import i18n from '_utils/i18n';

/* */
const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  textField: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  submit: {
    marginTop: theme.spacing.unit * 2,
  },
});

/* */
class ForgotPasswordForm extends React.Component {
  /* */
  render() {
    const {
      errors,
      values,
      classes,
      handleBlur,
      handleChange,
      handleSubmit,
      isSubmitting,
    } = this.props;

    return (
      <div className="form">
        <TextField
          required
          fullWidth
          name='email'
          type='email'
          label={i18n.t('auth.form.labels.email')}
          variant='outlined'
          onBlur={handleBlur}
          value={values.email}
          onChange={handleChange}
          error={!!(errors.email)}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          fullWidth
          size="large"
          color="secondary"
          className={classes.submit}
          onClick={handleSubmit}
          variant="contained">
           {isSubmitting ? <CircularProgress color="inherit" size={24} /> : i18n.t('default.actions.send')}
         </Button>
       </div>
    );
  }
}

export default withStyles(styles)(ForgotPasswordForm);
