import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  helpers,
  CreditCard,
  AccountWallet,
} from '@aps-management/primapp-common';
import {
  colors,
  Divider,
  Paper,
  Typography,
} from '@material-ui/core';
/* */
import i18n from '_utils/i18n';
import Payment from '_components/Payment';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import masterClient from '_utils/masterClient';
import { DialogPaymentIP } from '_components/elements';

/* */
const styles = theme => ({
  loaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
    justifyContent: 'center',
  },
  divider: {
    margin: `${theme.spacing.unit * 1.5}px 0`,
  },
  paymentSummaryBlock: {
    marginBottom: `${theme.spacing.unit * 4}px`,
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
    display: 'flex',
    flexDirection: 'column',
  },
  line: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

/* */
class AccountWalletPayment extends React.Component {
  /* */
  constructor(props) {
    super(props);

    const {
      golf,
      account,
      cart,
    } = this.props;

    this.redirect = false;
    if (cart.length === 0) {
      this.redirect = true;
    }

    [this.product] = cart;

    // build session 3DS
    this.session3DS = {
      address: {
        city: golf.city,
        countryCode: 250, // golf.country
        line1: golf.name,
        zipCode: golf.postalCode,
      },
      customer: {
        email: account.email,
        lastName: account.lastname,
        firstName: account.firstname,
        phone: account.phone,
      },
    };
    this.currency = golf.currency;

    this.state = {
      // Screen
      error: null,
      loading: true,
      // Payment
      status: null,
      imprint: null,
      waiting: false,
    };
  }

  /* */
  componentDidMount() {
    if (this.redirect) return;

    CreditCard.api.getCreditCards(apolloClient, {})
      .then(({ creditCards }) => {
        const newState = {
          imprint: null,
          loading: false,
        };

        if (creditCards[0]) {
          const status = CreditCard.functions.getStatus(creditCards[0].dueDate);
          newState.imprint = {
            ...status,
            ...creditCards[0],
          };
        }

        this.setState(newState);
      });
  }

  /* */
  creditWallet(creditCardTransaction) {
    const { golf, account } = this.props;

    return AccountWallet.api.createOperation(masterClient, {
      creditCardTransaction,
      golfId: golf.id,
      accountId: account.id,
      product: helpers.object.filterByKeys(this.product, [
        'id',
        'name',
        'refilledAmount',
        'paidAmount',
      ]),
    });
  }

  /* */
  goToVoucher = () => this.props.history.push('/accountwallet/payment/voucher');

  /*
  values : {
    brand
    country
    cvv
    dueDate
    id3D
    number
    saveImprint
    type
  }
  */
  payWithNewCard = (values, { setSubmitting }) => {
    const { refilledAmount, paidAmount } = this.product;

    const { id3D = null, ...creditCard } = values;
    const creditCardMapped = {
      ...creditCard,
      name: '3DS web',
      number: values.number.replace(/\s/g, ''),
      dueDate: values.dueDate.replace(/\D/g, ''),
    };

    this.setState({
      error: null,
      waiting: true,
      status: 'Nous interrogeons votre banque...',
    });

    const creditCardTransaction = {
      id3D,
      amount: paidAmount,
      currency: this.currency,
      creditCard: creditCardMapped,
    };

    return this.creditWallet(creditCardTransaction)
      .then(({ transaction }) => {
        this.props.addTransaction({
          transaction,
          refilledAmount,
        });
        this.goToVoucher();
      })
      .catch((err) => {
        this.setState({
          waiting: false,
          error: err.message,
        });
        setSubmitting(false);
      });
  }

  /*
  imprint: {
    brand
    country
    dueDate
    id
    isExpired
    isSoonExpired
    name
    number
    token
    type
  }
  */
  payWithCardImprint = () => {
    const { imprint } = this.state;
    const { refilledAmount, paidAmount } = this.product;

    this.setState({
      error: null,
      waiting: true,
      status: 'Nous interrogeons votre banque...',
    });

    const creditCardTransaction = {
      amount: paidAmount,
      currency: this.currency,
      subscriber: {
        ...helpers.object.filterByKeys(imprint, [
          'id',
          'token',
          'dueDate',
          'number',
          'type',
          'brand',
          'country',
        ]),
      },
    };

    return this.creditWallet(creditCardTransaction)
      .then(({ transaction }) => {
        this.props.addTransaction({
          transaction,
          refilledAmount,
        });
        this.goToVoucher();
      })
      .catch((err) => {
        this.setState({
          waiting: false,
          error: err.message,
        });
      });
  }

  /* */
  render() {
    if (this.redirect) {
      return <Redirect to="/account/statement" />;
    }

    const {
      error,
      status,
      imprint,
      loading,
      waiting,
    } = this.state;
    const {
      paidAmount,
      refilledAmount,
    } = this.product;
    const { classes, balance } = this.props;

    const currentAmountColor = balance < 0
      ? colors.red[500]
      : colors.green[500];
    const futureAmountColor = (balance + refilledAmount) < 0
      ? colors.red[500]
      : colors.green[500];

    return (
      <Screen
        error={error}
        loading={loading}
        title="Rechargement du compte client - Paiement par CB">
        <Paper className={classes.paymentSummaryBlock}>
          <Typography
            variant="h5"
            className={classes.line}
            align="center">
            {'Solde actuel'}
            <span style={{ color: currentAmountColor }}>{i18n.l('currency', balance / 100)}</span>
          </Typography>
          <Divider className={classes.divider} />
          <Typography
            variant="h5"
            className={classes.line}
            align="center">
            {'Solde futur'}
            <span style={{ color: futureAmountColor }}>{i18n.l('currency', (balance + refilledAmount) / 100)}</span>
          </Typography>
        </Paper>
        <Payment
          amount={paidAmount}
          imprint={imprint}
          currency={this.currency}
          session3DS={this.session3DS}
          payWithNewCard={this.payWithNewCard}
          payWithCardImprint={this.payWithCardImprint} />
        <DialogPaymentIP open={waiting} status={status} />
      </Screen>
    );
  }
}

const mapStateToProps = ({
  app: { account, golf },
  accountWallet: { cart, clientId, balance },
}) => ({
  cart,
  golf,
  account,
  clientId,
  balance,
});

const StyledComponent = withStyles(styles)(AccountWalletPayment);

export default connect(
  mapStateToProps,
  AccountWallet.actions,
)(StyledComponent);
