import React from 'react';
import { connect } from 'react-redux';
import { helpers, App, Booking } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import { PhoneOutlined, EmailOutlined } from '@material-ui/icons';
import {
  colors,
  Grid,
  Link,
  Paper,
  Divider,
  Typography,
} from '@material-ui/core';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import { WeatherPreview } from '_components/elements';

/* */
const styles = theme => ({
  subtitle: {
    padding: theme.spacing.unit * 1,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.dark,
  },
  separator: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  status: {
    fontWeight: 'bold',
    marginLeft: theme.spacing.unit * 2,
  },
  paper: { overflow: 'hidden' },
  icon: { marginRight: theme.spacing.unit * 1 },
  typoInline: { display: 'inline' },
});

/* */
class Home extends React.PureComponent {
  /* */
  componentDidMount() {
    const { golf, account, data: { booking }, setBooking, history } = this.props;
    if (booking?.from === 'public') {
      const {
        email,
        phone,
        lastname,
        firstname,
        matches,
      } = account;
      const match = Object.values(matches).find((m) => m.golf === golf.id);
      setBooking({
        ...booking,
        from: 'home',
        owner: {
          tid: match?.reference || 'UNKNOWN',
          name: `${firstname} ${lastname}`,
          email: email,
          phone: phone,
        },
        players:[
          {
            ...booking.players.find((p) => p.type === 'owner'),
            tid: match?.reference || 'UNKNOWN',
            email,
            phone,
            type: 'owner',
            isMember: match?.isMember || false,
            reference: match?.reference || 'UNKNOWN',
            lastname: lastname?.toUpperCase(),
            firstname: firstname && helpers.string.ucfirst(firstname),
          },
          ...booking.players.filter((p) => p.type !== 'owner'),
        ]
      })
      if (!booking?.course?.anonymous) {
        if (booking?.players?.length === 1) {
          history.push('/booking/cart');
        } else {
          history.push('/booking');
        }
      } else {
        history.push('/booking/cart');
      }
    }

    this.props.getGolfWeather(apolloClient, { id: golf.id });
    this.props.getGolfGameConditions(apolloClient, { id: golf.id });
  }

  /* */
  lookForLinks(text) {
    const allInformations = [];
    const links = [];
    let hasLink = false;
    let informationsAfter;

    if (text) {
      const processedText = text.replace(/\n/g, ' \n');
      const linkRegexp = /(http|https){1}(:)(\/){2}(\S){1,}/;
      informationsAfter = processedText;
      let allLinksFound = false;

      while (!allLinksFound) {
        const linkFound = linkRegexp.exec(informationsAfter);
        if (linkFound) {
          hasLink = true;
          allInformations.push(informationsAfter.slice(0, linkFound.index));
          links.push(linkFound[0]);
          informationsAfter = informationsAfter.slice(linkFound.index + linkFound[0].length);
        } else {
          allLinksFound = true;
        }
      }
    }

    return {
      allInformations,
      links,
      hasLink,
      informationsAfter,
    };
  }

  /* */
  renderInformationsLink(text, link, index) {
    const { classes } = this.props;
    return (
      <React.Fragment key={index}>
        <Typography variant="body1" className={classes.typoInline}>
          {text}
          <Link
            color="secondary"
            underline="hover"
            variant="subtitle1"
            href={link}
            target="_blank"
            rel="noopener">
            {link}
          </Link>
        </Typography>
      </React.Fragment>
    );
  }

  /* */
  render() {
    const {
      golf,
      classes,
      weather,
      informations,
      gameConditions,
    } = this.props;
    const types = gameConditions && Object.keys(gameConditions);

    const {
      allInformations,
      links,
      hasLink,
      informationsAfter,
    } = this.lookForLinks(informations);

    return (
      <Screen
        title={golf.name}
        loading={!gameConditions}>
        <Grid container spacing={40}>
          <Grid item md={5} xs={12}>
            {weather && (
              <Paper className={classes.paper}>
                <Typography
                  variant="subtitle1"
                  align="center"
                  className={classes.subtitle}>
                  {'Météo'}
                </Typography>
                <Box
                  p={2}
                  display="flex"
                  alignItems="center"
                  flexDirection="row"
                  justifyContent="space-evenly">
                  <WeatherPreview
                    icon={weather.icon}
                    alt={weather.weather}
                    height={100} width={100} />
                  <Box>
                    <Typography variant="body1" component="span">
                      {`${Math.floor(weather.temp)}°C`}
                    </Typography>
                    <Typography variant="body1" component="span">
                      {helpers.string.ucfirst(weather.weather)}
                    </Typography>
                    <br />
                    <Typography variant="caption" component="span">
                      {`Vent : ${Math.round(weather.wind)} km/h`}
                    </Typography>
                    <Typography variant="caption" component="span">
                      {`Humidité : ${Math.round(weather.humidity)}%`}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            )}
            <br />
            {gameConditions && Boolean(types.length) && (
              <Paper className={classes.paper}>
                <Typography
                  variant="subtitle1"
                  align="center"
                  className={classes.subtitle}>
                  {'Conditions de jeu'}
                </Typography>
                <Box p={2}>
                  {types && types.map((type, i) => (
                    <Box key={i}>
                      {gameConditions[type].map((gc, j) => (
                        <Box
                          key={j}
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between">
                          <Typography variant="body1">
                            {`${gc.label} ${gc.name}`}
                          </Typography>
                          <Typography
                            variant="caption"
                            className={classes.status}
                            style={{ color: gc.status ? colors.green[500] : colors.red[500] }}>
                            {gc.statuses[gc.status].label}
                          </Typography>
                        </Box>
                      ))}
                      {i < (types.length - 1) && <Divider className={classes.separator} />}
                    </Box>
                  ))}
                </Box>
              </Paper>
            )}
          </Grid>
          <Grid item md={7} xs={12}>
            <Paper className={classes.paper}>
              <Typography
                variant="subtitle1"
                align="center"
                className={classes.subtitle}>
                {'Contact'}
              </Typography>
              <Box p={2} textAlign="center">
                <Typography variant="h6">
                  {golf.name}
                </Typography>
                <Typography variant="body1">
                  {golf.postalCode} {golf.city}
                </Typography>
                <Box
                  mt={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="center">
                  <PhoneOutlined
                    color="secondary"
                    className={classes.icon} />
                  <Link
                    color="secondary"
                    underline="hover"
                    variant="subtitle1"
                    href={`tel:${golf.phone}`}>
                    {golf.phone}
                  </Link>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center">
                  <EmailOutlined
                    color="secondary"
                    className={classes.icon} />
                  <Link
                    color="secondary"
                    underline="hover"
                    variant="subtitle1"
                    href={`mailto:${golf.email}`}>
                    {golf.email}
                  </Link>
                </Box>
              </Box>
            </Paper>
            <br />
            {informations && (
              <Paper className={classes.paper}>
                <Typography
                  variant="subtitle1"
                  align="center"
                  className={classes.subtitle}>
                  {'Informations'}
                </Typography>
                <Box p={2}>
                  {hasLink && allInformations && allInformations.map((value, index) =>
                    this.renderInformationsLink(value, links[index], index))}
                  {hasLink
                    && <Typography variant="body1" className={classes.typoInline}>{informationsAfter}</Typography>
                  }
                  {!hasLink
                    && <Typography variant="body1">{informations}</Typography>
                  }
                </Box>
              </Paper>
            )}
          </Grid>
        </Grid>
      </Screen>
    );
  }
}

const mapStateToProps = ({
  app: {
    golf,
    account,
    weather,
    gameConditions,
    informations,
  },
  bookingSearch,
  bookingData,
}) => ({
  golf,
  account,
  weather,
  informations,
  gameConditions,
  search: bookingSearch,
  data: bookingData,
});

const StyledComponent = withStyles(styles)(Home);

export default connect(
  mapStateToProps,
  {
    ...App.actions,
    ...Booking.actions,
  }
)(StyledComponent);
