import React, { useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { withStyles } from "@material-ui/core/styles";
import { unstable_Box as Box } from "@material-ui/core/Box";
import masterClient from "_utils/masterClient";
import {
  helpers,
  App,
  Academy,
  HELP_URL,
} from "@aps-management/primapp-common";
import { MdOutlineSchool, MdOutlineAccessTime, MdOutlineBook } from "react-icons/md";
import { CalendarDay } from '_components/elements';
import {
  Button,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Avatar,
  Chip,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { FaceOutlined as FaceIcon } from "@material-ui/icons";
/* */
import i18n from "_utils/i18n";
import { Screen } from "_components/core";

/* */
const styles = (theme) => ({
  avatar: {
    width: 30,
    height: 30,
    backgroundColor: theme.palette.text.primary,
  },
  header: {
    position: "relative",
    boxShadow: theme.shadows[2],
    backgroundColor: "white",
  },
  section: {
    margin: `${theme.spacing.unit * 1}px 0`,
  },
  initPrice: {
    color: theme.palette.grey[500],
    textDecoration: "line-through",
    marginLeft: theme.spacing.unit * 1.5,
  },
  subtotal: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  subheader: {
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  canceled: {
    top: 16,
    right: -90,
    width: 250,
    overflow: "hidden",
    fontWeight: "bold",
    lineHeight: "30px",
    textAlign: "center",
    position: "absolute",
    transform: "rotate(40deg)",
    boxShadow: theme.shadows[3],
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.dark,
  },
  cancelBtn: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
    fontSize: '0.6rem',
    fontWeight: 'bold',
  },
  hints: {
    position: "absolute",
    bottom: -12,
    right: -10,
    zIndex: 8,
  },
  price: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    paddingLeft: theme.spacing.unit * 1,
    paddingRight: theme.spacing.unit * 1,
    height: 25,
    fontWeight: "bold",
    borderRadius: "5px",
    backgroundColor: "rgba(224, 225, 215, 1)",
    cursor: "pointer",
  },
  slots: {
    marginLeft: theme.spacing.unit * 1,
    height: 25,
    fontWeight: "bold",
    borderRadius: "5px",
    backgroundColor: "rgba(132, 189, 227, 1)",
    cursor: "pointer",
  },
  players: {
    marginLeft: theme.spacing.unit * 1,
    height: 25,
    fontWeight: "bold",
    borderRadius: "5px",
    backgroundColor: "rgba(61, 195, 77, 1)",
    cursor: "pointer",
  },
  pro: {
    marginRight: theme.spacing.unit * 1,
    height: 25,
    fontWeight: "bold",
    borderRadius: "5px",
    cursor: "pointer",
  },
  proContent: {
    position: "absolute",
    bottom: -12,
    left: -10,
    zIndex: 8,
  },
  status: {
    position: "absolute",
    left: -1,
    right: -1,
    bottom: -1,
    zIndex: 8,
    top: -1,
    overflow: "hidden",
  },
  errPaid: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    paddingLeft: theme.spacing.unit * 1,
    paddingRight: theme.spacing.unit * 1,
    height: 25,
    fontWeight: "bold",
    borderRadius: "5px",
    backgroundColor: "rgba(237, 74, 74, 1)",
    cursor: "pointer",
  },
  unpaid: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    paddingLeft: theme.spacing.unit * 1,
    paddingRight: theme.spacing.unit * 1,
    height: 25,
    fontWeight: "bold",
    borderRadius: "5px",
    backgroundColor: "rgba(102, 187, 226, 1)",
    cursor: "pointer",
  },
  paid: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    paddingLeft: theme.spacing.unit * 1,
    paddingRight: theme.spacing.unit * 1,
    height: 25,
    fontWeight: "bold",
    borderRadius: "5px",
    backgroundColor: "rgba(61, 195, 77, 1)",
    cursor: "pointer",
  },
});
/* */
const AcademyView = (props) => {
  const { classes, golf, account } = props;
  const queryClient = useQueryClient();
  const history = useHistory();
  const { id } = useParams();
  const [lesson, setLesson] = useState(null);
  const [source, setSource] = useState(null);
  const [open, setOpen] = useState(false);
  const [cancelType, setCancelType] = useState(null);
  const [permissions, setPermissions] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const [success, setSuccess] = useState(null);
  const [isCanceled, setIsCanceled] = useState(false);
  const { cancellableUntilXHoursBefore } = {
    ...golf?.options?.academy?.onlineRestrictions?.global,
  } || 0;
  /* */
  const {
    isLoading: loadingMyRegistration,
    data: { items: myRegistration },
    error: errorMyRegistration,
  } = useQuery(
    ["academy-my-lesson-resistration", id],
    () =>
      Academy.api.getMyLessonRegistrations(masterClient, {
        filter: {
          lessonId: {
            eq: id,
          },
        },
      }),
    {
      enabled: !!id,
      retry: 0,
      cacheTime: 0,
      refetchOnWindowFocus: true,
      initialData: () => ({
        items: null,
      }),
    }
  );
  /* */
  const {
    isLoading: loadingPlayers,
    data: players,
    error: errorPlayers,
  } = useQuery(
    ["academy-lesson-partners", id],
    async () => {
      const registrations = await Academy.api.getMyLessonPartners(
        masterClient,
        {
          lessonId: id,
        }
      );
      return [
        ...registrations,
        {
          customer: myRegistration[0].customer,
          paymentStatus: myRegistration[0].paymentStatus,
          price: myRegistration[0].price,
          status: myRegistration[0].status,
        },
      ];
    },
    {
      enabled: !!myRegistration,
      retry: 0,
      cacheTime: 0,
      refetchOnWindowFocus: true,
      initialData: () => [],
    }
  );

  const { mutate: individualCancel } = useMutation(
    async (id) => Academy.api.individualLessonCancel(masterClient, {
      input: {
        id
      }
    }), {
    onMutate: (variables) => {
      const oldValue = queryClient.getQueryData(["academy-my-lesson-resistration", id]);
      queryClient.setQueryData(["academy-my-lesson-resistration", id], {
        ...oldValue,
        items: oldValue.items.map((res) => res.lessonId === id ? { ...res, lesson: { ...res.lesson, status: 'CANCELLED' } } : res)
      });
      return {
        old: oldValue,
      }
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(["academy-my-lesson-resistration", id], context.old);
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries(["academy-my-lesson-resistration"]);
      queryClient.fetchQuery(["academy-my-lesson-resistration", id]);
      setSuccess("Annulation effectuée.");
    },
  });

  const { mutate: groupCancel } = useMutation(
    async ({ lessonId, ids }) => Academy.api.lessonRegistrationBatchCancel(masterClient, {
      input: { 
        lessonId,
        ids,
      }
    }), {
    onMutate: (variables) => {
      const oldValue = queryClient.getQueryData(["academy-my-lesson-resistration", id]);
      queryClient.setQueryData(["academy-my-lesson-resistration", id], {
        ...oldValue,
        items: oldValue.items.map((res) => variables.ids.includes(res.id) ? { ...res, status: 'CANCELLED' } : res)
      });
      return {
        old: oldValue,
      }
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(["academy-my-lesson-resistration", id], context.old);
    },
    onSuccess: (result, variables, context) => {
      queryClient.invalidateQueries(["academy-my-lesson-resistration"]);
      queryClient.fetchQuery(["academy-my-lesson-resistration", id]);
      setSuccess("Annulation effectuée.");
    },
  });

  useEffect(() => {
    if (!myRegistration || !myRegistration.length > 0) return;
    const value =
      lesson?.status === "CANCELED" || myRegistration[0]?.status === "CANCELED";
    setIsCanceled(value);
  }, [myRegistration, lesson]);
  /* */
  const [loading, error] = [
    loadingMyRegistration || loadingPlayers,
    errorMyRegistration || errorPlayers,
  ];
  /* */
  useEffect(() => {
    if (!myRegistration || !myRegistration.length > 0) return;
    setLesson(myRegistration[0].lesson);
    setSource("db");
  }, [myRegistration]);
  /* */
  useEffect(() => {
    if (!lesson) return;
    let newPermissions = {
      cancelAll: false,
      cancelMine: myRegistration[0]?.source !== 'WEB' ? false : true,
    };
    const isFree = myRegistration[0]?.price
      ? myRegistration[0].price === 0
      : true;
    const checkLastStatus = ["CONFIRMED", "WAITLISTED"].includes(
      myRegistration[0].status
    );
    const deadline = moment(lesson.startsAt).subtract(
      cancellableUntilXHoursBefore, "hours"
    );
    // check no actions
    if (deadline.isAfter()) {
      if (source === "db" && checkLastStatus) {
        if (isFree) {
          newPermissions.cancelAll = false;
          // sur place ou compte client
        } else if ([1, 2].includes(lesson.paymentMethod)) {
          newPermissions.cancelAll = true;
        }
      } else if (source === "ws" && isFree) {
        newPermissions.cancelMine = !lesson?.isLocked;
      }
      if (myRegistration[0].lesson.type === "INDIVIDUAL") {
        newPermissions.cancelAll = true;
      }
    }
    const anyPermission = Object.keys(newPermissions).every(
      (k) => !newPermissions[k]
    );
    if (!anyPermission) setPermissions(newPermissions);
  }, [lesson, golf, source, myRegistration, cancellableUntilXHoursBefore]);


  const renderSlotsList = () => {
    if (!lesson) return null;
    const { slotCount } = lesson;
    return (
      <Grid item md={6} xs={12} className={classes.section}>
        <Box display="flex" flexDirection="row">
          <MdOutlineBook size={24} />
          &nbsp;
          <Typography gutterBottom variant="body1">
            {'Liste des créneaux :'}
          </Typography>
          <Chip
            className={classes.slots}
            color="default"
            size="small"
            label={slotCount}
          />
        </Box>
        <Grid container spacing={8} style={{ paddingBottom: 12 }}>
          {lesson.coachSlots.filter((s) => s.status !== "CANCELED").map((s, key) => (
            <Grid item key={key}>
              <CalendarDay
                key={key}
                displayTime
                datetime={moment(s?.startsAt)}
                endTime={moment(s?.endsAt)}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  };
  /* */
  const renderHeader = () => {
    const { status, name, type, headCoach } = lesson;
    const dateTxt = helpers.string.ucfirst(
      i18n.l("date.formats.long_y", new Date(lesson.startsAt))
    );
    /* */
    return (
      <Box py={2} mb={2} className={classes.header} position="relative">
        <Box className={classes.status}>
          {status && status === "CANCELED" && (
            <Typography
              component="span"
              variant="caption"
              className={classes.canceled}
            >
              {"Annulée"}
            </Typography>
          )}
        </Box>
        <Typography
          align="center"
          color="textSecondary"
          component="h2"
          variant="h5"
        >
          {golf.name}
        </Typography>
        <Typography align="center" component="p" variant="h5">
          {`${dateTxt}`}
        </Typography>
        <Typography
          align="center"
          component="p"
          color="textSecondary"
          variant="h6"
        >
          {type === 'GROUP' ? name : `Cours avec ${headCoach.lastName} ${headCoach.firstName}`}
        </Typography>
      </Box>
    );
  };
  /* */
  const handleClickOpen = (cancelType) => () => {
    setCancelType(cancelType);
    setOpen(true);
  };

  /* */
  const renderActions = () => {
    if (
      !permissions ||
      lesson.status === "CANCELED" ||
      myRegistration[0].status === "CANCELED"
    )
      return null;
    /* */
    return (
      <Box mb={2}>
        <Box display="flex" justifyContent="center">
          {permissions.cancelAll && (
            <Button
              disabled={waiting}
              variant="contained"
              className={classes.cancelBtn}
              onClick={handleClickOpen("all")}
            >
              {waiting ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                "Annuler la réservation"
              )}
            </Button>
          )}
        </Box>
      </Box>
    );
  };
  /* */
  const renderPlayers = () => {
    const { headCoach } = lesson;
    const deadline = moment(lesson.startsAt).subtract(cancellableUntilXHoursBefore, "hours");
    const match = App.functions.match(account, golf) || {
      isMember: false,
      reference: "UNKNOWN",
    };
    const accountReference = match.reference;
    if (
      myRegistration?.length === 0 &&
      players?.filter((p) => ["CONFIRMED", "NO_SHOW"].includes(p.status))
        ?.length === 0
    )
      return null;

    const subtotal = myRegistration.reduce(
      (counter, line) =>
        counter +
        players.filter((p) =>
          ["CONFIRMED", "NO_SHOW"].includes(p.status)
        ).reduce((counter, line) => counter + line?.price || 0, 0),
      0
    );
    const showSubtotal =
      source === "db" &&
      players?.filter((p) => ["CONFIRMED", "NO_SHOW"].includes(p.status))
        ?.length > 1 &&
      subtotal > 0;

    return (
      <Grid item md={6} xs={12} className={classes.section}>
        {headCoach && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            mt={2}
            mb={2}
          >
            <MdOutlineSchool size={24} />
            &nbsp;
            <Typography variant="body1">
              {`Pro : ${headCoach?.lastName} ${headCoach?.firstName}`}
            </Typography>
          </Box>
        )}
        {players?.filter((p) =>
              ["CONFIRMED", "NO_SHOW"].includes(p.status)
            )?.length > 0 && (
              <Typography paragraph component="h3" variant="h5">
                {i18n.t("terms.players", {
                  count: players?.filter((p) =>
                    ["CONFIRMED", "NO_SHOW"].includes(p.status)
                  )?.length,
                })}
              </Typography>
            )}
        <Paper elevation={2} style={{ marginBottom: 16 }}>
          <List disablePadding>
            {players
              ?.filter((p) =>
                ["CONFIRMED", "CANCELED", "NO_SHOW"].includes(p.status)
              )
              .map((pl, key) => (
                <ListItem divider key={key}>
                  <Avatar className={classes.avatar}>
                    <FaceIcon />
                  </Avatar>
                  <ListItemText
                    primary={`${pl.customer.lastName} ${pl.customer.firstName}`}
                  />
                  {pl.status === "CANCELED" ? (
                    <Chip
                      label="Annulée"
                      color="secondary"
                      size="small"
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        fontWeight: "bold",
                        borderRadius: 5,
                        marginRight: 8,
                        height: 25,
                      }}
                    />
                  ) : 
                  !isCanceled && (
                    <>
                    {pl.price > 0 && (
                      <Typography
                        className={classes.price}
                        component="span"
                        variant="subtitle1"
                      >
                        {i18n.l(
                          "currency",
                          (pl.price) / 100
                        )}
                      </Typography>
                    )}
                    {pl.price === 0 ? (
                        <Typography
                          component="span"
                          variant="subtitle1"
                          style={{ paddingRight: 24 }}
                        >
                          {i18n.t("terms.included")}
                        </Typography>
                      ) : pl.paymentStatus === "SUCCESSFUL" ? (
                        <Chip
                          className={classes.paid}
                          color="default"
                          size="small"
                          label={`Réglé`} />
                      ) : pl.paymentStatus === "FAILED" ? (
                        <Chip
                          className={classes.errPaid}
                          color="default"
                          size="small"
                          label={`Refusé`} />
                      ) : (
                        <Chip
                          className={classes.unpaid}
                          color="default"
                          size="small"
                          label={`En attente de réglement`} />
                      )}
                    </>
                  )}
                  {!isCanceled &&
                    deadline.isAfter() &&
                    permissions &&
                    !permissions.cancelAll &&
                    permissions.cancelMine &&
                    pl.customer.pgbTid === accountReference && (
                      <Button
                        disabled={waiting}
                        variant="contained"
                        className={classes.cancelBtn}
                        onClick={handleClickOpen("mine")}
                      >
                        {waiting ? (
                          <CircularProgress color="inherit" size={24} />
                        ) : (
                          "Supprimer"
                        )}
                      </Button>
                    )}
                </ListItem>
              ))}
            {showSubtotal && (
              <ListItem className={classes.subtotal}>
                <ListItemText
                  primary="Sous-total"
                  primaryTypographyProps={{ color: "inherit" }}
                />
                <Typography variant="subtitle1" color="inherit">
                  {i18n.l("currency", subtotal / 100)}
                </Typography>
              </ListItem>
            )}
          </List>
        </Paper>
        {players.filter((p) => p.status === "WAITLISTED").length > 0 && (
          <>
            <Typography paragraph component="h3" variant="h5">
              {`Vous êtes en liste d'attente`}
            </Typography>
            <Paper elevation={2} style={{ marginBottom: 16 }}>
              <List disablePadding>
                {players
                  .filter((p) => p.status === "WAITLISTED")
                  .map((pl, key) => (
                    <ListItem divider key={key}>
                      <Avatar className={classes.avatar}>
                        <MdOutlineAccessTime size="1.5rem" />
                      </Avatar>
                      <ListItemText
                        primary={`${pl.customer.lastName} ${pl.customer.firstName}`}
                      />
                      {!isCanceled &&
                        deadline.isAfter() &&
                        permissions &&
                        !permissions.cancelAll &&
                        permissions.cancelMine &&
                        pl.customer.pgbTid === accountReference &&  (
                          <Button
                            disabled={waiting}
                            variant="contained"
                            className={classes.cancelBtn}
                            onClick={handleClickOpen("mine")}
                          >
                            {waiting ? (
                              <CircularProgress color="inherit" size={24} />
                            ) : (
                              "Supprimer"
                            )}
                          </Button>
                        )}
                    </ListItem>
                  ))}
              </List>
            </Paper>
          </>
        )}
      </Grid>
    );
  };
  /* */
  const renderTotal = () => {
    if (isCanceled || !lesson.price || lesson.price === 0) return null;
    if (players.filter((p) =>
      ["CONFIRMED", "NO_SHOW"].includes(p.status)
    ).reduce((counter, line) => counter + line?.price || 0, 0) === 0) return null;
    return (
      <Grid container spacing={24} direction="row" justify="center">
        <Grid item md={6} xs={12} className={classes.section}>
          <Paper elevation={2}>
            <List
              subheader={
                <ListSubheader className={classes.subheader} color="inherit">
                  {"Total"}
                </ListSubheader>
              }
              disablePadding
            >
              <ListItem divider style={{ alignItems: "baseline" }}>
                <ListItemText primary={"Montant"} />
                <Typography
                  className={classes.price}
                  component="span"
                  variant="subtitle1"
                >
                  {i18n.l(
                    "currency",
                    (players.filter((p) =>
                      ["CONFIRMED", "NO_SHOW"].includes(p.status)
                    ).reduce((counter, line) => counter + line?.price || 0, 0) /
                      100
                  ))}
                </Typography>
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    );
  };

  /* */
  const handleClose = () => {
    setOpen(false);
  };

  /* */
  const doCancelRequest = () => {
    setWaiting(true);
    setTimeout(() => {
      switch(cancelType) {
        case "all":
          individualCancel(myRegistration[0].lessonId);
          break;
        case "mine":
          groupCancel({
            lessonId: myRegistration[0].lessonId,
            ids: [myRegistration[0].id]
          });
          break;
        default:
          break;
      }
      setWaiting(false);
      setOpen(false);
    }, 300);
  };
  /* */
  const renderCancelDialog = () => {
    const title =
      cancelType === "all"
        ? "Êtes-vous sûr de vouloir annuler votre réservation ?"
        : "Êtes-vous sûr de vouloir annuler votre inscription ?";

    return (
      <Dialog
        open={open}
        disableBackdropClick
        disableEscapeKeyDown
        onClose={handleClose}
      >
        <DialogTitle>{"Annulation"}</DialogTitle>
        <DialogContent>
          <DialogContentText color="default">{title}</DialogContentText>
          <DialogContentText color="default" variant="caption">
            {players.length > 1 &&
              "Un e-mail de confirmation sera envoyé aux autres joueurs."}
            <br />
            {cancelType === "mine" &&
              'Seul votre inscription sera annulé. Celle-ci ne sera plus présente dans "Mes cours".'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="secondary" onClick={handleClose}>
            {"Non"}
          </Button>
          <Button
            color="inherit"
            disabled={waiting}
            variant="contained"
            className={classes.cancelBtn}
            onClick={doCancelRequest}
          >
            {waiting ? <CircularProgress color="inherit" size={24} /> : "Oui"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  /* */
  return (
    <Screen
      error={error}
      loading={loading}
      success={success}
      title="Mon cours"
      onBackPress={() => history.goBack()}
      helpURL={`${HELP_URL}/mon-historique/mes-r%C3%A9servations#h.p_cPznL7GMyTRp`}
    >
      {lesson && (
        <React.Fragment>
          {renderHeader()}
          {renderActions()}
          <Grid container spacing={24} justify="center">
            {renderSlotsList()}
            {renderPlayers()}
          </Grid>
          {renderTotal()}
          {renderCancelDialog()}
          {!isCanceled && permissions && !permissions.cancelMine && (
            <Typography style={{ textAlign: "center", fontWeight: "bold" }}>{'Votre inscription a été effectuée à l’accueil. Pour toute modification, veuillez contacter directement le golf'}</Typography>
          )}
          {isCanceled && (
            <Typography style={{ textAlign: "center", fontWeight: "bold", color: "red" }}>{'Votre inscription a été annulé. Pour toute modification, veuillez contacter directement le golf'}</Typography>
          )}
        </React.Fragment>
      )}
    </Screen>
  );
};

const mapStateToProps = ({ app }) => ({
  golf: app.golf,
  account: app.account,
});

const StyledComponent = withStyles(styles)(AcademyView);

export default connect(mapStateToProps)(StyledComponent);

