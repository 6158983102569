import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';

// Guideline sizes are based on standard ~5" screen Apple (6, 7 & 8) device
const guidelineBaseWidth = 375;

const useScaling = () => {
  const { innerWidth: SCREEN_WIDTH, innerHeight: SCREEN_HEIGHT } = window;
  const scale = SCREEN_WIDTH / guidelineBaseWidth;

  const isMobileView = useMediaQuery('(max-width: 767px)');
  const isTabletView = useMediaQuery('(min-width: 768px) and (max-width: 1023px)');
  const isDesktopView = useMediaQuery('(min-width: 1024px)');

  const scaleH = (size) => {
    const newSize = size * scale;
    return Math.round(newSize) - 1;
  };
  const scaleV = (size) => {
    let newSize = (size + 16) * ((SCREEN_WIDTH - 568) / (768 - 568));
    if (newSize < 0) {
      newSize = -newSize;
    }
    return Math.round(newSize) - 1;
  };

  return {
    isMobileView,
    isTabletView,
    isDesktopView,
    width: SCREEN_WIDTH,
    height: SCREEN_HEIGHT,
    scaleH,
    scaleV,
  };
};

export {
  useScaling,
};
