import React from 'react';
import i18n from '_utils/i18n';

import {
  Button,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* */
const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  textField: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

class ResetPasswordForm extends React.Component {
  /* */
  render() {
    const {
      errors,
      values,
      classes,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      isSubmitting,
    } = this.props;

    return (
      <div className="form">
        <TextField
          fullWidth
          required
          name='password'
          type='password'
          variant='outlined'
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.password}
          error={!!(errors.password)}
          className={classes.textField}
          label={i18n.t('Nouveau mot de passe')}
          helperText={!!(this.props.errors.password) && 'Au moins 1 chiffre, 1 majuscule et 1 minuscule.'}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          fullWidth
          required
          type='password'
          variant='outlined'
          onBlur={handleBlur}
          name='passwordConfirm'
          onChange={handleChange}
          className={classes.textField}
          value={values.passwordConfirm}
          label={i18n.t('Confirmation du mot de passe')}
          error={!!(touched.passwordConfirm && errors.passwordConfirm)}
          helperText={touched.passwordConfirm && errors.passwordConfirm}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          fullWidth
          size="large"
          color="secondary"
          variant="contained"
          onClick={handleSubmit}
          disabled={isSubmitting}
          className={classes.submit}>
           {isSubmitting ? <CircularProgress color="inherit" size={24} /> : i18n.t('default.actions.send')}
         </Button>
      </div>
    );
  }
}

export default withStyles(styles)(ResetPasswordForm);
