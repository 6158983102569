import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Switch,
  Divider,
  Typography,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';
import i18n from '_utils/i18n';

/* */
const styles = theme => ({
  textField: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  submitButton: {
    marginTop: theme.spacing.unit * 2,
  },
});

/* */
class ChangePrivacyForm extends React.Component {
  /* */
  render() {
    const {
      values,
      classes,
      handleChange,
      handleSubmit,
      isSubmitting,
    } = this.props;

    return (
      <form>
        <Typography
          component="h2"
          color="secondary"
          variant="subtitle2">
          {'Planning des réservations'}
        </Typography>
        <FormControlLabel
          disabled={isSubmitting}
          name="beAnonymousOnSchedule"
          label="Masquer mon identité"
          control={
            <Switch
              onChange={handleChange}
              value="beAnonymousOnSchedule"
              checked={values.beAnonymousOnSchedule} />
          } />
        <Typography variant="caption">
          {'Vous apparaîtrez en tant que joueur anonyme pour les autres joueurs.'}
        </Typography>
        <br />
        <Divider />
        <br />
        <Typography
          component="h2"
          color="secondary"
          variant="subtitle2">
          {'Annuaire des membres'}
        </Typography>
        <FormControlLabel
          disabled={isSubmitting}
          name="doNotAppearInDirectory"
          label="Ne pas figurer dans l'annuaire"
          control={
            <Switch
              onChange={handleChange}
              value="doNotAppearInDirectory"
              checked={values.doNotAppearInDirectory} />
          } />
        <Typography variant="caption">
          {'Les autres membres ne pourront pas vous chercher et réserver pour vous.'}
        </Typography>
        <FormControlLabel
          name="hideContactDetailsInDirectory"
          label="Masquer mes coordonnées"
          disabled={isSubmitting}
          control={
            <Switch
              onChange={handleChange}
              value="hideContactDetailsInDirectory"
              checked={values.hideContactDetailsInDirectory} />
          } />
        <Typography variant="caption">
          {'E-mail, numéro de téléphone et adresse postale ne seront pas affichés.'}
        </Typography>
        <Button
          fullWidth
          size="large"
          color="secondary"
          variant="contained"
          onClick={handleSubmit}
          disabled={isSubmitting}
          className={classes.submitButton}>
          {isSubmitting
            ? <CircularProgress color="inherit" size={24} />
            : i18n.t('default.actions.save')}
        </Button>
      </form>
    );
  }
}
export default withStyles(styles)(ChangePrivacyForm);
