import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Fab,
} from '@material-ui/core';
import {
  Add as AddIcon,
  Remove as RemoveIcon,
} from '@material-ui/icons';

/* */
const styles = theme => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    // justifyContent: 'space-between',
  },
  fab: {
    // color: theme.palette.secondary.main,
    // backgroundColor: theme.palette.common.white,
    '&:hover': {
      // backgroundColor: theme.palette.grey[200],
    },
  },
  fabSm: {
    height: theme.spacing.unit * 5,
    width: theme.spacing.unit * 5,
  },
  avatar: {
    color: theme.palette.secondary.main,
    background: theme.palette.secondary.contrastText,
    fontSize: '2rem',
    height: theme.spacing.unit * 10,
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    width: theme.spacing.unit * 10,
    boxShadow: theme.shadows[2],
  },
  avatarSm: {
    fontSize: '1.6rem',
    height: theme.spacing.unit * 7,
    width: theme.spacing.unit * 7,
  },
});

/* */
class Incrementer extends React.Component {
  static defaultProps = {
    minValue: 0,
    maxValue: null,
  }

  /* */
  onDecrease = () => {
    const { minValue, value } = this.props;
    if (value > minValue) this.props.onDecrease();
  };

  /* */
  onIncrease = () => {
    const { maxValue, value } = this.props;
    if (!maxValue || value < maxValue) this.props.onIncrease();
  };

  /* */
  render() {
    const {
      id,
      size,
      value,
      classes,
      minValue,
      maxValue,
    } = this.props;

    const disabledMinus = value <= minValue;
    const disabledPlus = maxValue && value >= maxValue;

    return (
      <div id={id} className={classes.container}>
        <Fab
          color="secondary"
          disabled={disabledMinus}
          className={classNames(classes.fab, {
            [classes.fabSm]: size === 'small',
          })}
          onClick={this.onDecrease}>
          <RemoveIcon />
        </Fab>
        <Avatar
          className={classNames(classes.avatar, {
            [classes.avatarSm]: size === 'small',
          })}>
          {value}
        </Avatar>
        <Fab
          color="secondary"
          disabled={disabledPlus}
          className={classNames(classes.fab, {
            [classes.fabSm]: size === 'small',
          })}
          onClick={this.onIncrease}>
          <AddIcon />
        </Fab>
      </div>
    );
  }
}

export default withStyles(styles)(Incrementer);
