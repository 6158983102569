import React from 'react';
import { connect } from 'react-redux';
import { Booking } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  Paper,
  Typography,
} from '@material-ui/core';
import { Alert, Incrementer } from '_components/elements';

/* */
const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  itemContainer: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
  },
  itemName: {
    flex: 1,
    paddingRight: theme.spacing.unit * 2,
  },
});

/* */
class SetAccessories extends React.Component {
  /* */
  addItem(idx) {
    const { data } = this.props;

    this.props.addAccessory({
      id: data.accessories[idx].id,
      name: data.accessories[idx].name,
    });
  }

  /* */
  removeItem(idx) {
    this.props.removeAccessory({
      id: this.props.data.accessories[idx].id,
    });
  }

  /* */
  render() {
    const { classes, data, search } = this.props;

    const accsQty = search.accessoriesTotalQty;
    const playersQty = search.players.length;

    let alertViarant;
    let alertMessage;
    if (accsQty > playersQty) {
      alertViarant = 'error';
      alertMessage = 'Vous avez dépassé la quantité maximum autorisée (1 par joueur).';
    } else if (accsQty === playersQty) {
      alertViarant = 'info';
      alertMessage = 'Vous avez atteint la quantité maximum autorisée (1 par joueur).';
    }

    return (
      <Box m={1} mb={3}>
        <Typography
          paragraph
          component="p"
          variant="h6">
          {'Quel(s) accessoire(s) ?'}
        </Typography>
        {alertMessage && <Alert variant={alertViarant}>{alertMessage}</Alert>}
        <Paper className={classes.paper}>
          {data.accessories.map((acc, key) => {
            const { qty } = search.accessories[acc.id] || { qty: 0 };

            return (
              <div className={classes.itemContainer} key={key}>
                <Typography
                  className={classes.itemName}
                  component="span"
                  variant="subtitle1">
                  {acc.name}
                </Typography>
                <Incrementer
                  onDecrease={() => this.removeItem(key)}
                  onIncrease={() => this.addItem(key)}
                  minValue={0}
                  maxValue={acc.max}
                  size="small"
                  value={qty} />
              </div>
            );
          })}
        </Paper>
      </Box>
    );
  }
}

const mapStateToProps = ({ bookingData, bookingSearch }) =>
  ({ data: bookingData, search: bookingSearch });

const StyledComponent = withStyles(styles)(SetAccessories);

export default connect(
  mapStateToProps,
  Booking.actions,
)(StyledComponent);
