import React, { useState, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { createContext } from '@fluentui/react-context-selector';
import { Booking } from '@aps-management/primapp-common'
import { useQuery } from '@tanstack/react-query';
import masterClient from '_utils/masterClient';
import moment from 'moment';

const PublicBookingContext = createContext({
  loading: true,
  data: {
    courses: null,
    initial: null,
    coursesSchedule: null,
    coursesScheduleLoading: true,
    accessories: null,
    datesList: null,
    openCourses: null,
    availableDates: null,
  },
  players: ['UNKNOWN'],
  setPlayers: () => {},
  playerCount: 1,
  setPlayerCount: () => {},
  selectedDate: moment(),
  setSelectedDate: () => {},
  selectedCourse: null,
  setSelectedCourse: () => {},
})

const PublicBookingProvider = (props) => {
  const location = useLocation();
  const { golf, children } = props;
  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState(['UNKNOWN']);
  const [playerCount, setPlayerCount] = useState(1);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment());

  useEffect(() => {
    setPlayers(Array(playerCount).fill('UNKNOWN'));
  }, [playerCount]);

  const { data } = useQuery(
    ['courses', 1, golf?.id],
    async () => {
      setLoading(true);
      const results = await Booking.api.selectGolf(masterClient, {
        activity: 1,
        id: golf?.id,
      }).then((data) => data || null);
      return results;
    },
    {
      enabled: !!golf?.id && location.pathname.includes('public-booking'),
      retry: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      initialData: () => ({
        golf: {},
        courses: [],
        accessories: [],
      }),
    },
  );

  const { data: { courses, initial, datesList, openCourses, availableDates } } = useQuery(
    ['public-courses', data?.courses?.map((c) => c.id)],
    async () => {
      setLoading(true);
      const {
        initial,
        openDates,
        openCourses,
        availableDates,
      } = Booking.functions.processCourses(data.courses);
      const availableCourses = await data.courses.filter((c) =>
        Booking.functions.checkCourseAvailability(c, selectedDate.format('YYYY-MM-DD')));
      setSelectedCourse(availableCourses[0]);
      return {
        courses: availableCourses,
        initial,
        datesList: openDates,
        openCourses,
        availableDates,
      };
    },
    {
      enabled: data?.courses?.length > 0,
      retry: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      initialData: () => ({
        courses: [],
        initial: [],
        datesList: [],
        openCourses: [],
        availableDates: [],
      }),
    },
  )

  const { data: { coursesSchedule, accessoriesSchedule } } = useQuery(
    ['teetimes', `${selectedCourse?.id}-${selectedCourse?.type}-${selectedDate.format('YYYY-MM-DD')}`],
    async () => {
      setLoading(true);
      const results = await Booking.api.getPublicSchedules(masterClient, {
        golfId: golf.id,
        courseId: selectedCourse.id,
        courseType: selectedCourse.type,
        date: selectedDate.format('YYYY-MM-DD'),
        players: players,
        accessoryIds: ['1'],
        fullday: 0,
      }).then((res) => ({
        coursesSchedule: res[0]?.courseSchedule || [],
        accessoriesSchedule: res[1]?.accessoriesSchedule || [],
      }))
      .catch(() => ({ coursesSchedule: [], accessoriesSchedule: [] }));
      return results;
    },
    {
      enabled: !!selectedCourse?.id,
      retry: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      initialData: () => ({
        coursesSchedule: null,
        accessoriesSchedule: null,
      }),
    },
  )
  const { data: detailedSchedule } = useQuery(
    ['detailedSchedule', `${selectedCourse?.id}-${selectedCourse?.type}-${selectedDate.format('YYYY-MM-DD')}`],
    async () => {
      const schedules = {};
      schedules.course = await Booking.functions.processSchedule('course', coursesSchedule.teetimes);
      if (accessoriesSchedule) {
        schedules.accessories = await Booking.functions.processSchedule('accessories', accessoriesSchedule);
      } else {
        schedules.accessories = {};
      }
      const detailedSchedule = await Booking.functions.getDetailedSchedule(
        schedules,
        { accessories: {}, players: players },
      );
      setLoading(false);
      return detailedSchedule;
    },
    {
      enabled: !!coursesSchedule,
      retry: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      initialData: () => ({}),  
    },
  )

  const value = useMemo(() => {
    return ({
      loading: loading,
      data: {
        courses: courses,
        initial: initial,
        coursesSchedule: Object.keys(detailedSchedule).sort((a, b) => a - b).map(key => detailedSchedule[key]),
        accessoriesSchedule: accessoriesSchedule,
        accessories: data?.accessories,
        datesList: datesList,
        openCourses: openCourses,
        availableDates: availableDates,
      },
      players,
      setPlayers,
      playerCount: playerCount,
      setPlayerCount: setPlayerCount,
      selectedDate: selectedDate,
      setSelectedDate: setSelectedDate,
      selectedCourse: selectedCourse,
      setSelectedCourse: setSelectedCourse,
    });
  }, [
    loading,
    courses,
    initial,
    detailedSchedule,
    accessoriesSchedule,
    data.accessories,
    datesList,
    openCourses,
    availableDates,
    players,
    setPlayers,
    playerCount,
    setPlayerCount,
    selectedDate,
    setSelectedDate,
    selectedCourse,
    setSelectedCourse,
  ]);
  
  return (
      <PublicBookingContext.Provider value={value}>
          {children}
      </PublicBookingContext.Provider>
  )
}

export default PublicBookingContext;

export { PublicBookingProvider };
