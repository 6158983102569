import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import i18n from '_utils/i18n';

/* */
const styles = theme => ({
  textField: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  submitButton: {
    marginTop: theme.spacing.unit * 2,
  },
});

/* */
class EditAddressForm extends React.Component {
  /* */
  state = {
    isValid: false,
    isChecked: false,
  };

  /* */
  render() {
    const {
      errors,
      values,
      classes,
      handleBlur,
      handleChange,
      handleSubmit,
      isSubmitting,
    } = this.props;

    return (
      <React.Fragment>
        <TextField
          required
          fullWidth
          label="Adresse"
          name='address'
          variant='outlined'
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.address}
          error={!!(errors.address)}
          className={classes.textField}
          helperText={errors.address}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          fullWidth
          label="Adresse (2)"
          name='address2'
          variant='outlined'
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.address2}
          error={!!(errors.address2)}
          helperText={errors.address2}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          required
          fullWidth
          name='postcode'
          label="Code postal"
          variant='outlined'
          onBlur={handleBlur}
          value={values.postcode}
          onChange={handleChange}
          error={!!(errors.postcode)}
          helperText={errors.postcode}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          required
          fullWidth
          name='city'
          label="Ville"
          variant='outlined'
          onBlur={handleBlur}
          value={values.city}
          onChange={handleChange}
          error={!!(errors.city)}
          helperText={errors.city}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          fullWidth
          size="large"
          color="secondary"
          variant="contained"
          onClick={handleSubmit}
          disabled={isSubmitting}
          className={classes.submitButton}>
          {isSubmitting
            ? <CircularProgress color="inherit" size={24} />
            : i18n.t('default.actions.save')}
        </Button>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(EditAddressForm);
