/* eslint-disable no-unused-vars */
import React from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router-dom';
import {
  colors, Typography, Tooltip, Chip,
} from '@material-ui/core';
import { unstable_Box as Box } from '@material-ui/core/Box';
import { Academy } from '@aps-management/primapp-common';
import {
  Lock as LockIcon,
  LockOpen as LockOpenIcon,
  AccessTime as AccessTimeIcon,
  InfoOutlined as InfoOutlinedIcon,
  FormatListNumbered as FormatListNumberedIcon,
} from '@material-ui/icons';
import {
  MdOutlineAccountCircle,
  MdOutlineDeveloperBoard,
  MdOutlineEuro,
  MdOutlineBook,
  MdOutlineSchool,
} from 'react-icons/md';
import { CalendarDay } from '_components/elements';

/* */
const styles = (theme) => ({
  lifecycle: { color: theme.palette.text.primary },
  registrationWarning: { color: '#FF7E07' },
  registrationClose: { color: colors.red[500] },
  registrationOpen: { color: colors.green[500] },
  hints: {
    position: 'absolute',
    bottom: -12,
    right: -10,
    zIndex: 2,
  },
  price: {
    marginLeft: theme.spacing.unit * 1,
    height: 25,
    fontWeight: 'bold',
    borderRadius: '5px',
    backgroundColor: 'rgba(224, 225, 215, 1)',
    cursor: 'pointer',
  },
  slots: {
    marginLeft: theme.spacing.unit * 1,
    height: 25,
    fontWeight: 'bold',
    borderRadius: '5px',
    backgroundColor: 'rgba(132, 189, 227, 1)',
    cursor: 'pointer',
  },
  players: {
    marginLeft: theme.spacing.unit * 1,
    height: 25,
    fontWeight: 'bold',
    borderRadius: '5px',
    backgroundColor: 'rgba(61, 195, 77, 1)',
    cursor: 'pointer',
  },
  pro: {
    marginLeft: theme.spacing.unit * 1,
    height: 25,
    fontWeight: 'bold',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  proContent: {
    position: 'absolute',
    bottom: -12,
    left: -10,
    zIndex: 2,
  },
  headerContainer: {
    position: 'relative',
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit * 3,
  },
});

/* */
const getColorStatus = (status) => {
  switch (status) {
    case 1:
    case 2:
      return 'registrationOpen';
    case 3:
      return 'registrationWarning';
    default:
      return 'registrationClose';
  }
};

/* */
const Header = ({ classes, lesson, golf }) => {
  const {
    name,
    price,
    type,
    startsAt,
    endsAt,
    description,
    attendeeCount,
    maxGroupSize,
    skillLevels,
    slotCount,
    headCoach,
    lifecycle = 'registration',
  } = lesson;
  let icon;
  let status;
  let statusText;
  let classCSS = classes.lifecycle;
  const matchRoute = useRouteMatch('/academy/:id/individual');

  if (lifecycle === 'registration') {
    ({ status, statusText } = matchRoute
      ? true
      : Academy.functions.getRegistrationStatus(
        { ...lesson, openOnline: true },
          golf?.options,
      ));
    classCSS = classes[getColorStatus(status)];
    icon = status > 0 ? (
        <LockOpenIcon className={classCSS} />
    ) : (
        <LockIcon className={classCSS} />
    );
  } else if (lifecycle === 'teetime') {
    icon = <AccessTimeIcon className={classCSS} />;
    statusText = 'Départs publiés';
  } else if (lifecycle === 'results') {
    icon = <FormatListNumberedIcon className={classCSS} />;
    statusText = 'Résultats publiés';
  }

  return (
    <Box mb={3} pb={3} borderBottom={1} borderColor="#ddd" position="relative">
      <Box display="flex" direction="row" alignItems="flex-start">
        <CalendarDay
          datetime={moment(startsAt)}
          endTime={moment(endsAt)}
          displayTime={matchRoute}
        />
        <Box ml={3} flex={1} display="flex" flexDirection="column">
          <Typography color="secondary" variant="subtitle1">
            {type === 'GROUP' ? name : `Cours avec ${headCoach.lastName} ${headCoach.firstName}`}
          </Typography>
          {icon && statusText && (
            <Box display="flex" flexDirection="row" alignItems="center">
              {icon}&nbsp;
              <Typography className={classCSS} variant="body1">
                {statusText}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {headCoach && (
        <Box display="flex" flexDirection="row" alignItems="center" mt={2}>
          <MdOutlineSchool size={24} />
          &nbsp;
          <Box display="flex" flexDirection="row">
              <Typography color="secondary" variant="body1">
                {'Pro :'}
              </Typography>
          <Chip
            className={classes.pro}
            style={{ backgroundColor: headCoach?.color, color: 'white' }}
            color="default"
            size="small"
            label={`${headCoach?.lastName} ${headCoach?.firstName}`}
          />
          </Box>
        </Box>
      )}
      {skillLevels?.length > 0 && (
        <Box display="flex" flexDirection="row" alignItems="center" mt={1}>
          <MdOutlineDeveloperBoard size={24} />
          &nbsp;
          <Typography variant="body1">
            {`Niveaux: ${skillLevels.map((skill) => skill.name).join(', ')}`}
          </Typography>
        </Box>
      )}
      {price && (
        <Box display="flex" flexDirection="row" alignItems="center" mt={1}>
          <MdOutlineEuro size={24} />
          &nbsp;
          <Box display="flex" flexDirection="row">
              <Typography color="secondary" variant="body1">
                {'Tarif de base :'}
              </Typography>
              <Chip
                className={classes.price}
                color="default"
                size="small"
                label={parseFloat(price / 100).toFixed(2)}
              />
          </Box>
        </Box>
      )}
      <Typography variant="subtitle2" style={{
        fontStyle: 'italic',
        fontSize: 12,
        paddingLeft: 30,
        paddingTop: 10,
      }}>{'* Les tarifs peuvent varier en fonction de votre catégorie de joueur. Pour plus d’informations, merci de vous rapprocher de l\'accueil de votre golf.'}</Typography>
      {description && (
        <Box mt={1} display="flex" flexDirection="row" alignItems="start">
          <InfoOutlinedIcon />
          &nbsp;
          <Box display="flex" flexDirection="column">
            {description.split('<br/>').map((information, index) => (
              <Typography key={index} variant="body1">
                {information}
              </Typography>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default withStyles(styles)(Header);
