import React from 'react';
import moment from 'moment';
import { Event } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  colors,
  Typography,
} from '@material-ui/core';
import {
  Lock as LockIcon,
  Check as CheckIcon,
  LockOpen as LockOpenIcon,
} from '@material-ui/icons';
import {
  CalendarDay,
} from '_components/elements';

/* */
const styles = theme => ({
  lifecycle: { color: theme.palette.text.primary },
  lifecycleOpened: { color: colors.green[500] },
  lifecycleClosed: { color: colors.red[500] },
});

/* */
const Header = ({ classes, event }) => {
  const {
    name,
    lifecycle,
    startDate,
  } = event;

  let icon;
  let status;
  let statusText;
  let classCSS = classes.lifecycle;

  if (lifecycle === 'registration') {
    ({ status, statusText } = Event.functions.getRegistrationStatus(event));
    classCSS = status > 0 ? classes.lifecycleOpened : classes.lifecycleClosed;
    icon = status > 0
      ? <LockOpenIcon className={classCSS} />
      : <LockIcon className={classCSS} />;
  } else {
    statusText = 'Terminé';
    classCSS = classes.lifecycleOpened;
    icon = <CheckIcon className={classCSS} />;
  }

  return (
    <Box
      mb={3}
      pb={3}
      display="flex"
      direction="row"
      borderBottom={1}
      borderColor="#ddd"
      alignItems="flex-start">
      <CalendarDay datetime={moment(startDate, 'YYYY-MM-DD')} />
      <Box
        ml={3}
        flex={1}
        display="flex"
        flexDirection="column">
        <Typography
          color="secondary"
          variant="subtitle1">
          {Event.functions.sanitize(name)}
        </Typography>
        {(icon && statusText) && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center">
            {icon}&nbsp;
            <Typography
              className={classCSS}
              variant="body1">
              {statusText}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default withStyles(styles)(Header);
