import React from 'react';
import { connect } from 'react-redux';
import { Booking } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  Button,
  Typography,
} from '@material-ui/core';
import {
  ToggleButton,
  ToggleButtonGroup,
} from '@material-ui/lab';
import i18n from '_utils/i18n';

/* */
const styles = theme => ({
  toggleButton: {
    flex: 1,
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.dark,
      backgroundColor: theme.palette.common.white,
    },
    '&$selected': {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
      '&:hover': {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.dark,
      },
    },
  },
  selected: {},
  toggleButtonGroup: {
    display: 'flex',
    marginBottom: theme.spacing.unit * 4,
  },
  choiceButton: {
    marginBottom: theme.spacing.unit * 1,
  },
});

/* */
class SetCourse extends React.Component {
  /* */
  constructor(props) {
    super(props);

    const { search } = this.props;
    const activeType = (search.course) ? search.course.type : null;

    this.setAvailableCourses();
    this.state = { courseType: activeType };
  }

  /* */
  setAvailableCourses() {
    const { data, search } = this.props;

    this.availableCourses = data.courses.filter(c =>
      Booking.functions.checkCourseAvailability(c, search.date));
  }

  /* */
  onSelect(idx) {
    this.props.setCourse(this.availableCourses[idx]);
  }

  /* */
  handleSwitch = (event, type) => {
    if (type !== null) this.setState({ courseType: type });
  };

  /* */
  renderSwitch() {
    const { classes } = this.props;
    const { courseType } = this.state;
    const types = [];

    this.availableCourses.forEach((course) => {
      if (!course.type) return;
      if (types.includes(course.type)) return;
      types.push(course.type);
    });

    if (types.length === 0) return null;

    types.sort();

    return (
      <React.Fragment>
        <Typography
          paragraph
          component="p"
          variant="h6">
          {i18n.t('booking.titles.which_course_type')}
        </Typography>
        <ToggleButtonGroup
          exclusive
          selected
          className={classes.toggleButtonGroup}
          value={courseType}
          onChange={this.handleSwitch}>
          {types.map((type, key) => (
            <ToggleButton
              key={key}
              value={type}
              classes={{
                root: classes.toggleButton,
                selected: classes.selected,
              }}
            >
              {i18n.t(`terms.course_type_${type}`)}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </React.Fragment>
    );
  }

  /* */
  renderList() {
    const { courseType } = this.state;
    const {
      activity,
      classes,
      golf,
      search,
    } = this.props;

    const title = i18n.getFromOpts('booking.titles.which_course', golf.options, { activity });

    return (
      <React.Fragment>
        <Typography
          paragraph
          component="p"
          variant="h6">
          {title}
        </Typography>
        {this.availableCourses.map((c, i) => {
          let active = false;
          if (search.course
              && search.course.id === c.id
              && search.course.type === c.type) {
            active = true;
          }
          if (c.type === courseType) {
            return (
              <Button
                key={i}
                fullWidth
                color="secondary"
                className={classes.choiceButton}
                variant={active ? 'contained' : 'outlined'}
                onClick={() => this.onSelect(i)}>
                {c.name}
              </Button>
            );
          }
          return null;
        })}
      </React.Fragment>
    );
  }

  /* */
  render() {
    return (
      <Box m={1} mb={3}>
        { this.renderSwitch() }
        { this.renderList() }
      </Box>
    );
  }
}

const mapStateToProps = ({ app, bookingData, bookingSearch }) =>
  ({ data: bookingData, golf: app.golf, search: bookingSearch });

const StyledComponent = withStyles(styles)(SetCourse);

export default connect(
  mapStateToProps,
  Booking.actions,
)(StyledComponent);
