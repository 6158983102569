import { Academy } from "@aps-management/primapp-common";
import masterClient from '_utils/masterClient';
import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import {
  Chip,
  Avatar,
} from '@material-ui/core';
import {
  CheckCircle as CheckIcon,
  Warning as WarningIcon,
} from '@material-ui/icons';

/* */
const styles = (theme) => ({
  chip: {
    marginTop: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 1,
  },
  chipExceeded: {
    marginTop: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 1,
  },
  labelMore: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing.unit * 2,
  },
});

const RenderChip = ({ classes, entrant, handleDeleteEntrant, setListExceededQuotaPlayers, setOpenPartners, matchRoute, index }) => {
  const { data: quotaData } = useQuery(
    ['academy-lesson-quotas', entrant?.id, matchRoute ? 'INDIVIDUAL' : 'GROUP'],
    () => Academy.api.getLessonRegistrationsQuota(masterClient, {
      customerId: entrant?.id,
      lessonType: matchRoute ? 'INDIVIDUAL' : 'GROUP',
    }),
    {
      enabled: !!entrant?.id,
      retry: 0,
      cacheTime: 0,
      initialData: () => ({
        quotas: 1,
        isExceeded: false,
        __typename: "LessonRegistrationQuotaResponse"
      }),
    },
  );

  useEffect(() => {
    if (quotaData?.isExceeded) {
      setListExceededQuotaPlayers((prev) => [...prev.filter((p) => p.id !== entrant?.id), entrant]);
    } else {
      setListExceededQuotaPlayers((prev) => prev.filter((p) => p.id !== entrant?.id));
    }
  }, [entrant, quotaData, setListExceededQuotaPlayers]);

  const isOwner = entrant?.type === 'owner';
  const isAnonymous = entrant?.type === 'anonymous';
  const isDeletable = (entrant?.type !== 'owner' && entrant?.type !== 'anonymous');

  const fullname = isAnonymous
    ? 'Participant anonyme'
    : `${entrant?.firstname} ${entrant?.lastname}`;

  const customProps = {
    label: fullname,
    clickable: true,
    color: isOwner ? 'primary' : 'secondary',
    style: quotaData?.isExceeded ? {
      backgroundColor: '#d3772b',
    } : styles.chip,
    avatar: <Avatar style={{
      backgroundColor: quotaData?.isExceeded ? '#a34700' : 'unset',
    }}>{quotaData?.isExceeded ? (<WarningIcon />) : (<CheckIcon />)}</Avatar>,
  };

  if (isAnonymous) {
    customProps.avatar = null;
    customProps.variant = 'outlined';
    customProps.onClick = () => setOpenPartners(true);
  }
  if (isOwner) {
    customProps.color = 'primary';
    customProps.clickable = false;
  }
  if (isDeletable) {
    customProps.onDelete = (() => handleDeleteEntrant(index));
  }

  return (<Chip className={classes.chip} {...customProps} />);
}

export default RenderChip;
