import React from 'react';
import _ from 'lodash/fp/lang';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { App, helpers } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {
  Paper,
  Avatar,
  Typography,
} from '@material-ui/core';
import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import { ResetPasswordForm } from '_components/forms';
import { createValidationSchema, validators } from '_utils/validation';

const successImg = require('@aps-management/primapp-common/assets/images/tick.png');

/* */
const validationSchema = createValidationSchema({
  password: validators.password,
  passwordConfirm: validators.passwordConfirm,
});

/* */
const styles = theme => ({
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: theme.spacing.unit * 4,
    padding: theme.spacing.unit * 3,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  avatar: {
    marginBottom: theme.spacing.unit * 2,
    backgroundColor: theme.palette.secondary.main,
  },
  successImg: {
    color: theme.palette.common.white,
    margin: '1rem auto',
    position: 'relative',
    width: '10rem',
    height: '10rem',
  },
});

/* */
class ResetPassword extends React.Component {
  /* */
  state = {
    error: null,
    success: null,
    loading: true,
  };

  /* */
  componentDidMount() {
    const { hash } = this.props.match.params;
    this.hash = hash;

    App.api.forgotPasswordCheck(hash)
      .then((res) => {
        if (res.golfId) {
          this.props.loadGolf(apolloClient, { id: res.golfId });
        } else {
          this.props.noGolf();
        }
      })
      .catch((error) => {
        this.setState({ loading: false, error: i18n.t(error.message) });
      });
  }

  /* */
  componentDidUpdate(prevProps) {
    const { golf } = this.props;

    // load golf
    if (!_.isEqual(golf, prevProps.golf)) {
      document.title = (golf && golf.name) || 'prima.golf';
      this.setState({ loading: false });
    }
  }

  /* */
  onSubmit = (values, { resetForm, setSubmitting }) => {
    this.setState({ error: null });

    setSubmitting(false);
    App.api.resetPassword(this.hash, values)
      .then(() => this.setState({ success: i18n.t('Mot de passe enregistré.') }))
      .then(() => resetForm())
      .catch(error => this.setState({ error: i18n.t(error.message) }))
      .finally(() => setSubmitting(false));
  };

  /* */
  render() {
    const { classes, golf } = this.props;
    const { loading, error, success } = this.state;

    return (
      <Screen
        noDrawer
        error={error}
        layout='fixed'
        loading={loading}>
        <Typography component="h1" variant="h5" align="center">
          {golf && golf.name}
        </Typography>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography gutterBottom component="h2" variant="h5">
            {i18n.t('Choisir mon mot de passe.')}
          </Typography>
          {!error && success && (
            <Box m={2} textAlign="center">
              <img src={successImg} alt="ok" className={classes.successImg} />
              <Typography paragraph variant="h6" color="textSecondary">
                {success}
              </Typography>
            </Box>
          )}
          {!success && (
            <React.Fragment>
              {error && (
                <Box m={2} textAlign="center">
                  <Typography paragraph variant="subtitle1" color="error">
                    {helpers.string.handleError(error.message || error || '')}
                  </Typography>
                </Box>
              )}
              {!error && (
                <Formik
                  component={ResetPasswordForm}
                  initialValues={{
                    password: '',
                    passwordConfirm: '',
                  }}
                  validationSchema={validationSchema}
                  onSubmit={this.onSubmit}
                />
              )}
            </React.Fragment>
          )}
        </Paper>
      </Screen>
    );
  }
}

const mapStateToProps = ({ app }) => ({ golf: app.golf });

export default connect(mapStateToProps, App.actions)(withStyles(styles)(ResetPassword));
