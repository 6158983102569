import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Booking, HELP_URL } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import { FaceOutlined, InfoOutlined } from '@material-ui/icons';
import {
  Chip,
  Grid,
  Paper,
  Button,
  ButtonBase,
  Typography,
} from '@material-ui/core';
/* */
import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import { CalendarDay } from '_components/elements';

/* */
const styles = theme => ({
  item: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    padding: theme.spacing.unit * 2,
  },
  chip: {
    display: 'flex',
    borderColor: 'transparent',
    justifyContent: 'flex-start',
  },
  chipIcon: {
    marginLeft: 0,
    marginRight: theme.spacing.unit * -0.5,
  },
  canceled: {
    top: 16,
    right: -90,
    width: 250,
    overflow: 'hidden',
    fontWeight: 'bold',
    lineHeight: '30px',
    textAlign: 'center',
    position: 'absolute',
    transform: 'rotate(40deg)',
    boxShadow: theme.shadows[3],
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.dark,
  },
  itemContainer: { display: 'block' },
  itemOther: { backgroundColor: theme.palette.grey[50] },
  itemCanceled: { backgroundColor: theme.palette.grey[200] },
});

const LIMIT = 3;

/* */
class BookingHistory extends React.Component {
  /* */
  state = {
    // Data
    last: [],
    comingSoon: [],
    // UX
    showMore: null,
    // Screen
    error: null,
    loading: true,
  };

  /* */
  componentDidMount() {
    this.load();
  }

  /* */
  load = () => {
    this.setState({
      error: null,
      loading: true,
    });

    const { golf } = this.props;

    Booking.api.getBookingList(apolloClient, { golfId: golf.id })
      .then(({ bookings }) => {
        const last = [];
        const comingSoon = [];

        bookings.forEach((b) => {
          const datetime = moment(`${b.date} ${b.time}`, 'YYYY-MM-DD HHmm');
          if (datetime.isBefore()) {
            last.push(b);
          } else {
            comingSoon.push(b);
          }
        });

        this.setState({
          last,
          comingSoon,
          loading: false,
          ...(last.length > LIMIT && { showMore: false }),
        });
      })
      .catch(e => this.setState({ error: e.message }))
      .finally(() => this.setState({ loading: false, loadingMore: false }));
  };

  /* */
  goToView = item => () => {
    this.props.detailBooking(item);
    this.props.history.push(`/mybookings/${item.id || 'details'}`);
  }

  /* */
  renderItem = (item, key) => {
    const { classes, golf } = this.props;

    const isCanceled = item.status === 'canceled';
    const fromAnother = item.golf.id !== golf.id;

    const bookingDate = moment(`${item.date} ${item.time}`, 'YYYY-MM-DD HHmm');

    return (
      <Grid
        item
        key={key}
        md={6} sm={10} xs={12}>
        <ButtonBase
          disableRipple
          component="div"
          onClick={this.goToView(item)}
          className={classes.itemContainer}>
          <Paper
            className={classNames(classes.item, {
              [classes.itemOther]: fromAnother,
              [classes.itemCanceled]: isCanceled,
            })}>
            <Box
              display="flex"
              flexDirection="row">
              <CalendarDay
                displayTime={true}
                datetime={bookingDate} />
              <Box ml={3} flex={1}>
                {fromAnother && (
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                    component="span">
                    {item.golf.name}
                  </Typography>
                )}
                <Typography
                  variant="subtitle1"
                  component="span">
                  {item.course.name}
                </Typography>
                <Typography
                  component="span"
                  variant="subtitle1">
                  {i18n.t(`terms.course_type_${item.course.type}`)}
                </Typography>
                {item.nbPlayers > 0 && (
                  <Chip
                    variant="outlined"
                    icon={<FaceOutlined />}
                    classes={{ icon: classes.chipIcon, outlined: classes.chip }}
                    label={`${i18n.t('terms.players', { count: item.nbPlayers })}`} />
                )}
                {item.nbAccessories > 0 && (
                  <Chip
                    variant="outlined"
                    icon={<InfoOutlined />}
                    classes={{ icon: classes.chipIcon, outlined: classes.chip }}
                    label={`${i18n.t('terms.rentals', { count: item.nbAccessories })}`} />
                )}
              </Box>
            </Box>
            {isCanceled && (
              <Typography
                component="span"
                variant='caption'
                className={classes.canceled}>
                {'Annulée'}
              </Typography>
            )}
          </Paper>
        </ButtonBase>
      </Grid>
    );
  }

  /* */
  renderEmpty() {
    return (
      <Box textAlign="center">
        <Typography
          paragraph
          variant="h5"
          align="center"
          component="h2">
          {'Aucune réservation pour le moment.'}
        </Typography>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => this.props.history.push('/booking')}>
          {'Réserver'}
        </Button>
      </Box>
    );
  }

  /* */
  renderHistory() {
    const { last, comingSoon, showMore } = this.state;

    if ((last.length + comingSoon.length) === 0) {
      return this.renderEmpty();
    }

    // reduce array elements
    const renderedLast = (showMore === false)
      ? last.filter((l, idx) => idx < LIMIT)
      : last;

    return (
      <React.Fragment>
        {comingSoon.length > 0 && (
          <Box mb={4}>
            <Typography
              paragraph
              variant="h6"
              component="h2">
              {'Mes prochaines réservations'}
            </Typography>
            <Grid
              container
              spacing={24}>
              {comingSoon.map(this.renderItem)}
            </Grid>
          </Box>
        )}
        {last.length > 0 && (
          <Box mb={2}>
            <Typography
              paragraph
              variant="h6"
              component="h2">
              {'Mes réservations passées'}
            </Typography>
            <Grid
              container
              spacing={24}>
              {renderedLast.map(this.renderItem)}
            </Grid>
          </Box>
        )}
        {showMore === false && (
          <Button
            fullWidth
            color="secondary"
            onClick={() => this.setState({ showMore: true })}>
            {'Voir plus'}
          </Button>
        )}
      </React.Fragment>
    );
  }

  /* */
  render() {
    const { error, loading } = this.state;

    return (
      <Screen
        error={error}
        loading={loading}
        helpURL={`${HELP_URL}/mes-r%C3%A9servations`}
        title="Mes réservations">
        {!loading && this.renderHistory()}
      </Screen>
    );
  }
}

const mapStateToProps = ({ app }) => ({ golf: app.golf });

const StyledComponent = withStyles(styles)(BookingHistory);

export default connect(
  mapStateToProps,
  Booking.actions,
)(StyledComponent);
