import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Event, Tournament } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  colors,
  Input,
  Paper,
  Table,
  Select,
  Collapse,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  InputLabel,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';

import Header from './_Header';

/* */
const styles = theme => ({
  flex1: { flex: 1 },
  flex2: { flex: 2 },
  flex3: { flex: 3 },
  bold: { fontWeight: 600 },
  clickable: { cursor: 'pointer' },
  flagCol: { flex: 0.5, padding: 0 },
  flex8: { flex: 8, alignItems: 'center' },
  table: {
    '& tr': {
      '& td, & th': {
        padding: theme.spacing.unit * 1,
      },
    },
  },
  theader: {
    backgroundColor: colors.grey[50],
  },
  flag: {
    width: 20,
    height: 12,
    border: `1px solid ${colors.grey[500]}`,
  },
  resultCell: {
    flex: 1,
    justifyContent: 'center',
  },
  resultX: {
    backgroundColor: colors.grey[300],
  },
  resultGood: {
    color: colors.common.white,
    backgroundColor: colors.green[500],
  },
  resultBad: {
    color: colors.common.white,
    backgroundColor: colors.red[500],
  },
});

/* */
const ResultCell = withStyles(styles)(({ par, score = null, classes }) => {
  let className = null;

  let data = (score == null) ? par : score;

  if (score && score > 0) {
    const difference = score - par;

    if (difference !== 0) {
      className = (difference > 0) ? classes.resultBad : classes.resultGood;
    }
  } else if (score === 0) {
    data = 'X';
    className = classes.resultX;
  }

  return (
    <TableCell className={classNames(classes.resultCell, className)}>
      <Typography align="center" variant="body2" color="inherit">{data}</Typography>
    </TableCell>
  );
});

/* */
const ResultTable = props => (
  <TableRow>
    <TableCell colSpan={5} style={{ padding: 16 }}>
      {props.children}
    </TableCell>
  </TableRow>
);

/* */
class TournamentResults extends React.Component {
  /* */
  state = {
    // Screen
    error: null,
    // Data
    type: 'B',
    series: 0,
    results: [],
    loading: true,
    activeRows: [],
    tournament: null,
  };

  /* */
  id = null;

  /* */
  typesList = [
    { value: 'B', label: 'Brut' },
    { value: 'N', label: 'Net' },
  ];

  /* */
  seriesList = [
    'Toutes',
  ];

  /* */
  firstLoading = true;

  /* */
  tempResults = [];

  /* */
  componentDidUpdate(prevProps, prevState) {
    // On change results type
    if (prevState.type !== this.state.type) {
      this.load();
    }
    // On change results series
    if (prevState.series !== this.state.series) {
      this.filterResults(this.state.series);
    }
  }

  /* */
  componentDidMount() {
    this.id = this.props.match.params.id;
    this.load();
  }

  /* */
  filterResults(series) {
    let temp = [];

    if (series === 0) {
      temp = this.tempResults;
    } else {
      temp = this.tempResults.filter(r => r.series === this.seriesList[series]);
    }

    const results = Tournament.functions.getResultsByRank(temp);

    this.setState({ results, activeRows: [] });
  }

  /* */
  load() {
    const { id } = this;
    const { type, series } = this.state;
    const {
      tournaments,
      golf: { id: golfId },
    } = this.props;

    if (!id) {
      return this.setState({
        results: [],
        loading: false,
        error: 'errors.not_found',
      });
    }

    // Reset state
    this.setState({
      error: null,
      results: [],
      loading: true,
      activeRows: [],
    });

    // Reset data
    this.tempResults = [];

    return Promise.resolve()
      .then(() => {
        if (this.state.tournament) return Promise.resolve();
        // Try to load from store
        const trnmt = tournaments && tournaments.find(t => t.id === id);
        if (trnmt) return this.setState({ tournament: trnmt });
        // Otherwise load from API
        return Tournament.api.getTournament(apolloClient, { id, golfId })
          .then(tournament => this.setState({ tournament }));
      })
      .then(() => Tournament.api.getResults(apolloClient, { id, type, golfId }))
      .then(({ results }) => {
        this.tempResults = results;
        this.filterResults(series);

        if (this.firstLoading) {
          this.firstLoading = false;

          Tournament.functions.getSeriesList(results)
            .forEach(s => this.seriesList.push(s));
        }
      })
      .catch(e => this.setState({ error: e.message }))
      .finally(() => this.setState({ loading: false }));
  }

  /* */
  onChange = field => (e) => { this.setState({ [field]: e.target.value }); }

  /* */
  toggleScorecard = player => () => {
    const { activeRows } = this.state;
    const idx = activeRows.indexOf(player);
    if (idx >= 0) {
      activeRows.splice(idx, 1);
    } else {
      activeRows.push(player);
    }
    this.setState({ activeRows });
  };

  /* */
  renderResults() {
    const { classes } = this.props;
    const {
      type,
      series,
      loading,
      results,
    } = this.state;

    if (!results) return null;

    return (
      <React.Fragment>
        <Box
          p={2}
          mb={2}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end">
          <Box
            mr={2}
            flex={2}
            display="flex"
            flexDirection="column"
            justifyContent="space-around">
          {results[0] && results[0].length === 1 && (
            <React.Fragment>
              <InputLabel shrink htmlFor="select-series">
                {'Série'}
              </InputLabel>
              <Select
                value={series}
                label='Série'
                input={<Input name="series" id="select-series" />}
                onChange={this.onChange('series')}>
                {this.seriesList.map((label, idx) => (
                  <MenuItem key={idx} value={idx}>{label}</MenuItem>
                ))}
              </Select>
            </React.Fragment>
          )}
          </Box>
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="space-around">
            <InputLabel shrink htmlFor="select-type">
              {'Type'}
            </InputLabel>
            <Select
              value={type}
              label='Type'
              input={<Input name="type" id="select-type" />}
              onChange={this.onChange('type')}>
              {this.typesList.map(({ label, value }, idx) => (
                <MenuItem key={idx} value={value}>{label}</MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.flex1}>
                  <Typography variant="body2" className={classes.bold}>{'#'}</Typography>
                </TableCell>
                <TableCell className={classes.flagCol} />
                <TableCell className={classes.flex8} />
                <TableCell align='right' className={classes.flex2}>
                  <Typography variant="body2" className={classes.bold}>{'Index'}</Typography>
                </TableCell>
                <TableCell align='right' className={classes.flex2}>
                  <Typography variant="body2" className={classes.bold}>{'Total'}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <div className={classes.loaderContainer}>
                  <CircularProgress
                    size={32}
                    thickness={4}
                    color="secondary" />
                  </div>
              )}
              {results && results.map(this.renderRank)}
            </TableBody>
          </Table>
        </Paper>
      </React.Fragment>
    );
  }

  /* */
  renderRank = (rank, i) => {
    const { classes } = this.props;
    const { activeRows } = this.state;
    return rank.map((player, j) => (
      <React.Fragment key={i + j}>
        <TableRow
          onClick={this.toggleScorecard(`${i}-${j}`)}
          className={classes.clickable}
          style={{ backgroundColor: (i % 2) ? '#f6f6f6' : '#fff' }}>
          <TableCell className={classes.flex1}>
            {j === 0 && <Typography variant="body2" className={classes.bold}>{i + 1}</Typography>}
          </TableCell>
          <TableCell className={classes.flagCol}>
            <img
              className={classes.flag}
              alt={player.nationality}
              src={`https://web.ffgolf.org/resultats/img/flag/${player.nationality}.png` } />
          </TableCell>
          <TableCell className={classes.flex8}>
            <Typography variant="body2">{player.firstname} {player.lastname}</Typography>
          </TableCell>
          <TableCell align='right' className={classes.flex2}>
            <Typography variant="body2">{player.hcp.toFixed(1)}</Typography>
          </TableCell>
          <TableCell align='right' className={classes.flex2}>
            <Typography variant="body2" className={classes.bold}>{player.result}</Typography>
          </TableCell>
        </TableRow>
        <Collapse component={ResultTable} in={activeRows.includes(`${i}-${j}`)} timeout="auto" unmountOnExit>
          {player.scorecard.map((sc, k) => {
            const nbOfHoles = sc.par.length;
            const totalPar = sc.par.reduce((counter, value) => counter + value, 0);
            const totalScore = sc.scores.reduce((counter, value) => counter + value, 0);

            return (
              <Table className={classes.table} key={k}>
                <TableHead className={classes.theader}>
                  <TableRow className={classes.flex3}>
                    <TableCell className={classes.flex1}>
                      <Typography variant="body2" className={classes.bold}>{'TROU'}</Typography>
                    </TableCell>
                    {[...Array(nbOfHoles)].map((el, l) => (
                      <TableCell key={l} className={classes.flex1}>
                        <Typography align="center" className={classes.bold} variant="body2">{l + 1}</Typography>
                      </TableCell>
                    ))}
                    <TableCell className={classes.flex2}>
                      <Typography align="right" variant="body2" className={classes.bold}>{'TOT'}</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.flex3}>
                      <Typography variant="body2" className={classes.bold}>{'PAR'}</Typography>
                    </TableCell>
                    {[...Array(nbOfHoles)].map((el, l) => (
                      <ResultCell key={l} par={sc.par[l]} />
                    ))}
                    <TableCell className={classes.flex2}>
                      <Typography align="right" variant="body2" className={classes.bold}>{totalPar}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.flex3}>
                      <Typography variant="body2" className={classes.bold}>{`T${k + 1}`}</Typography>
                    </TableCell>
                    {[...Array(nbOfHoles)].map((el, l) => (
                      <ResultCell
                        key={l}
                        par={sc.par[l]}
                        score={sc.scores[l]} />
                    ))}
                    <TableCell className={classes.flex2}>
                      <Typography align="right" variant="body2" className={classes.bold}>{totalScore}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            );
          })}
        </Collapse>
      </React.Fragment>
    ));
  }

  /* */
  render() {
    const { error, loading, tournament } = this.state;

    return (
      <Screen
        error={error}
        loading={loading}
        title="Compétitions - Résultats">
        {tournament && (
          <React.Fragment>
            <Header tournament={tournament} />
            {this.renderResults()}
          </React.Fragment>
        )}
      </Screen>
    );
  }
}

const mapStateToProps = ({ app, tournament }) => ({
  golf: app.golf,
  account: app.account,
  tournaments: tournament.list,
});

const StyledComponent = withStyles(styles)(TournamentResults);

export default connect(
  mapStateToProps,
  Event.actions,
)(StyledComponent);
