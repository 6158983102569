import React from 'react';
import { Formik } from 'formik';
import { App } from '@aps-management/primapp-common';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {
  Link,
  Paper,
  Avatar,
  Typography,
} from '@material-ui/core';
import { Screen } from '_components/core';
import { RegisterForm } from '_components/forms';
import { createValidationSchema, validators } from '_utils/validation';

/* */
const validationSchema = createValidationSchema({
  email: validators.email,
  password: validators.password,
});

/* */
const styles = theme => ({
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: theme.spacing.unit * 4,
    padding: theme.spacing.unit * 3,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  avatar: {
    marginBottom: theme.spacing.unit * 2,
    backgroundColor: theme.palette.secondary.main,
  },
});

/* */
class Register extends React.Component {
  /* */
  state = {
    error: null,
    success: null,
  };

  /* */
  onSubmit = (values, { resetForm, setSubmitting }) => {
    this.setState({ error: null });
    App.api.signUp(values.email, values.password)
      .catch((error) => {
        this.setState({ error: error.message });
        resetForm();
        setSubmitting(false);
      });
  };

  /* */
  render() {
    const { classes, golf } = this.props;
    const { error, success } = this.state;

    return (
      <Screen
        noDrawer
        error={error}
        layout='fixed'
        success={success}>
        <Typography component="h1" variant="h5" align="center">
          {golf && golf.name}
        </Typography>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography gutterBottom component="h2" variant="h5">
            {'Inscription visiteur'}
          </Typography>
          <Typography gutterBottom variant="body2" align="justify">
            {'Attention : Si vous êtes membre ou abonné(e) du golf, ce système d\'inscription ne vous est pas destiné. Merci de vous rapprocher de votre golf pour l\'activation de votre compte.'}
          </Typography>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            component={RegisterForm}
            onSubmit={this.onSubmit}
            validationSchema={validationSchema} />
          <Box mt={2}>
            <Typography>
              {'En continuant, vous acceptez nos '}
              <Link
                rel="noopener"
                target="_blank"
                color="textSecondary"
                href="/terms-conditions">
                {"Conditions générales d'utilisation"}
              </Link>
              {' et notre '}
              <Link
                rel="noopener"
                target="_blank"
                color="textSecondary"
                href="/privacy-policy">
                {'Politique de confidentialité.'}
              </Link>
            </Typography>
          </Box>
        </Paper>
        <Box textAlign="center">
          <Typography>
            {'Déjà inscrit ?'}
          </Typography>
          <Link
            to="/login"
            color="textSecondary"
            component={RouterLink}>
            {'Connectez-vous'}
          </Link>
        </Box>
      </Screen>
    );
  }
}

const mapStateToProps = ({ app }) => ({ golf: app.golf });

export default connect(mapStateToProps)(withStyles(styles)(Register));
