import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  App,
  Event,
  Directory,
} from '@aps-management/primapp-common';
import {
  Chip,
  Grid,
  Avatar,
  Button,
  Typography,
} from '@material-ui/core';
import {
  CheckCircle as CheckIcon,
} from '@material-ui/icons';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import ChoosePartners from '_components/ChoosePartners';
import { Alert, Incrementer } from '_components/elements';

import i18n from '_utils/i18n';
import Header from '../_Header';

/* */
const styles = theme => ({
  chip: {
    marginRight: theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit * 2,
  },
});

/* */
class SetPlayers extends React.Component {
  /* */
  state = {
    // Screen
    error: null,
    loading: true,
    // Data
    openForm: false,
    openPartners: false,
    buttonDisabled: true,
  };

  /* */
  eventIdx = 0;

  /* */
  openFormDialog = () => this.setState({ openForm: true });

  /* */
  closeFormDialog = () => this.setState({ openForm: false });

  /* */
  openPartnersDialog = () => this.setState({ openPartners: true });

  /* */
  closePartnersDialog = () => this.setState({ openPartners: false });

  /* */
  componentDidMount() {
    this.id = this.props.match.params.id;
    const { enter: { entrants } } = this.props;
    this.setState({ buttonDisabled: entrants.some(e => e.type === 'anonymous') });

    this.load();
  }

  /* */
  componentDidUpdate() {
    const { enter: { entrants } } = this.props;
    const buttonDisabled = entrants.some(e => e.type === 'anonymous');

    if (this.state.buttonDisabled !== buttonDisabled) {
      this.setState({ buttonDisabled });
    }
  }

  /* */
  load() {
    const { id } = this;
    const { account, golf } = this.props;

    if (!id) {
      return this.setState({
        loading: false,
        error: 'errors.not_found',
      });
    }

    // Reset state
    this.setState({
      error: null,
      loading: true,
    });

    const match = App.functions.match(account, golf) || { isMember: false, reference: 'UNKNOWN' };

    return Event.api.getEvent(apolloClient, { id, golfId: golf.id, reference: match.reference })
      .then((event) => {
        const {
          email,
          phone,
          lastname,
          firstname,
        } = account;

        this.props.initialize({
          events: [{
            ...event,
            name: Event.functions.sanitize(event.name),
          }],
          status: ['init'],
        });
        this.props.addEntrant({
          email,
          phone,
          lastname,
          firstname,
          type: 'owner',
          isMember: match.isMember,
          reference: match.reference,
        });
      })
      .catch(e => this.setState({ error: e.message }))
      .finally(() => this.setState({ loading: false }));
  }

  /* */
  onNext = () => {
    const { enter } = this.props;
    // hydrate entrants
    enter.entrants.forEach((e) => {
      e.events = []; // no event options selected yet
      e.tournament = null;
    });
    enter.total = { tournament: 0, events: 0 };
    enter.status.push('entrants');
    this.props.history.push(`/event/${this.id}/fees`);
  }

  /* */
  handleIncrease = () => {
    this.props.addEntrant({
      email: null,
      phone: null,
      lastname: null,
      firstname: null,
      isMember: false,
      type: 'anonymous',
      reference: 'UNKNOWN',
    });
  }

  /* */
  handleDecrease = () => {
    const { enter: { entrants } } = this.props;
    this.props.adjustEntrants(entrants.length - 1);
  }

  /* */
  handleDeleteEntrant(i) {
    this.props.replaceEntrant({
      email: null,
      phone: null,
      lastname: null,
      firstname: null,
      isMember: false,
      type: 'anonymous',
      reference: 'UNKNOWN',
    }, i);
  }

  /* */
  renderChip = (entrant, i) => {
    const { classes } = this.props;

    const isOwner = entrant.type === 'owner';
    const isAnonymous = entrant.type === 'anonymous';
    const isDeletable = (entrant.type !== 'owner' && entrant.type !== 'anonymous');

    const fullname = isAnonymous
      ? 'Participant anonyme'
      : `${entrant.firstname} ${entrant.lastname}`;

    const customProps = {
      label: fullname,
      clickable: true,
      color: 'secondary',
      style: styles.chip,
      avatar: <Avatar><CheckIcon /></Avatar>,
    };

    if (isAnonymous) {
      customProps.avatar = null;
      customProps.variant = 'outlined';
      customProps.onClick = this.openPartnersDialog;
    }
    if (isOwner) {
      customProps.color = 'primary';
      customProps.clickable = false;
    }
    if (isDeletable) {
      customProps.onDelete = (() => this.handleDeleteEntrant(i));
    }

    return (<Chip key={i} className={classes.chip} {...customProps} />);
  }

  /* */
  renderPartnersDialog() {
    const { openPartners } = this.state;
    const { enter: { entrants } } = this.props;

    return (
      <ChoosePartners
        partners={entrants}
        open={openPartners}
        onClose={this.closePartnersDialog}
        replacePartner={this.props.replaceEntrant} />
    );
  }

  /* */
  renderContent() {
    const { buttonDisabled } = this.state;
    const { enter: { entrants, events } } = this.props;
    const event = events[this.eventIdx];

    // Check event exists
    if (!event) return null;

    // Check event is opened
    const isOpened = Event.functions.isOpened(event);
    if (!isOpened) {
      return <Redirect to={'/event'} />;
    }

    // Check number of entrants allowed
    const { limit, counter, partnerAllowed } = event;

    const number = entrants.length;
    let maxNumber = partnerAllowed + 1;

    let alertMessage;
    if (limit) {
      const ticketRemaining = limit - counter;
      if (ticketRemaining < maxNumber) {
        maxNumber = ticketRemaining;
        alertMessage = `Il reste ${maxNumber} place(s) disponible(s).`;
      }
    }

    if (maxNumber === number) {
      alertMessage = 'Vous avez atteint le nombre de participants maximum autorisé.';
    }

    return (
      <React.Fragment>
        <Header event={event} />
        <Typography
          gutterBottom
          variant="body1">
          {'Combien serez-vous ?'}
        </Typography>
        <br />
        <Grid container spacing={40}>
          <Grid item xs={12} sm={6} md={5}>
            <Incrementer
              minValue={1}
              value={number}
              maxValue={maxNumber}
              onDecrease={this.handleDecrease}
              onIncrease={this.handleIncrease} />
          </Grid>
          <Grid item xs={12} sm={6} md={7}>
            {entrants.map(this.renderChip)}
            {alertMessage && (
              <Alert variant="info">{alertMessage}</Alert>
            )}
          </Grid>
        </Grid>
        <br />
        <Button
          fullWidth
          size="large"
          color="secondary"
          variant="contained"
          onClick={this.onNext}
          disabled={buttonDisabled}>
          {'Continuer'}
        </Button>
        {this.renderPartnersDialog()}
      </React.Fragment>
    );
  }

  /* */
  render() {
    const { golf } = this.props;
    const {
      error,
      loading,
    } = this.state;

    return (
      <Screen
        error={error}
        loading={loading}
        title={i18n.getFromOpts('event_signin.title', golf.options)}>
        {this.renderContent()}
      </Screen>
    );
  }
}

const mapStateToProps = ({ app, eventEnter, event }) => ({
  golf: app.golf,
  enter: eventEnter,
  account: app.account,
  events: event.list,
});
const mapDispatchToProps = { ...App.actions, ...Event.actions, ...Directory.actions };

const styledComponent = withStyles(styles)(SetPlayers);

export default connect(mapStateToProps, mapDispatchToProps)(styledComponent);
