import React from 'react';
import classNames from 'classnames';
import {
  Paper,
  Typography,
} from '@material-ui/core';
import {
  InfoOutlined,
  ErrorOutlined,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

/* */
const styles = theme => ({
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing.unit * 2,
    color: theme.palette.common.white,
    marginBottom: theme.spacing.unit * 2,
  },
  icon: { marginRight: 16 },
  info: { backgroundColor: '#32B5E5', fontWeight: 'bold' },
  error: { backgroundColor: '#FF3446', fontWeight: 'bold' },
  warning: { backgroundColor: '#FFBB32' , fontWeight: 'bold' },
});

const Alert = ({
  classes,
  children,
  className,
  variant = 'error',
  as,
  ...props
}) => {
  const Component = as || Typography;
  return (
    children && (
      <Paper
        {... props}
        className={classNames(
          classes.paper,
          classes[variant],
          className,
        )}>
        {(variant === 'info')
          ? <InfoOutlined className={classes.icon}/>
          : <ErrorOutlined className={classes.icon}/>}
        <Component
          color="inherit"
          variant="body2">
          {children}
        </Component>
      </Paper>
    )
  )
};

export default withStyles(styles)(Alert);
