import React from 'react';

/* */
const image01d = require('@aps-management/primapp-common/assets/images/weather/01d.png');
const image01n = require('@aps-management/primapp-common/assets/images/weather/01n.png');
const image02d = require('@aps-management/primapp-common/assets/images/weather/02d.png');
const image02n = require('@aps-management/primapp-common/assets/images/weather/02n.png');
const image03d = require('@aps-management/primapp-common/assets/images/weather/03d.png');
const image03n = require('@aps-management/primapp-common/assets/images/weather/03n.png');
const image04d = require('@aps-management/primapp-common/assets/images/weather/04d.png');
const image04n = require('@aps-management/primapp-common/assets/images/weather/04n.png');
const image09d = require('@aps-management/primapp-common/assets/images/weather/09d.png');
const image09n = require('@aps-management/primapp-common/assets/images/weather/09n.png');
const image10d = require('@aps-management/primapp-common/assets/images/weather/10d.png');
const image10n = require('@aps-management/primapp-common/assets/images/weather/10n.png');
const image11d = require('@aps-management/primapp-common/assets/images/weather/11d.png');
const image11n = require('@aps-management/primapp-common/assets/images/weather/11n.png');
const image13d = require('@aps-management/primapp-common/assets/images/weather/13d.png');
const image13n = require('@aps-management/primapp-common/assets/images/weather/13n.png');
const image50d = require('@aps-management/primapp-common/assets/images/weather/50d.png');
const image50n = require('@aps-management/primapp-common/assets/images/weather/50n.png');

/* */
const images = {
  '01d': image01d,
  '01n': image01n,
  '02d': image02d,
  '02n': image02n,
  '03d': image03d,
  '03n': image03n,
  '04d': image04d,
  '04n': image04n,
  '09d': image09d,
  '09n': image09n,
  '10d': image10d,
  '10n': image10n,
  '11d': image11d,
  '11n': image11n,
  '13d': image13d,
  '13n': image13n,
  '50d': image50d,
  '50n': image50n,
};

/* */
const WeatherPreview = ({
  icon,
  alt = '',
  width = 60,
  height = 60,
  ...props
}) => (
  <img
    alt={alt}
    src={images[icon]}
    style={{ height, width }}
    {...props} />
);
export default WeatherPreview;
