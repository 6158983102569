import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  App,
  helpers,
  Booking,
  Account,
  HELP_URL,
} from '@aps-management/primapp-common';
import {
  Chip,
  Paper,
  Grid,
  List,
  Avatar,
  Button,
  ListItem,
  Typography,
  ListItemText,
  ListSubheader,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {
  Face as FaceIcon,
  InfoOutlined as InfoIcon,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';

import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import { isFeatureEnabled } from '_utils/security';
import PaymentMethods from '_components/PaymentMethods';

/* */
const styles = theme => ({
  chip: { height: 'auto' },
  chipLabel: {
    padding: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 1}px`,
  },
  avatar: {
    height: 30,
    width: 30,
    backgroundColor: theme.palette.text.primary,
  },
  section: {
    margin: `${theme.spacing.unit * 1}px 0`,
  },
  included: {
    marginLeft: theme.spacing.unit * 1.5,
  },
  price: {
    color: theme.palette.text.secondary,
  },
  initPrice: {
    color: theme.palette.grey[500],
    textDecoration: 'line-through',
    marginLeft: theme.spacing.unit * 1.5,
  },
  subtotal: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  subheader: {
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  checkboxCtrl: {
    marginLeft: 0,
  },
  comment: {
    lineHeight: 'normal',
    marginLeft: theme.spacing.unit * 1.5,
    color: theme.palette.primary.main,
  },
  cancelBtn: {
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.dark,
      backgroundColor: theme.palette.common.white,
    },
  },
});

/* */
const getPlayerName = player => ((player.firstname && player.lastname)
  ? `${player.firstname} ${player.lastname}`
  : helpers.string.ucfirst(i18n.t('terms.anonymous')));

/* */
class BookingCart extends React.Component {
  /* */
  constructor(props) {
    super(props);

    const { data: { booking }, account, golf } = this.props;

    // payment methods
    this.paymentMethods = App.functions.getPaymentMethods(account, golf, 'booking');
    const paymentMethod = this.paymentMethods[0];

    // player types
    const playerTypes = account.playerTypes.filter(pt => pt.golfId === golf.id);
    this.hasPlayerTypes = Boolean(playerTypes.length);

    this.redirect = false;
    if (booking === null) {
      this.redirect = true;
    }

    // Si booking et course.handicap = null ou undefined, aucune condition !
    const areCourseConditionsAccepted = typeof booking?.course.handicap !== 'number';

    this.state = {
      // UI
      error: null,
      waiting: true,
      // Moyens de paiment / CGV
      paymentMethod,
      paymentMethodOpts: null,
      areTermsAccepted: false,
      // Covid
      covidCertificate: null,
      isCovidDisabled: !isFeatureEnabled(golf.options.features, 'global/covid'),
      // Conditions parcours
      areCourseConditionsAccepted,
    };
  }

  /* */
  componentDidMount() {
    if (this.redirect) return;

    const { loadAccount, golf } = this.props;
    loadAccount(apolloClient).then((upToDateAccount) => {
      this.setState({
        covidCertificate: upToDateAccount.payload.covidCertificate,
        isCovidDisabled: !isFeatureEnabled(golf.options.features, 'global/covid'),
        waiting: false,
      });
    });
  }

  /* */
  canBook() {
    const { data: { booking } } = this.props;

    if (this.state.isCovidDisabled) { return true; }

    Account.api.setCovidCertificateOwned(apolloClient, { id: booking.accountId });
    return !!(this.state.covidCertificate || booking.covidCertificateChecked);
  }

  /* */
  goToPayment() {
    if (this.canBook()) {
      this.props.history.replace('/booking/payment');
    } else {
      setTimeout(() => {
        this.setState({
          error: null,
        });
      }, 100);
      this.setState({
        error: 'Un pass vaccinal est obligatoire pour accéder au golf.',
        waiting: false,
      });
    }
  }

  /* */
  handleChangePaymentMethod = (type, opts) => {
    this.setState({
      paymentMethod: type,
      paymentMethodOpts: opts,
    });
  }

  /* */
  handleChangeAcceptTerms = checked => this.setState({ areTermsAccepted: checked });

  /* */
  goToNext = () => {
    const { data: { booking } } = this.props;
    const { paymentMethod, paymentMethodOpts } = this.state;

    booking.players[0].payments = [{
      id: null,
      type: paymentMethod,
      amount: booking.total.price,
      ...(paymentMethod === 2 && { walletId: paymentMethodOpts.wallet.id }),
    }];

    // si paiment sur place ou par compte client
    if ([1, 2].includes(paymentMethod)) {
      this.doBookRequest();
    } else {
      this.goToPayment();
    }
  }

  /* */
  doBookRequest() {
    const { data: { booking } } = this.props;
    this.setState({
      error: null,
      waiting: true,
    });

    if (!this.canBook()) {
      setTimeout(() => {
        this.setState({
          error: null,
        });
      }, 100);
      this.setState({
        error: 'Un pass vaccinal est obligatoire pour accéder au golf.',
        waiting: false,
      });
      return null;
    }

    return Booking.api.bookingBook(apolloClient, booking)
      .catch((err) => {
        throw Object.assign(err, { handled: true });
      })
      .then(res => Booking.api.bookingConfirm(apolloClient, {
        id: res.id,
      }))
      .catch((err) => {
        if (!err.handled) {
          return {};
        }
        throw err;
      })
      .then(() => this.props.history.replace('/booking/voucher'))
      .catch((err) => {
        this.setState({
          waiting: false,
          error: err.message,
        });
      });
  }

  /* */
  renderPlayers() {
    const {
      classes,
      data: { booking },
    } = this.props;

    if (booking.players.length === 0) return null;
    const subtotal = booking.players.reduce((counter, line) => counter + line.price, 0);
    const showSubtotal = booking.players.length > 1 && subtotal > 0;

    return (
      <Grid
        item md={6} xs={12}
        className={classes.section}>
        <Typography
          paragraph
          component="h3"
          variant="h5">
          {helpers.string.ucfirst(`${i18n.t('terms.players', { count: booking.players.length })}`)}
        </Typography>
        <Paper>
          <List disablePadding>
            {booking.players.map((pl, key) => (
              <ListItem divider key={key}>
                <Avatar className={classes.avatar}>
                  <FaceIcon />
                </Avatar>
                <ListItemText
                  disableTypography
                  primary={<Typography>{getPlayerName(pl)}</Typography>}
                  secondary={pl.playerType && (
                    <Box display="flex" alignItems="center" mt={0.25}>
                      <Chip
                        classes={{
                          root: classes.chip,
                          label: classes.chipLabel,
                        }}
                        label={pl.playerType.name} />
                      {pl.comment && (
                        <Typography
                          className={classes.comment}
                          component="span"
                          variant="caption">
                          {i18n.t(`terms.${pl.comment}`).toUpperCase()}
                        </Typography>
                      )}
                    </Box>
                  )} />
                {pl.price === 0 && (
                  <Typography
                    className={classes.price}
                    component="span"
                    variant="subtitle1">
                    {pl.playerType ? i18n.l('currency', pl.price) : i18n.t('terms.included')}
                  </Typography>
                )}
                {pl.price > 0 && (
                  <Typography
                    className={classes.price}
                    component="span"
                    variant="subtitle1">
                    {i18n.l('currency', pl.price / 100)}
                  </Typography>
                )}
                {pl.discount > 0 && (
                  <Typography
                    className={classes.initPrice}
                    component="span"
                    variant="body2">
                    {i18n.l('currency', pl.initPrice / 100)}
                  </Typography>
                )}
              </ListItem>
            ))}
            {showSubtotal && (
              <ListItem className={classes.subtotal}>
                <ListItemText
                  primary="Sous-total"
                  primaryTypographyProps={{ color: 'inherit' }} />
                <Typography
                  variant="subtitle1"
                  color="inherit">
                  {i18n.l('currency', subtotal / 100)}
                </Typography>
              </ListItem>
            )}
          </List>
        </Paper>
        {booking.anonymousAllowed && (
          <FormControlLabel
            className={classes.checkboxCtrl}
            control={
              <Checkbox
                color="secondary"
                onChange={(e) => { booking.forceAnonymous = e.target.checked; }} />
            }
            label="Masquer ma partie sur le planning"
          />
        )}
        {booking.privatizationAllowed && (
          <FormControlLabel
            className={classes.checkboxCtrl}
            control={
              <Checkbox
                color="secondary"
                onChange={(e) => { booking.isPrivatized = e.target.checked; }} />
            }
            label="Bloquer ma partie"
          />
        )}
        {!this.state.isCovidDisabled && !this.state.covidCertificate && (
          <FormControlLabel
            className={classes.checkboxCtrl}
            control={
              <Checkbox
                color="secondary"
                onChange={(e) => { booking.covidCertificateChecked = e.target.checked; }} />
            }
            label="J'ai mon pass vaccinal*"
          />
        )}
        {!this.state.isCovidDisabled && (
          <Box>
            <Typography
              style={{ fontSize: 12, paddingTop: 16 }}
              align="center"
              weight="bold">
              {'*Nous vous rappelons que l\'accès au parcours est réservé aux seules personnes présentant un pass vaccinal à jour. Merci de votre compréhension'}
            </Typography>
          </Box>
        )}
        {this.hasPlayerTypes && (
          <Box display="flex" alignItems="center" mt={1}>
            <InfoIcon />
            <Typography variant="caption" style={{ marginLeft: 8 }}>
              {'Pour consulter vos conditions tarifaires, cliquez sur votre type de joueur dans le menu.'}
            </Typography>
          </Box>
        )}
      </Grid>
    );
  }

  /* */
  renderAccessories() {
    const { classes, data: { booking } } = this.props;
    if (booking.accessories.length === 0) return null;

    const subtotal = booking.accessories.reduce((counter, line) => counter + line.price, 0);
    const showSubtotal = booking.accessories.length > 1 && subtotal > 0;

    return (
      <Grid
        item md={6} xs={12}
        className={classes.section}>
        <Typography
          paragraph
          component="h3"
          variant="h5">
          {helpers.string.ucfirst(`${i18n.t('terms.rentals', { count: booking.accessories.length })}`)}
        </Typography>
        <Paper>
          <List disablePadding>
            {booking.accessories.map((acc, key) => (
              <ListItem divider key={key}>
                <Avatar className={classes.avatar}>{acc.qty}</Avatar>
                <ListItemText primary={acc.name} />
                {acc.price > 0 && (
                  <Typography
                    className={classes.price}
                    component="span"
                    variant="subtitle1">
                    {i18n.l('currency', acc.price / 100)}
                  </Typography>
                )}
                {acc.price === 0 && (
                  <Typography
                    className={classes.included}
                    component="span"
                    variant="subtitle1">
                    {i18n.t('terms.included')}
                  </Typography>
                )}
              </ListItem>
            ))}
            {showSubtotal && (
              <ListItem className={classes.subtotal}>
                <ListItemText
                  primary="Sous-total"
                  primaryTypographyProps={{ color: 'inherit' }} />
                <Typography
                  variant="subtitle1"
                  color="inherit">
                  {i18n.l('currency', subtotal / 100)}
                </Typography>
              </ListItem>
            )}
          </List>
        </Paper>
      </Grid>
    );
  }

  /* */
  renderTotal() {
    const { classes, data: { booking } } = this.props;
    const { waiting, areCourseConditionsAccepted } = this.state;

    const justifyContent = booking.accessories.length === 0 ? 'center' : 'flex-end';

    if (booking.total.price === 0) {
      return (
        <Grid
          container
          spacing={24}
          direction="row"
          justify={justifyContent}>
          <Grid
            item md={6} xs={12}
            className={classes.section}>
            <Button
              fullWidth
              size="large"
              color="secondary"
              variant="contained"
              disabled={waiting || !areCourseConditionsAccepted}
              onClick={() => this.doBookRequest()}>
              {waiting ? <CircularProgress color="inherit" size={24} /> : 'Réserver'}
            </Button>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid
        container
        spacing={24}
        direction="row"
        justify={justifyContent}>
        <Grid
          item md={6} xs={12}
          className={classes.section}>
          <Paper>
            <List
              subheader={
                <ListSubheader
                  className={classes.subheader}
                  color="inherit">
                  {'Total'}
                </ListSubheader>}
              disablePadding>
              <ListItem divider style={{ alignItems: 'baseline' }}>
                <ListItemText primary={'À payer'} />
                <Typography
                  className={classes.price}
                  component="span"
                  variant="subtitle1">
                  {i18n.l('currency', booking.total.price / 100)}
                </Typography>
                {booking.total.discount > 0 && (
                  <Typography
                    className={classes.initPrice}
                    component="span"
                    variant="body2">
                    {i18n.l('currency', booking.total.initPrice / 100)}
                  </Typography>
                )}
              </ListItem>
              {booking.total.discount > 0 && (
                <ListItem divider>
                  <ListItemText primary={'Remise'} />
                  <Typography component="span" variant="subtitle1">
                    {i18n.toPercentage(-1 * booking.total.discount, { precision: 0 })}
                  </Typography>
                </ListItem>
              )}
            </List>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  /* */
  renderPaymentMethod() {
    const { classes, data: { booking } } = this.props;
    const {
      waiting,
      paymentMethod,
      areTermsAccepted,
      areCourseConditionsAccepted,
    } = this.state;

    if (booking.total.price === 0) {
      return null;
    }

    const buttonTxt = `${(paymentMethod === 1 ? 'Valider' : 'Payer')}`
      + ` ${i18n.l('currency', booking.total.price / 100)}`;

    return (
      <Grid
        container
        spacing={24}
        justify="center">
        <Grid
          item md={6} xs={12}
          className={classes.section}>
          <Typography
            paragraph
            component="h3"
            variant="h6">
            {'Votre moyen de paiement'}
          </Typography>
          <PaymentMethods
            selected={paymentMethod}
            amount={booking.total.price}
            methods={this.paymentMethods}
            onChange={this.handleChangePaymentMethod}
            onChangeAcceptTerms={this.handleChangeAcceptTerms}
          />
        </Grid>
        <Grid
          item md={6} xs={12}
          style={{ display: 'flex', alignItems: 'flex-end' }}
          className={classes.section}>
          <Button
            fullWidth
            size="large"
            color="secondary"
            variant="contained"
            disabled={waiting || !areTermsAccepted || !areCourseConditionsAccepted }
            onClick={this.goToNext}>
            {waiting ? <CircularProgress color="inherit" size={24} /> : buttonTxt}
          </Button>
        </Grid>
      </Grid>
    );
  }

  /* */
  renderCourseConditionsModal() {
    const { data: { booking }, classes } = this.props;
    const { areCourseConditionsAccepted } = this.state;

    if (typeof booking.course.handicap !== 'number') return null;

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={!areCourseConditionsAccepted}>
        <DialogTitle id="confirmation-dialog-title">
          {'Condition(s) d\'accès au parcours'}
        </DialogTitle>
        <DialogContent>
          <Typography>{' - Avoir un index inférieur ou égal à '}<u>{`${booking.course.handicap.toFixed(1)}`}</u></Typography>
          <br />
          <Typography variant="caption">
            {'Je confirme que l\'ensemble des joueurs respectent les conditions décrites ci-dessus. En cas de non-respect, je suis conscient que ma réservation pourra être annulée sans remboursement possible.'}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="inherit"
            className={classes.cancelBtn}
            onClick={() => this.props.history.replace('/booking')}
          >
            {'J\'annule'}
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => this.setState({ areCourseConditionsAccepted: true })}
          >
            {'Je confirme'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  /* */
  render() {
    if (this.redirect) {
      return <Redirect to="/booking" />;
    }

    const { error } = this.state;
    const { data: { booking } } = this.props;

    const dateTxt = helpers.string.ucfirst(i18n.l('date.formats.long_y', new Date(booking.date)));
    const courseName = `${i18n.t(`terms.course_type_${booking.course.type}`)} "${booking.course.name}"`;

    return (
      <Screen
        error={error}
        helpURL={`${HELP_URL}/r%C3%A9server#h.p_-2136gq5aMrY`}
        title="Réserver">
        <Typography
          align="center"
          component="h2"
          variant="h5">
          {`${dateTxt} • ${Booking.functions.formatTime(booking.time)}`}
        </Typography>
        <Typography
          align="center"
          component="p"
          color="textSecondary"
          variant="h6">
          {courseName}
        </Typography>
        <br />
        <Grid container spacing={24} justify="center">
          {this.renderPlayers()}
          {this.renderAccessories()}
        </Grid>
        {this.renderTotal()}
        {this.renderPaymentMethod()}
        {this.renderCourseConditionsModal()}
      </Screen>
    );
  }
}

const mapStateToProps = ({ bookingData, app }) => ({
  golf: app.golf,
  data: bookingData,
  account: app.account,
});
const mapDispatchToProps = { ...App.actions, ...Booking.actions };

const StyledComponent = withStyles(styles)(BookingCart);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StyledComponent);
