import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { helpers, Primaccess } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  colors,
  Input,
  Paper,
  Table,
  Select,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  InputLabel,
  Typography,
  TableFooter,
  TablePagination,
  CircularProgress,
} from '@material-ui/core';
/* */
import i18n from '_utils/i18n';
import apolloClient from '_utils/apolloClient';

/* */
const styles = {
  error: { fontWeight: 'bold' },
  noEndpoint: { fontStyle: 'italic' },
  dateColumn: { width: 175, paddingRight: 0 },
  errorRow: { backgroundColor: colors.red[50] },
};

/* */
class SearchMoves extends React.Component {
  /* */
  state = {
    page: 0,
    moves: [],
    count: null,
    loading: true,
    endpoints: [],
    endpointId: -1,
  };

  /* */
  componentDidMount() {
    this.loadEndpoints();
  }

  /* */
  componentDidUpdate(prevProps) {
    // On focus
    if (prevProps.isFocused !== this.props.isFocused
      && this.props.isFocused === true) {
      this.loadMoves();
    }
  }

  /* */
  loadEndpoints = () => {
    const { golfId } = this.props;

    Primaccess.api.getEndpoints(apolloClient, { golfId })
      .then((endpoints) => {
        endpoints.sort(({ name: a }, { name: b }) => a.localeCompare(b));
        this.setState({ endpoints });
      });
  };

  /* */
  loadMoves = () => {
    const { page, endpointId } = this.state;
    const { golfId, clientId, rowsPerPage } = this.props;

    const offset = page * rowsPerPage;

    this.setState({ loading: true });

    Primaccess.api.searchMoves(apolloClient, {
      golfId,
      offset,
      clientId,
      limit: rowsPerPage,
      endpointId: (endpointId === -1 ? null : endpointId),
    })
      .then(({ moves, metadata }) => {
        this.setState({
          moves,
          loading: false,
          count: metadata.total,
        });
      });
  };

  /* */
  handleChangeType = e => this.setState({
    page: 0,
    endpointId: parseFloat(e.target.value),
  }, this.loadMoves);

  /* */
  handleChangePage = (e, page) => this.setState({ page }, this.loadMoves);

  /* */
  renderEndpointName(id) {
    const { classes } = this.props;
    const { endpoints } = this.state;
    const endpoint = endpoints.find(e => e.id === id);

    return !endpoint ? (
      <Typography
        variant="caption"
        className={classes.noEndpoint}>
        {'(Borne inconnue)'}
      </Typography>
    ) : helpers.string.ucfirst(endpoint.name.toLowerCase());
  }

  /* */
  renderRow = (m, k) => {
    const { classes } = this.props;

    const reason = (m.error) ? (
      <Typography
        variant="caption"
        className={classes.error}>
        {i18n.t(`primaccess.moves.error_${m.error}`)}
      </Typography>
    ) : null;
    const className = m.error && classes.errorRow;

    return (
      <TableRow key={k} className={className}>
        <TableCell className={classes.dateColumn}>
          {i18n.l('time.formats.default', new Date(m.createdAt))}
        </TableCell>
        <TableCell>
          {this.renderEndpointName(m.endpointId)}
          {reason}
        </TableCell>
        <TableCell align="right">
          {`#${m.badgeId}`}
        </TableCell>
      </TableRow>
    );
  }

  /* */
  renderEmpty() {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={3}>
            {'Aucune donnée.'}
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  /* */
  renderLabelRows = ({ from, to, count }) => `${from}-${to} sur ${count}`;

  /* */
  render() {
    const { classes, rowsPerPage } = this.props;
    const {
      page,
      type,
      count,
      moves,
      loading,
      endpoints,
    } = this.state;

    return (
      <Fragment>
        <Box
          mt={1}
          mb={3}
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="space-around">
          <InputLabel shrink htmlFor="select-endpoint">
            {'Borne'}
          </InputLabel>
          <Select
            native
            value={type}
            label='Type'
            onChange={this.handleChangeType}
            input={<Input name="type" id="select-type" />}>
            <option value={-1}>{'Tous'}</option>
            {endpoints.map((e, k) => <option key={k} value={e.id}>{e.name}</option>)}
          </Select>
        </Box>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center">
            <CircularProgress size={24} color="secondary" />
          </Box>
        ) : (
          <Paper>
            <Table padding="dense">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.dateColumn}>
                    {'Date'}
                  </TableCell>
                  <TableCell>{'Borne'}</TableCell>
                  <TableCell align="right">{'Badge'}</TableCell>
                </TableRow>
              </TableHead>
              {moves.length > 0 && [
                <TableBody key="tbody">
                  {moves.map(this.renderRow)}
                </TableBody>,
                <TableFooter key="tfooter">
                  <TableRow>
                    <TablePagination
                      page={page}
                      count={count}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[rowsPerPage]}
                      onChangePage={this.handleChangePage}
                      labelDisplayedRows={this.renderLabelRows} />
                  </TableRow>
                </TableFooter>,
              ]}
              {moves.length === 0 && this.renderEmpty()}
            </Table>
          </Paper>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ app: { golf }, primaccess: { clientId } }) =>
  ({ golfId: golf.id, clientId });

const StyledComponent = withStyles(styles)(SearchMoves);

export default connect(mapStateToProps)(StyledComponent);
