import React from 'react';
import moment from 'moment';
import Icon from '@mdi/react';
import { connect } from 'react-redux';
import { App, helpers, Event } from '@aps-management/primapp-common';
import { mdiTrophy, mdiCalendarStar } from '@mdi/js';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  Grid,
  List,
  Paper,
  Button,
  Dialog,
  ListItem,
  Typography,
  DialogTitle,
  ListItemText,
  DialogActions,
  DialogContent,
  ListSubheader,
  CircularProgress,
  DialogContentText,
} from '@material-ui/core';
/* */
import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import { Alert } from '_components/elements';
import apolloClient from '_utils/apolloClient';

/* */
const styles = theme => ({
  header: {
    overflow: 'hidden',
    position: 'relative',
    boxShadow: theme.shadows[2],
    backgroundColor: 'white',
  },
  section: {
    margin: `${theme.spacing.unit * 1}px 0`,
  },
  initPrice: {
    color: theme.palette.grey[500],
    textDecoration: 'line-through',
    marginLeft: theme.spacing.unit * 1.5,
  },
  subtotal: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  subheader: {
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  canceled: {
    top: 16,
    right: -90,
    width: 250,
    overflow: 'hidden',
    fontWeight: 'bold',
    lineHeight: '30px',
    textAlign: 'center',
    position: 'absolute',
    transform: 'rotate(40deg)',
    boxShadow: theme.shadows[3],
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.dark,
  },
  cancelBtn: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
});

/* */
const isCanceled = order => order && order.status
  && order.status.find(s => s.status === 'canceled');

/* */
const getLastStatus = order => ((order.status && order.status.length)
  ? order.status[0].status
  : null
);

/* */
class EventView extends React.Component {
  /* */
  constructor(props) {
    super(props);

    const { match: { params } } = this.props;

    this.id = params.id;
    this.orderId = params.orderId;

    this.permissions = null;

    this.state = {
      // Data
      entry: null,
      event: null,
      owner: null,
      partners: [],
      // Screen
      error: null,
      open: false,
      loading: true,
      waiting: false,
    };
  }

  /* */
  componentDidMount() {
    const { id, orderId } = this;
    const { account, golf } = this.props;

    if (!id) {
      return this.setState({
        loading: false,
        error: 'errors.not_found',
      });
    }

    return Event.api.getEvent(apolloClient, { id, golfId: golf.id })
      .then((event) => {
        this.setState({ event });

        if (!orderId) {
          return Event.api.getEntryList(apolloClient, { id, golfId: golf.id })
            .then(({ entries }) => {
              const match = App.functions.match(account, golf);
              if (match && match.reference) {
                const {
                  entry,
                  owner,
                  partners,
                } = Event.functions.getEntryStatus(entries, match.reference);
                this.setState({ entry, owner, partners });
              }
            });
        }

        return Event.api.getSalesOrder(apolloClient, orderId)
          .then(order => this.setState({ order }));
      })
      .catch(e => this.setState({ error: e.message }))
      .finally(() => this.setState({ loading: false }));
  }

  /* */
  getPermissions() {
    const { event, order } = this.state;

    if (!order || !event.cancelableUntil) {
      return {
        isCancelable: false,
        cancelableUntil: null,
      };
    }

    const cancelableUntil = moment(event.cancelableUntil, 'YYYY-MM-DD HH:mm');
    const checkLastStatus = ['updated', 'confirmed'].includes(getLastStatus(order));

    return {
      cancelableUntil,
      isCancelable: cancelableUntil.isAfter() && checkLastStatus,
    };
  }

  /* */
  handleSwitchModal = () => this.setState(prevState => ({ open: !prevState.open }));

  /* */
  doCancelRequest = () => {
    const { orderId } = this;

    this.setState({
      error: null,
      waiting: true,
      success: null,
    });

    Event.api.cancel(apolloClient, orderId)
      .then(({ status }) => this.setState(prevState => ({
        order: {
          ...prevState.order,
          status: [
            { status, date: moment().format() },
            ...prevState.order.status,
          ],
        },
        success: 'Votre inscription a été annulée avec succès.',
      })))
      .catch(e => this.setState({ error: e.message }))
      .finally(() => this.setState({
        open: false,
        waiting: false,
      }));
  }

  /* */
  renderHeader() {
    const { classes } = this.props;
    const { event, order } = this.state;

    const dateTxt = helpers.string.ucfirst(i18n.l('date.formats.long_y', new Date(event.startDate)));

    return (
      <Box
        px={2}
        py={2}
        mb={2}
        className={classes.header}>
        {isCanceled(order) && (
          <Typography
            component="span"
            variant='caption'
            className={classes.canceled}>
            {'Annulée'}
          </Typography>
        )}
        <Typography
          variant="h5"
          align="center"
          component="h2"
          color="textSecondary">
          {Event.functions.sanitize(event.name)}
        </Typography>
        <Typography
          variant="h5"
          align="center"
          component="p">
          {dateTxt}
        </Typography>
      </Box>
    );
  }

  /* */
  renderActions() {
    const { golf, classes } = this.props;
    const { waiting, order } = this.state;

    const permissions = this.getPermissions();
    const { isCancelable, cancelableUntil } = permissions;

    if (!isCancelable) return null;

    return (
      <Box mb={2}>
        <Alert variant="info">
          {`L'inscription est annulable jusqu'au ${cancelableUntil.format('DD/MM/YYYY')} à ${cancelableUntil.format('HH:mm')}.`}<br />
          {order.tournamentEntrants.length > 0 && i18n.getFromOpts('event_history.view.cancellable', golf.options)}
        </Alert>
        <Box
          display="flex"
          justifyContent="center">
          <Button
            disabled={waiting}
            variant="contained"
            className={classes.cancelBtn}
            onClick={this.handleSwitchModal}>
            {waiting
              ? <CircularProgress color="inherit" size={24} />
              : "Annuler l'inscription"}
          </Button>
        </Box>
      </Box>
    );
  }

  /* */
  renderEntry() {
    const {
      event,
      order,
      entry,
      owner,
      partners,
    } = this.state;
    const { classes } = this.props;

    if (!entry || order) return null;

    const entrants = [entry, ...partners];

    return (
      <Grid container spacing={24} justify="center">
        <Grid
          item md={6} xs={12}
          className={classes.section}>
          <Typography
            paragraph
            variant="h5"
            component="h3">
            {i18n.t('terms.entrants', { count: entrants.length })}
          </Typography>
          <Paper>
            <List disablePadding>
              {entrants.map((e, key) => {
                const option = event.options && event.options
                  .find(o => Number(o.id) === Number(e.option));

                return (
                  <ListItem divider key={key}>
                    <Icon path={mdiCalendarStar} size={1} />
                    <ListItemText
                      secondary={option && option.name}
                      primary={`${e.firstname} ${e.lastname}`} />
                  </ListItem>
                );
              })}
            </List>
          </Paper>
          <br />
          <br />
          {owner && (
            <Paper>
              <List
                subheader={
                  <ListSubheader
                    className={classes.subheader}
                    color="inherit">
                    {'Informations'}
                  </ListSubheader>}
                disablePadding>
                  <ListItem>
                    <ListItemText primary={'Inscrit par'} />
                    <Typography component="span" variant="subtitle1">
                      {`${owner.firstname} ${owner.lastname}`}
                    </Typography>
                  </ListItem>
              </List>
            </Paper>
          )}
        </Grid>
      </Grid>
    );
  }

  /* */
  renderEventEntrants() {
    const { classes } = this.props;
    const { event, order } = this.state;

    if (order.eventEntrants.length === 0) return null;

    const { eventEntrants: entrants } = order;

    const subtotal = entrants.reduce((counter, line) => counter + (line.price || 0), 0);
    const showSubtotal = entrants.length > 1 && subtotal > 0;

    return (
      <Grid
        item md={8} xs={12}
        className={classes.section}>
        <Typography
          paragraph
          variant="h5"
          component="h3">
          {i18n.t('terms.entrants', { count: entrants.length })}
        </Typography>
        <Paper>
          <List disablePadding>
            {entrants.map((e, key) => {
              const option = event.options
                .find(o => Number(o.id) === Number(e.option));

              return (
                <ListItem divider key={key}>
                  <Icon path={mdiCalendarStar} size={1} />
                  <ListItemText
                    primary={e.name}
                    secondary={option && option.name} />
                  {e.price > 0 && (
                    <Typography
                      component="span"
                      color="secondary"
                      variant="subtitle1">
                      {i18n.l('currency', e.price / 100)}
                    </Typography>
                  )}
                  {e.discount > 0 && (
                    <Typography
                      className={classes.initPrice}
                      component="span"
                      variant="body2">
                      {i18n.l('currency', e.initPrice / 100)}
                    </Typography>
                  )}
                  {e.price === 0 && (
                    <Typography component="span" variant="subtitle1">
                      {i18n.t('terms.included')}
                    </Typography>
                  )}
                </ListItem>
              );
            })}
            {showSubtotal && (
              <ListItem className={classes.subtotal}>
                <ListItemText
                  primary="Sous-total"
                  primaryTypographyProps={{ color: 'inherit' }} />
                <Typography
                  variant="subtitle1"
                  color="inherit">
                  {i18n.l('currency', subtotal / 100)}
                </Typography>
              </ListItem>
            )}
          </List>
        </Paper>
      </Grid>
    );
  }

  /* */
  renderTournamentEntrants() {
    const { order } = this.state;
    const { classes, history } = this.props;

    if (order.tournamentEntrants.length === 0) return null;

    const { tournamentEntrants: entrants } = order;
    const { tournamentId } = entrants[0];

    const subtotal = entrants.reduce((counter, line) => counter + (line.price || 0), 0);
    const showSubtotal = entrants.length > 1 && subtotal > 0;

    return (
      <Grid
        item md={8} xs={12}
        className={classes.section}>
        <Box
          mb={2}
          display="flex"
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <Typography
            variant="h5"
            component="h3">
            {i18n.t('terms.entrants', { count: entrants.length })}
          </Typography>
          <Button
            size="small"
            color="secondary"
            variant="outlined"
            onClick={() => history.push(`/mytournaments/${tournamentId}/${order.id}`)}>
            {'Voir la compétition'}
          </Button>
        </Box>
        <Paper>
          <List disablePadding>
            {entrants.map((e, key) => (
              <ListItem divider key={key}>
                <Icon path={mdiTrophy} size={1} />
                <ListItemText
                  primary={e.name} />
                {e.price > 0 && (
                  <Typography
                    component="span"
                    color="secondary"
                    variant="subtitle1">
                    {i18n.l('currency', e.price / 100)}
                  </Typography>
                )}
                {e.discount > 0 && (
                  <Typography
                    className={classes.initPrice}
                    component="span"
                    variant="body2">
                    {i18n.l('currency', e.initPrice / 100)}
                  </Typography>
                )}
                {e.price === 0 && (
                  <Typography component="span" variant="subtitle1">
                    {i18n.t('terms.included')}
                  </Typography>
                )}
              </ListItem>
            ))}
            {showSubtotal && (
              <ListItem className={classes.subtotal}>
                <ListItemText
                  primary="Sous-total"
                  primaryTypographyProps={{ color: 'inherit' }} />
                <Typography
                  variant="subtitle1"
                  color="inherit">
                  {i18n.l('currency', subtotal / 100)}
                </Typography>
              </ListItem>
            )}
          </List>
        </Paper>
      </Grid>
    );
  }

  /* */
  renderTotal() {
    const { order } = this.state;
    const { classes } = this.props;

    const {
      total,
      payments,
      refunds,
      paymentMethod,
    } = order;

    if (total.price === 0) return null;

    return (
      <Grid
        item md={6} xs={12}
        className={classes.section}>
        <Paper>
          <List
            subheader={
              <ListSubheader
                className={classes.subheader}
                color="inherit">
                {'Paiement'}
              </ListSubheader>}
            disablePadding>
            <ListItem divider style={{ alignItems: 'baseline' }}>
              <ListItemText primary={'Total'} />
              <Typography
                className={classes.price}
                component="span"
                variant="subtitle1">
                {i18n.l('currency', total.price / 100)}
              </Typography>
            </ListItem>
            {paymentMethod === 1 && (
              <ListItem divider>
                <ListItemText primary={'Moyen de paiement'} />
                <Typography component="span" variant="subtitle1">
                  {'Sur place'}
                </Typography>
              </ListItem>
            )}
            {paymentMethod === 2 && (
              <ListItem divider>
                <ListItemText primary={'Moyen de paiement'} />
                <Typography component="span" variant="subtitle1">
                  {'Par compte client'}
                </Typography>
              </ListItem>
            )}
            {payments && payments[0] && (
              <React.Fragment>
                {payments[0].card && (
                  <ListItem divider alignItems="flex-start">
                    <ListItemText primary={'Moyen de paiement'} />
                    <Box>
                      <Typography component="span" variant="subtitle1">
                        {'Carte bancaire'}
                      </Typography>
                      <Typography
                        component="span"
                        color="textSecondary"
                        variant="subtitle1">
                        {payments[0].card}
                      </Typography>
                    </Box>
                  </ListItem>
                )}
                <ListItem divider>
                  <ListItemText primary={'E-ticket'} />
                  <Typography component="span" variant="subtitle1">
                    {payments[0].transactionNo}
                  </Typography>
                </ListItem>
              </React.Fragment>
            )}
          </List>
        </Paper>
        <br />
        {refunds && refunds[0] && (
          <Paper>
            <List
              subheader={
                <ListSubheader
                  className={classes.subheader}
                  color="inherit">
                  {'Remboursement'}
                </ListSubheader>}
              disablePadding>
              <ListItem divider style={{ alignItems: 'baseline' }}>
                <ListItemText primary={'Total'} />
                <Typography
                  className={classes.price}
                  component="span"
                  variant="subtitle1">
                  {i18n.l('currency', refunds[0].amount / 100)}
                </Typography>
              </ListItem>
              <ListItem divider>
                <ListItemText
                  primary={'E-ticket'}
                  secondary={`CB ${refunds[0].card}`} />
                <Typography component="span" variant="subtitle1">
                  {refunds[0].transactionNo}
                </Typography>
              </ListItem>
            </List>
          </Paper>
        )}
      </Grid>
    );
  }

  /* */
  renderStatus() {
    const { order } = this.state;
    const { classes } = this.props;

    const { refunds } = order;

    return (
      <Grid
        item md={6} xs={12}
        className={classes.section}>
        <Paper>
          <List
            subheader={
              <ListSubheader
                className={classes.subheader}
                color="inherit">
                {'Historique'}
              </ListSubheader>}
            disablePadding>
            {refunds && refunds[0] && (
              <ListItem divider>
                <ListItemText primary={i18n.t('terms.status_refunded')} />
                <Typography component="span" variant="subtitle1">
                  {`${i18n.l('time.formats.default', refunds[0].date)}`}
                </Typography>
              </ListItem>
            )}
            {order.status
              .filter(({ status }) => status !== 'booked')
              .map((item, key) => (
                <ListItem divider key={key}>
                  <ListItemText primary={i18n.t(`terms.status_${item.status}`)} />
                  <Typography component="span" variant="subtitle1">
                    {`${i18n.l('time.formats.default', item.date)}`}
                  </Typography>
                </ListItem>
              ))}
          </List>
        </Paper>
      </Grid>
    );
  }

  /* */
  renderOrder() {
    const { order } = this.state;

    if (!order) return null;

    return (
      <React.Fragment>
        <Grid container spacing={24} justify="center">
          {this.renderEventEntrants()}
          {this.renderTournamentEntrants()}
        </Grid>
        <Grid container spacing={24} justify="center">
          {this.renderTotal()}
          {this.renderStatus()}
        </Grid>
      </React.Fragment>
    );
  }

  /* */
  renderCancelDialog() {
    const { classes } = this.props;
    const { waiting, open, order } = this.state;

    if (!order) return null;

    return (
      <Dialog
        open={open}
        disableBackdropClick
        disableEscapeKeyDown
        onClose={this.handleSwitchModal}>
        <DialogTitle>{'Annulation'}</DialogTitle>
        <DialogContent>
          <DialogContentText color="default">
            {'Êtes-vous sûr de vouloir annuler votre inscription ?'}
          </DialogContentText>
          <DialogContentText color="default" variant="caption">
            {'Un e-mail de confirmation sera envoyé aux autres joueurs.'}<br />
            {(order.total.price > 0 && order.paymentMethod !== 1)
              && 'Vous serez remboursé dans les plus brefs délais.'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="secondary"
            onClick={this.handleSwitchModal}>
            {'Non'}
          </Button>
          <Button
            color="inherit"
            disabled={waiting}
            variant="contained"
            className={classes.cancelBtn}
            onClick={this.doCancelRequest}>
            {waiting
              ? <CircularProgress color="inherit" size={24} />
              : 'Oui'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  /* */
  render() {
    const { golf } = this.props;
    const {
      error,
      event,
      loading,
      success,
    } = this.state;

    return (
      <Screen
        error={error}
        loading={loading}
        success={success}
        title={i18n.getFromOpts('event_history.view.title', golf.options)}>
        {event && (
          <React.Fragment>
            {this.renderHeader()}
            {this.renderActions()}
            {this.renderEntry()}
            {this.renderOrder()}
            {this.renderCancelDialog()}
          </React.Fragment>
        )}
      </Screen>
    );
  }
}

const mapStateToProps = ({ app }) => ({
  golf: app.golf,
  account: app.account,
});

const StyledComponent = withStyles(styles)(EventView);

export default connect(mapStateToProps)(StyledComponent);
