import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { useContextSelector } from "@fluentui/react-context-selector";
import MomentUtils from "@date-io/moment";
import { Booking } from "@aps-management/primapp-common";
import {
  Grid,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
} from "material-ui-pickers";
import { Screen } from "_components/core";
import PublicBookingContext from "./services/publicBookingService";
import NotFound from "_screens/Public/NotFound";
import { Loading } from "_screens";
import PublicBookingOptions from "./PublicBookingOptions";
import PublicBookingHeader from "./PublicBookingHeader";
import { useScaling } from "_utils/scalingUtil";

/* */
const styles = () => ({
  container: {
    backgroundColor: "transparent",
    display: "grid !important",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridTemplateRows: "0.05fr 35px 0.95fr",
    gridTemplateAreas: `
      "title title title"
      "header header header"
      "main main main"`,
  },
});

/* */
const PublicBooking = ({ classes  }) => {
  const [error] = useState(null);
  const [success] = useState(null);
  const { isMobileView } = useScaling();
  const {
    loading: publicBookingLoading,
    data: { courses },
  } = useContextSelector(PublicBookingContext, (c) => c);

  if (publicBookingLoading && !courses) return <Loading />;
  if (!publicBookingLoading && courses && courses.length === 0)
    return <NotFound />;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Screen noDrawer layout="fluid-large" error={error} success={success}>
        <Grid
          className={classes.container}
          style={{
            maxHeight: !isMobileView ? "calc(100vh - 75px)" : "calc(100vh - 150px)",
            minHeight: !isMobileView ? "calc(100vh - 75px)" : "calc(100vh - 150px)",
          }}
        >
          <PublicBookingHeader />
          <PublicBookingOptions />
        </Grid>
      </Screen>
    </MuiPickersUtilsProvider>
  );
};

/* */
const mapStateToProps = ({ app: { golf }, bookingData, bookingSearch }) => ({
  golf,
  data: bookingData,
  search: bookingSearch,
});
export default connect(
  mapStateToProps,
  Booking.actions
)(withRouter(withStyles(styles)(PublicBooking)));
