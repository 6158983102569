import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import i18n from '_utils/i18n';

/* */
const styles = theme => ({
  textField: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  submitButton: {
    marginTop: theme.spacing.unit * 2,
  },
});

/* */
class ChangeLoginForm extends React.Component {
  /* */
  render() {
    const {
      errors,
      values,
      classes,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      isSubmitting,
    } = this.props;

    return (
      <form autoComplete="off">
        <TextField
          required
          fullWidth
          name="email"
          type="email"
          label="Nouveau"
          variant="outlined"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.email}
          className={classes.textField}
          error={!!(touched.email && errors.email)}
          helperText={touched.email && errors.email}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          required
          fullWidth
          type="email"
          variant="outlined"
          onBlur={handleBlur}
          label="Confirmation"
          name="emailConfirm"
          onChange={handleChange}
          className={classes.textField}
          value={values.emailConfirm}
          error={!!(touched.emailConfirm && errors.emailConfirm)}
          helperText={touched.emailConfirm && errors.emailConfirm}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          fullWidth
          size="large"
          color="secondary"
          variant="contained"
          onClick={handleSubmit}
          disabled={isSubmitting}
          className={classes.submitButton}>
          {isSubmitting
            ? <CircularProgress color="inherit" size={24} />
            : i18n.t('default.actions.save')}
        </Button>
      </form>
    );
  }
}
export default withStyles(styles)(ChangeLoginForm);
