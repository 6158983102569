import React from 'react';
import { connect } from 'react-redux';
import { App, Event, helpers } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
/* */
import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
/* */
import Header from '../_Header';

/* */
const styles = theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    fontSize: 100,
    marginBottom: theme.spacing.unit * 2,
  },
});

/* */
class EventEnter extends React.Component {
  /* */
  state = {
    // Screen
    error: null,
    loading: true,
    specificError: null,
    // Data
    entry: null,
    owner: null,
    partners: [],
  };

  /* */
  id = null;

  /* */
  componentDidMount() {
    this.id = this.props.match.params.id;
    this.load();
  }

  /* */
  goToNext = () => this.props.history.push(`/event/${this.id}/entrants`);

  /* */
  goToMyEvents = () => this.props.history.push('/myevents');

  /* */
  load() {
    const { id } = this;
    const { account, golf } = this.props;

    if (!id) {
      return this.setState({
        entries: [],
        loading: false,
        error: 'errors.not_found',
      });
    }

    const specificErrors = ['R454', 'R457', 'R458', 'R455'];
    const match = App.functions.match(account, golf) || { reference: 'UNKNOWN' };

    return Event.api.getEvent(apolloClient, { id, golfId: golf.id, reference: match.reference })
      .then((event) => {
        this.setState({ event });

        const { lifecycle } = event;
        const { status } = Event.functions.getRegistrationStatus(event);
        // Only if registration is open
        if (status !== 1 && lifecycle === 'registration') {
          return Event.api.getEntryList(apolloClient, { id, golfId: golf.id });
        }

        return Promise.resolve({ entries: [] });
      })
      .then(({ entries }) => {
        if (match && match.reference) {
          const {
            entry,
            owner,
            partners,
          } = Event.functions.getEntryStatus(entries, match.reference);
          this.setState({ entry, owner, partners });
        }
      })
      .catch((e) => {
        const message = helpers.string.handleError(e.message);
        if (specificErrors.includes(message)) {
          this.setState({ specificError: message });
        } else {
          this.setState({ error: e.message });
        }
      })
      .finally(() => { this.setState({ loading: false }); });
  }

  /* */
  renderEntry() {
    const { golf } = this.props;
    const { entry } = this.state;

    if (!entry) return null;

    return (
      <React.Fragment>
        <Typography variant="body1">
          {i18n.getFromOpts('event_enter.already_in', golf.options)}
        </Typography>
        <br />
        <Button
          color="secondary"
          variant="outlined"
          onClick={this.goToMyEvents}>
          {'Voir mes inscriptions'}
        </Button>
      </React.Fragment>
    );
  }

  /* */
  renderEnterButton() {
    const { entry, event } = this.state;
    const { limit, counter } = event;

    // Inscription ouverte + pas inscrit
    const isOpened = !entry && Event.functions.isOpened(event);
    if (!isOpened) return null;

    // Limite atteinte
    if (limit && counter >= limit) {
      return (
        <Button
          disabled
          fullWidth
          size="large"
          onClick={null}
          color="primary"
          variant="contained">
          {'Complet'}
         </Button>
      );
    }

    return (
      <Button
        fullWidth
        size="large"
        color="secondary"
        variant="contained"
        onClick={this.goToNext}>
        {'Participer'}
       </Button>
    );
  }

  /* */
  renderContent() {
    const { classes } = this.props;
    const { event, specificError } = this.state;

    if (specificError) {
      return (
        <div className={classes.wrapper}>
          <LockOutlinedIcon
            className={classes.icon}
            fontSize="inherit" />
          <Typography component="h2">
            {i18n.t(specificError)}
          </Typography>
          <br />
          {specificError === 'R454' && (
            <Button
              size="large"
              color="secondary"
              variant="contained"
              onClick={() => this.props.history.replace(`/membership/subscribe?redirect=/event/${this.id}`)}>
              {"S'abonner"}
             </Button>
          )}
          {specificError === 'R457' && (
            <Button
              size="large"
              color="secondary"
              variant="contained"
              onClick={() => this.props.history.replace(`/membership?redirect=/event/${this.id}`)}>
              {'Régler ma cotisation'}
             </Button>
          )}
          {specificError === 'R458' && (
            <Button
              size="large"
              color="secondary"
              variant="contained"
              onClick={() => this.props.history.replace('/event')}>
              {'Voir le calendrier'}
             </Button>
          )}
        </div>
      );
    }

    if (event) {
      return (
        <React.Fragment>
          <Header event={event} />
          {this.renderEntry()}
          {this.renderEnterButton()}
        </React.Fragment>
      );
    }

    return null;
  }

  /* */
  render() {
    const { golf } = this.props;
    const { error, loading } = this.state;

    return (
      <Screen
        error={error}
        loading={loading}
        title={i18n.getFromOpts('event_signin.title', golf.options)}>
        {this.renderContent()}
      </Screen>
    );
  }
}

const mapStateToProps = ({ app }) => ({
  golf: app.golf,
  account: app.account,
});

const StyledComponent = withStyles(styles)(EventEnter);

export default connect(mapStateToProps)(StyledComponent);
