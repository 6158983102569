import React from 'react';
import { connect } from 'react-redux';
import {
  helpers,
  Paybox,
  CreditCard,
  HELP_URL,
} from '@aps-management/primapp-common';
import {
  Grid,
  Paper,
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import GetCreditCard from '_components/GetCreditCard';
import { Alert, CreditCardPreview } from '_components/elements';

/* */
const styles = theme => ({
  panel: {
    display: 'inherit',
  },
  paper: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
  },
  alert: {
    marginTop: theme.spacing.unit * 2,
  },
});

/* */
class ManageCreditCard extends React.Component {
  /* */
  constructor(props) {
    super(props);

    const { golf, account } = this.props;

    this.currency = golf.currency;
    this.authorizationAmount = 1000;

    // build session 3DS
    this.session3DS = {
      address: {
        city: golf.city,
        countryCode: 250, // golf.country
        line1: golf.name,
        zipCode: golf.postalCode,
      },
      customer: {
        email: account.email,
        lastName: account.lastname,
        firstName: account.firstname,
        phone: account.phone,
      },
    };

    this.state = {
      error: null,
      success: null,
      loading: true,
      imprint: null,
      expanded: false,
    };
  }

  /* */
  componentDidMount() {
    this.load();
  }

  /* */
  load = () => {
    this.setState({
      loading: true,
      imprint: null,
    });
    CreditCard.api.getCreditCards(apolloClient, {})
      .then(({ creditCards }) => {
        const newState = {
          imprint: null,
          loading: false,
          expanded: false,
        };

        if (creditCards[0]) {
          const status = CreditCard.functions.getStatus(creditCards[0].dueDate);
          newState.imprint = {
            ...status,
            ...creditCards[0],
          };
          newState.expanded = status.isExpired || status.isSoonExpired;
        }

        this.setState(newState);
      })
      .catch(error => this.setState({ error }));
  }

  /* */
  handleSubmit = (values, { setSubmitting, resetForm }) => {
    const { id3D = null } = values;
    const { account } = this.props;

    const uuid = Paybox.functions.createUuidFrom(account.id);

    const creditCard = {
      ...values,
      name: '3DS web',
      number: values.number.replace(/\s/g, ''),
      dueDate: values.dueDate.replace(/\D/g, ''),
    };

    this.setState({ error: null, success: null });

    return Paybox.api.createSubscriber(apolloClient, {
      id3D,
      uuid,
      reference: account.id,
      amount: this.authorizationAmount,
      creditCard: helpers.object.filterByKeys(creditCard, ['number', 'dueDate', 'cvv']),
    })
      .catch((err) => {
        throw Object.assign(err, { handled: true });
      })
      .then(res => CreditCard.api.setCreditCard(apolloClient, {
        id: uuid,
        token: res.PORTEUR,
        number: CreditCard.functions.anonymizeNumber(creditCard.number),
        ...helpers.object.filterByKeys(creditCard, ['name', 'dueDate', 'type', 'brand', 'country']),
      }))
      .catch((err) => {
        if (!err.handled) {
          throw Object.assign(err, {
            handled: true,
            message: 'Impossible d’enregistrer la carte. Veuillez réessayer ultérieurement.',
          });
        }
        throw err;
      })
      .then(() => {
        this.setState({
          expanded: false,
          imprint: creditCard,
          success: 'Votre carte est enregistrée avec succès.',
        });
        resetForm();
      })
      .catch(err => this.setState({ error: err.message }))
      .finally(() => setSubmitting(false));
  }

  /* */
  handleExpand = (event, expanded) => this.setState({ expanded });

  /* */
  renderImprint() {
    const { imprint } = this.state;
    const { classes } = this.props;

    if (!imprint) return null;

    return (
      <Grid item xs={12} sm={9} md={6}>
        <Typography
          gutterBottom
          variant="h6"
          component='h2'>
          {'Ma carte bancaire'}
        </Typography>
        <CreditCardPreview
          brand={imprint.brand}
          dueDate={imprint.dueDate}
          number={imprint.number} />
        {imprint.isExpired && (
          <Alert
            variant="error"
            className={classes.alert}>
              {'La carte est expirée.'}
          </Alert>
        )}
        {imprint.isSoonExpired && (
          <Alert
            variant="warning"
            className={classes.alert}>
              {'La carte arrive bientôt à expiration.'}
          </Alert>
        )}
      </Grid>
    );
  }

  /* */
  renderForm() {
    const { classes } = this.props;
    const { imprint, expanded } = this.state;

    const form = (
      <GetCreditCard
        currency={this.currency}
        session3DS={this.session3DS}
        onSubmit={this.handleSubmit}
        authorizationAmount={this.authorizationAmount} />
    );

    return (
      <Grid item xs={12} sm={9} md={6}>
        {imprint ? (
          <ExpansionPanel
            expanded={expanded}
            onChange={this.handleExpand}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}>
              <Typography
                variant="subtitle1"
                component='h2'>
                {'Changer de carte bancaire'}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.panel}>
              {form}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ) : (
          <Paper className={classes.paper}>
            <Typography
              gutterBottom
              variant="subtitle1"
              component='h2'>
              {'Ajouter une carte bancaire'}
            </Typography>
            <br/>
            {form}
          </Paper>
        )}
      </Grid>
    );
  }

  /* */
  render() {
    const { loading, error, success } = this.state;

    return (
      <Screen
        error={error}
        loading={loading}
        success={success}
        title={'Ma carte bancaire'}
        helpURL={`${HELP_URL}/carte-bancaire`}
        onBackPress={() => this.props.history.goBack()}>
        <Grid
          container
          spacing={40}
          justify="center">
          {this.renderImprint()}
          {this.renderForm()}
        </Grid>
      </Screen>
    );
  }
}

const mapStateToProps = ({ app }) => ({
  golf: app.golf,
  account: app.account,
});

const StyledComponent = withStyles(styles)(ManageCreditCard);

export default connect(
  mapStateToProps,
  CreditCard.actions,
)(StyledComponent);
