import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import { AccountWallet } from '@aps-management/primapp-common';
import {
  Paper,
  Button,
  Typography,
  CircularProgress,
  DialogTitle,
  Dialog,
  DialogContent,
} from '@material-ui/core';
/* */
import i18n from '_utils/i18n';
import masterClient from '_utils/masterClient';
import PaymentMethods from '_components/PaymentMethods';

/* */
const styles = theme => ({
  product: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing.unit * 1.5,
    marginBottom: theme.spacing.unit * 2,
  },
  productSelected: {
    boxShadow: '0px 1px 5px 1px rgba(0, 109, 183, 1)',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'center',
  },
  dialogPaper: {
    backgroundColor: theme.palette.background.default,
  },
});

/* */
class ChooseRefillModal extends React.Component {
  /* */
  constructor(props) {
    super(props);
    // payment methods
    // uniquement CB
    this.paymentMethod = 0;
    this.state = {
      loading: false,
      selected: null,
      areAcceptedTerms: false,
    };
  }

  /* */
  componentDidMount() { this.load(); }

  /* */
  handleChangeAcceptTerms = checked => this.setState({ areAcceptedTerms: checked });

  /* */
  load = async () => {
    const { golfId, products } = this.props;

    // cache products list in reducer
    if (!products || products.length === 0) {
      this.setState({ loading: true });

      AccountWallet.api.getRefills(masterClient, { golfId })
        .then((list) => {
          list.sort(({ paidAmount: a }, { paidAmount: b }) => a - b);
          this.props.update({ products: list });
        })
        .catch(() => null)
        .finally(() => this.setState({ loading: false }));
    }
    // reset cart
    this.props.update({ cart: [] });
  };

  /* */
  selectProduct = p => () => this.setState({ selected: p });

  /* */
  renderProduct = (p, k) => {
    const { classes } = this.props;
    const { selected } = this.state;

    const className = classNames(classes.product, {
      [classes.productSelected]: (selected === k),
    });

    return (
      <Paper
        key={k}
        className={className}
        onClick={this.selectProduct(k)}>
        <Typography variant="h5">
          {i18n.l('currency', p.paidAmount / 100)}
        </Typography>
        {p.bonusAmount > 0 && (
          <Typography
            variant="h6"
            color="secondary"
            align="center">
            {`+${i18n.l('currency', p.bonusAmount / 100)} offerts !`}
          </Typography>
        )}
      </Paper>
    );
  }

  /* */
  renderPayButton() {
    const { selected, areAcceptedTerms } = this.state;
    const { products } = this.props;

    // un produit est sélectionné et son montant > 0
    if (!products[selected] || products[selected].paidAmount <= 0) return null;

    return (
      <>
        {this.renderProduct(products[selected], selected)}
        <PaymentMethods
          selected={this.paymentMethod}
          amount={products[selected].paidAmount}
          methods={[this.paymentMethod]}
          onChange={() => null}
          onChangeAcceptTerms={this.handleChangeAcceptTerms}
        />
        <Button
          fullWidth
          size="large"
          color="secondary"
          variant="contained"
          disabled={!areAcceptedTerms}
          onClick={this.goToPayment}
        >
          {`Payer ${i18n.l('currency', products[selected].paidAmount / 100)}`}
        </Button>
      </>
    );
  }

  /* */
  goToPayment = () => {
    const { selected } = this.state;
    const { products } = this.props;

    this.props.addProductToCart(products[selected]);
    this.props.history.push('/accountwallet/payment');
  }

  /* */
  renderHeader = () => {
    const { selected } = this.state;
    const { products } = this.props;

    return (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          {'Sélectionnez le rechargement souhaité'}
        </Box>
        {(products[selected]) && (
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={() => this.setState({ selected: null, areAcceptedTerms: false })}>
            {'Modifier'}
          </Button>
        )}
      </Box>
    );
  }

  /* */
  renderList() {
    const { loading, selected } = this.state;
    const { products } = this.props;

    if (loading) {
      return (
        <Box
          display="flex"
          justifyContent="center">
          <CircularProgress size={24} color="secondary" />
        </Box>
      );
    }

    if (products && products.length === 0) {
      return (
        <Typography align="center">
          {'Aucun rechargement disponible.'}
        </Typography>
      );
    }

    if (products[selected]) return null;

    return products.map(this.renderProduct);
  }

  /* */
  render() {
    const { open, handleSwitchModal, classes } = this.props;
    return (
      <Dialog
        open={open}
        scroll="body"
        classes={{ paper: classes.dialogPaper }}
        onClose={handleSwitchModal}>
        <DialogTitle>
          {this.renderHeader()}
        </DialogTitle>
        <DialogContent>
          <br />
          {this.renderList()}
          {this.renderPayButton()}
        </DialogContent>
      </Dialog>
    );
  }
}


const mapStateToProps = ({ app: { golf }, accountWallet }) => ({
  golfId: golf.id,
  products: accountWallet.products,
});

const StyledComponent = withStyles(styles)(ChooseRefillModal);

export default connect(
  mapStateToProps,
  AccountWallet.actions,
)(StyledComponent);
