import React from 'react';
import * as qs from 'query-string';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { ArrowForwardIos as ForwardIcon } from '@material-ui/icons';
import {
  helpers,
  App,
  Booking,
} from '@aps-management/primapp-common';
import {
  Grid,
  Paper,
  Button,
  Typography,
} from '@material-ui/core';

/* */
import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import { ActivitySelector } from '_components/elements';

/* */
import SetDate from './SetDate';
import SetCourse from './SetCourse';

/* */
const styles = theme => ({
  titleButton: {
    marginLeft: theme.spacing.unit * 2,
  },
  optionContainer: {
    alignItems: 'center',
    color: theme.palette.grey[500],
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
  },
  optionContent: {
    flex: 1,
    marginRight: theme.spacing.unit * 2,
    overflow: 'hidden',
  },
  optionValue: {
    color: theme.palette.text.secondary,
    lineHeight: 'inherit',
  },
});

/* */
const SearchOption = ({
  classes,
  label,
  onClick,
  value,
  isExpanded = false,
  warningMessage,
}) => (
  <Paper
    className={classes.optionContainer}
    onClick={onClick}>
    <div className={classes.optionContent}>
      <Typography
        gutterBottom
        color="inherit"
        component="span"
        variant="h6">
        {label}
      </Typography>
      <Typography
        className={classes.optionValue}
        color="secondary"
        component="span"
        noWrap={true}
        variant="h5">
        {value}
      </Typography>
      {warningMessage && (
        <Typography
          color="error"
          component="p"
          variant="caption">
          {warningMessage}
        </Typography>
      )}
    </div>
    {isExpanded
      ? <ForwardIcon style={{ transform: 'rotate(90deg)' }} color="action" />
      : <ForwardIcon color="action" />}
  </Paper>
);

/* */
class Search extends React.Component {
  /* */
  constructor(props) {
    super(props);

    const {
      account,
      golf,
      location,
      search,
    } = this.props;
    const { activity = null } = qs.parse(location.search);

    this.state = {
      error: null,
      loading: true,
      activeOption: 0,
      activity: Number(activity) || search.activity,
    };

    this.match = App.functions.match(account, golf)
      || { isMember: false, reference: 'UNKNOWN' };
  }

  /* */
  componentDidMount() {
    const { search, data: { initial, openDates } } = this.props;

    if (!search.golf || !initial || !openDates) {
      this.load();
    } else {
      // check if valid
      if (!openDates.includes(search.date)) {
        this.props.setDate(initial.openDate);
      }
      if (!Booking.functions.checkCourseOpen(search.course, search.date)) {
        this.props.setCourse(initial.openCourse);
      }
      this.setState({ loading: false });
    }
  }

  /* */
  load = () => {
    const { match } = this;
    const { activity } = this.state;
    const { account, golf, data } = this.props;

    this.props.reset();
    this.setState({ loading: true, activeOption: 0 });

    Booking.api.selectGolf(apolloClient, {
      activity,
      id: golf.id,
      player: match.reference,
    })
      .then((result) => {
        // Set golf et reset search result
        this.props.setGolf(result.golf);
        this.props.setActivity(activity);

        if (result.courses && result.courses.length) {
          const {
            courses,
            initial,
            openDates,
            openCourses,
            availableDates,
          } = Booking.functions.processCourses(result.courses);

          // save initial
          data.initial = initial;
          data.openDates = openDates;
          data.openCourses = openCourses;

          // Choose initial
          this.props.setCourse(initial.openCourse);
          this.props.setDate(initial.openDate);

          const {
            email,
            phone,
            lastname,
            firstname,
          } = account;

          this.props.addPlayer({
            email,
            phone,
            type: 'owner',
            isMember: match.isMember,
            reference: match.reference,
            lastname: lastname.toUpperCase(),
            firstname: helpers.string.ucfirst(firstname),
          });

          this.props.setCoursesList(courses);
          this.props.setDatesList(availableDates);
          this.props.setAccessoriesList(result.accessories);
        } else {
          // Reset sur reload (= changement d'activité)
          this.props.setCoursesList([]);
          this.props.setDatesList([]);
          this.props.setAccessoriesList([]);
        }
      })
      .catch((err) => {
        this.setState({ error: err.message });
      })
      .finally(() => this.setState({ loading: false }));
  }

  /* */
  changeActivity = activity => this.setState({ activity }, () => this.load());

  /* */
  renderDateOption() {
    const { activeOption } = this.state;
    const { classes, search: { date }, width } = this.props;

    const value = helpers.string.ucfirst(i18n.l('date.formats.long', new Date(date)));

    return (
      <SearchOption
        label="Date"
        value={value}
        classes={classes}
        onClick= {() => this.setState({ activeOption: 0 })}
        isExpanded={!isWidthUp('md', width) && activeOption === 0} />
    );
  }

  /* */
  renderCourseOption() {
    const { activeOption, activity } = this.state;
    const {
      golf,
      width,
      classes,
      search: { course },
    } = this.props;

    const { name, type } = course || { name: null, type: null };

    let value;
    if (activity && activity !== 1) {
      value = name || '-';
    } else {
      const nbOfHoles = i18n.t(`terms.course_type_holes_${type}`, { defaultValue: '' });
      value = name ? `${!name.includes(nbOfHoles) ? `(${nbOfHoles} T) ` : ''}${name}` : '-';
    }

    const label = i18n.getFromOpts('booking.titles.course', golf.options, { activity });

    return (
      <SearchOption
        label={label}
        value={value}
        classes={classes}
        onClick= {() => this.setState({ activeOption: 1 })}
        isExpanded={!isWidthUp('md', width) && activeOption === 1} />
    );
  }

  /* */
  renderUnavailabilityMsg() {
    return (
      <Typography
        paragraph
        variant="h5"
        align="center"
        component="h2">
        <br />
        {'Aucune disponibilité pour le moment.'}
      </Typography>
    );
  }

  /* */
  renderActivities() {
    const { golf } = this.props;
    const { activities } = golf.options;
    const { activity: currentActivity } = this.state;

    return (
      <ActivitySelector
        data={activities}
        value={currentActivity}
        onChange={this.changeActivity}
      />
    );
  }

  /* */
  renderAllOptions() {
    const { activeOption, activity } = this.state;
    const { data: { openDates: dates, courses }, width } = this.props;

    // Aucune date disponible pour les parcours
    if (courses.length === 0 || dates.length === 0) {
      return this.renderUnavailabilityMsg();
    }

    if (isWidthUp('md', width)) {
      return (
        <React.Fragment>
          <Grid container spacing={24}>
            <Grid item md={6}>
              {this.renderDateOption()}
              {this.renderCourseOption()}
              <br />
              {this.renderButton()}
            </Grid>
            <Grid item md={6}>
              {activeOption === 0 && <SetDate />}
              {activeOption === 1 && <SetCourse activity={activity} />}
            </Grid>
          </Grid>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {this.renderDateOption()}
        {activeOption === 0 && <SetDate />}
        {this.renderCourseOption()}
        {activeOption === 1 && <SetCourse activity={activity} />}
        <br />
        {this.renderButton()}
      </React.Fragment>
    );
  }

  /* */
  renderButton() {
    const { history, search } = this.props;

    if (search.golf != null
      && search.date != null
      && search.course != null) {
      return (
        <Button
          fullWidth
          color="secondary"
          size="large"
          variant="contained"
          onClick={() => history.push('/schedule/result')}>
          {'Consulter'}
        </Button>
      );
    }

    return null;
  }

  /* */
  render() {
    const { error, loading } = this.state;

    return (
      <Screen
        error={error}
        title="Planning"
        loading={loading}
        onRetry={this.load}>
        {this.renderActivities()}
        {!loading && this.renderAllOptions()}
      </Screen>
    );
  }
}

const mapStateToProps = ({
  app,
  bookingData,
  bookingSearch,
}) => ({
  golf: app.golf,
  data: bookingData,
  account: app.account,
  search: bookingSearch,
});

const StyledComponent = withStyles(styles)(Search);

export default connect(
  mapStateToProps,
  Booking.actions,
)(withWidth()(StyledComponent));
