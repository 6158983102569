import React from 'react';
import { connect } from 'react-redux';
import { App, Account } from '@aps-management/primapp-common';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';

import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';

import { Screen } from '_components/core';
import Appbar from '_components/elements/Appbar';
import apolloClient from '_utils/apolloClient';

const covidImg = require('@aps-management/primapp-common/assets/images/covid.webp');

/* */
const styles = () => ({
  button: {
    marginTop: 10,
  },
  covid: {
    marginBottom: 24,
    width: 160,
    height: 240,
  },
});

/* */
class CovidCertificate extends React.Component {
  /* */
  state = {
    // Screen
    error: null,
    success: null,
    loading: true,
    covidCertificate: null,
  };

  /* */
  static navigationOptions = ({ navigation }) => ({
    header:
      <Appbar
        title="Pass Vaccinal"
        onBackPress={() => navigation.goBack()} />,
  });

  /* */
  doCheckCertificationCovid(id) {
    Account.api.setCovidCertificateOwned(apolloClient, { id });
    this.setState({ covidCertificate: moment().format() });
  }

  componentDidMount() {
    const { loadAccount } = this.props;
    loadAccount(apolloClient).then((upToDateAccount) => {
      this.setState({
        covidCertificate: upToDateAccount.payload.covidCertificate,
        loading: false,
      });
    });
  }

  /* */
  render() {
    const { account, classes } = this.props;
    const {
      // error,
      // success,
      covidCertificate,
      loading,
    } = this.state;

    return (
      <Screen loading={this.state.loading}>
        <Grid container alignItems='center' justify="center">
          {!loading && (
            <>
              {!covidCertificate ? (
                <>
                  <img src={covidImg} className={classes.covid} alt={'pass vaccinal'}/>
                </>
              ) : (
                <>
                  <CheckCircleOutline />
                </>
              )}
              {!covidCertificate ? (
                <>
                  <Typography
                    variant="body2"
                    align="center"
                    gutterBottom
                    weight="bold">
                    { 'Le « pass vaccinal » est mis en œuvre dans le cadre du renforcement des outils de gestion de crise sanitaire.'
                      + 'Il permet de renforcer la couverture vaccinale, de limiter les risques de diffusion épidémique, de minimiser '
                      + 'la probabilité de contamination dans des situations à risque, et donc la pression sur le système de soins, '
                      + 'tout en permettant de maintenir ouvertes certaines activités ou lieux en complément des protocoles sanitaires '
                      + 'propres à chaque secteur. Le « pass vaccinal » est exigé pour accéder aux lieux de loisirs et de culture' }
                  </Typography>
                  <Button
                    className={classes.button}
                    variant="contained"
                    onClick={() => this.doCheckCertificationCovid(account.id)}
                    color="secondary">
                    {"J'ai mon pass vaccinal"}
                  </Button>
                </>
              ) : (
                <Typography
                  variant="h6"
                  align="center"
                  weight="bold">
                  Vous avez déclaré avoir votre
                  pass sanitaire
                </Typography>
              )}
            </>
          )}
        </Grid>
      </Screen>
    );
  }
}

const mapStateToProps = ({ app: { account, golf } }) => ({ account, golf });

const StyledComponent = withStyles(styles)(CovidCertificate);

export default connect(
  mapStateToProps,
  App.actions,
)(StyledComponent);
