import React, { Fragment } from 'react';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import fileDownload from 'js-file-download';
import HomeIcon from '@material-ui/icons/Home';
import master from '_utils/master';
import {
  helpers,
  HELP_URL,
  App,
  Document,
} from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  AppBar,
  Button,
  Drawer,
  Hidden,
  Toolbar,
  IconButton,
  Typography,
  CssBaseline,
  CircularProgress,
  SwipeableDrawer,
  Link,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import {
  CheckBox,
  CheckBoxOutlineBlank,
  HelpOutline as HelpOutlineIcon,
} from '@material-ui/icons';
import i18n from '_utils/i18n';
import masterClient from '_utils/masterClient';
import Menu from './Menu';
import { Wrapper } from '../elements';
import Weather from './WeatherPopover';

/* */
const drawerWidth = 250;

/* */
const styles = (theme) => ({
  root: {
  },
  drawerSwipeable: {
    position: 'fixed',
    top: 0,
    paddingLeft: 40,
    paddingRight: 40,
    background: 'transparent',
  },
  drawerContent: {
    border: '2px solid #ffffff',
    backgroundColor: theme.palette.background.default,
    borderRadius: 15,
    textAlign: 'center',
    color: theme.palette.text.primary,
    fontSize: 16,
    padding: 20,
    fontWeight: 400,
    paddingTop: 30,
    margin: 'auto',
    userSelect: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  drawerContentLink: {
    color: theme.palette.text.secondary,
    fontWeight: 600,
    cursor: 'pointer',
    paddingTop: 10,
  },
  drawerContentButton: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: `${theme.spacing.unit * 4}px 0`,
  },
  drawer: {
    [theme.breakpoints.up('xl')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('xl')]: {
      display: 'none',
    },
  },
  homeButton: {
    marginRight: 20,
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  grow: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
});

/* */
class Screen extends React.Component {
  /* */
  static defaultProps = {
    error: null,
    success: null,
    loading: false,
    layout: 'fluid',
    noDrawer: false,
    errorAction: null,
    successAction: null,
    checked: false,
    drawerComponent: <Menu />,
    hideWeather: false,
    hideHomeIcon: false,
  };

  /* */
  state = {
    tabletOpen: false,
    openInfoPanel: true,
  };

  toggleDrawer = (open) => () => {
    this.setState({
      openInfoPanel: open,
    });
  };

  /* */
  componentDidUpdate(prevProps) {
    const {
      error, success, errorAction, successAction,
    } = this.props;

    const options = {};

    if (
      this.props.success !== null
      && this.props.success !== prevProps.success
    ) {
      if (successAction) {
        options.action = <Button size="small">{successAction.title}</Button>;
        options.onClickAction = successAction.onClick;
      }
      this.props.enqueueSnackbar(success, { variant: 'success', ...options });
    }

    if (this.props.error !== null && this.props.error !== prevProps.error) {
      if (errorAction) {
        options.action = <Button size="small">{errorAction.title}</Button>;
        options.onClickAction = errorAction.onClick;
      }
      this.props.enqueueSnackbar(i18n.t(helpers.string.handleError(error)), {
        variant: 'error',
        ...options,
      });
    }
  }

  /* */
  handleDrawerToggle = () => {
    this.setState((state) => ({ tabletOpen: !state.tabletOpen }));
  };

  /* */
  handleValidate = (documents) => {
    documents.map((doc) =>
      Document.api.validateDocument(masterClient, {
        accountId: this.props.account.id,
        id: doc.id,
      }));
    this.setState({
      openInfoPanel: false,
    });
  };
  downloadDocument = (d) => {
    master.blob(`document/${d.id}`)
      .then(data => fileDownload(data, `${d.name}.pdf`))
      .catch((error) => this.props.enqueueSnackbar(i18n.t(helpers.string.handleError(error)), {
        variant: 'error',
      }));
  };
  /* */
  render() {
    const { tabletOpen, checked } = this.state;
    const {
      title,
      layout,
      classes,
      loading,
      account,
      helpURL,
      noDrawer,
      children,
      drawerComponent,
      hideWeather,
      hideHomeIcon,
    } = this.props;

    const { documentAcceptance } = account?.documentAcceptance?.length > 0
      ? {
        documentAcceptance: account.documentAcceptance.filter(
          (doc) => !doc.acceptedAt,
        ),
      }
      : { documentAcceptance: false };
    return (
      <div className={classes.root}>
        <CssBaseline />
        {!noDrawer && (
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              {!hideHomeIcon && (
                <IconButton
                  to="/home"
                  color="inherit"
                  aria-label="Home"
                  component={NavLink}
                  className={classes.homeButton}
                >
                  <HomeIcon />
                </IconButton>
              )}
              <Typography
                noWrap
                variant="h6"
                color="inherit"
                className={classes.grow}
              >
                {title}
              </Typography>
              {!hideWeather && <Weather />}
              <IconButton
                aria-label="Info"
                color="inherit"
                onClick={() => window.open(helpURL || HELP_URL, '_blank')}
              >
                <HelpOutlineIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        )}
        {!noDrawer && (
          <nav className={classes.drawer}>
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden xlUp implementation="css">
              <Drawer
                container={this.props.container}
                variant="temporary"
                open={tabletOpen}
                onClose={this.handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {drawerComponent}
              </Drawer>
            </Hidden>
            <Hidden lgDown implementation="css">
              <Drawer
                open
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
              >
                {drawerComponent}
              </Drawer>
            </Hidden>
          </nav>
        )}
        <div className={classes.grow}>
          {!noDrawer && <div className={classes.toolbar} />}
          <Wrapper component="main" layout={layout}>
            {loading ? (
              <div className={classes.loaderContainer}>
                <CircularProgress size={32} thickness={4} color="secondary" />
              </div>
            ) : (
              children
            )}
          </Wrapper>
        </div>
        {account
          && (documentAcceptance && documentAcceptance.length > 0) && (
            <SwipeableDrawer
              elevation={0}
              classes={{
                paper: classes.drawerSwipeable,
              }}
              anchor="bottom"
              open={this.state.openInfoPanel}
              onClose={() => null}
              onOpen={this.toggleDrawer(true)}
            >
              <Box
                tabIndex={0}
                classes={{
                  root: classes.drawerContent,
                }}
                role="dialog"
              >
                <Typography style={{ fontSize: 16 }}>
                  {
                    'Pour pouvoir continuer à naviger sur le site, vous devez avoir pris connaissance et accepté les documents ci-dessous : '
                  }
                </Typography>
                {documentAcceptance.length > 0
                  && documentAcceptance.map((doc) => (
                    <Fragment key={doc.id}>
                      <Link
                        classes={{ root: classes.drawerContentLink }}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => this.downloadDocument(doc)}
                      >
                        {doc.name}
                      </Link>
                    </Fragment>
                  ))}
                <Box>
                  <FormControlLabel
                    checked={this.state.checked || false}
                    onChange={(event) =>
                      this.setState({ checked: event.target.checked })
                    }
                    style={{ marginLeft: 8 }}
                    control={
                      <Checkbox
                        color="primary"
                        checkedIcon={<CheckBox nativeColor="secondary" />}
                        icon={<CheckBoxOutlineBlank nativeColor="secondary" />}
                        disableRipple
                      />
                    }
                    label={
                      <Typography
                        style={{ fontSize: 16, paddingTop: 2, marginLeft: -8 }}
                        color="primary"
                      >
                        {
                          'En cochant cette case, je reconnais avoir pris connaissance et accepté les documents ci-dessus.'
                        }
                      </Typography>
                    }
                  />
                </Box>
                <Button
                  disabled={!checked}
                  classes={{ root: classes.drawerContentButton }}
                  variant="outlined"
                  color="secondary"
                  onClick={() =>
                    this.handleValidate(documentAcceptance)
                  }
                >
                  Valider
                </Button>
              </Box>
            </SwipeableDrawer>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ app: { account, golf } }) => ({ account, golf });

const StyledComponent = withStyles(styles)(Screen);

export default connect(
  mapStateToProps,
  App.actions,
)(withSnackbar(StyledComponent));
