import React, { useEffect, useState } from 'react';
import { Paper, Grow, Button } from '@material-ui/core';
import AddToCalendarHOC from 'react-add-to-calendar-hoc';
import { SiGooglecalendar, SiMicrosoftoutlook, SiYcombinator } from 'react-icons/si';
import { MdOutlineSave } from 'react-icons/md';

const AddToCalendarDropdown = (props) => {
  const CalButton = (buttonProps) => (
      <Button onClick={buttonProps.onClick}>{buttonProps.children}</Button>
  );

  const DropDown = ({ isOpen, children, onRequestClose }) => {
    const [isHovered, setIsHovered] = useState(false);
    useEffect(() => {
      const closeTimer = setTimeout(() => {
        if (isOpen && !isHovered) {
          onRequestClose({ preventDefault: () => {} });
        }
      }, 500);
      return () => clearTimeout(closeTimer);
    }, [isHovered, isOpen, onRequestClose]);
    const Icon = ({ type, ...iconProps }) => {
      switch (type) {
        case 'Goolge':
          return <SiGooglecalendar {...iconProps} />;
        case 'iCal':
          return <MdOutlineSave {...iconProps} />;
        case 'Outlook':
          return <SiMicrosoftoutlook {...iconProps} />;
        case 'Yahoo':
          return <SiYcombinator {...iconProps} />;
        default:
          return <SiGooglecalendar {...iconProps} />;
      }
    };

    return (
      <Grow in={isOpen}>
        <Paper
          onMouseOver={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {children.map((child, i) => (
            <Button key={i} {...child.props}><Icon size={24} type={child.key} /></Button>
          ))}
        </Paper>
      </Grow>
    );
  };

  const AddToCalendar = AddToCalendarHOC(CalButton, DropDown);

  return <AddToCalendar {...props} />;
};

export default AddToCalendarDropdown;
