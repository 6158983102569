import React from 'react';
import * as qs from 'query-string';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  App,
  Paybox,
  Account,
  helpers,
  CreditCard,
} from '@aps-management/primapp-common';
import {
  Grid,
  Paper,
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core';

import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import GetCreditCard from '_components/GetCreditCard';
import { Alert, CreditCardPreview } from '_components/elements';

/* */
const styles = theme => ({
  panel: {
    display: 'inherit',
  },
  paper: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
  },
  alert: {
    marginTop: theme.spacing.unit * 2,
  },
});

class ChangeCreditCard extends React.Component {
  /* */
  constructor(props) {
    super(props);

    this.authorizationAmount = 1000;

    this.state = {
      // ux
      error: null,
      success: null,
      loading: true,
      expanded: false,
      // data
      golf: null,
      account: null,
      imprint: null,
    };
  }

  /* */
  componentDidMount() {
    this.load()
      .catch(error => this.setState({ error }))
      .finally(() => this.setState({ screenLoading: false }));
  }

  /* */
  load = async () => {
    const { token } = qs.parse(this.props.location.search);

    // console.log(token);

    if (!token) { throw new Error('Lien erroné.'); }

    const account = await Account.api.getAccountByToken(apolloClient, { token });
    if (!account) throw new Error('Client inexistant.');

    // console.log('account', account);

    const matching = account.matches.find(m => (m.token === token ? m : false));

    const { golf } = await App.api.getGolfInfo(apolloClient, { id: matching.golfId });
    document.title = (golf && golf.name) || 'prima.golf';

    // console.log('golf', golf);

    // build session 3DS
    this.session3DS = {
      address: {
        city: golf.city,
        countryCode: 250, // golf.country
        line1: golf.name,
        zipCode: golf.postalCode,
      },
      customer: {
        email: account.email,
        lastName: account.lastname,
        firstName: account.firstname,
        phone: account.phone,
      },
    };

    const newState = {};
    const { creditCards } = await CreditCard.api.getCreditCards(apolloClient, {
      accountId: account.id,
    });

    if (creditCards[0]) {
      const status = CreditCard.functions.getStatus(creditCards[0].dueDate);
      newState.imprint = {
        ...status,
        ...creditCards[0],
      };
      newState.expanded = status.isExpired || status.isSoonExpired;
    }

    return this.setState({
      golf,
      account,
      loading: false,
      ...newState,
    });
  }

  /* */
  handleExpand = (e, expanded) => this.setState({ expanded });

  /* */
  handleSubmit = (values, { setSubmitting, resetForm }) => {
    const { id3D = null } = values;
    const { account } = this.state;

    const uuid = Paybox.functions.createUuidFrom(account.id);

    const creditCard = {
      ...values,
      name: '3DS web',
      number: values.number.replace(/\s/g, ''),
      dueDate: values.dueDate.replace(/\D/g, ''),
    };

    this.setState({ error: null, success: null });

    return Paybox.api.createSubscriber(apolloClient, {
      id3D,
      uuid,
      reference: account.id,
      amount: this.authorizationAmount,
      creditCard: helpers.object.filterByKeys(creditCard, ['number', 'dueDate', 'cvv']),
    })
      .catch((err) => {
        throw Object.assign(err, { handled: true });
      })
      // Enregistrement cc
      .then(res => CreditCard.api.setCreditCard(apolloClient, {
        id: uuid,
        token: res.PORTEUR,
        accountId: account.id,
        number: CreditCard.functions.anonymizeNumber(creditCard.number),
        ...helpers.object.filterByKeys(creditCard, ['name', 'dueDate', 'type', 'brand', 'country']),
      }))
      .catch((err) => {
        if (!err.handled) {
          throw Object.assign(err, {
            handled: true,
            message: 'Impossible d’enregistrer la carte. Veuillez réessayer ultérieurement.',
          });
        }
        throw err;
      })
      .then(() => {
        this.setState({
          expanded: false,
          imprint: creditCard,
          success: 'Votre carte est enregistrée avec succès.',
        });
        resetForm();
      })
      .catch(err => this.setState({ error: err.message }))
      .finally(() => setSubmitting(false));
  };

  /* */
  renderImprint() {
    const { imprint } = this.state;
    const { classes } = this.props;

    return (
      <Grid item xs={12} sm={9} md={6}>
        {imprint ? (
          <>
            <Typography
              gutterBottom
              variant="h6"
              component='h2'>
              {'Ma carte bancaire'}
            </Typography>
            <CreditCardPreview
              brand={imprint.brand}
              dueDate={imprint.dueDate}
              number={imprint.number} />
            {imprint.isExpired && (
              <Alert
                variant="error"
                className={classes.alert}>
                  {'La carte est expirée.'}
              </Alert>
            )}
            {imprint.isSoonExpired && (
              <Alert
                variant="warning"
                className={classes.alert}>
                  {'La carte arrive bientôt à expiration.'}
              </Alert>
            )}
          </>
        ) : (
          <Typography
            gutterBottom
            variant="h6"
            component='h2'>
            {'Aucune carte bancaire enregistrée.'}
          </Typography>
        )}
      </Grid>
    );
  }

  /* */
  renderForm() {
    const { classes } = this.props;
    const { imprint, expanded } = this.state;

    const form = (
      <GetCreditCard
        session3DS={this.session3DS}
        onSubmit={this.handleSubmit}
        authorizationAmount={this.authorizationAmount} />
    );

    return (
      <Grid item xs={12} sm={9} md={6}>
        {imprint ? (
          <ExpansionPanel
            expanded={expanded}
            onChange={this.handleExpand}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}>
              <Typography
                variant="subtitle1"
                component='h2'>
                {'Changer de carte'}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.panel}>
              {form}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ) : (
          <Paper className={classes.paper}>
            <Typography
              gutterBottom
              variant="subtitle1"
              component='h2'>
              {'Ajouter une carte'}
            </Typography>
            <br/>
            {form}
          </Paper>
        )}
      </Grid>
    );
  }

  /* */
  render() {
    const {
      // ux
      error,
      loading,
      success,
      // data
      golf,
      account,
    } = this.state;

    if (!account || !golf) return null;

    return (
      <Screen
        noDrawer
        error={error}
        success={success}
        loading={loading}>
        <div>
          <Typography variant="h5" gutterBottom>
            {golf.name}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {'Votre moyen de paiement'}
          </Typography>
          <br />
          <Typography gutterBottom>
            {'Bonjour '}<b>{`${account.firstname} ${account.lastname}`}</b>{','}
          </Typography>
          <Typography gutterBottom>
            {"Afin d'assurer le règlement de vos mensualités, veuillez vérifier l'état de votre carte bancaire."}
          </Typography>
          <Typography>
            <u>{'Rappel'}</u>{' : l\'ensemble des prélèvements effectués pour le compte de votre golf apparaîtront sous la mention "GOLF PRIMA" sur votre relevé bancaire.'}
          </Typography>
        </div>
        <br />
        <Grid
          container
          spacing={40}
          justify="center">
          {this.renderImprint()}
          {this.renderForm()}
        </Grid>
      </Screen>
    );
  }
}

export default withStyles(styles)(ChangeCreditCard);
