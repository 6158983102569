import i18n from 'i18n-js';
import { translations } from '@aps-management/primapp-common';

i18n.locale = 'fr'; // Localization.locale;
i18n.fallbacks = true;
i18n.defaultLocale = 'fr';
i18n.translations = translations;

/* */
i18n.getFromOpts = (scope, opts, params) => {
  if (params && typeof params.activity !== 'undefined') {
    const translation = opts.activities[params.activity]?.scopes[scope];
    if (translation) { return translation; }
  } else {
    const translation = opts.i18n?.[scope];
    if (translation) return translation;
  }

  return i18n.t(scope);
};

/* */
i18n.t = (str, opts) => i18n.translate(str, { defaultValue: str, ...opts });

export default i18n;
