import React from 'react';
import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import * as Screens from '_screens';
import { getAuthorization } from '_utils/security';

/* */
class Router extends React.Component {
  /* */
  createRoute(path, Component) {
    const { golf, account } = this.props;

    const { isAuthorized, status, type } = getAuthorization(path, { golf, account });

    switch (type) {
      case 'anonymous':
        return (
          <Route
            exact
            path={path}
            render={(props) => (isAuthorized ? <Component {...props} /> : <Redirect to="/" />)} />
        );
      default:
        // Nothing
    }

    const ComponentDeny = !status.isAuthenticated
      ? <Redirect to="/login" />
      : <Screens.Public.Unauthorized />;

    return (
      <Route
        exact
        path={path}
        render={(props) => (isAuthorized ? <Component {...props} /> : ComponentDeny)} />
    );
  }

  render() {
    const { user } = this.props;
    const defaultRoute = user ? '/home' : '/login';

    return (
      <Switch>
        <Route exact path="/" component={() => <Redirect to={defaultRoute} />} />
        <Route exact path="/practice" component={() => <Redirect to="/primaccess" />} />
        {/* Home */}
        {this.createRoute('/home', Screens.Home)}
        {this.createRoute('/guest', Screens.CreateGuest)}
        {/* Account */}
        {this.createRoute('/account', Screens.Account.Submenu)}
        {this.createRoute('/account/edit', Screens.Account.Edit)}
        {this.createRoute('/account/id', Screens.Account.ChangeLoginId)}
        {this.createRoute('/account/statement', Screens.Account.ViewStatement)}
        {this.createRoute('/account/interests', Screens.Account.ChangeInterests)}
        {this.createRoute('/account/creditcard', Screens.Account.ManageCreditCard)}
        {this.createRoute('/account/covidcertificate', Screens.Account.CovidCertificate)}
        {this.createRoute('/accountwallet/payment', Screens.AccountWallet.Payment)}
        {this.createRoute('/accountwallet/payment/voucher', Screens.AccountWallet.Voucher)}
        {/* Schedule */}
        {this.createRoute('/schedule', Screens.Schedule.Search)}
        {this.createRoute('/schedule/result', Screens.Schedule.Result)}
        {/* Booking - Book */}
        {this.createRoute('/booking', Screens.Booking.Search)}
        {this.createRoute('/booking/cart', Screens.Booking.Cart)}
        {this.createRoute('/booking/result', Screens.Booking.Result)}
        {this.createRoute('/booking/payment', Screens.Booking.Payment)}
        {this.createRoute('/booking/voucher', Screens.Booking.Voucher)}
        {/* My bookings */}
        {this.createRoute('/mybookings', Screens.Booking.History)}
        {this.createRoute('/mybookings/details', Screens.Booking.View)}
        {this.createRoute('/mybookings/:id/edit', Screens.Booking.Edit)}
        {this.createRoute('/mybookings/:id', Screens.Booking.View)}
        {/* Academy */}
        {this.createRoute('/academy', Screens.Academy.Search)}
        {this.createRoute('/academy/:id', Screens.Academy.Enter)}
        {this.createRoute('/academy/:id/entrants', Screens.Academy.SetPlayers)}
        {this.createRoute('/academy/:id/individual', Screens.Academy.SetPlayers)}
        {this.createRoute('/academy/:id/fees', Screens.Academy.Cart)}
        {this.createRoute('/academy/:id/voucher', Screens.Academy.Voucher)}
        {this.createRoute('/booking/voucher', Screens.Booking.Voucher)}
        {/* My academy */}
        {this.createRoute('/myacademy', Screens.Academy.History)}
        {this.createRoute('/myacademy/details', Screens.Academy.View)}
        {this.createRoute('/myacademy/:id', Screens.Academy.View)}
        {/* Document */}
        {this.createRoute('/document', Screens.Document.MyFolder)}
        {this.createRoute('/document/shared-with-me', Screens.Document.SharedWithMe)}
        {/* Directory */}
        {this.createRoute('/directory', Screens.Directory.SearchMembers)}
        {this.createRoute('/directory/members', Screens.Directory.SearchMembers)}
        {this.createRoute('/directory/contacts', Screens.Directory.ManageContacts)}
        {/* Membership */}
        {this.createRoute('/membership', Screens.Membership.List)}
        {this.createRoute('/membership/subscribe', Screens.Membership.Subscribe)}
        {this.createRoute('/membership/view', Screens.Membership.View)}
        {this.createRoute('/membership/payment', Screens.Membership.Payment)}
        {this.createRoute('/membership/voucher', Screens.Membership.Voucher)}
        {this.createRoute('/membership/schedule/:months', Screens.Membership.ApproveSchedule)}
        {/* Tournament */}
        {this.createRoute('/tournament', Screens.Tournament.Calendar)}
        {this.createRoute('/tournament/:id', Screens.Tournament.Enter)}
        {this.createRoute('/tournament/:id/fees', Screens.Tournament.Cart)}
        {this.createRoute('/tournament/:id/payment', Screens.Tournament.Payment)}
        {this.createRoute('/tournament/:id/voucher', Screens.Tournament.Voucher)}
        {this.createRoute('/tournament/:id/results', Screens.Tournament.Results)}
        {this.createRoute('/tournament/:id/event/:index', Screens.Tournament.Event)}
        {this.createRoute('/tournament/:id/startlist', Screens.Tournament.StartList)}
        {this.createRoute('/tournament/:id/entrants', Screens.Tournament.SetPlayers)}
        {this.createRoute('/mytournaments', Screens.Tournament.History)}
        {this.createRoute('/mytournaments/:id', Screens.Tournament.View)}
        {this.createRoute('/mytournaments/:id/:orderId', Screens.Tournament.View)}
        {/* Event */}
        {this.createRoute('/event', Screens.Event.Calendar)}
        {this.createRoute('/event/:id', Screens.Event.Enter)}
        {this.createRoute('/event/:id/fees', Screens.Event.Cart)}
        {this.createRoute('/event/:id/payment', Screens.Event.Payment)}
        {this.createRoute('/event/:id/voucher', Screens.Event.Voucher)}
        {this.createRoute('/event/:id/entrants', Screens.Event.SetPlayers)}
        {this.createRoute('/myevents', Screens.Event.History)}
        {this.createRoute('/myevents/:id', Screens.Event.View)}
        {this.createRoute('/myevents/:id/:orderId', Screens.Event.View)}
        {/* Primaccess */}
        {this.createRoute('/primaccess', Screens.Primaccess.View)}
        {this.createRoute('/primaccess/payment', Screens.Primaccess.Payment)}
        {this.createRoute('/primaccess/voucher', Screens.Primaccess.Voucher)}
        {this.createRoute('/primaccess/autorefill', Screens.Primaccess.AutoRefill)}
        {/* Anonymous */}
        {this.createRoute('/edg', Screens.School.Lessons)}
        {this.createRoute('/login', Screens.Auth.Login)}
        {this.createRoute('/forgot', Screens.Auth.Forgot)}
        {this.createRoute('/register', Screens.Auth.Register)}
        {this.createRoute('/public-booking', Screens.Public.PublicBooking)}
        <Route component={Screens.Public.NotFound} />
      </Switch>
    );
  }
}

export default Router;
