import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  Paper,
  Button,
  Typography,
} from '@material-ui/core';
/* */
import i18n from '_utils/i18n';
import { Screen } from '_components/core';

/* */
const styles = theme => ({
  voucher: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 1,
  },
  paperBottom: {
    display: 'flex',
    borderTop: `1px dashed ${theme.palette.grey[400]}`,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing.unit * 4,
    paddingTop: theme.spacing.unit * 2,
  },
  body1: {
    ...theme.typography.body2,
    textAlign: 'center',
  },
  table: {
    paddingTop: theme.spacing.unit * 1,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  underline: {
    textDecoration: 'underline',
  },
});

/* */
class PrimaccessVoucher extends React.Component {
  /* */
  constructor(props) {
    super(props);

    const { primaccess: { transaction } } = this.props;

    this.redirect = !transaction;
  }

  /* */
  renderPaymentInfo() {
    const { classes, primaccess } = this.props;
    const { transaction } = primaccess;

    return (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
        className={classes.table}
        justifyContent="space-between">
        <Box>
          <Typography gutterBottom>{'Total'}</Typography>
          <Typography color="textSecondary">
            {i18n.l('currency', transaction.amount / 100)}
          </Typography>
        </Box>
        {transaction.transactionNo && (
          <Box>
            <Typography gutterBottom>{'E-ticket'}</Typography>
            <Typography color="textSecondary">
              {transaction.transactionNo}
            </Typography>
          </Box>
        )}
        {transaction.mode === 2 && (
          <Typography color="textSecondary">
            {'Paiement par compte client'}
          </Typography>
        )}
      </Box>
    );
  }

  /* */
  render() {
    const {
      golf,
      account,
      classes,
      primaccess,
    } = this.props;

    if (this.redirect) {
      return <Redirect to="/primaccess" />;
    }

    const { cart } = primaccess;
    const product = cart[0];

    return (
      <Screen layout="fixed" title="Confirmation de paiement">
        <Typography
          paragraph
          variant="h4"
          align="center">
          {'Merci de votre achat !'}
        </Typography>
        <div className={classes.voucher}>
          <Paper className={classes.paper}>
            <Typography
              gutterBottom
              variant="h5"
              align="center"
              component="h2"
              color="textSecondary">
              {golf.name}
            </Typography>
            <Typography
              align="center"
              variant="body2"
              className={classes.underline}>
              {golf.email}
            </Typography>
          </Paper>
          <Paper className={classes.paper}>
            <Typography
              gutterBottom
              variant="h5"
              align="center"
              component="h2"
              color="textSecondary">
              {product.name}
            </Typography>
            <p className={classes.body1}>
              {i18n.t('membership.messages.sent_confirm_email')} <span className={classes.underline}>{account.email}</span>
            </p>
            {this.renderPaymentInfo()}
          </Paper>
        </div>
        <Button
          fullWidth
          size="large"
          color="secondary"
          variant="contained"
          onClick={() => this.props.history.push('/primaccess')}>
          {'Retour'}
        </Button>
      </Screen>
    );
  }
}

const mapStateToProps = ({ app, primaccess }) => ({
  primaccess,
  golf: app.golf,
  account: app.account,
});

const StyledComponent = withStyles(styles)(PrimaccessVoucher);

export default connect(
  mapStateToProps,
  null,
)(StyledComponent);
