import React from 'react';
import moment from 'moment';
import { InfoOutlined } from '@material-ui/icons';
import { unstable_Box as Box } from '@material-ui/core/Box';
import { Chip, Popover, Typography } from '@material-ui/core';
import i18n from '_utils/i18n';

/* */
class PlayerTypesListWithRules extends React.Component {
  /* */
  constructor(props) {
    super(props);

    this.state = {
      rules: [],
      anchorEl: null,
    };
    this.chipRef = React.createRef();
  }

  handleClick = (event, rules) => {
    const formattedRules = rules.map(r => ({
      name: r.name,
      data: JSON.parse(r.value),
    }));

    this.setState({
      rules: formattedRules,
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  /* */
  render() {
    const { rules } = this.state;
    const { playerTypes } = this.props;

    if (!playerTypes || !playerTypes.length) return null;

    // Init
    const availableIn = rules.find(r => r.name === 'available_in');
    const availableOver = rules.find(r => r.name === 'available_over');
    const maxSlots = rules.find(r => r.name === 'max_slots');
    const maxSlotsPerPlayer = rules.find(r => r.name === 'max_slots_per_player');
    const closures = rules.filter(r => r.name === 'closure');
    const unitPrice = rules.find(r => r.name === 'unit_price');
    const discount = rules.find(r => r.name === 'discount');

    const translatedRules = [];
    // Prix unitaire appliqué
    if (unitPrice) {
      translatedRules.push({
        name: 'Tarif réduit',
        value: i18n.l('currency', unitPrice.data.value / 100),
      });
    }
    // Remise appliquée
    if (discount) {
      translatedRules.push({
        name: 'Remise appliquée',
        value: i18n.l('percentage', -1 * discount.data.value),
      });
    }
    // Période autorisée
    if (availableIn && availableOver) {
      translatedRules.push({
        name: 'Période',
        value: availableIn.data.value === 0
          ? `J à J+${availableOver.data.value}`
          : `J+${availableIn.data.value} à J+${availableOver.data.value}`,
      });
    }
    // Quota total
    if (maxSlots) {
      translatedRules.push({
        name: 'Quota total',
        value: maxSlots.data.duration === null
          ? maxSlots.data.value
          : `${maxSlots.data.value} / ${i18n.t(`terms.${maxSlots.data.duration}`)}`,
      });
    }
    // Quota par joueur
    if (maxSlotsPerPlayer) {
      translatedRules.push({
        name: 'Quota par joueur',
        value: maxSlotsPerPlayer.data.duration === null
          ? maxSlotsPerPlayer.data.value
          : `${maxSlotsPerPlayer.data.value} / ${i18n.t(`terms.${maxSlotsPerPlayer.data.duration}`)}`,
      });
    }

    // Exceptions
    const translatedClosures = closures.map((c) => {
      let timeRange = '';
      if (c.data.timeRange[0] === '0:00') {
        timeRange = `jusqu'à ${c.data.timeRange[1]}`;
      }
      if (c.data.timeRange[1] === '0:00') {
        timeRange = `à partir de ${c.data.timeRange[0]}`;
      }
      if (c.data.timeRange[0] !== '0:00' && c.data.timeRange[1] !== '0:00') {
        timeRange = `de ${c.data.timeRange[0]} à ${c.data.timeRange[1]}`;
      }

      const days = c.data.dayOfWeek.length > 1
        ? c.data.dayOfWeek.map(dof => `${moment(dof, 'E').format('dddd')}`).join(', ')
        : `${moment(c.data.dayOfWeek[0], 'E').format('dddd')}`;

      return {
        days,
        timeRange,
      };
    });

    // Règles simples
    const rulesJSX = translatedRules.map((tr, idx) => (
      <Box
        key={`pt-rules-${idx}`}
        display="flex"
        flexDirection="row"
        justifyContent="space-between">
        <Typography>{tr.name}</Typography>
        <Typography>{tr.value}</Typography>
      </Box>
    ));

    // Exceptions
    if (translatedClosures.length > 0) {
      rulesJSX.push(
        <Box key="pt-rules-exceptions">
          <hr/>
          <Typography><i>{'Exceptions'}</i></Typography>
          {translatedClosures.map((c, k) => (
            <Box
              key={k}
              display="flex"
              flexDirection="row"
              justifyContent="space-between">
              <Typography>{c.days}</Typography>
              <Typography>{c.timeRange}</Typography>
            </Box>
          ))}
        </Box>,
      );
    }

    return (
      <Box mt={0.5}>
        {playerTypes.map((pt, idx) => (
          <Chip
            key={idx}
            label={pt.name}
            icon={<InfoOutlined />}
            onClick={event => this.handleClick(event, pt.rules)} />
        ))}
        <Popover
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
          anchorEl={this.state.anchorEl}
          anchorReference="anchorEl"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box px={2} py={2}>
            <Typography variant="subtitle1">
              <b>{'Réservation de green-fees'}</b>
            </Typography>
            {rulesJSX}
            <hr/>
            <Typography variant="caption">
              {'En dehors de ces règles, le tarif visteur est appliqué.'}
            </Typography>
          </Box>
        </Popover>
      </Box>
    );
  }
}

export default PlayerTypesListWithRules;
