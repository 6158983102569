import React from 'react';
import { Formik } from 'formik';
import { App } from '@aps-management/primapp-common';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {
  Paper,
  Avatar,
  Typography,
} from '@material-ui/core';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import { CreateAccountForm } from '_components/forms';
import { createValidationSchema, validators } from '_utils/validation';

const validationSchema = createValidationSchema({
  firstname: validators.required,
  lastname: validators.required,
  phone: validators.required,
});

/* */
const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
});

/* */
class CreateAccount extends React.Component {
  /* */
  state = {
    error: null,
    success: null,
  };

  /* */
  onSubmit = (values, { setSubmitting }) => {
    this.setState({ error: null });
    const { user: { email }, golf } = this.props.app;

    this.props.setAccount(apolloClient, {
      email,
      activationData: {
        golfId: golf.id,
        reference: 'UNKNOWN',
      },
      ...values,
    })
      .catch((error) => {
        this.setState({ error: error.message });
        setSubmitting(false);
      });
  };

  /* */
  render() {
    const { classes } = this.props;
    const { error, success } = this.state;

    return (
      <Screen
        noDrawer
        error={error}
        layout='fixed'
        success={success}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography gutterBottom component="h1" variant="h5">
            {'Vos données personnelles'}
          </Typography>
          <Typography gutterBottom component="p" variant="body1">
            {'Pour continuer sur notre site, veuillez renseigner les informations ci-dessous.'}
          </Typography>
          <Formik
            component={CreateAccountForm}
            validationSchema={validationSchema}
            initialValues={{
              lastname: '',
              firstname: '',
              phone: '',
            }}
            onSubmit={this.onSubmit} />
        </Paper>
      </Screen>
    );
  }
}

const mapStateToProps = ({ app }) => ({ app });

export default withStyles(styles)(connect(mapStateToProps, App.actions)(CreateAccount));
