import store from '_config/store';
import { master } from '@aps-management/primapp-common';

function getOptions({ headers, ...options }) {
  const context = headers || {};
  const {
    golf,
    token,
  } = store.getState().app;
  if (golf) context['x-golf'] = golf.id;
  if (token) context['x-access-token'] = token;
  return {
    headers: context,
    ...options,
  };
}

export default {
  api: master.api,
  get: (url, params, options = {}) => master.api(url, 'GET', getOptions({ params, ...options })),
  post: (url, data, options = {}) => master.api(url, 'POST', getOptions({ data, ...options })),
  put: (url, data, options = {}) => master.api(url, 'PUT', getOptions({ data, ...options })),
  delete: (url, params, options = {}) => master.api(url, 'DELETE', getOptions({ params, ...options })),
  blob: (url, params, options = {}) => master.api(url, 'GET', getOptions({ params, responseType: 'blob', ...options })),
};
