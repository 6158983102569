import React from 'react';
import { Typography } from '@material-ui/core';
import { unstable_Box as Box } from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const styles = (theme) => ({
  container: {
    width: 84,
    borderRadius: 4,
    overflow: 'hidden',
    boxShadow: theme.shadows[2],
    backgroundColor: theme.palette.common.white,
  },
  month: {
    textTransform: 'capitalize',
    marginBottom: 4,
    lineHeight: '26px',
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
  },
  dayOfWeek: {
    lineHeight: '22px',
    textTransform: 'capitalize',
  },
  dayOfMonth: { lineHeight: '32px' },
  icon: { marginRight: theme.spacing.unit * 0.5 },
});

const CalendarDay = ({
  classes,
  datetime,
  displayTime,
  endTime = null,
}) => (
  <div>
    <Box
      display="flex"
      textAlign="center"
      flexDirection="column"
      className={classes.container}>
      <Typography
        color="inherit"
        component="span"
        variant='subtitle1'
        className={classes.month}>
        {datetime.format('MMM')}
      </Typography>
      <Typography
        variant="h5"
        component="span"
        className={classes.dayOfMonth}>
        {datetime.format('DD')}
      </Typography>
      <Typography
        component="span"
        variant="caption"
        className={classes.dayOfWeek}>
        {datetime.format('dddd')}
      </Typography>
      {displayTime && (
        <Box
          my={0.5}
          display="flex"
          alignItems="center"
          flexDirection="row"
          justifyContent="center">
          <AccessTimeIcon
            color="action"
            fontSize="small"
            className={classes.icon} />
          <Box display="flex" flexDirection="column">
            <Typography
              color="textSecondary"
              variant="body1"
              component="span">
              {datetime.format('HH:mm')}
            </Typography>
            {endTime && (
              <Typography
                color="textSecondary"
                variant="body1"
                component="span">
                {endTime.format('HH:mm')}
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </Box>
  </div>
);

export default withStyles(styles)(CalendarDay);
