import React from 'react';
import { Formik } from 'formik';
import { App } from '@aps-management/primapp-common';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {
  Link,
  Paper,
  Avatar,
  Typography,
} from '@material-ui/core';
import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import { ForgotPasswordForm } from '_components/forms';
import { createValidationSchema, validators } from '_utils/validation';

/* */
const validationSchema = createValidationSchema({
  email: validators.email,
});

/* */
const styles = theme => ({
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: theme.spacing.unit * 4,
    padding: theme.spacing.unit * 3,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  avatar: {
    marginBottom: theme.spacing.unit * 2,
    backgroundColor: theme.palette.secondary.main,
  },
});

/* */
class Forgot extends React.Component {
  /* */
  state = {
    error: null,
    success: null,
  };

  /* */
  onSubmit = (values, { setSubmitting }) => {
    const { golf } = this.props;
    this.setState({ error: null });

    App.api.forgotPassword(values.email, golf.id)
      .then(() => this.setState({ success: `Un email a été envoyé à l'adresse ${values.email}` }))
      .catch((error) => {
        this.setState({ error: error.message });
      })
      .finally(() => setSubmitting(false));
  };

  /* */
  render() {
    const { classes, golf } = this.props;
    const { error, success } = this.state;

    return (
      <Screen
        noDrawer
        error={error}
        layout='fixed'
        success={success}>
        <Typography component="h1" variant="h5" align="center">
          {golf && golf.name}
        </Typography>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography gutterBottom component="h2" variant="h5">
            {i18n.t('auth.titles.forgot_password')}
          </Typography>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={validationSchema}
            onSubmit={this.onSubmit}
            component={ForgotPasswordForm} />
        </Paper>
        <Box textAlign="center">
          <Link
            to="/login"
            color="textSecondary"
            component={RouterLink}>
            {i18n.t('auth.titles.log_in')}
          </Link>
        </Box>
      </Screen>
    );
  }
}

const mapStateToProps = ({ app }) => ({ golf: app.golf });

export default connect(mapStateToProps)(withStyles(styles)(Forgot));
