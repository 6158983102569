import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import {
  App,
  Booking,
  helpers,
  HELP_URL,
} from '@aps-management/primapp-common';
import {
  Face as FaceIcon,
  People as PeopleIcon,
  FiberManualRecord as PersonIcon,
} from '@material-ui/icons';
import {
  colors,
  Grid,
  Paper,
  Avatar,
  Button,
  Switch,
  Collapse,
  IconButton,
  Typography,
  FormControlLabel,
} from '@material-ui/core';
import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import { Alert } from '_components/elements';
import apolloClient from '_utils/apolloClient';

/* */
const styles = theme => ({
  timeslot: {
    padding: theme.spacing.unit * 1.5,
    paddingTop: theme.spacing.unit * 1,
  },
  time: {
    minWidth: 66,
    fontWeight: 'bold',
  },
  players: {
    fontSize: 10,
    color: theme.palette.grey[300],
  },
  player: {
    color: colors.green[500],
  },
  people: {
    marginRight: theme.spacing.unit * 1,
  },
  others: {
    marginTop: theme.spacing.unit * 1.5,
  },
  other: {
    padding: theme.spacing.unit * 1.5,
  },
  price: {
    color: theme.palette.text.secondary,
  },
  initPrice: {
    color: theme.palette.grey[500],
    textDecoration: 'line-through',
    marginRight: theme.spacing.unit * 1.5,
  },
  hcp: {
    fontSize: 13,
    fontWeight: 'bold',
    marginRight: theme.spacing.unit * 1,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.dark,
  },
  alert: {
    margin: `${theme.spacing.unit * 2}px 0`,
  },
});

/* */
const USER_OPTION_NAME = 'showPlanning';

/* */
class BookingResult extends React.Component {
  /* */
  constructor(props) {
    super(props);

    const { search, account, golf } = props;

    this.redirect = false;
    if (search.golf === null
      || search.date === null
      || search.course === null) {
      this.redirect = true;
      return;
    }

    this.state = {
      time: null,
      error: null,
      reasons: [],
      schedule: {},
      loading: true,
      expanded: null,
      dailyNote: null,
      locations: null,
      showPlanning: false,
    };
    this.timeslotsList = [];

    this.accountMatch = App.functions.match(account, golf);
    this.isMember = App.functions.isMember(account, golf);
    this.anonymousAllowed = false;

    // init golf options
    this.showOthersOnTimeslot = this.isMember
      || (golf.options.booking.showOthersOnTimeslot || false);
    this.showAvailabilityOnTimeslot = this.isMember
      || (golf.options.booking.showAvailabilityOnTimeslot || false);
  }

  /* */
  componentDidMount() {
    this.load();
  }

  /* */
  load = () => {
    if (this.redirect) return;

    const { user, search } = this.props;

    this.setState({
      time: null,
      error: null,
      schedule: {},
      loading: true,
      expanded: null,
      locations: null,
      showPlanning: this.isMember && (user.options && user.options[USER_OPTION_NAME]),
    });

    const params = {
      golfId: search.golf.id,
      courseId: search.course.id,
      courseType: search.course.type,
      date: search.date,
      players: search.players.map(pl => pl.reference),
    };

    if (Object.keys(search.accessories).length) {
      params.accessoryIds = Object.keys(search.accessories);
    }

    // Get schedules, course + accessories
    Booking.api.getSchedules(apolloClient, params)
      .then(([{ courseSchedule }, { accessoriesSchedule }]) => {
        const schedules = {};
        const locations = [];

        const { reasons, dailyNote, anonymousAllowed } = courseSchedule;
        this.anonymousAllowed = anonymousAllowed;

        schedules.course = Booking.functions.processSchedule('course', courseSchedule.teetimes);
        if (accessoriesSchedule) {
          schedules.accessories = Booking.functions.processSchedule('accessories', accessoriesSchedule);
        } else {
          schedules.accessories = {};
        }
        const detailedSchedule = Booking.functions.getDetailedSchedule(
          schedules,
          { accessories: search.accessories, players: search.players },
        );
        this.timeslotsList = Object.keys(detailedSchedule).sort((a, b) => a - b);

        if (detailedSchedule) {
          Object.values(detailedSchedule).forEach(({ others }) => {
            if (others) {
              others.forEach((other) => {
                if (other && other.location) {
                  // fix pas confiance dans l'id location
                  if (!locations.find(l => l.name === other.location.name)) {
                    locations.push(other.location);
                  }
                }
              });
            }
          });
        }
        this.setState({
          reasons,
          dailyNote,
          locations,
          loading: false,
          schedule: detailedSchedule,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
          error: e.message,
        });
      });
  };

  /* */
  goToCart(time) {
    const { accountMatch } = this;
    const { schedule } = this.state;
    const {
      golf,
      account,
      history,
      search,
    } = this.props;

    const {
      firstname,
      lastname,
      email,
      phone,
      covidCertificate,
    } = account;

    const booking = schedule[time];
    booking.golf = search.golf;
    booking.date = search.date;
    booking.course = search.course;
    booking.accountId = account.id;
    // option
    booking.anonymousAllowed = this.anonymousAllowed;
    // currency
    booking.total.currency = golf.currency;

    // account =/= players[0]
    booking.owner = {
      email,
      phone,
      name: `${firstname} ${lastname}`,
      covidCertificate,
      tid: accountMatch && accountMatch.reference, // use for insert partnership
    };

    this.props.setBooking(booking);
    history.push('/booking/cart');
  }

  /* */
  displayOtherName(other, key) {
    const { classes, width } = this.props;

    let location = '';
    const br = isWidthUp('md', width) ? (<br />) : '\xa0'; // &nbsp;
    const isAnonymous = `${other.firstname || ''}${other.lastname || ''}`.trim() === '';

    const fullname = isAnonymous
      ? (<span>{'Joueur'}{br}{'Anonyme'}</span>)
      : (<span>{other.firstname}{other.firstname && br}{other.lastname}</span>);

    if (this.isMember && other.location) {
      location = (
        <span title={other.location.name}>
          {` (${other.location.name[0]})`.toUpperCase()}
        </span>
      );
    }

    return (
      <Grid item key={key} sm={6} xs={12} className={classes.other}>
        <Box
          display="flex"
          alignItems="flex-start"
          flexDirection="row">
          {!other.index && <Avatar className={classes.hcp}><FaceIcon /></Avatar>}
          {!!other.index && <Avatar className={classes.hcp}>{other.index.toFixed(1)}</Avatar>}
          <Typography variant="body2">{fullname}{location}</Typography>
        </Box>
      </Grid>
    );
  }

  /* */
  renderDailyNote() {
    const { dailyNote } = this.state;

    if (!dailyNote) return null;

    return (
      <Alert variant="info">
        {dailyNote.split('\\n').map((line, i) => (
          <Fragment key={i}>{line}<br /></Fragment>
        ))}
      </Alert>
    );
  }

  /* */
  renderReasons() {
    const { reasons } = this.state;
    const { classes, search: { players } } = this.props;

    if (reasons.length === 0) return null;

    const reasonsByPlayer = reasons.reduce((acc, { player, code }) => {
      if (typeof acc[player] === 'undefined') {
        acc[player] = [];
      }
      acc[player].push(code);
      return acc;
    }, {});

    return (
      <Alert variant="error" className={classes.alert}>
        {Object.keys(reasonsByPlayer).map((ref) => {
          const playerOnError = players.find(p => p.reference === ref)
            || { firstname: 'Joueur', lastname: 'anonyme' };

          return (
            <Fragment key={ref}>
              <strong>{`${playerOnError.firstname} ${playerOnError.lastname}`}</strong><br />
              {reasonsByPlayer[ref].map((code, i) => (
                <span key={i}>{i18n.t(code)}<br /></span>
              ))}
            </Fragment>
          );
        })}
      </Alert>
    );
  }

  /* */
  renderPlanningSwitch() {
    const {
      locations,
      showPlanning,
    } = this.state;

    if (!this.isMember || this.timeslotsList.length === 0) return <br />;

    return (
      <Box
        mt={3}
        mb={1}
        display="flex"
        alignItems="center"
        flexDirection="row">
        <Box flex={1}>
          {locations && locations.map(l => (
            <Typography variant="caption" display="block" gutterBottom key={l.id}>
              {`(${l.name[0]}) ${l.name}`}
            </Typography>
          ))}
        </Box>
        <Box flex={1} textAlign="end">
          <FormControlLabel label='Afficher le planning' control={<Switch
            checked={showPlanning}
            onChange={() => this.setState({ showPlanning: !showPlanning }, () =>
              App.api.setUserOption(apolloClient, {
                name: USER_OPTION_NAME,
                value: this.state.showPlanning,
              }))}
            inputProps={{ 'aria-label': 'primary checkbox' }} />} />
        </Box>
      </Box>
    );
  }

  /* */
  renderEmpty() {
    return (
      <Box textAlign="center">
        <Typography
          paragraph
          align="center"
          variant="subtitle1">
          {"Aucun créneau n'est disponible avec vos critères."}
        </Typography>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => this.props.history.goBack()}>
          {'Retour'}
        </Button>
      </Box>
    );
  }

  /* */
  renderTimeslot = (time, key) => {
    const { classes } = this.props;
    const { schedule, expanded, showPlanning } = this.state;

    const {
      free,
      others,
      deficiency,
      availability,
    } = schedule[time];
    const { price, discount, initPrice } = schedule[time].total;

    if (!showPlanning && !free) return null;

    const showAvailability = this.showAvailabilityOnTimeslot;
    const showOthers = this.showOthersOnTimeslot && !showPlanning;
    const hasOthers = this.showOthersOnTimeslot && Boolean(others && others.length);
    const displayOthers = showPlanning || (showOthers && (time === this.state.time));

    const isExpanded = expanded === key;

    const callToAction = free ? (
      <Button
        fullWidth
        size="large"
        color="secondary"
        variant="contained"
        onClick={() => this.goToCart(time)}>
        {'Réserver'}
      </Button>
    ) : (
      <Button
        disabled
        fullWidth
        size="large"
        color="secondary"
        variant="contained">
        {'Complet'}
      </Button>
    );

    if (price === 0) {
      return (
        <Grid item key={key} md={6} sm={10} xs={12}>
          <Paper className={classes.timeslot}>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="row">
              <Typography
                className={classes.time}
                component="span"
                variant="h6">
                {Booking.functions.formatTime(time)}
                {showAvailability && (
                  <div
                    className={classes.players}
                    title={i18n.t('terms.availability', { count: availability })}>
                    {[...Array(availability).keys()].map(i => (
                      <PersonIcon key={i} className={classes.player} fontSize="inherit" />
                    ))}
                    {others && others.map((o, i) => (<PersonIcon key={i} fontSize="inherit" />))}
                  </div>
                )}
              </Typography>
              {showOthers && (
                <IconButton
                  size="small"
                  disabled={!hasOthers}
                  style={{ visibility: showOthers ? 'visible' : 'hidden' }}
                  className={classes.people}
                  onClick={() => this.setState({ time: (this.state.time === time) ? null : time })}>
                  <PeopleIcon />
                </IconButton>
              )}
              {callToAction}
            </Box>
            {hasOthers && (
              <Collapse timeout={100} in={displayOthers}>
                <Grid container spacing={8} className={classes.others}>
                  {others && others.map((o, i) => this.displayOtherName(o, i))}
                </Grid>
              </Collapse>
            )}
          </Paper>
        </Grid>
      );
    }

    return (
      <Grid item key={key} md={6} sm={10} xs={12}>
        <Paper className={classes.timeslot}>
          <Box
            mb={1}
            display="flex"
            alignItems="center"
            flexDirection="row">
            <Typography
              className={classes.time}
              component="span"
              variant="h6">
              {Booking.functions.formatTime(time)}
              {showAvailability && (
                <div
                  className={classes.players}
                  title={i18n.t('terms.availability', { count: availability })}>
                  {[...Array(availability).keys()].map(i => (
                    <PersonIcon key={i} className={classes.player} fontSize="inherit" />
                  ))}
                  {others && others.map((o, i) => (<PersonIcon key={i} fontSize="inherit" />))}
                </div>
              )}
            </Typography>
            {showOthers && (
              <IconButton
                size="small"
                style={{ visibility: showOthers ? 'visible' : 'hidden' }}
                disabled={!hasOthers}
                className={classes.people}
                onClick={() => this.setState({ time: (this.state.time === time) ? null : time })}>
                <PeopleIcon />
              </IconButton>
            )}
            <Box
              flex={1}
              display="flex"
              alignItems="center"
              flexDirection="row"
              justifyContent="flex-end">
              {discount > 0 && (
                <Typography
                  className={classes.initPrice}
                  variant="body1"
                  component="span">
                  {i18n.l('currency', initPrice / 100)}
                </Typography>
              )}
              <Typography
                className={classes.price}
                variant="h6"
                component="span">
                {i18n.l('currency', price / 100)}
              </Typography>
            </Box>
          </Box>
          {deficiency.total === 0 && callToAction}
          {deficiency.total > 0 && !isExpanded && (
            <Button
              fullWidth
              color="secondary"
              variant="outlined"
              onClick={() => this.setState({ expanded: key })}>
              {i18n.t('booking.actions.missing_rentals', { count: deficiency.total })}
            </Button>
          )}
          {deficiency.total > 0 && (
            <Collapse in={isExpanded}>
              <Typography
                align="right"
                color="error"
                component="p"
                variant="caption">
                {'Qté indisponible'}
              </Typography>
              <Box mb={1}>
                {deficiency.lines.map((acc, i) => (
                  <Box
                    key={i}
                    display="flex"
                    flexDirection="row"
                    alignItems="baseline"
                    justifyContent="space-between">
                    <Typography
                      color="error"
                      component="span"
                      variant="caption">
                      {acc.name}
                    </Typography>
                    <Typography
                      color="error"
                      component="span"
                      variant="caption">
                      {`x ${acc.qty}`}
                    </Typography>
                  </Box>
                ))}
              </Box>
              {callToAction}
            </Collapse>
          )}
          {hasOthers && (
            <Collapse timeout={100} in={displayOthers}>
              <Grid container spacing={8} className={classes.others}>
                {others && others.map((o, i) => this.displayOtherName(o, i))}
              </Grid>
            </Collapse>
          )}
        </Paper>
      </Grid>
    );
  }

  /* */
  renderSchedule() {
    if (this.timeslotsList.length > 0) {
      return (
        <Grid
          container
          spacing={24}
          justify="center">
          {this.timeslotsList.map(this.renderTimeslot)}
        </Grid>
      );
    }

    return this.renderEmpty();
  }

  /* */
  render() {
    if (this.redirect) {
      return <Redirect to="/booking" />;
    }

    const { data, search } = this.props;
    const { error, loading } = this.state;

    const accsQty = search.accessoriesTotalQty;
    const numberOfPlayers = search.players.length;
    const { maxPlayers: maxNumberOfPlayers } = search.course;

    const dateTxt = helpers.string.ucfirst(i18n.l('date.formats.long_y', new Date(search.date)));
    const courseName = `${i18n.t(`terms.course_type_${search.course.type}`)} "${search.course.name}"`;
    const detailsTxt = `${i18n.t('terms.players', { count: numberOfPlayers })} • ${i18n.t('terms.rentals', { count: accsQty })}`;

    const hideDetails = (maxNumberOfPlayers === 1) && (data.accessories.length === 0);

    return (
      <Screen
        error={error}
        title={'Réserver'}
        loading={loading}
        onRetry={this.load}
        onBackPress={() => this.props.history.goBack()}
        helpURL={`${HELP_URL}/r%C3%A9server#h.p_KPrQp8TPZy75`}>
        <Typography
          align="center"
          component="h2"
          variant="h5">
          {dateTxt}
        </Typography>
        <Typography
          align="center"
          component="p"
          color="textSecondary"
          variant="h6">
          {courseName}
        </Typography>
        {!hideDetails && (
            <Typography
              align="center"
              component="p"
              variant="body1">
              {detailsTxt}
          </Typography>
        )}
        <br />
        {this.renderDailyNote()}
        {this.renderReasons()}
        {this.renderPlanningSwitch()}
        {!loading && this.renderSchedule()}
      </Screen>
    );
  }
}

/* */
const mapStateToProps = ({ app: { golf, account, user }, bookingData, bookingSearch }) => ({
  golf,
  user,
  account,
  data: bookingData,
  search: bookingSearch,
});

/* */
const StyledComponent = withStyles(styles)(BookingResult);

export default connect(
  mapStateToProps,
  Booking.actions,
)(withWidth()(StyledComponent));
