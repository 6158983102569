import React from 'react';
import _ from 'lodash/fp/lang';
import { connect } from 'react-redux';
import { App, helpers } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
} from '@material-ui/core';
import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';

const successImg = require('@aps-management/primapp-common/assets/images/tick.png');

/* */
const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  successImg: {
    color: theme.palette.common.white,
    margin: '1rem auto',
    position: 'relative',
    width: '10rem',
    height: '10rem',
  },
});

/* */
class Activation extends React.Component {
  /* */
  state = {
    error: null,
    success: null,
    loading: true,
  };

  /* */
  componentDidMount() {
    const { hash } = this.props.match.params;
    this.hash = hash;

    App.api.activation(hash)
      .then((res) => {
        if (res.golfId) {
          this.props.loadGolf(apolloClient, { id: res.golfId });
        } else {
          this.props.noGolf();
        }
        this.setState({ success: i18n.t('Votre compte est maintenant activé.') });
      })
      .catch((error) => {
        this.setState({ loading: false, error: i18n.t(error.message) });
      });
  }

  /* */
  componentDidUpdate(prevProps) {
    const { golf } = this.props;

    // load golf
    if (!_.isEqual(golf, prevProps.golf)) {
      document.title = (golf && golf.name) || 'prima.golf';
      this.setState({ loading: false });
    }
  }

  /* */
  render() {
    const { classes, golf } = this.props;
    const { error, success } = this.state;

    return (
      <Screen
        noDrawer
        layout='fixed'>
        <Typography component="h1" variant="h4" align="center">
          {golf && golf.name}
        </Typography>
        <Paper className={classes.paper}>
          <Typography component="h2" variant="h5">
            {i18n.t('Activation de mon compte.')}
          </Typography>
          {error && (
            <React.Fragment>
              <div style={{ margin: '2rem' }} />
              <Typography paragraph variant="subtitle1" color="error">
                {helpers.string.handleError(error.message || error || '')}
              </Typography>
            </React.Fragment>
          )}
          {(!error && success) && (
            <React.Fragment>
              <img src={successImg} alt="ok" className={classes.successImg} />
              <Typography paragraph variant="h6" color="textSecondary">
                {success}
              </Typography>
            </React.Fragment>
          )}
        </Paper>
      </Screen>
    );
  }
}

const mapStateToProps = ({ app }) => ({ golf: app.golf });

export default connect(mapStateToProps, App.actions)(withStyles(styles)(Activation));
