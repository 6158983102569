import thunkMiddleware from 'redux-thunk';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import {
  App,
  Event,
  Booking,
  Directory,
  Membership,
  Tournament,
  Primaccess,
  AccountWallet,
} from '@aps-management/primapp-common';

/* */
const appReducer = combineReducers({
  ...App.reducers,
  ...Event.reducers,
  ...Booking.reducers,
  ...Directory.reducers,
  ...Membership.reducers,
  ...Tournament.reducers,
  ...Primaccess.reducers,
  ...AccountWallet.reducers,
});

/* */
const rootReducer = (state, action) => {
  // Reset all state excluding app reducer
  if (action.type === 'app/LOGOUT') {
    const { app } = state;
    return appReducer({ app }, action);
  }
  return appReducer(state, action);
};

/* */
export default createStore(
  rootReducer,
  applyMiddleware(thunkMiddleware),
);
