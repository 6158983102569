import React from 'react';
import { connect } from 'react-redux';
import { Tournament } from '@aps-management/primapp-common';
import { Grid, Button, Typography } from '@material-ui/core';
import { unstable_Box as Box } from '@material-ui/core/Box';
import i18n from '_utils/i18n';
/* */
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
/* */
import Header from '../_Header';

/* */
class TournamentEnter extends React.Component {
  /* */
  state = {
    // Screen
    error: null,
    loading: true,
    // Data
    myEntry: null,
    entryList: [],
    partners: [],
    tournament: null,
  };

  /* */
  id = null;

  /* */
  componentDidMount() {
    this.id = this.props.match.params.id;
    this.load();
  }

  /* */
  goToNext = () => this.props.history.push(`/tournament/${this.id}/entrants`)

  /* */
  goToMyTournaments = () => this.props.history.push('/mytournaments');

  /* */
  load() {
    const { id } = this;
    const { account: { ffg }, golf } = this.props;

    if (!id) {
      return this.setState({
        loading: false,
        error: 'errors.not_found',
      });
    }

    return Tournament.api.getTournament(apolloClient, { id, golfId: golf.id })
      .then((tournament) => {
        this.setState({ tournament });

        const { lifecycle } = tournament;
        const { status } = Tournament.functions.getRegistrationStatus(tournament);
        // Only if registration is open
        if (status !== 1 && lifecycle === 'registration') {
          return Tournament.api.getEntryList(apolloClient, { id, golfId: golf.id });
        }

        return Promise.resolve({ entries: [] });
      })
      .then(({ entries }) => {
        this.setState({ entryList: entries });
        if (ffg.license) {
          const { entry, partners } = Tournament.functions.getEntryStatus(entries, ffg.license);
          this.setState({ entry, partners });
        }
      })
      .catch(e => this.setState({ error: e.message }))
      .finally(() => { this.setState({ loading: false }); });
  }

  /* */
  renderEntry() {
    const { golf } = this.props;
    const { entry } = this.state;

    if (!entry) return null;

    return (
      <React.Fragment>
        <Typography variant="body1">
          {i18n.getFromOpts('event_enter.already_in', golf.options)}
        </Typography>
        <br />
        <Button
          color="secondary"
          variant="outlined"
          onClick={this.goToMyTournaments}>
          {'Voir mes inscriptions'}
        </Button>
      </React.Fragment>
    );
  }

  /* */
  renderEntryList() {
    const { entryList, tournament } = this.state;

    if (!tournament.showEntryList || !entryList.length) return null;

    const columns = 3;
    const perColumn = Math.ceil(entryList.length / 3);

    return (
      <Box
        mt={3}
        pt={3}
        borderTop={1}
        borderColor="#ddd">
        <Typography gutterBottom variant="body1">
          {`Liste des inscrits (${entryList.length})`}
        </Typography>
        <Grid container>
          {[...Array(columns)].map((und, c) => (
            <Grid
              item
              xs={12}
              sm={4}
              key={c}>
              {entryList.slice(c * perColumn, (c * perColumn) + perColumn).map((e, key) => (
                <Typography key={key} variant="caption">{e.name}</Typography>
              ))}
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }

  /* */
  renderEnterButton() {
    const { entry, tournament } = this.state;
    const { registration: { limit, counter } } = tournament;

    // Inscription ouverte + joueur non inscrit
    const isOpened = !entry && Tournament.functions.isOpened(tournament);
    if (!isOpened) return null;

    // Limite atteinte
    if (limit && counter >= limit) {
      return (
        <Button
          disabled
          fullWidth
          size="large"
          onClick={null}
          color="primary"
          variant="contained">
          {'Complet'}
         </Button>
      );
    }

    return (
      <Button
        fullWidth
        size="large"
        color="secondary"
        variant="contained"
        onClick={this.goToNext}>
        {'Participer'}
       </Button>
    );
  }

  /* */
  render() {
    const { error, loading, tournament } = this.state;

    return (
      <Screen
        error={error}
        loading={loading}
        title="Compétitions - S'inscrire">
        {tournament && (
          <React.Fragment>
            <Header tournament={tournament} />
            {this.renderEntry()}
            {this.renderEnterButton()}
            {this.renderEntryList()}
          </React.Fragment>
        )}
      </Screen>
    );
  }
}

const mapStateToProps = ({ app }) => ({
  golf: app.golf,
  account: app.account,
});

export default connect(mapStateToProps)(TournamentEnter);
