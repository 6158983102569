import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  withMobileDialog,
} from '@material-ui/core';

/* */
const styles = theme => ({
  dialogContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: `${theme.spacing.unit * 3}px`,
  },
  dialogTitle: {
    textAlign: 'center',
  },
});

/* */
const securityImg = 'https://static.prima.golf/images/payment/up2pay.png';

/* */
class DialogPaymentInProgress extends React.Component {
  /* */
  render() {
    const {
      classes,
      fullScreen,
      open,
      status,
    } = this.props;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}>
        <DialogTitle className={classes.dialogTitle}>
          {'Veuillez patienter, votre demande est en cours de traitement.'}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <CircularProgress size={32} />
          <br /><br />
          <Typography
            paragraph
            align="center"
            color="primary"
            variant="subtitle1">
            {status}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Typography>{'Transaction sécurisée par'}</Typography>
          <img alt="CA E-transactions" height={64} src={securityImg} />
        </DialogActions>
      </Dialog>
    );
  }
}

const StyledComponent = withStyles(styles)(DialogPaymentInProgress);
const WithMobileDialogComponent = withMobileDialog({ breakpoint: 'xs' })(StyledComponent);

export default WithMobileDialogComponent;
