import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { helpers, Booking } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  Paper,
  Button,
  Typography,
} from '@material-ui/core';
import i18n from '_utils/i18n';
import { Screen } from '_components/core';

/* */
const styles = theme => ({
  voucher: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 1,
    paddingBottom: theme.spacing.unit * 2,
  },
  body1: {
    ...theme.typography.body2,
    textAlign: 'center',
  },
  table: {
    paddingTop: theme.spacing.unit * 1,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  underline: { textDecoration: 'underline' },
});

/* */
class BookingVoucher extends React.Component {
  /* */
  constructor(props) {
    super(props);

    const { data: { booking } } = this.props;

    this.redirect = false;
    if (booking === null) {
      this.redirect = true;
    }
  }

  /* */
  renderPaymentInfo() {
    const { classes, data: { booking } } = this.props;

    const payments = booking.players[0].payments || null;
    const payment = payments ? payments[0] : null;

    if (!payment) return null;

    const text = payment.type === 1 ? 'Total à régler' : 'Total réglé';

    return (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
        className={classes.table}
        justifyContent="space-between">
        <Box>
          <Typography gutterBottom>{text}</Typography>
          <Typography color="textSecondary">
            {i18n.l('currency', booking.total.price / 100)}
          </Typography>
        </Box>
        {booking.transaction && (
          <Box>
            <Typography gutterBottom>{'E-ticket'}</Typography>
            <Typography color="textSecondary">
              {booking.transaction.transactionNo || ''}
            </Typography>
          </Box>
        )}
        {payment.type === 1 && (
          <Typography color="textSecondary">
            {'Paiement sur place'}
          </Typography>
        )}
        {payment.type === 2 && (
          <Typography color="textSecondary">
            {'Paiement par compte client'}
          </Typography>
        )}
      </Box>
    );
  }

  /* */
  render() {
    if (this.redirect) {
      return <Redirect to="/booking" />;
    }

    const { classes, data: { booking } } = this.props;

    const detailsTxt = `${i18n.t('terms.players', { count: booking.players.length })} • ${i18n.t('terms.rentals', { count: booking.accessories.length })}`;

    return (
      <Screen layout="fixed" title="Confirmation de réservation">
        <Typography
          paragraph
          variant="h4"
          align="center">
          {'Merci !'}
        </Typography>
        <div className={classes.voucher}>
          <Paper className={classes.paper}>
            <Typography
              gutterBottom
              align="center"
              variant="h5"
              component="h2"
              color="textSecondary">
              {booking.golf.name}
            </Typography>
            <Typography
              align="center"
              variant="body2"
              className={classes.underline}>
              {booking.golf.email}
            </Typography>
          </Paper>
          <Paper className={classes.paper}>
            <Typography
              gutterBottom
              variant="h5"
              align="center"
              component="h2"
              color="textSecondary">
              {helpers.string.ucfirst(i18n.l('date.formats.long_y', new Date(booking.date)))}
              {' • '}
              {Booking.functions.formatTime(booking.time)}
            </Typography>
            <Typography
              gutterBottom
              variant="h6"
              component="p"
              align="center">
              {i18n.t(`terms.course_type_${booking.course.type}`)} {`"${booking.course.name}"`}
            </Typography>
            <Typography
              gutterBottom
              component="p"
              align="center"
              variant="subtitle1">
              {detailsTxt}
            </Typography>
            <p className={classes.body1}>
              {i18n.t('membership.messages.sent_confirm_email')} <span className={classes.underline}>{booking.owner.email}</span>
            </p>
            {this.renderPaymentInfo()}
          </Paper>
        </div>
        <Button
          fullWidth
          size="large"
          color="secondary"
          variant="contained"
          onClick={() => this.props.history.go(-2)}>
          {'Réserver de nouveau'}
        </Button>
      </Screen>
    );
  }
}

const mapStateToProps = ({ bookingData }) => ({ data: bookingData });

const StyledComponent = withStyles(styles)(BookingVoucher);

export default connect(
  mapStateToProps,
  null,
)(StyledComponent);
