import React from 'react';
import moment from 'moment';
import { App } from '@aps-management/primapp-common';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  List,
  Avatar,
  Button,
  Hidden,
  Divider,
  Collapse,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  Home,
  Folder,
  ExpandLess,
  ExpandMore,
  FolderShared,
  AccountCircle,
  CardMembership,
  EventAvailable,
  School,
} from '@material-ui/icons';
import Icon from '@mdi/react';
import {
  mdiTrophy,
  mdiCalendarStar,
  mdiCalendarMonth,
  mdiNotebookOutline,
  mdiCardAccountDetails,
} from '@mdi/js';
import i18n from '_utils/i18n';
import Firebase from '_utils/firebase';
import { getAuthorization } from '_utils/security';
import PlayerTypesListWithRules from '_components/PlayerTypesListWithRules';

/* */
const styles = theme => ({
  toolbar: {
    height: theme.spacing.unit * 2,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('xl')]: {
      backgroundColor: theme.palette.common.white,
      ...theme.mixins.toolbar,
    },
  },
  link: {
    '& $icon': {
      color: theme.palette.secondary.main,
      fill: theme.palette.secondary.main, // svg
    },
    '&:hover': {
      '& $text, & $icon': {
        color: theme.palette.text.secondary,
        fill: theme.palette.text.secondary, // svg
      },
    },
  },
  selected: {
    '&, &:hover': {
      backgroundColor: theme.palette.secondary.main,
      '& $text, & $icon': {
        color: theme.palette.secondary.contrastText,
        fill: theme.palette.secondary.contrastText, // svg
      },
    },
  },
  text: {},
  icon: {},
  email: { color: theme.palette.grey[500] },
  identity: { padding: theme.spacing.unit * 1 },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    marginBottom: theme.spacing.unit * 1,
  },
  logout: {
    display: 'block',
    margin: `${theme.spacing.unit * 1}px auto`,
  },
  hcp: { fontSize: 26 },
});

const CustomListItem = ({
  icon,
  text,
  classes,
  expanded = null,
  ...props
}) => {
  const customProps = { ...props };
  if (props.to) {
    customProps.component = NavLink;
    customProps.activeClassName = classes.selected;
  }

  return (
    <ListItem
      button
      disableRipple
      className={classes.link}
      {...customProps}>
      {icon && (
        <ListItemIcon className={classes.icon}>
          {icon}
        </ListItemIcon>
      )}
      <ListItemText
        classes={{ primary: classes.text }}
        primary={text} />
      {expanded !== null && (
        expanded
          ? <ExpandLess color="action" />
          : <ExpandMore color="action" />
      )}
    </ListItem>
  );
};

/* */
class Menu extends React.PureComponent {
  /* */
  constructor(props) {
    super(props);

    const { golf, account } = this.props;

    const match = App.functions.match(account, golf);
    this.accountReference = (match && match.reference) || null;
  }

  /* */
  handleExpand = type => () => { this.props.togglePanelNav(type); };

  /* */
  isExpanded = type => this.props.navDrawer.includes(type);

  /* */
  isAuthorized(path) {
    const { golf, account } = this.props;
    const { isAuthorized } = getAuthorization(path, { golf, account });

    return isAuthorized;
  }

  /* */
  renderPlayerTypes = () => {
    const { account, golf } = this.props;

    if (!account && !account.playerTypes) return null;

    const types = account.playerTypes.filter(pt => pt.golfId === golf.id);

    return <PlayerTypesListWithRules playerTypes={types} />;
  }

  /* */
  renderIndex = () => {
    const { account, classes } = this.props;

    if (!account.ffg) return null;

    const { handicap, handicapDate } = account.ffg;

    return handicap && (
      <Box
        px={1}
        py={1}
        display="flex"
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between">
        <Typography component="span">
          {'Mon index'}<br/>{`au ${i18n.l('date.formats.default', moment(handicapDate, 'x').toDate())}`}
        </Typography>
        <Typography
          color="primary"
          component="span"
          className={classes.hcp}>
          {handicap.toFixed(1)}
        </Typography>
      </Box>
    );
  }

  /* */
  render() {
    const { accountReference } = this;
    const {
      golf,
      classes,
      account,
      wording,
    } = this.props;

    return (
      <div>
        <div className={classes.toolbar} />
        <div className={classes.identity} >
          <Avatar className={classes.avatar} title={accountReference}>
            {App.functions.getUserInitials(account.firstname, account.lastname)}
          </Avatar>
          <Typography
            component="p"
            variant="h6">
            {`${account.firstname} ${account.lastname}`}
          </Typography>
          <Typography
            className={classes.email}
            component="p"
            variant="body2">
            {account.email}
          </Typography>
          {this.renderPlayerTypes()}
        </div>
        <Divider />
        {this.renderIndex()}
        <Divider />
        <List component="nav">
          <Hidden xlUp implementation="css">
            <CustomListItem
              to="/home"
              text="Accueil"
              icon={<Home />}
              classes={classes} />
          </Hidden>
          {this.isAuthorized('/booking') && (
            <CustomListItem
              to="/booking"
              text={i18n.t('navigation.booking')}
              classes={classes}
              icon={<EventAvailable />} />
          )}
          {this.isAuthorized('/academy') && (
            <CustomListItem
              to="/academy"
              text={i18n.t('navigation.academy')}
              classes={classes}
              icon={<School />} />
          )}
          {!this.isAuthorized('/tournament') && this.isAuthorized('/guest') && (
            <CustomListItem
              to="/guest?redirect=/tournament"
              text={i18n.t('navigation.tournament')}
              classes={classes}
              icon={<Icon path={mdiTrophy} size={1} />} />
          )}
          {!this.isAuthorized('/event') && this.isAuthorized('/guest') && (
            <CustomListItem
              to="/guest?redirect=/event"
              text={i18n.t(`navigation.event_${golf.reference}`, { defaultValue: i18n.getFromOpts('navigation.event', golf.options) })}
              classes={classes}
              icon={<Icon path={mdiCalendarStar} size={1} />} />
          )}
          {this.isAuthorized('/tournament') && (
            <CustomListItem
              to="/tournament"
              text={i18n.t('navigation.tournament')}
              classes={classes}
              icon={<Icon path={mdiTrophy} size={1} />} />
          )}
          {this.isAuthorized('/event') && (
            <CustomListItem
              to="/event"
              text={i18n.t(`navigation.event_${golf.reference}`, { defaultValue: i18n.getFromOpts('navigation.event', golf.options) })}
              classes={classes}
              icon={<Icon path={mdiCalendarStar} size={1} />} />
          )}
          {this.isAuthorized('/membership') && (
            <CustomListItem
              to="/membership"
              text={i18n.t(`navigation.membership_${wording.membership || 1}`)}
              classes={classes}
              icon={<CardMembership />} />
          )}
          {!this.isAuthorized('/membership') && this.isAuthorized('/membership/subscribe') && (
            <CustomListItem
              to="/membership/subscribe"
              text={i18n.t(`navigation.membership_${wording.membership || 1}`)}
              classes={classes}
              icon={<CardMembership />} />
          )}
          {this.isAuthorized('/schedule') && (
            <CustomListItem
              to="/schedule"
              text={i18n.t('navigation.schedule')}
              classes={classes}
              icon={<Icon path={mdiCalendarMonth} size={1} />} />
          )}
          {this.isAuthorized('/primaccess') && (
            <CustomListItem
              to="/primaccess"
              text={'Practice'}
              classes={classes}
              icon={<Icon path={mdiCardAccountDetails} size={1} />} />
          )}
          <CustomListItem
            text={'Historique'}
            classes={classes}
            expanded={this.isExpanded('my_history')}
            onClick={this.handleExpand('my_history')}
            icon={<Icon path={mdiNotebookOutline} size={1} />} />
          <Collapse in={this.isExpanded('my_history')}>
            <List component="div" disablePadding>
              {this.isAuthorized('/booking') && (
                <CustomListItem
                  classes={classes}
                  to="/mybookings"
                  text={i18n.t('navigation.my_bookings')} />
              )}
              {this.isAuthorized('/academy') && (
                <CustomListItem
                  classes={classes}
                  to="/myacademy"
                  text={i18n.t('navigation.my_academy')} />
              )}
              {this.isAuthorized('/tournament') && (
                <CustomListItem
                  to="/mytournaments"
                  classes={classes}
                  text={i18n.t('navigation.my_tournaments')} />
              )}
              {this.isAuthorized('/event') && (
                <CustomListItem
                  to="/myevents"
                  classes={classes}
                  text={i18n.getFromOpts('navigation.my_events', golf.options)} />
              )}
            </List>
          </Collapse>
          <CustomListItem
            text={i18n.t('navigation.my_account')}
            classes={classes}
            icon={<AccountCircle />}
            expanded={this.isExpanded('account')}
            onClick={this.handleExpand('account')} />
          <Collapse in={this.isExpanded('account')}>
            <List component="div" disablePadding>
              <CustomListItem
                exact
                to="/account/id"
                text={i18n.t('navigation.my_ids')}
                classes={classes} />
              <CustomListItem
                exact
                to="/account/edit"
                text={i18n.t('navigation.my_profile')}
                classes={classes} />
              {this.isAuthorized('/account/statement') && (
                <CustomListItem
                  exact
                  to="/account/statement"
                  text={i18n.t('navigation.my_statement')}
                  classes={classes} />
              )}
              {this.isAuthorized('/account/interests') && (
                <CustomListItem
                  exact
                  to="/account/interests"
                  text={i18n.t('navigation.my_interests')}
                  classes={classes} />
              )}
              <CustomListItem
                exact
                to="/account/creditcard"
                text={i18n.t('navigation.my_creditcard')}
                classes={classes} />
              {this.isAuthorized('/account/covidcertificate') && (
                <CustomListItem
                  exact
                  to="/account/covidcertificate"
                  text={i18n.t('navigation.my_covid_certificate')}
                  classes={classes} />
              )}
            </List>
          </Collapse>
          <CustomListItem
            text={i18n.t('navigation.directory')}
            classes={classes}
            icon={<FolderShared />}
            expanded={this.isExpanded('directory')}
            onClick={this.handleExpand('directory')} />
          <Collapse in={this.isExpanded('directory')}>
            <List component="div" disablePadding>
              {this.isAuthorized('/directory/members') && (
                <CustomListItem
                  exact
                  to="/directory/members"
                  text={i18n.t(`navigation.members_${wording.member || 1}`)}
                  classes={classes} />
              )}
              <CustomListItem
                exact
                to="/directory/contacts"
                text={i18n.t('navigation.my_contacts')}
                classes={classes} />
            </List>
          </Collapse>
          {this.isAuthorized('/document') && (
            <React.Fragment>
              <CustomListItem
                text={'Documents'}
                classes={classes}
                expanded={this.isExpanded('document')}
                onClick={this.handleExpand('document')}
                icon={<Folder />} />
              <Collapse in={this.isExpanded('document')}>
                <List component="div" disablePadding>
                  <CustomListItem
                    exact
                    to="/document"
                    text={'Mon dossier'}
                    classes={classes} />
                  <CustomListItem
                    exact
                    to="/document/shared-with-me"
                    text={'Partagés avec moi'}
                    classes={classes} />
                </List>
              </Collapse>
            </React.Fragment>
          )}
        </List>
        <Button
          fullWidth
          color="secondary"
          className={classes.logout}
          onClick={() => Firebase.logout()}>
          {i18n.t('default.actions.logout')}
        </Button>
      </div>
    );
  }
}

const mapStateToProps = ({ app: { account, navDrawer, golf } }) => ({
  golf,
  account,
  navDrawer,
  wording: golf && golf.options && golf.options.wording,
});

const StyledComponent = withStyles(styles)(Menu);

export default connect(
  mapStateToProps,
  App.actions,
)(StyledComponent);
