import React, { Fragment } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  App,
  Event,
  Directory,
  Tournament,
  TEE_TIME_MAX_PLAYERS,
} from '@aps-management/primapp-common';
import {
  Chip,
  Grid,
  Avatar,
  Button,
  Dialog,
  TextField,
  Typography,
  DialogActions,
  DialogContent,
  CircularProgress,
  DialogContentText,
} from '@material-ui/core';
import {
  CheckCircle as CheckIcon,
} from '@material-ui/icons';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import ChoosePartners from '_components/ChoosePartners';
import { Alert, Incrementer } from '_components/elements';
import { createValidationSchema, validators } from '_utils/validation';
/* */
import Header from '../_Header';

/* */
const styles = theme => ({
  chip: {
    marginTop: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 1,
  },
  labelMore: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing.unit * 2,
  },
});

/* */
class SetPlayers extends React.Component {
  /* */
  state = {
    // Screen
    error: null,
    loading: true,
    // Data
    slotId: null,
    openForm: false,
    openPartners: false,
    buttonDisabled: true,
  };

  commentInput = React.createRef();

  /* */
  openFormDialog = () => this.setState({ openForm: true });

  /* */
  closeFormDialog = () => this.setState({ openForm: false });

  /* */
  openPartnersDialog = () => this.setState({ openPartners: true });

  /* */
  closePartnersDialog = () => this.setState({ openPartners: false });

  /* */
  componentDidMount() {
    this.id = this.props.match.params.id;
    this.setState({ buttonDisabled: !this.canBeContinued() });
    this.load();
  }

  /* */
  componentDidUpdate() {
    const buttonDisabled = !this.canBeContinued();

    if (this.state.buttonDisabled !== buttonDisabled) {
      this.setState({ buttonDisabled });
    }
  }

  /* */
  canBeContinued() {
    const { slotId } = this.state;
    const { enter: { entrants, tournament } } = this.props;

    // check none anonymous player
    const checkIds = !entrants.some(e => e.type === 'anonymous');

    // check slot selected & ticket remaining
    let checkSlot = true;
    if (tournament && tournament.slots) {
      const slot = tournament.slots.find(s => String(s.id) === slotId);
      if (!slot) {
        checkSlot = false;
      } else {
        checkSlot = slot.availability !== null ? slot.availability >= entrants.length : true;
      }
    }

    return checkIds && checkSlot;
  }

  /* */
  load() {
    const { id } = this;
    const { account, golf } = this.props;

    if (!id) {
      return this.setState({
        loading: false,
        error: 'errors.not_found',
      });
    }

    // Reset state
    this.setState({
      error: null,
      loading: true,
    });

    return Tournament.api.getTournament(apolloClient, { id, golfId: golf.id })
      .then((tournament) => {
        const {
          ffg,
          email,
          phone,
          lastname,
          firstname,
        } = account;

        const match = App.functions.match(account, golf) || { isMember: false, reference: 'UNKNOWN' };

        this.props.initialize({ tournament, status: ['init'] });
        this.props.addEntrant({
          email,
          phone,
          lastname,
          firstname,
          type: 'owner',
          isMember: match.isMember,
          reference: match.reference,
          licenseNumber: ffg.license || null,
        });
      })
      .catch(e => this.setState({ error: e.message }))
      .finally(() => this.setState({ loading: false }));
  }

  /* */
  onNext = () => {
    const { slotId } = this.state;
    const { enter } = this.props;

    enter.comment = this.commentInput.current.value.trim().substring(0, 280);

    // hydrate entrants
    if (slotId) {
      const slot = enter.tournament.slots.find(s => String(s.id) === slotId);
      enter.entrants.forEach((e) => {
        e.timeslot = slot;
      });
    }

    const checkLicenseNumber = enter.entrants.every(e => e.licenseNumber);
    if (checkLicenseNumber) {
      this.goToFees();
    } else {
      this.openFormDialog();
    }
  }

  /* */
  goToFees = () => {
    this.props.enter.status.push('entrants');
    this.props.history.push(`/tournament/${this.id}/fees`);
  }

  /* */
  onSubmit = (values, { setSubmitting }) => {
    const { enter: { entrants } } = this.props;
    const promises = [];

    // update license numbers by type
    entrants.forEach((e, i) => {
      if (!e.licenseNumber && values[i]) {
        e.licenseNumber = values[i];
        if (e.type === 'contact') { // update my contact
          promises.push(Directory.api.setContact(apolloClient, e));
        }
        if (e.type === 'owner') { // update account
          promises.push(this.props.setAccount(apolloClient, { ...e, license: e.licenseNumber }));
        }
      }
    });

    Promise.all(promises)
      .then((results) => {
        // only my contacts
        results.filter(r => r.contact).forEach(({ contact }) => {
          this.props.updateContact(contact);
        });
        this.goToFees();
      })
      .catch(e => this.setState({ error: e.message }))
      .finally(() => {
        setSubmitting(false);
        this.closeFormDialog();
      });
  };

  /* */
  handleIncrease = () => {
    this.props.addEntrant({
      email: null,
      phone: null,
      lastname: null,
      firstname: null,
      isMember: false,
      type: 'anonymous',
      reference: 'UNKNOWN',
    });
  }

  /* */
  handleDecrease = () => {
    const { enter: { entrants } } = this.props;
    this.props.adjustEntrants(entrants.length - 1);
  }

  /* */
  handleDeleteEntrant(i) {
    this.props.replaceEntrant({
      email: null,
      phone: null,
      lastname: null,
      firstname: null,
      isMember: false,
      type: 'anonymous',
      reference: 'UNKNOWN',
    }, i);
  }

  /* */
  renderMorePlayers() {
    const { enter: { entrants } } = this.props;

    return entrants.map(this.renderChip);
  }

  /* */
  renderChip = (entrant, i) => {
    const { classes } = this.props;

    const isOwner = entrant.type === 'owner';
    const isAnonymous = entrant.type === 'anonymous';
    const isDeletable = (entrant.type !== 'owner' && entrant.type !== 'anonymous');

    const fullname = isAnonymous
      ? 'Participant anonyme'
      : `${entrant.firstname} ${entrant.lastname}`;

    const customProps = {
      label: fullname,
      clickable: true,
      color: 'secondary',
      style: styles.chip,
      avatar: <Avatar><CheckIcon /></Avatar>,
    };

    if (isAnonymous) {
      customProps.avatar = null;
      customProps.variant = 'outlined';
      customProps.onClick = this.openPartnersDialog;
    }
    if (isOwner) {
      customProps.color = 'primary';
      customProps.clickable = false;
    }
    if (isDeletable) {
      customProps.onDelete = (() => this.handleDeleteEntrant(i));
    }

    return (<Chip key={i} className={classes.chip} {...customProps} />);
  }

  /* */
  renderFormDialog() {
    const { enter } = this.props;
    const { openForm } = this.state;

    const schema = enter.entrants.reduce((acc, e, i) => {
      acc[i] = validators.required;
      return acc;
    }, {});
    const initialValues = enter.entrants.reduce((acc, e, i) => {
      acc[i] = e.licenseNumber || '';
      return acc;
    }, {});
    const validationSchema = createValidationSchema(schema);

    return (
      <Formik
        enableReinitialize
        onSubmit={this.onSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}>
        {({
          values,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Dialog
            open={openForm}
            onClose={this.closeFormDialog}>
            <DialogContent>
              <DialogContentText>
                {'Le numéro de licence est obligatoire pour participer à une compétition.'}&nbsp;
                {'Veuillez compléter les informations ci-dessous.'}
              </DialogContentText>
              <br />
              {enter.entrants.map((e, i) => (
                (e.licenseNumber)
                  ? null
                  : <TextField
                      fullWidth
                      key={i}
                      variant="filled"
                      name={String(i)}
                      value={values[i]}
                      label={`${e.firstname} ${e.lastname}`}
                      error={touched[i] && errors[i] && errors[i]}
                      onChange={event => setFieldValue(i, event.target.value)} />
              )).filter(e => e)}
            </DialogContent>
            <DialogActions>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleSubmit}
                disabled={isSubmitting}>
                {isSubmitting
                  ? <CircularProgress color="inherit" size={24} />
                  : 'Continuer'}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    );
  }

  /* */
  renderPartnersDialog() {
    const { openPartners } = this.state;
    const { enter: { entrants } } = this.props;

    return (
      <ChoosePartners
        partners={entrants}
        open={openPartners}
        onClose={this.closePartnersDialog}
        replacePartner={this.props.replaceEntrant} />
    );
  }

  /* */
  handleSlotChange = (event) => {
    const slotId = event.target.value;
    this.setState({ slotId });
  }

  /* */
  renderSlots() {
    const { slotId } = this.state;
    const { enter: { entrants, tournament }, classes } = this.props;

    const numberOfTickets = entrants.length;

    if (tournament.slots && tournament.slots.length) {
      return (
        <Box
          mt={1}
          mb={2}
          pt={3}
          borderTop={1}
          borderColor="#ddd">
          <Typography gutterBottom variant="body1">
            {'Sur quel créneau horaire ? (choix obligatoire)'}
          </Typography>
          <RadioGroup name="slot" value={slotId || 0} onChange={this.handleSlotChange}>
            {tournament.slots.map(({
              id,
              name,
              availability,
            }) => {
              let disabled = false;
              let labelMore = null;

              if (availability !== null) {
                if (availability < numberOfTickets) {
                  disabled = true;
                  labelMore = <span className={classes.labelMore}>{'indisponible'}</span>;
                } else if (availability < TEE_TIME_MAX_PLAYERS) {
                  labelMore = <span className={classes.labelMore}>{`${availability} place(s) restante(s)`}</span>;
                }
              }

              return (
                <FormControlLabel
                  key={id}
                  value={String(id)}
                  control={<Radio />}
                  disabled={disabled}
                  label={<Typography>{name} {labelMore}</Typography>} />
              );
            })}
          </RadioGroup>
        </Box>
      );
    }

    return null;
  }

  /* */
  renderNextButton() {
    const { buttonDisabled } = this.state;
    const { enter: { tournament } } = this.props;
    const { registration: { limit, counter } } = tournament;

    if (limit && counter >= limit) {
      return (
        <Button
          fullWidth
          disabled
          size="large"
          color="primary"
          variant="contained"
          onClick={null}>
          {'Complet'}
        </Button>
      );
    }

    return (
      <Button
        fullWidth
        size="large"
        color="secondary"
        variant="contained"
        onClick={this.onNext}
        disabled={buttonDisabled}>
        {'Continuer'}
      </Button>
    );
  }

  /* */
  renderPlayers() {
    const { enter: { entrants, tournament } } = this.props;

    // Check number of entrants allowed
    const {
      registration: {
        limit,
        counter,
        partnerAllowed,
      },
    } = tournament;

    const number = entrants.length;
    let maxNumber = partnerAllowed + 1;

    let alertMessage;
    if (limit) {
      const ticketRemaining = limit - counter;
      if (ticketRemaining > 0 && ticketRemaining < maxNumber) {
        maxNumber = ticketRemaining;
        alertMessage = `Il reste ${maxNumber} place(s) disponible(s).`;
      }
    }

    if (maxNumber === number) {
      alertMessage = 'Vous avez atteint le nombre de participants maximum autorisé.';
    }

    return (
      <Fragment>
        <Grid container spacing={40}>
          <Grid item xs={12} sm={12} md={6}>
            <Typography variant="body1">
              {'Combien serez-vous ?'}
            </Typography>
            <br />
            <Incrementer
              minValue={1}
              value={number}
              maxValue={maxNumber}
              onDecrease={this.handleDecrease}
              onIncrease={this.handleIncrease} />
            <br />
            {this.renderMorePlayers()}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Typography variant="body1">
              {'Informations à communiquer'}
            </Typography>
            <br />
            <TextField
              fullWidth
              multiline
              rows={4}
              rowsMax={8}
              variant="outlined"
              inputRef={this.commentInput} />
          </Grid>
          <Grid item xs={12} md={12} sm={12}>
            {alertMessage && (
              <Alert variant="info">{alertMessage}</Alert>
            )}
          </Grid>
        </Grid>
        <br />
      </Fragment>
    );
  }

  /* */
  render() {
    const { error, loading } = this.state;
    const { enter: { tournament } } = this.props;

    // Check tournament is opened
    if (tournament) {
      const isOpened = Tournament.functions.isOpened(tournament);
      if (!isOpened) {
        return <Redirect to={'/tournament'} />;
      }
    }

    return (
      <Screen
        error={error}
        loading={loading}
        title="Compétitions - S'inscrire">
        {tournament && (
          <Fragment>
            <Header tournament={tournament} />
            {this.renderPlayers()}
            {this.renderSlots()}
            {this.renderNextButton()}
            {this.renderFormDialog()}
            {this.renderPartnersDialog()}
          </Fragment>
        )}
      </Screen>
    );
  }
}

const mapStateToProps = ({ app, eventEnter }) => ({
  golf: app.golf,
  enter: eventEnter,
  account: app.account,
});
const mapDispatchToProps = { ...App.actions, ...Event.actions, ...Directory.actions };

const styledComponent = withStyles(styles)(SetPlayers);

export default connect(mapStateToProps, mapDispatchToProps)(styledComponent);
