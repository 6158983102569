import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Booking } from "@aps-management/primapp-common";
import {
  Button,
  Popper,
  Grow,
  Paper,
  Grid,
  ClickAwayListener,
  Typography,
} from "@material-ui/core";
import { BasePicker, Calendar } from "material-ui-pickers";
import {
  KeyboardArrowLeft as LeftIcon,
  KeyboardArrowRight as RightIcon,
  Event as CalendarIcon,
} from "@material-ui/icons";
import moment from "moment";
import { useContextSelector } from "@fluentui/react-context-selector";
import PublicBookingContext from "./services/publicBookingService";

/* */
const styles = () => ({
  dateSelector: {
    gridArea: "options",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    padding: "0 16px 16px 16px",
  },
});

const PublicBookingDateSelector = ({ golf, classes, ...props }) => {
  const anchorEl = useRef(null);
  const [openDateSelector, setOpenDateSelector] = useState(false);
  const {
    setCourse,
  } = props;
  const {
    data: { availableDates },
    selectedDate,
    setSelectedDate,
    selectedCourse,
  } = useContextSelector(PublicBookingContext, (c) => c);
  /* */
  const shouldDisableDate = (day) => {
    const formattedDate = day.format("YYYY-MM-DD");

    return !availableDates.includes(formattedDate);
  };
  /* */
  const handleDateChange = (day) => {
    const formattedDate = day.format("YYYY-MM-DD");

    setSelectedDate(moment(formattedDate));

    if (
      !Booking.functions.checkCourseAvailability(selectedCourse, formattedDate)
    ) {
      setCourse(null);
    }

    setOpenDateSelector(false);
  };

  const maxDate = new Date(availableDates[availableDates.length - 1]);
  return (
    <Grid className={classes.dateSelector}>
        <Typography
        color="secondary"
        variant="body2"
        style={{ marginBottom: 8, fontSize: "calc(8px + 0.390625vw)", fontWeight: 500 }}
        paragraph
        >
        {"Date :"}
        </Typography>
      <Button
        buttonRef={anchorEl}
        aria-owns={openDateSelector ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        variant="outlined"
        style={{ fontSize: "calc(8px + 0.390625vw)", display: "flex", alignItems: "center", justifyContent: "space-between", maxHeight: "35px", marginInline: 8 }}
        onClick={() =>
          openDateSelector
            ? setOpenDateSelector(false)
            : setOpenDateSelector(true)
        }
      >
        {selectedDate.format("ddd DD MMM YYYY")}
        <CalendarIcon />
      </Button>
      <Popper
        open={openDateSelector}
        anchorEl={anchorEl}
        transition
        disablePortal
        style={{ zIndex: 999 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
              zIndex: 999,
            }}
          >
            <ClickAwayListener onClickAway={() => setOpenDateSelector(false)}>
              <Paper
                className={classes.picker}
                onBlur={() => setOpenDateSelector(false)}
              >
                <BasePicker
                  onChange={handleDateChange}
                  value={selectedDate.format("YYYY-MM-DD")}
                >
                  {({ date }) => (
                    <Calendar
                      date={date}
                      disablePast={true}
                      leftArrowIcon={<LeftIcon />}
                      maxDate={maxDate}
                      onChange={handleDateChange}
                      rightArrowIcon={<RightIcon />}
                      shouldDisableDate={shouldDisableDate}
                    />
                  )}
                </BasePicker>
              </Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
};
/* */
const mapStateToProps = ({ app: { golf }, bookingData }) => ({
  golf,
  data: bookingData,
});
export default connect(
  mapStateToProps,
  Booking.actions
)(withRouter(withStyles(styles)(PublicBookingDateSelector)));
