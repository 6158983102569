import React from 'react';
import {
  Button,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

/* */
const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  textField: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

/* */
class CreateAccountForm extends React.Component {
  /* */
  render() {
    const {
      errors,
      values,
      classes,
      handleBlur,
      handleChange,
      handleSubmit,
      isSubmitting,
    } = this.props;

    return (
      <div className="form">
        <TextField
          fullWidth
          required
          className={classes.textField}
          error={!!(errors.lastname)}
          label='Nom'
          name='lastname'
          onBlur={handleBlur}
          onChange={handleChange}
          type='lastname'
          value={values.lastname}
          variant='outlined'
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          fullWidth
          required
          className={classes.textField}
          error={!!(errors.firstname)}
          label='Prénom'
          name='firstname'
          onBlur={handleBlur}
          onChange={handleChange}
          type='firstname'
          value={values.firstname}
          variant='outlined'
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          fullWidth
          required
          className={classes.textField}
          error={!!(errors.phone)}
          label='Téléphone'
          name='phone'
          onBlur={handleBlur}
          onChange={handleChange}
          type='phone'
          value={values.phone}
          variant='outlined'
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          fullWidth
          className={classes.submit}
          color="primary"
          onClick={handleSubmit}
          size="large"
          variant="contained">
           {isSubmitting ? <CircularProgress color="inherit" size={24} /> : 'Sauvegarder'}
         </Button>
      </div>
    );
  }
}

export default withStyles(styles)(CreateAccountForm);
