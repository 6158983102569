import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import i18n from '_utils/i18n';

/* */
const styles = theme => ({
  textField: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  submitButton: {
    marginTop: theme.spacing.unit * 2,
  },
});

/* */
class AddContactForm extends React.Component {
  /* */
  render() {
    const {
      errors,
      values,
      classes,
      handleBlur,
      handleChange,
      handleSubmit,
      isSubmitting,
    } = this.props;

    return (
      <React.Fragment>
        <TextField
          required
          fullWidth
          label="Prénom"
          name="firstname"
          variant="outlined"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.firstname}
          error={!!(errors.firstname)}
          className={classes.textField}
          helperText={errors.firstname}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          required
          fullWidth
          label="Nom"
          name="lastname"
          variant="outlined"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.lastname}
          error={!!(errors.lastname)}
          helperText={errors.lastname}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          fullWidth
          name="email"
          label="E-mail"
          variant="outlined"
          onBlur={handleBlur}
          value={values.email}
          onChange={handleChange}
          error={!!(errors.email)}
          helperText={errors.email}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          fullWidth
          name="phone"
          label="Téléphone"
          variant="outlined"
          onBlur={handleBlur}
          value={values.phone}
          onChange={handleChange}
          error={!!(errors.phone)}
          helperText={errors.phone}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          fullWidth
          name="licenseNumber"
          label="Numéro de licence"
          variant="outlined"
          onBlur={handleBlur}
          value={values.licenseNumber}
          onChange={handleChange}
          error={!!(errors.licenseNumber)}
          helperText={errors.licenseNumber}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          fullWidth
          size="large"
          color="secondary"
          variant="contained"
          onClick={handleSubmit}
          disabled={isSubmitting}
          className={classes.submitButton}>
          {isSubmitting
            ? <CircularProgress color="inherit" size={24} />
            : i18n.t('default.actions.save')}
        </Button>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(AddContactForm);
