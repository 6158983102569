import React from 'react';
import moment from 'moment';
import { Tournament } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  colors,
  Typography,
} from '@material-ui/core';
import {
  Lock as LockIcon,
  LockOpen as LockOpenIcon,
  AccessTime as AccessTimeIcon,
  InfoOutlined as InfoOutlinedIcon,
  FormatListNumbered as FormatListNumberedIcon,
} from '@material-ui/icons';
import {
  CalendarDay,
} from '_components/elements';

/* */
const styles = theme => ({
  lifecycle: { color: theme.palette.text.primary },
  registrationWarning: { color: '#FF7E07' },
  registrationClose: { color: colors.red[500] },
  registrationOpen: { color: colors.green[500] },
});

/* */
const getColorStatus = (status) => {
  switch (status) {
    case 1:
    case 2:
      return 'registrationOpen';
    case 3:
      return 'registrationWarning';
    default:
      return 'registrationClose';
  }
};

/* */
const Header = ({ classes, tournament }) => {
  const {
    name,
    lifecycle,
    startDate,
    gameFormula,
    informations,
  } = tournament;

  let icon;
  let status;
  let statusText;
  let classCSS = classes.lifecycle;

  if (lifecycle === 'registration') {
    ({ status, statusText } = Tournament.functions.getRegistrationStatus(tournament));
    classCSS = classes[getColorStatus(status)];
    icon = status > 0
      ? <LockOpenIcon className={classCSS} />
      : <LockIcon className={classCSS} />;
  } else if (lifecycle === 'teetime') {
    icon = <AccessTimeIcon className={classCSS} />;
    statusText = 'Départs publiés';
  } else if (lifecycle === 'results') {
    icon = <FormatListNumberedIcon className={classCSS} />;
    statusText = 'Résultats publiés';
  }

  return (
    <Box
      mb={3}
      pb={3}
      borderBottom={1}
      borderColor="#ddd">
      <Box
        display="flex"
        direction="row"
        alignItems="flex-start">
        <CalendarDay datetime={moment(startDate, 'YYYY-MM-DD')} />
        <Box
          ml={3}
          flex={1}
          display="flex"
          flexDirection="column">
          <Typography
            color="secondary"
            variant="subtitle1">
            {name}
          </Typography>
          <Typography
            gutterBottom
            variant="body1">
            {gameFormula}
          </Typography>
          {(icon && statusText) && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center">
              {icon}&nbsp;
              <Typography
                className={classCSS}
                variant="body1">
                {statusText}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {informations && (
        <Box
          mt={2}
          display="flex"
          flexDirection="row"
          alignItems="start">
          <InfoOutlinedIcon />&nbsp;
          <Box
            display="flex"
            flexDirection="column">
            {informations.split('<br/>').map((information, index) => (
              <Typography key={index} variant="body1">
                {information}
              </Typography>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default withStyles(styles)(Header);
