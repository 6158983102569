import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { App, Event } from '@aps-management/primapp-common';
import { Redirect } from 'react-router-dom';
import {
  Grid,
  List,
  Paper,
  Button,
  Select,
  ListItem,
  MenuItem,
  Typography,
  FormControl,
  ListItemText,
  CircularProgress,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
/* */
import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import PaymentMethods from '_components/PaymentMethods';
/* */
import Header from '../_Header';

/* */
const styles = theme => ({
  formControl: {
    minWidth: '33%',
  },
  menuItem: {
    padding: theme.spacing.unit * 1,
  },
  subtotal: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
});

/* */
class TournamentEvent extends React.Component {
  /* */
  constructor(props) {
    super(props);

    const {
      account,
      enter,
      golf,
      match: { params },
    } = props;

    this.id = params.id;
    this.redirect = false;
    this.eventIdx = Number(params.index);

    if (!enter.status.includes('entrants')
      || typeof enter.events[this.eventIdx] !== 'object') {
      this.redirect = true;
    }

    this.event = enter.events[this.eventIdx];

    // payment methods
    this.paymentMethods = App.functions.getPaymentMethods(account, golf, 'event');
    const paymentMethod = this.paymentMethods[0];

    this.state = {
      // UI
      error: null,
      loading: false,
      // Moyens de paiment / CGV
      paymentMethod,
      paymentMethodOpts: null,
      areAcceptedTerms: false,
      // Data
      waiting: false,
    };
  }

  /* */
  componentDidUpdate(prevProps) {
    const { enter, match: { params } } = this.props;

    // on goToEvent => refresh new event
    if (prevProps.match.params.index !== params.index) {
      this.eventIdx = Number(params.index);
      this.event = enter.events[this.eventIdx];
      this.forceUpdate();
    }
  }

  /* */
  goToPayment = () => {
    this.propagatePaymentMethod();
    this.props.enter.status.push('cart');
    this.props.history.push(`/tournament/${this.id}/payment`);
  }

  /* */
  goToEvent = index => () => {
    this.props.history.push(`/tournament/${this.id}/event/${index}`);
  }

  /* */
  goToVoucher = () => {
    this.props.enter.status.push('payment');
    this.props.history.push(`/tournament/${this.id}/voucher`);
  }

  /* */
  propagatePaymentMethod() {
    const { paymentMethod } = this.state;
    const { enter: { entrants } } = this.props;

    // set payment method
    entrants.forEach((e, i) => {
      entrants[i].tournament.type = paymentMethod;
      entrants[i].events.forEach((ev, j) => {
        entrants[i].events[j].type = paymentMethod;
      });
    });
  }

  /* */
  onConfirm = () => {
    const { enter, golf } = this.props;
    const { paymentMethod, paymentMethodOpts } = this.state;

    this.setState({ error: null, waiting: true });

    this.propagatePaymentMethod();
    const totalAmount = enter.total.tournament + enter.total.events;

    const transaction = {
      amount: totalAmount,
      mode: paymentMethod,
      ...(paymentMethod === 2 && { walletId: paymentMethodOpts.wallet.id }),
    };

    return Event.functions.asyncSetEntry(apolloClient, {
      enter,
      golf,
      transaction,
    })
      .then(() => {
        enter.transaction = transaction;
        this.goToVoucher();
      })
      .catch((err) => {
        this.setState({ error: err.message, waiting: false });
      });
  }

  /* */
  handleChangePaymentMethod = (type, opts) => {
    this.setState({
      paymentMethod: type,
      paymentMethodOpts: opts,
    });
  }

  /* */
  handleChangeAcceptTerms = checked => this.setState({ areAcceptedTerms: checked });

  /* */
  renderPaymentMethod() {
    const { classes, enter: { total, tournament } } = this.props;
    const { waiting, paymentMethod, areAcceptedTerms } = this.state;

    const totalAmount = total.tournament + total.events;
    const buttonTxt = `${(paymentMethod === 1 ? 'Valider' : 'Payer')}`
      + ` ${i18n.l('currency', totalAmount / 100)}`;
    const cancelableUntil = tournament.cancelableUntil
      && moment(tournament.cancelableUntil, 'YYYY-MM-DD HH:mm');

    return (
      <Grid
        container
        spacing={24}
        justify="center">
        <Grid
          item md={6} xs={12}
          className={classes.section}>
          <Typography
            paragraph
            component="h3"
            variant="h6">
            {'Votre moyen de paiement'}
          </Typography>
          <PaymentMethods
            selected={paymentMethod}
            amount={totalAmount}
            methods={this.paymentMethods}
            onChange={this.handleChangePaymentMethod}
            onChangeAcceptTerms={this.handleChangeAcceptTerms}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          className={classes.section}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}>
          {cancelableUntil && (
            <Typography gutterBottom variant="caption" align="center">
              {`L'inscription est annulable jusqu'au ${cancelableUntil.format('DD/MM/YYYY')} à ${cancelableUntil.format('HH:mm')}.`}
            </Typography>
          )}
          <Button
            fullWidth
            size="large"
            color="secondary"
            variant="contained"
            disabled={waiting || !areAcceptedTerms}
            onClick={paymentMethod === 1 ? this.onConfirm : this.goToPayment}>
            {waiting ? <CircularProgress color="inherit" size={24} /> : buttonTxt}
          </Button>
        </Grid>
      </Grid>
    );
  }

  /* */
  renderNextButton() {
    const { waiting } = this.state;
    const { event, eventIdx } = this;
    const { enter: { events, entrants, total } } = this.props;

    const totalAmount = total.tournament + total.events;
    const hasMoreEvent = events.length > (eventIdx + 1);
    const disabled = event.required && !entrants.every(e => (
      e.events[eventIdx] && e.events[eventIdx].option
    ));

    if (!hasMoreEvent && totalAmount > 0) return this.renderPaymentMethod();

    let label;
    let onClick;
    if (hasMoreEvent) {
      label = 'Continuer';
      onClick = this.goToEvent(eventIdx + 1);
    } else {
      label = 'Confirmer';
      onClick = this.onConfirm;
    }

    return (
      <Button
        fullWidth
        size="large"
        color="secondary"
        onClick={onClick}
        disabled={disabled || waiting}
        variant="contained">
        {waiting ? <CircularProgress color="inherit" size={24} /> : label}
      </Button>
    );
  }

  /* */
  onChange = entrantIdx => (ev) => {
    const { enter } = this.props;
    const { event, eventIdx } = this;

    const optionId = ev.target.value;

    // remove event
    if (Number(optionId) === 0) {
      delete enter.entrants[entrantIdx].events[eventIdx];
    } else {
      // hydrate entrant events
      const option = event.options.find(opt => opt.id === optionId);
      enter.entrants[entrantIdx].events[eventIdx] = {
        type: 0, // payment method (0: immediate, 1: deferred)
        id: event.id,
        name: event.name.toUpperCase(),
        amount: option.fees[entrantIdx],
        hasManyOptions: event.hasManyOptions,
        option: {
          id: option.id,
          name: option.name,
        },
      };
    }

    // calculate total events
    enter.total.events = enter.entrants.reduce((total, entrant) => (total
       + (entrant.events && entrant.events.reduce((acc, e) => (acc + e.amount), 0))
    ), 0);

    // re-render
    this.forceUpdate();
  }

  /* */
  renderOption = ({ name }, i) => (
    <ListItem divider key={i}>
      <ListItemText>
        {`Option ${i + 1}`}
      </ListItemText>
      <Typography
        variant="body1"
        color="textSecondary">
        {name}
      </Typography>
    </ListItem>
  )

  /* */
  renderDropdown = (e, i) => {
    const { event, eventIdx } = this;
    const { classes, enter: { entrants } } = this.props;

    const value = entrants[i].events[eventIdx]
      ? entrants[i].events[eventIdx].option.id
      : 0;
    const data = event.options.reduce((acc, opt, j) => {
      const fees = (opt.fees[i] === 0)
        ? i18n.t('terms.included')
        : i18n.l('currency', opt.fees[i] / 100);

      acc.push({
        value: opt.id,
        label: `Option ${j + 1} (${fees})`,
      });

      return acc;
    }, [{ value: 0, label: event.required ? 'Choisir une option' : 'Ne participe pas' }]);

    return (
      <ListItem key={i} divider>
        <ListItemText>
          {`${e.firstname} ${e.lastname}`}
        </ListItemText>
        <FormControl className={classes.formControl}>
          <Select
            value={value}
            onChange={this.onChange(i)}
            classes={{ select: classes.menuItem }}>
            {data.map((item, idx) => (
              <MenuItem
                key={idx}
                value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ListItem>
    );
  }

  /* */
  renderSubtotal() {
    const { eventIdx } = this;
    const { classes, enter } = this.props;

    // calculate subtotal event
    const subtotal = enter.entrants.reduce((total, entrant) => (total
       + (entrant.events?.[eventIdx]?.amount || 0)), 0);

    if (subtotal === 0) return null;

    return (
      <ListItem className={classes.subtotal}>
        <ListItemText
          primary="Sous-total"
          primaryTypographyProps={{ color: 'inherit' }} />
        <Typography
          variant="subtitle1"
          color="inherit">
          {i18n.l('currency', subtotal / 100)}
        </Typography>
      </ListItem>
    );
  }

  /* */
  render() {
    if (this.redirect) return <Redirect to={`/tournament/${this.id}`} />;

    const { event } = this;
    const { error, loading } = this.state;
    const { enter: { entrants, tournament } } = this.props;

    return (
      <Screen
        error={error}
        loading={loading}
        title="Compétitions - Événement"
        onBackPress={() => this.props.history.goBack()}>
        <Header tournament={tournament} />
        <Typography
          color="secondary"
          variant="body1">
          {event.name.toUpperCase()}
        </Typography>
        <br />
        <Paper>
          <List disablePadding>
            {event.options.map(this.renderOption)}
          </List>
        </Paper>
        <br />
        <br />
        <Typography variant="body1">
          {'Qui participera ?'}
        </Typography>
        <br />
        <Paper>
          <List disablePadding>
            {entrants.map(this.renderDropdown)}
            {this.renderSubtotal()}
          </List>
        </Paper>
        <br />
        <br />
        {this.renderNextButton()}
      </Screen>
    );
  }
}

const mapStateToProps = ({
  app: {
    account,
    golf,
  },
  eventEnter: enter,
}) => ({ account, golf, enter });

const StyledComponent = withStyles(styles)(TournamentEvent);

export default connect(
  mapStateToProps,
  Event.actions,
)(StyledComponent);
