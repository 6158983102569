import * as yup from 'yup';
import {
  PASSWORD_MIN_LENGTH,
  PASSWORD_SECURITY,
} from '@aps-management/primapp-common';
import i18n from '_utils/i18n';

/* */
function equalTo(ref: any, msg: any) {
  return yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg, // || `${path} must be the same as ${reference}`,
    params: {
      reference: ref.path,
    },
    test(value: any) {
      return value === this.resolve(ref);
    },
  });
}
yup.addMethod(yup.string, 'equalTo', equalTo);

/* */
export const createValidationSchema = validators => yup.object().shape(validators);

/* */
export const validators = {
  /* */
  nullable: validator => validator.nullable(),
  require: validator => validator.required(i18n.t('errors.yup.required')),

  /* */
  email: yup
    .string()
    .trim()
    .email(i18n.t('errors.yup.string.email'))
    .required(i18n.t('errors.yup.string.required')),
  /* */
  emailConfirm: yup
    .string()
    .email(i18n.t('errors.yup.string.email'))
    .equalTo(
      yup.ref('email'),
      i18n.t('errors.yup.custom.mismatched_emails'),
    ),
  /* */
  password: yup
    .string()
    .trim()
    .min(PASSWORD_MIN_LENGTH, i18n.t('errors.yup.string.min'))
    .matches(PASSWORD_SECURITY, i18n.t('errors.yup.custom.low_complexity'))
    .required(i18n.t('errors.yup.string.required')),
  /* */
  unsecuredPassword: yup
    .string()
    .trim()
    .min(3, i18n.t('errors.yup.string.min'))
    .required(i18n.t('errors.yup.string.required')),
  /* */
  passwordConfirm: yup
    .string()
    .equalTo(
      yup.ref('password'),
      i18n.t('errors.yup.custom.mismatched_passwords'),
    ),
  /* */
  optional: yup
    .string()
    .trim()
    .typeError(i18n.t('errors.yup.string.type')),
  /* */
  get required() {
    return this.optional
      .required(i18n.t('errors.yup.string.required'));
  },
  /* */
  array: of => yup
    .array()
    .of(of)
    .typeError(i18n.t('errors.yup.array.type')),

  /* */
  number: yup
    .number()
    .typeError(i18n.t('errors.yup.number.type')),
  /* */
  get optionalNumber() {
    return this.number
      .nullable();
  },
  /* */
  get requiredNumber() {
    return this.number
      .required(i18n.t('errors.yup.required'));
  },
  /* */
  get optionalInt() {
    return this.optionalNumber
      .integer(i18n.t('errors.yup.number.integer'));
  },
  /* */
  get requiredInt() {
    return this.optionalInt
      .nullable(false)
      .required(i18n.t('errors.yup.required'));
  },

  boolean: yup
    .boolean()
    .typeError(i18n.t('errors.yup.boolean.type')),

  /* */
  get optionalBoolean() {
    return this.boolean
      .nullable();
  },
  /* */
  get requiredBoolean() {
    return this.optionalBoolean
      .required(i18n.t('errors.yup.required'));
  },
  /* */
  dateFourDigits: yup
    .string()
    .matches(/^([0-9]{2}\/[0-9]{2})$/, {
      message: ' ',
      excludeEmptyString: true,
    })
    .required(),
  /* */
  cvv: yup
    .string()
    .matches(/^([0-9]{3})$/, {
      message: ' ',
      excludeEmptyString: true,
    })
    .required(),
  /* */
  oneDigit: yup
    .number()
    .min(0, i18n.t('errors.yup.string.min'))
    .max(9, i18n.t('errors.yup.string.max'))
    .required(i18n.t('errors.yup.string.required')),
};
