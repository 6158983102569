import React from 'react';
import { Formik } from 'formik';
import { App, helpers } from '@aps-management/primapp-common';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  Button,
  TextField,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import i18n from '_utils/i18n';
import { Alert } from '_components/elements';
import apolloClient from '_utils/apolloClient';
import { createValidationSchema, validators } from '_utils/validation';

const validationSchema = createValidationSchema({
  phone: validators.required,
});

/* */
const styles = theme => ({
  submit: {
    marginLeft: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
  },
});

/* */
class PhoneAlert extends React.Component {
  /* */
  onSubmit = (values, { setSubmitting }) => {
    const { account } = this.props;
    this.props.setAccount(apolloClient, {
      ...account,
      ...values,
    })
      .then(() => this.props.enqueueSnackbar('Modifications enregistrées', { variant: 'success' }))
      .catch((error) => {
        const message = helpers.string.handleError(error);
        this.props.enqueueSnackbar(i18n.t(message, { defaultValue: message }), { variant: 'error' });
      })
      .finally(() => setSubmitting(false));
  }

  /* */
  render() {
    const {
      account,
      classes,
    } = this.props;

    if (!account.phone) {
      return (
        <Alert variant="warning">
          <Typography
            gutterBottom
            component="p"
            variant="body1">
            {'Votre numéro de téléphone doit être renseigné pour effectuer une réservation.'}
          </Typography>
          <Formik
            initialValues={{ phone: '' }}
            onSubmit={this.onSubmit}
            validationSchema={validationSchema}
            render={({
              errors,
              values,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <Box
                mt={3}
                display="flex"
                alignItems="stretch"
                flexDirection="row">
                <TextField
                  fullWidth
                  required
                  error={!!(errors.phone)}
                  label='Téléphone'
                  name='phone'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='phone'
                  value={values.phone}
                  variant='outlined'
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  className={classes.submit}
                  disabled={isSubmitting}
                  onClick={handleSubmit}>
                   {isSubmitting ? <CircularProgress color="inherit" size={24} /> : i18n.t('default.actions.save')}
                 </Button>
               </Box>
            )}
          />
        </Alert>
      );
    }

    return null;
  }
}

const mapStateToProps = ({ app: { account } }) => ({ account });

export default connect(
  mapStateToProps,
  App.actions,
)(withSnackbar(withStyles(styles)(PhoneAlert)));
