import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { helpers } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  Paper,
  Divider,
  Typography,
} from '@material-ui/core';
import i18n from '_utils/i18n';
import { Screen } from '_components/core';

/* */
const styles = theme => ({
  voucher: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 1,
    paddingBottom: theme.spacing.unit * 2,
  },
  body1: {
    ...theme.typography.body2,
    textAlign: 'center',
  },
  divider: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  table: {
    paddingTop: theme.spacing.unit * 3,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  underline: { textDecoration: 'underline' },
});

/* */
class Voucher extends React.Component {
  /* */
  constructor(props) {
    super(props);

    const { enter, match: { params } } = props;

    this.id = params.id;
    this.redirect = false;

    if (!enter.status.includes('payment')) {
      this.redirect = true;
      return;
    }

    enter.status = [];
    enter.entrants = enter.entrants.map(entrant => ({
      ...entrant,
      events: entrant.events.filter(e => e),
    }));
  }

  /* */
  renderTournament() {
    const {
      classes,
      enter: {
        total,
        entrants,
        tournament,
      },
    } = this.props;

    return tournament && (
      <React.Fragment>
        <Typography
          gutterBottom
          variant="h6"
          color="secondary">
          {tournament.name}
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between">
          <Typography variant="body1">
            {'Participant(s) :'}
          </Typography>
          <Box ml={2}>
            {entrants
              .filter(en => en.tournament.id === tournament.id)
              .map((en, i) => (
                <Typography
                  noWrap
                  key={i}
                  variant="body1">
                  {`${en.firstname} ${en.lastname}`}
                </Typography>
              ))}
          </Box>
        </Box>
        <Box
          mt={1}
          display="flex"
          flexDirection="row"
          justifyContent="space-between">
          <Typography variant="subtitle1" color="textSecondary">
            {'Sous-total'}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {i18n.l('currency', total.tournament / 100)}
          </Typography>
        </Box>
        <Divider className={classes.divider} />
      </React.Fragment>
    );
  }

  /* */
  renderEvents() {
    const {
      classes,
      enter: {
        total,
        events,
        entrants,
      },
    } = this.props;

    const atLeastOneEvent = Boolean(entrants.filter(en => en.events.length).length);

    if (!atLeastOneEvent) return null;

    return (
      <React.Fragment>
        {events && events.length > 0 && events.map((event) => {
          const list = entrants.filter(en => en.events.find(ev => ev.id === event.id));
          if (list.length === 0) return null;

          return (
            <Box mb={2} key={event.id}>
              <Typography
                gutterBottom
                variant="h6"
                key={event.id}
                color="secondary">
                {event.name.toUpperCase()}
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between">
                <Typography variant="body1">
                  {'Participant(s) :'}
                </Typography>
                <Box ml={2}>
                  {entrants.filter(en => en.events
                    .find(ev => ev.id === event.id))
                    .map((en, i) => (
                      <Typography
                        key={i}
                        variant="body1">
                      {`${en.firstname} ${en.lastname}`}
                      </Typography>
                    ))}
                </Box>
              </Box>
            </Box>
          );
        })}
        <Box
          mt={1}
          display="flex"
          flexDirection="row"
          justifyContent="space-between">
          <Typography
            variant="subtitle1"
            color="textSecondary">
            {'Sous-total'}
          </Typography>
          <Typography
            variant="subtitle1"
            color="textSecondary">
            {i18n.l('currency', total.events / 100)}
          </Typography>
        </Box>
        <Divider className={classes.divider} />
      </React.Fragment>
    );
  }


  /* */
  renderPaymentInfo() {
    const { classes, enter: { transaction } } = this.props;

    if (!transaction) return null;

    const text = transaction.mode === 1 ? 'Total à régler' : 'Total réglé';

    return (
      <Box
        mb={2}
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
        className={classes.table}
        justifyContent="space-between">
        <Box>
          <Typography gutterBottom>{text}</Typography>
          <Typography color="textSecondary">
            {i18n.l('currency', transaction.amount / 100)}
          </Typography>
        </Box>
        {transaction.transactionNo && (
          <Box>
            <Typography gutterBottom>{'E-ticket'}</Typography>
            <Typography color="textSecondary">
              {transaction.transactionNo}
            </Typography>
          </Box>
        )}
        {transaction.mode === 1 && (
          <Typography color="textSecondary">
            {'Paiement sur place'}
          </Typography>
        )}
        {transaction.mode === 2 && (
          <Typography color="textSecondary">
            {'Paiement par compte client'}
          </Typography>
        )}
      </Box>
    );
  }

  /* */
  render() {
    if (this.redirect) {
      return <Redirect to={`/tournament/${this.id}`} />;
    }

    const {
      classes,
      golf,
      enter: {
        comment,
        events,
        entrants,
        tournament,
      },
    } = this.props;

    const date = (tournament && tournament.startDate) || (events[0] && events[0].startDate);
    const cancelableUntil = tournament.cancelableUntil && moment(tournament.cancelableUntil, 'YYYY-MM-DD HH:mm');

    return (
      <Screen layout="fixed" title="Compétitions - Confirmation d'inscription">
        <Typography
          paragraph
          variant="h4"
          align="center">
          {'Merci !'}
        </Typography>
        <div className={classes.voucher}>
          <Paper className={classes.paper}>
            <Typography
              gutterBottom
              align="center"
              variant="h5"
              component="h2"
              color="textSecondary">
              {golf.name}
            </Typography>
            <Typography
              align="center"
              variant="body2"
              className={classes.underline}>
              {golf.email}
            </Typography>
          </Paper>
          <Paper className={classes.paper}>
            <Typography
              gutterBottom
              variant="h5"
              align="center"
              component="h2"
              color="textSecondary">
              {helpers.string.ucfirst(i18n.l('date.formats.long', new Date(date)))}
            </Typography>
            <br />
            {comment && (
              <Box mb={2}>
                {comment.split('\n').map((line, key) => <Typography key={key}>{line}</Typography>)}
              </Box>
            )}
            {this.renderTournament()}
            {this.renderEvents()}
            <p className={classes.body1}>
              {'Un e-mail de confirmation vous a été envoyé à : '}
              <span className={classes.underline}>{entrants[0] && entrants[0].email}</span>
            </p>
            {this.renderPaymentInfo()}
            {cancelableUntil && (
              <Typography variant="caption" align="center">
                {`L'inscription est annulable jusqu'au ${cancelableUntil.format('DD/MM/YYYY')} à ${cancelableUntil.format('HH:mm')}.`}<br />
                {events.length > 0 && "L'annulation concerne l'inscription à la compétition ainsi qu'à l'événément."}
              </Typography>
            )}
          </Paper>
        </div>
      </Screen>
    );
  }
}

const mapStateToProps = ({ app: { golf }, eventEnter: enter }) => ({ golf, enter });

const StyledComponent = withStyles(styles)(Voucher);

export default connect(
  mapStateToProps,
  null,
)(StyledComponent);
