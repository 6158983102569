import React from 'react';
import { Helmet } from 'react-helmet';

const Manifest = ({ golf }) => {
  const url = window.location.href.split(`/${golf.slug}`)[0];
  const dynManifest = {
    id: golf.slug,
    name: golf.name,
    short_name: golf.name,
    description: `Page d'accueil - ${golf.name}`,
    start_url: `${url}/${golf.slug}`,
    display: 'standalone',
    background_color: '#ffffff',
    theme_color: '#ffffff',
    categories: ['sports'],
    orientation: 'natural',
    lang: 'fr',
    icons: [
      {
        src: `${url}/favicon16.ico`,
        sizes: '16x16',
        type: 'image/x-icon',
      },
      {
        src: `${url}/logo512.png`,
        type: 'image/png',
        sizes: '512x512',
        purpose: 'any',
      },
      {
        src: `${url}/maskable512.png`,
        type: 'image/png',
        sizes: '512x512',
        purpose: 'maskable',
      },
    ],
    screenshots: [
      {
        src: `${url}/home.png`,
        type: 'image/png',
        sizes: '1920x1080',
        form_factor: "wide",
        label: "Homescreen"
      },
      {
        src: `${url}/home.png`,
        type: 'image/png',
        sizes: '1920x1080',
        form_factor: "narrow",
        label: "Homescreen"
      },
    ],
  };
  const stringManifest = JSON.stringify(dynManifest);
  const blob = new Blob([stringManifest], { type: 'application/json' });
  const manifestURL = URL.createObjectURL(blob);
  return (
    <Helmet>
      <link rel="apple-touch-icon" />
      <link rel="manifest" href={manifestURL} />
    </Helmet>
  );
};

export default Manifest;
