import React from 'react';
import { connect } from 'react-redux';
import config from '_config';

class Lessons extends React.Component {
  componentDidMount = () => {
    const { golf } = this.props;

    window.location.replace(`${config.edgUrl}/${golf.slug}/`);
  }

  render() {
    return (null);
  }
}

const mapStateToProps = ({ app }) => ({ golf: app.golf });

export default connect(mapStateToProps)(Lessons);
