import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { App } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Grid,
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import { createValidationSchema, validators } from '_utils/validation';
import { ChangeLoginForm, ChangePasswordForm } from '_components/forms';

/* */
const validationSchemas = {
  login: createValidationSchema({
    email: validators.email,
    emailConfirm: validators.emailConfirm,
  }),
  password: createValidationSchema({
    password: validators.password,
    passwordConfirm: validators.passwordConfirm,
  }),
};

/* */
const styles = theme => ({
  panel: {
    paddingTop: 0,
    display: 'inherit',
  },
  subtitle: {
    color: theme.palette.primary.main,
  },
});

/* */
class ChangeLoginId extends React.Component {
  /* */
  state = {
    // Screen
    error: null,
    success: null,
    // UX
    expandedPanel: 'changeLogin',
    // Data,
    login: null,
  };

  componentDidMount() {
    const { user: { email: login } } = this.props;
    this.setState({ login });
  }

  /* */
  handleExpand = panel => (event, expanded) => {
    this.setState({ expandedPanel: expanded ? panel : null });
  }

  /* */
  handleSubmit = (values, { setSubmitting, resetForm }) => {
    this.setState({
      error: null,
      success: null,
    });

    let promise;
    let success;
    switch (values.form) {
      case 'login':
        success = 'Nouvel identifiant enregistré.';
        promise = App.api.changeLogin(apolloClient, values)
          .then(() => this.setState({ login: values.email }))
          .finally(() => resetForm());
        break;
      case 'password':
        success = 'Nouveau mot de passe enregistré.';
        promise = App.api.changePassword(apolloClient, values)
          .finally(() => resetForm());
        break;
      default:
        promise = Promise.reject(new Error('Erreur !'));
    }

    promise
      .then(() => this.setState({ success }))
      .catch(error => this.setState({ error: error.message }))
      .finally(() => setSubmitting(false));
  }

  /* */
  render() {
    const { classes } = this.props;
    const {
      error,
      login,
      success,
      expandedPanel,
    } = this.state;

    return (
      <Screen
        error={error}
        success={success}
        title='Mon profil'
        onBackPress={() => this.props.history.goBack()}>
        <Grid
          container
          spacing={40}
          justify="center">
          <Grid item sm={12} md={6}>
            <ExpansionPanel
              expanded={expandedPanel === 'changeLogin'}
              onChange={this.handleExpand('changeLogin')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}>
                <Typography
                  component="h2"
                  variant="subtitle1"
                  className={classes.subtitle}>
                  {'Mon identifiant de connexion'}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.panel}>
                <Typography variant="caption">
                  {'Actuellement, votre identifiant est :'} <strong>{login}</strong>{'.'}
                </Typography>
                <br />
                <Formik
                  component={ChangeLoginForm}
                  onSubmit={this.handleSubmit}
                  validationSchema={validationSchemas.login}
                  initialValues={{
                    email: '',
                    form: 'login',
                    emailConfirm: '',
                  }} />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          <Grid item sm={12} md={6}>
            <ExpansionPanel
              expanded={expandedPanel === 'changePwd'}
              onChange={this.handleExpand('changePwd')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}>
                <Typography
                  component="h2"
                  variant="subtitle1"
                  className={classes.subtitle}>
                  {'Mon mot de passe'}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.panel}>
                <Formik
                  component={ChangePasswordForm}
                  onSubmit={this.handleSubmit}
                  validationSchema={validationSchemas.password}
                  initialValues={{
                    password: '',
                    form: 'password',
                    passwordConfirm: '',
                  }} />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        </Grid>
      </Screen>
    );
  }
}

const mapStateToProps = ({ app: { user } }) => ({ user });

const StyledComponent = withStyles(styles)(ChangeLoginId);

export default connect(mapStateToProps)(StyledComponent);
