import React from 'react';
import { connect } from 'react-redux';
import { Event, Tournament } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import {
  colors,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';

import Header from './_Header';

/* */
const styles = () => ({
  flex1: { flex: 1 },
  flex2: { flex: 2 },
  bold: { fontWeight: 600 },
  flagCol: { flex: 0.5, padding: 0 },
  flex8: { flex: 8, alignItems: 'center' },
  flag: {
    width: 20,
    height: 12,
    border: `1px solid ${colors.grey[500]}`,
  },
});

/* */
class TournamentStartList extends React.Component {
  /* */
  state = {
    // Screen
    error: null,
    // Data
    startlist: [],
    loading: true,
    tournament: null,
  };

  /* */
  id = null;

  /* */
  componentDidMount() {
    this.id = this.props.match.params.id;
    this.load();
  }

  /* */
  load() {
    const { id } = this;
    const {
      tournaments,
      golf: { id: golfId },
    } = this.props;

    if (!id) {
      return this.setState({
        startlist: [],
        loading: false,
        error: 'errors.not_found',
      });
    }

    // Reset state
    this.setState({
      error: null,
      startlist: [],
      loading: true,
    });

    return Promise.resolve()
      .then(() => {
        if (this.state.tournament) return Promise.resolve();
        // Try to load from store
        const trnmt = tournaments && tournaments.find(t => t.id === id);
        if (trnmt) return this.setState({ tournament: trnmt });
        // Otherwise load from API
        return Tournament.api.getTournament(apolloClient, { id, golfId })
          .then(tournament => this.setState({ tournament }));
      })
      .then(() => Tournament.api.getStartList(apolloClient, { id, golfId }))
      .then(({ startlist }) => {
        this.setState({ startlist });
      })
      .catch(e => this.setState({ error: e.message }))
      .finally(() => this.setState({ loading: false }));
  }

  /* */
  renderStartList() {
    const { classes } = this.props;
    const {
      loading,
      startlist,
    } = this.state;

    if (!startlist) return null;

    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.flex2}>
                <Typography variant="body2" className={classes.bold}>{'Heure'}</Typography>
              </TableCell>
              <TableCell className={classes.flex1}>
                <Typography variant="body2" className={classes.bold}>{'Trou'}</Typography>
              </TableCell>
              <TableCell className={classes.flagCol} />
              <TableCell className={classes.flex8} />
              <TableCell align='right' className={classes.flex2}>
                <Typography variant="body2" className={classes.bold}>{'Index'}</Typography>
              </TableCell>
              <TableCell align='right' className={classes.flex2}>
                <Typography variant="body2" className={classes.bold}>{'Repère'}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.trow}>
            {loading && (
              <div className={classes.loaderContainer}>
                <CircularProgress
                  size={32}
                  thickness={4}
                  color="secondary" />
              </div>
            )}
            {startlist && startlist.map(this.renderTeetime)}
          </TableBody>
        </Table>
      </Paper>
    );
  }

  /* */
  renderTeetime = (start, i) => {
    const { classes } = this.props;
    const { tournament: { shotgun }, startlist } = this.state;
    const shotgunStartTime = startlist[0].time; // force everyone to start at the exact same time
    return start.players.map((player, j) => (
      <TableRow
        key={i + j}
        style={{ backgroundColor: (i % 2) ? '#f6f6f6' : '#fff' }}>
        <TableCell className={classes.flex2}>
          {j === 0 && <Typography variant="body2" className={classes.bold}>{shotgun ? shotgunStartTime : start.time}</Typography>}
        </TableCell>
        <TableCell className={classes.flex1}>
          <Typography variant="body2">{player.startHole}</Typography>
        </TableCell>
        <TableCell className={classes.flagCol}>
          <img
            className={classes.flag}
            alt={player.nationality}
            src={`https://web.ffgolf.org/resultats/img/flag/${player.nationality}.png`} />
        </TableCell>
        <TableCell className={classes.flex8}>
          <Typography variant="body2">{player.firstname} {player.lastname}</Typography>
        </TableCell>
        <TableCell align='right' className={classes.flex2}>
          <Typography variant="body2">{player.hcp.toFixed(1)}</Typography>
        </TableCell>
        <TableCell align='right' className={classes.flex2}>
          <Typography variant="body2">{player.startTeeColor}</Typography>
        </TableCell>
      </TableRow>
    ));
  }

  /* */
  render() {
    const { error, loading, tournament } = this.state;

    return (
      <Screen
        error={error}
        loading={loading}
        title="Compétitions - Liste des départs">
        {tournament && (
          <React.Fragment>
            <Header tournament={tournament} />
            {this.renderStartList()}
          </React.Fragment>
        )}
      </Screen>
    );
  }
}

const mapStateToProps = ({ app, tournament }) => ({
  golf: app.golf,
  account: app.account,
  tournaments: tournament.list,
});

const StyledComponent = withStyles(styles)(TournamentStartList);

export default connect(
  mapStateToProps,
  Event.actions,
)(StyledComponent);
