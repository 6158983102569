import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { HELP_URL } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { ArrowForwardIos as ForwardIcon } from '@material-ui/icons';
import {
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { Screen } from '_components/core';
import { getAuthorization } from '_utils/security';

/* */
const styles = theme => ({
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: theme.spacing.unit * 2,
  },
  paperLink: {
    cursor: 'pointer',
  },
  paperTitle: {
    flex: 1,
    fontWeight: 400,
    overflow: 'hidden',
    marginRight: theme.spacing.unit * 2,
  },
  paperContent: {
    color: theme.palette.grey[400],
  },
});

const Tile = ({ onClick, title, classes }) => (
  <Grid item xs={12} md={6}>
    <Paper
      className={classNames(classes.paper, classes.paperLink)}
      onClick={onClick}>
      <Typography
        variant="h6"
        color="primary"
        className={classes.paperTitle}>
        {title}
      </Typography>
      <ForwardIcon color="secondary" />
    </Paper>
  </Grid>
);

/* */
class AccoutSubmenu extends React.Component {
  /* */
  isAuthorized(path) {
    const { golf, account } = this.props;
    const { isAuthorized } = getAuthorization(path, { golf, account });

    return isAuthorized;
  }

  /* */
  goTo = route => () => this.props.history.push(route);

  /* */
  render() {
    const { classes } = this.props;

    return (
      <Screen
        helpURL={`${HELP_URL}/mon-profil`}
        title={'Mon compte'}>
        <Grid
          container
          spacing={24}
          justify="center">
          <Tile
            classes={classes}
            title="Mon profil"
            onClick={this.goTo('/account/edit')} />
          <Tile
            classes={classes}
            title="Mes identifiants"
            onClick={this.goTo('/account/id')} />
          {/* this.isAuthorized('/membership') && (
            <Tile
              classes={classes}
              title={i18n.t(`home.titles.my_membership_${wording.membership}`)}
              onClick={this.goTo('/membership')} />
          ) */}
          <Tile
            classes={classes}
            title={'Mes contacts'}
            onClick={this.goTo('/directory/contacts')} />
          <Tile
            classes={classes}
            title={'Ma carte bancaire'}
            onClick={this.goTo('/account/creditcard')} />
          {this.isAuthorized('/account/interests') && (
            <Tile
              classes={classes}
              title={'Mes centres d\'intérêts'}
              onClick={this.goTo('/account/interests')} />
          )}
          {this.isAuthorized('/account/statement') && (
            <Tile
              classes={classes}
              title={'Mon relevé de compte'}
              onClick={this.goTo('/account/statement')} />
          )}
        </Grid>
      </Screen>
    );
  }
}

const mapStateToProps = ({ app: { account, golf } }) => ({
  golf,
  account,
  // wording: golf && golf.options && golf.options.wording,
});

const StyledComponent = withStyles(styles)(AccoutSubmenu);

export default connect(mapStateToProps)(StyledComponent);
