import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ComputerIcon from '@material-ui/icons/Computer';
import { Typography } from '@material-ui/core';
import { Screen } from '_components/core';

/* */
const styles = theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    fontSize: 100,
    marginBottom: theme.spacing.unit * 2,
  },
});

/* */
const ComingSoon = props => (
  <Screen title={props.title}>
    <div className={props.classes.main}>
      <ComputerIcon
        className={props.classes.icon}
        fontSize="inherit" />
      <Typography
        component="h1"
        gutterBottom
        variant="h4">
        En maintenance, veuillez contacter la réception.
      </Typography>
    </div>
  </Screen>
);

const StyledComponent = withStyles(styles)(ComingSoon);

export default StyledComponent;
