import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
    Button,
    Radio,
    Typography,
    FormControl,
    FormControlLabel,
} from "@material-ui/core";
import { useContextSelector } from "@fluentui/react-context-selector";
import PublicBookingContext from "./services/publicBookingService";
import { unstable_Box as Box } from "@material-ui/core/Box";

/* */
const styles = (theme) => ({
});

const PublicBookingPlayerSelector = ({ maxPlayers = 4, classes }) => {
    const {
      playerCount,
      setPlayerCount,
    } = useContextSelector(PublicBookingContext, (c) => c);
  return (
    <FormControl
      style={{
        gridArea: "options",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        padding: "0 16px 16px 16px",
    }}
    >
        <Typography
        color="secondary"
        variant="body2"
        style={{ marginBottom: 8, fontSize: "calc(8px + 0.390625vw)", fontWeight: 500 }}
        paragraph
        >
        {"Nombre de joueurs :"}
      </Typography>
      <Box style={{ display: "flex", justifyContent: "space-between", marginInline: 8 }}>
        {Array.from({ length: maxPlayers }, (_, i) => (
          <FormControlLabel
            key={i}
            style={{ marginLeft: 0, marginRight: 0 }}
            control={
              <Radio
                checked={playerCount === i + 1}
                onChange={() => setPlayerCount(i + 1)}
                value="1"
                color="default"
                name="radio-button-demo"
                style={{ padding: 0 }}
                icon={<Button variant="contained" style={{ minWidth: '35px', padding: 0, height: "35px", fontSize: "calc(8px + 0.390625vw)" }}>{i + 1}</Button>}
                checkedIcon={
                  <Button variant="contained" style={{ minWidth: '35px', padding: 0, height: "35px", fontSize: "calc(8px + 0.390625vw)" }} color="secondary">
                    {i + 1}
                  </Button>
                }
              />
            }
            checked={playerCount === i + 1}
            onChange={() => setPlayerCount(i + 1)}
          />
        ))}
      </Box>
    </FormControl>
  );
};
/* */
const mapStateToProps = ({ app: { golf } }) => ({
  golf,
});

export default connect(mapStateToProps)(
  withRouter(withStyles(styles)(PublicBookingPlayerSelector))
);
