import _ from 'lodash';
import React, { Fragment, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { App, Academy, Directory } from '@aps-management/primapp-common';
import { unstable_Box as Box } from '@material-ui/core/Box';
import { useQuery } from '@tanstack/react-query';
import {
  Fab,
  Tabs,
  Tab,
  List,
  Paper,
  Badge,
  AppBar,
  Button,
  Dialog,
  Toolbar,
  Checkbox,
  ListItem,
  TextField,
  IconButton,
  Typography,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from '@material-ui/core';
import {
  Add as AddIcon,
  List as ListIcon,
  Star as StarIcon,
  Close as CloseIcon,
  Search as SearchIcon,
  Contacts as ContactsIcon,
} from '@material-ui/icons';
import { Wrapper } from '_components/elements';
import masterClient from '_utils/masterClient';
import apolloClient from '_utils/apolloClient';
import AddContact from '_components/AddContact';
import { AddContactForm } from '_components/forms';
import { createValidationSchema, validators } from '_utils/validation';

/* */
const validationSchema = createValidationSchema({
  lastname: validators.required,
  firstname: validators.required,
  email: validators.required,
});
/* */
const styles = (theme) => ({
  tabs: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.dark,
  },
  dialog: {
    backgroundColor: theme.palette.background.default,
  },
  rightButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemSurface: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  listItemSurfaceContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  badge: {
    marginTop: 15,
    width: 10,
    height: 10,
    borderRadius: 5,
  },
  flex: { flex: 1 },
  starIcon: { color: '#FDD835' },
  appBar: { position: 'relative' },
  chip: { margin: theme.spacing.unit },
  searchInput: { marginBottom: theme.spacing.unit * 3 },
});

const TAB_MEMBERS = 0;
const TAB_CONTACTS = 1;
/* */
const Loading = () => (
  <Box display="flex" justifyContent="center">
    <CircularProgress size={32} thickness={4} color="secondary" />
  </Box>
);

const AcademyChoosePartners = (props) => {
  const {
    account,
    classes,
    open,
    onClose,
    partners,
    replacePartner,
    disableOwner,
    contacts,
    setContactsList,
    updateContact,
    app: { golf },
  } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [contact, setContact] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [isMember, setIsMember] = useState(false);
  const [query, setQuery] = useState('');
  const [tab, setTab] = useState(0);
  const [counter, setCounter] = useState(
    partners.reduce((acc, p) => (p.type !== 'anonymous' ? acc + 1 : acc), 0),
  );
  const [totalCount, setTotalCount] = useState(0);
  const [total, setTotal] = useState(partners.length);
  const [noResultFound, setNoResultFound] = useState(false);

  useEffect(() => {
    setIsMember(App.functions.isMember(account, golf));
  }, [account, golf]);

  useEffect(() => {
    const totalSelected = partners.length - 1;
    const counterFinal = partners.reduce(
      (acc, p) => (p.type !== 'anonymous' ? acc + 1 : acc),
      0,
    );
    if (counterFinal - 1 === totalSelected) {
      onClose();
    } else {
      setTotal(totalSelected);
      setCounter(counterFinal - 1);
    }
    return () => {
      setCounter(0);
      setTotal(0);
    };
  }, [partners, onClose]);

  const handleClose = () => onClose();

  const handleAddContact = () => setOpenDialog(!openDialog);

  const searchMembers = _.debounce(() => {
    if (searchValue.trim().length >= 3) {
      setQuery(searchValue);
    }
  }, 1000);

  const { isFetching: memberLoading, data: members } = useQuery(
    ['academy-searched-players', query, golf.id],
    async () => {
      const results = await Academy.api.getCustomerFromPGB(masterClient, {
        search: query,
        limit: 20,
      });
      setNoResultFound(results.items.length === 0);
      setTotalCount(results.totalCount);
      return results.items.map(({
        firstName, lastName, pgbTid, ...m
      }) => ({
        firstname: firstName,
        lastname: lastName,
        reference: pgbTid,
        ...m,
      }));
    },
    {
      enabled: !!query,
      retry: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      initialData: () => [],
    },
  );

  const { data } = useQuery(
    ['academy-contacts'],
    () => Directory.api.getContacts(apolloClient, {}, 'no-cache'),
    {
      enabled: !contacts,
      retry: 0,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    if (data) {
      setContactsList(data.contacts);
    }
  }, [data, setContactsList]);

  useEffect(() => {
    searchMembers();
  }, [searchValue, searchMembers]);

  const handleSelect = async (i, type) => {
    const player = type === 'member'
      ? { ...members[i], isMember: true }
      : { ...contacts[i], isMember: false, reference: 'UNKNOWN' };

    await replacePartner({ ...player, type });
  };

  /* */
  const handleUnselect = async (i, type) => {
    const index = type === 'member'
      ? partners.findIndex((p) => p.reference === members[i].reference)
      : partners.findIndex((p) => p.uid === contacts[i].uid);

    await replacePartner(
      {
        email: null,
        phone: null,
        lastname: null,
        firstname: null,
        isMember: false,
        type: 'anonymous',
        reference: 'UNKNOWN',
      },
      index,
    );
  };

  const renderMember = (m, i) => {
    const refs = partners
      .filter((p) => p.reference !== 'UNKNOWN')
      .map((p) => p.reference);
    const checked = refs.includes(m.reference);

    const isOwner = partners.find(
      (p) => m.reference === p.reference && p.type === 'owner',
    );
    const disabled = isOwner && disableOwner;

    const isFavorite = partners.find((p) => m.reference === p.reference);

    const handleClick = () =>
      (!checked ? handleSelect(i, 'member') : handleUnselect(i, 'member'));

    return (
      <ListItem
        button
        key={i}
        disableRipple
        disabled={disabled}
        onClick={handleClick}
        classes={{ root: classes.listItem }}
      >
        <Checkbox
          disableRipple
          tabIndex={-1}
          checked={checked}
          disabled={disabled}
        />
        <Box elevation={0} className={classes.listItemSurface}>
          <Box elevation={0} className={classes.listItemSurfaceContent}>
            <ListItemText primary={`${m.firstname} ${m.lastname}`} />
            <ListItemText primaryTypographyProps={{ variant: 'caption' }} primary={`${m.email}`} />
          </Box>
          {isFavorite && (
            <ListItemIcon className={classes.starIcon}>
              <StarIcon />
            </ListItemIcon>
          )}
        </Box>
      </ListItem>
    );
  };

  const renderMemberList = () => {
    if (memberLoading) return <Loading />;

    if (noResultFound) {
      return <Typography weight="light">{'Aucun résultat.'}</Typography>;
    }

    if (members.length > 0) {
      return (
        <Paper>
          <List
            style={{
              maxHeight: 300,
              overflow: 'auto',
            }}
            disablePadding
          >
            {members.map(renderMember)}
          </List>
        </Paper>
      );
    }

    return null;
  };

  const renderMemberTab = () => (
    <div>
      <Typography align="center" variant="h5" paragraph>
        {'Rechercher un joueur'}
      </Typography>
      <TextField
        fullWidth
        value={searchValue}
        variant="outlined"
        placeholder="Nom, prénom, ou mail"
        onChange={(e) => setSearchValue(e.target.value)}
        className={classes.searchInput}
        InputProps={{
          id: 'cp-member-search',
          endAdornment: <SearchIcon />,
        }}
      />
      <Typography align="center" variant="caption" paragraph>
        {`Veuillez sélectionner ${total - counter + 1} joueur${
          total - counter > 1 ? 's' : ''
        }.`}
      </Typography>
      {renderMemberList()}
      {totalCount > 20 && (
        <Typography
          align="center"
          variant="caption"
          paragraph
          style={{ marginTop: 20 }}
        >
          {`Seulement 20 joueurs sont affichés sur un total de ${totalCount}. Veuillez affiner votre recherche.`}
        </Typography>
      )}
    </div>
  );
  /* */
  const doEditRequest = (values) => {
    const uids = partners.filter((p) => !!p?.uid).map((p) => p?.uid);
    const checked = uids.includes(contact.uid);
    Directory.api
      .setContact(apolloClient, {
        ...contact,
        ...values,
      })
      .then(() => {
        updateContact({
          ...contact,
          ...values,
        });
        if (!checked) {
          handleSelect(contact.index, 'contact');
        } else {
          handleUnselect(contact.index, 'contact');
        }
        setContact(null);
      });
  };
  /* */
  const renderForm = () => {
    if (contact === null) return null;
    return (
      <React.Fragment>
        <Typography gutterBottom variant="h6" component="h2">
          {contact.uid ? 'Editer le contact' : 'Ajouter un contact'}
        </Typography>
        <Formik
          initialValues={contact}
          onSubmit={doEditRequest}
          enableReinitialize={true}
          component={AddContactForm}
          validationSchema={validationSchema}
        />
      </React.Fragment>
    );
  };
  /* */
  const renderContact = (c, i) => {
    const uids = partners.filter((p) => !!p.uid).map((p) => p.uid);
    const checked = uids.includes(c.uid);

    const handleClick = () => {
      if (!c.email) {
        setContact({
          ...c,
          index: i,
        });
        return;
      }
      if (!checked) {
        handleSelect(i, 'contact');
      } else {
        handleUnselect(i, 'contact');
      }
    };

    return (
      <ListItem
        button
        disableRipple
        key={i}
        onClick={handleClick}
        classes={{ root: classes.listItem }}
      >
        <Badge invisible={!!c.email} color="error" classes={{ badge: classes.badge }} variant="dot">
        <Checkbox disableRipple tabIndex={-1} checked={checked} />
        <Box elevation={0} className={classes.listItemSurface}>
          <Box elevation={0} className={classes.listItemSurfaceContent}>
            <ListItemText primary={`${c.firstname} ${c.lastname}`} />
            <ListItemText primaryTypographyProps={{ variant: 'caption' }} primary={`${c.email}`} />
          </Box>
        </Box>
        </Badge>
      </ListItem>
    );
  };
  /* */
  const renderContactList = () => {
    if (contacts && contacts.length > 0) {
      return (
        <Paper>
          <List disablePadding>{contacts.map(renderContact)}</List>
        </Paper>
      );
    }

    return (
      <Typography weight="light">
        {'Aucun contact dans votre répertoire.'}
      </Typography>
    );
  };
  /* */
  const renderContactTab = () => {
    const title = isMember
      ? 'Choisir un joueur extérieur'
      : 'Choisir un joueur';

    return (
      <Fragment>
        <Box
          mb={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography align="center" variant="h5">
            {title}
          </Typography>
          <Fab
            color="secondary"
            id="cp-contact-add"
            aria-label="Ajouter un contact"
            onClick={handleAddContact}
          >
            <AddIcon />
          </Fab>
        </Box>
        <Typography align="center" variant="caption" paragraph>
          {`Veuillez sélectionner ${total - counter} partenaire(s).`}
        </Typography>
        {renderContactList()}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Dialog
        fullScreen
        open={open}
        classes={{ paperFullScreen: classes.dialog }}
      >
        <AppBar color="primary" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              className={classes.rightButton}
              onClick={handleClose}
              aria-label="Fermer"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              {'Vos partenaires'}
            </Typography>
            <Button color="inherit" onClick={handleClose} id="cp-save">
              {'Enregistrer'}
            </Button>
          </Toolbar>
        </AppBar>
        <Tabs
          value={tab}
          variant="fullWidth"
          className={classes.tabs}
          onChange={(t, val) => setTab(val)}
        >
          <Tab
            id="cp-member-title"
            icon={<ListIcon />}
            label={'Clients du golf'}
          />
          <Tab
            id="cp-contact-title"
            icon={<ContactsIcon />}
            label={'Nouveau Joueur'}
          />
        </Tabs>
        <Wrapper layout="fixed">
          {!contact && tab === TAB_MEMBERS && renderMemberTab()}
          {!contact && tab === TAB_CONTACTS && renderContactTab()}
          {contact && renderForm()}
        </Wrapper>
      </Dialog>
      <AddContact open={openDialog} onClose={handleAddContact} />
    </Fragment>
  );
};

const mapStateToProps = ({ app, directory: { contacts } }) => ({
  app,
  contacts,
  wording: app.golf.options.wording,
});

const StyledComponent = withStyles(styles)(AcademyChoosePartners);

export default connect(mapStateToProps, Directory.actions)(StyledComponent);
