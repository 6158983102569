import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Button,
  Typography,
} from '@material-ui/core';
/* */
import i18n from '_utils/i18n';
import { Screen } from '_components/core';

/* */
const styles = theme => ({
  voucher: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 1,
  },
  paperBottom: {
    display: 'flex',
    borderTop: `1px dashed ${theme.palette.grey[400]}`,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing.unit * 4,
    paddingTop: theme.spacing.unit * 2,
  },
  paperMiddle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing.unit,
  },
  balanceLabel: {
    paddingRight: `${theme.spacing.unit}px`,
    fontWeight: 400,
  },
  body1: {
    ...theme.typography.body2,
    textAlign: 'center',
  },
  table: {
    marginBottom: theme.spacing.unit * 4,
    marginTop: theme.spacing.unit * 2,
  },
  underline: {
    textDecoration: 'underline',
  },
  loaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
    justifyContent: 'center',
  },
});

/* */
class Voucher extends React.Component {
  /* */
  constructor(props) {
    super(props);

    const { accountWallet: { transaction } } = this.props;

    this.redirect = !transaction;
  }

  /* */
  render() {
    const {
      golf,
      account,
      classes,
      accountWallet: { transaction, balance },
    } = this.props;

    if (this.redirect) {
      return <Redirect to="/account/statement" />;
    }

    return (
      <Screen layout="fixed" title="Confirmation de paiement">
        <Typography
          paragraph
          variant="h4"
          align="center">
          {'Merci de votre achat !'}
        </Typography>
        <div className={classes.voucher}>
          <Paper className={classes.paper}>
            <Typography
              gutterBottom
              variant="h5"
              align="center"
              component="h2"
              color="textSecondary">
              {golf.name}
            </Typography>
            <Typography
              align="center"
              variant="body2"
              className={classes.underline}>
              {golf.email}
            </Typography>
          </Paper>
            <Paper className={classes.paper}>
              <Typography
                gutterBottom
                variant="h5"
                align="center"
                component="h2"
                color="textSecondary">
                {'Rechargement de compte client'}
              </Typography>
              <div className={classes.paperMiddle}>
                <Typography
                  gutterBottom
                  variant="h6"
                  align="center"
                  className={classes.balanceLabel}
                >
                  {'Votre nouveau solde :'}
                </Typography>
                <Typography
                  gutterBottom
                  variant="h5"
                  align="center"
                  color="textSecondary">
                  { i18n.l('currency', balance / 100) }
                </Typography>
              </div>
              <p className={classes.body1}>
                {i18n.t('membership.messages.sent_confirm_email')} <span className={classes.underline}>{account.email}</span>
              </p>
              {transaction && (
                <div className={classes.paperBottom}>
                  <div>
                    <Typography gutterBottom>{'Total'}</Typography>
                    <Typography color="textSecondary">
                      {i18n.l('currency', transaction.amount / 100)}
                    </Typography>
                  </div>
                    <div>
                      <Typography gutterBottom>{'E-ticket'}</Typography>
                      <Typography color="textSecondary">
                        {transaction.transactionNo}
                      </Typography>
                    </div>
                </div>
              )}
            </Paper>
        </div>
        <Button
          fullWidth
          size="large"
          color="secondary"
          variant="contained"
          onClick={() => this.props.history.push('/account/statement')}>
          {'Retour'}
        </Button>
      </Screen>
    );
  }
}

const mapStateToProps = ({ app, accountWallet }) => ({
  golf: app.golf,
  account: app.account,
  accountWallet,
});

const StyledComponent = withStyles(styles)(Voucher);

export default connect(
  mapStateToProps,
)(StyledComponent);
