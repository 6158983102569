import React from 'react';
import { colors } from '@material-ui/core';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';
import i18n from '_utils/i18n';

/* */
const styles = StyleSheet.create({
  flex1: { flex: 1 },
  flex4: { flex: 4 },
  header: {
    marginBottom: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerText: {
    fontSize: 9,
  },
  page: {
    padding: 20,
  },
  trow: {
    paddingVertical: 8,
    flexDirection: 'row',
    borderBottomWidth: 1,
  },
  thead: {
    fontSize: 11,
  },
  alignRight: {
    textAlign: 'right',
  },
  text: {
    fontSize: 10,
    marginBottom: 4,
  },
  rowHead: {
    backgroundColor: colors.grey[100],
    paddingHorizontal: 4,
  },
  textDetails: {
    fontSize: 10,
    color: colors.grey[500],
  },
});

/* */
const StatementPDF = ({ golf, account, receipts }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View fixed style={styles.header}>
        <View>
          <Text style={styles.headerText}>
            {golf.name}
          </Text>
          <Text style={styles.headerText}>
            {`Relevé de compte au ${i18n.l('date.formats.default', new Date())}`}
          </Text>
        </View>
        <View>
          <Text style={styles.headerText}>
            {`${account.firstname} ${account.lastname}`}
          </Text>
          <Text
            style={[styles.headerText, styles.alignRight]}
            render={({ pageNumber, totalPages }) => (
              `Page ${pageNumber} / ${totalPages}`
            )} fixed />
        </View>
      </View>
      <View style={[styles.trow, styles.rowHead]} fixed>
        <Text style={[styles.flex1, styles.thead]}>{'Date'}</Text>
        <Text style={[styles.flex4, styles.thead]}>{'Libellé'}</Text>
        <Text style={[styles.flex1, styles.thead, styles.alignRight]}>{'Débit'}</Text>
        <Text style={[styles.flex1, styles.thead, styles.alignRight]}>{'Crédit'}</Text>
        <Text style={[styles.flex1, styles.thead, styles.alignRight]}>{'Solde'}</Text>
      </View>
      {Object.keys(receipts).map((date, i) => (
        <View key={i}>
          {receipts[date] && receipts[date].map((r, j) => {
            const debit = r.total < 0 ? i18n.l('currency', r.total / 100) : null;
            const credit = r.total > 0 ? i18n.l('currency', r.total / 100) : null;

            return (
              <View
                key={j}
                wrap={false}
                style={styles.trow}>
                <Text style={[styles.flex1, styles.text]}>
                  {i18n.l('date.formats.default', new Date(date))}
                </Text>
                <View style={styles.flex4}>
                  <Text style={styles.text}>
                    {r.name.toUpperCase()}
                  </Text>
                  {r.details.map((line, k) => (
                    <Text
                      key={k}
                      style={styles.textDetails}>
                      {line.name.toUpperCase()}
                    </Text>
                  ))}
                </View>
                <View style={styles.flex1}>
                  <Text style={[styles.text, styles.alignRight]}>{debit}</Text>
                  {debit && r.details.map((line, k) => (
                    <Text key={k} style={[styles.textDetails, styles.alignRight]}>
                      {(line.amount && i18n.l('currency', (-1) * line.amount / 100)) || '-'}
                    </Text>
                  ))}
                </View>
                <View style={styles.flex1}>
                  <Text style={[styles.text, styles.alignRight]}>{credit}</Text>
                </View>
                <View style={styles.flex1}>
                  <Text style={[styles.text, styles.alignRight]}>
                    {i18n.l('currency', r.balance / 100)}
                  </Text>
                </View>
              </View>
            );
          })}
        </View>
      ))}
    </Page>
  </Document>
);

export default StatementPDF;
