import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { App, Event } from '@aps-management/primapp-common';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  Grid,
  List,
  Paper,
  Button,
  Select,
  ListItem,
  MenuItem,
  Typography,
  FormControl,
  ListItemText,
  CircularProgress,
} from '@material-ui/core';
/* */
import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import PaymentMethods from '_components/PaymentMethods';
/* */
import Header from '../_Header';

/* */
/* */
const styles = theme => ({
  section: {
    margin: `${theme.spacing.unit * 1}px 0`,
  },
  subtotal: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
});

/* */
class Cart extends React.Component {
  /* */
  constructor(props) {
    super(props);

    const {
      account,
      enter,
      golf,
      match: { params },
    } = props;

    this.id = params.id;
    this.redirect = false;
    this.eventIdx = 0;

    if (!enter.status.includes('init')) {
      this.redirect = true;
      return;
    }

    const event = enter.events[this.eventIdx];
    // empty options fees array
    event.options = event.options.map(opt => ({ ...opt, fees: [] }));

    // payment methods
    this.paymentMethods = App.functions.getPaymentMethods(account, golf, 'event');
    const paymentMethod = this.paymentMethods[0];

    this.state = {
      // UI
      error: null,
      loading: true,
      // Moyens de paiment / CGV
      paymentMethod,
      paymentMethodOpts: null,
      areAcceptedTerms: false,
      // Data
      waiting: false,
      getEntryFees: false,
    };
  }

  /* */
  componentDidMount() {
    if (!this.redirect) this.load();
  }

  /* */
  goToPayment = () => {
    this.propagatePaymentMethod();
    this.props.enter.status.push('cart');
    this.props.history.push(`/event/${this.id}/payment`);
  }

  /* */
  goToVoucher = () => {
    this.props.enter.status.push('payment');
    this.props.history.push(`/event/${this.id}/voucher`);
  }

  /* */
  propagatePaymentMethod() {
    const { paymentMethod } = this.state;
    const { enter: { entrants } } = this.props;

    // set payment method
    entrants.forEach((e, i) => {
      entrants[i].events.forEach((ev, j) => {
        entrants[i].events[j].type = paymentMethod;
      });
    });
  }

  /* */
  onConfirm = () => {
    const { enter, golf } = this.props;
    const { paymentMethod, paymentMethodOpts } = this.state;

    this.setState({ error: null, waiting: true });

    this.propagatePaymentMethod();
    const totalAmount = enter.total.events;
    const transaction = {
      amount: totalAmount,
      mode: paymentMethod,
      ...(paymentMethod === 2 && { walletId: paymentMethodOpts.wallet.id }),
    };

    return Event.functions.asyncSetEntry(apolloClient, {
      enter,
      golf,
      transaction,
    })
      .then(() => {
        enter.transaction = transaction;
        this.goToVoucher();
      })
      .catch((err) => {
        this.setState({ error: err.message, waiting: false });
      });
  }

  /* */
  hydrateEntrant(index, option) {
    const { eventIdx } = this;
    const { enter } = this.props;
    const event = enter.events[this.eventIdx];

    enter.entrants[index].events[eventIdx] = {
      type: 0, // payment method (0: immediate, 1: deferred)
      id: event.id,
      name: event.name.toUpperCase(),
      amount: option.fees[index],
      hasManyOptions: event.options.length > 1,
      option: {
        id: option.id,
        name: option.name,
      },
    };
  }

  /* */
  calculateTotal() {
    const { enter } = this.props;

    enter.total.events = enter.entrants.reduce((total, entrant) => (total
       + (entrant.events && entrant.events.reduce((acc, e) => (acc + e.amount), 0))
    ), 0);
  }

  /* */
  load() {
    const { enter, golf } = this.props;
    const { entrants } = enter;
    const event = enter.events[this.eventIdx];

    // Reset state
    this.setState({
      error: null,
      loading: true,
      getEntryFees: false,
    });

    Event.api.getEntryFees(apolloClient, {
      golfId: golf.id,
      eventId: event.id,
      players: entrants.map(e => e.reference),
    })
      .then(({ fees }) => {
        // critical error
        if (!fees || !fees.length) throw new Error('errors.internal');

        // build fees object by player ref
        const feesByRef = fees.reduce((acc, { player: reference, ...f }) => {
          if (typeof acc[reference] === 'undefined') {
            acc[reference] = f;
          }
          return acc;
        }, {});

        // hydrate entrants
        entrants.forEach((e, eIdx) => {
          const { events } = feesByRef[e.reference];

          // hydrate events with options player fees
          events.forEach((ev, evIdx) => {
            enter.events[evIdx].options.forEach((opt, j) => opt.fees
              && opt.fees.push(ev.options[j].amount));
          });

          e.events = []; // no event options selected yet
          e.tournament = null;

          // set unique option by default
          if (event.options.length === 1) {
            this.hydrateEntrant(eIdx, event.options[0]);
          }
        });

        // calculate total (events + tournament)
        this.calculateTotal();

        this.setState({ getEntryFees: true });
      })
      .catch(e => this.setState({ error: e.message }))
      .finally(() => this.setState({ loading: false }));
  }

  /* */
  handleChangePaymentMethod = (type, opts) => {
    this.setState({
      paymentMethod: type,
      paymentMethodOpts: opts,
    });
  }

  /* */
  handleChangeAcceptTerms = checked => this.setState({ areAcceptedTerms: checked });

  /* */
  renderPaymentMethod() {
    const { classes, enter } = this.props;
    const { waiting, paymentMethod, areAcceptedTerms } = this.state;

    const event = enter.events[this.eventIdx];
    const totalAmount = enter.total.events;
    const buttonTxt = `${(paymentMethod === 1 ? 'Valider' : 'Payer')}`
      + ` ${i18n.l('currency', totalAmount / 100)}`;
    const cancelableUntil = event.cancelableUntil
      && moment(event.cancelableUntil, 'YYYY-MM-DD HH:mm');

    return (
      <Grid
        container
        spacing={24}
        justify="center">
        <Grid
          item md={6} xs={12}
          className={classes.section}>
          <Typography
            paragraph
            component="h3"
            variant="h6">
            {'Votre moyen de paiement'}
          </Typography>
          <PaymentMethods
            selected={paymentMethod}
            amount={totalAmount}
            methods={this.paymentMethods}
            onChange={this.handleChangePaymentMethod}
            onChangeAcceptTerms={this.handleChangeAcceptTerms}
          />
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          className={classes.section}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}>
          {cancelableUntil && (
            <Typography gutterBottom variant="caption" align="center">
              {`L'inscription est annulable jusqu'au ${cancelableUntil.format('DD/MM/YYYY')} à ${cancelableUntil.format('HH:mm')}.`}
            </Typography>
          )}
          <Button
            fullWidth
            size="large"
            color="secondary"
            variant="contained"
            disabled={waiting || !areAcceptedTerms}
            onClick={paymentMethod === 0 ? this.goToPayment : this.onConfirm}>
            {waiting ? <CircularProgress color="inherit" size={24} /> : buttonTxt}
          </Button>
        </Grid>
      </Grid>
    );
  }

  /* */
  renderNextButton() {
    const { eventIdx } = this;
    const { getEntryFees, waiting } = this.state;
    const { enter: { entrants, total } } = this.props;

    if (!getEntryFees) return null;

    const totalAmount = total && total.events;
    const disabled = !entrants.every(e => (e.events[eventIdx] && e.events[eventIdx].option));

    if (disabled) return null;
    if (totalAmount > 0) return this.renderPaymentMethod();

    return (
      <Box py={2}>
        <Button
          fullWidth
          size="large"
          color="secondary"
          onClick={this.onConfirm}
          disabled={disabled || waiting}
          variant="contained">
          {waiting ? <CircularProgress color="inherit" size={24} /> : 'Confirmer'}
        </Button>
      </Box>
    );
  }

  /* */
  onChange = entrantIdx => (ev) => {
    const { eventIdx } = this;
    const { enter } = this.props;
    const event = enter.events[eventIdx];

    const optionId = ev.target.value;

    if (!enter.entrants[entrantIdx]) return;
    if (!enter.entrants[entrantIdx].events) return;
    // if (!enter.entrants[entrantIdx].events[eventIdx]) return;

    // remove event
    if (Number(optionId) === 0) {
      delete enter.entrants[entrantIdx].events[eventIdx];
    } else {
      // hydrate entrant events
      const option = event.options.find(opt => opt.id === optionId);
      this.hydrateEntrant(entrantIdx, option);
    }

    // calculate total (events + tournament)
    this.calculateTotal();

    // re-render
    this.forceUpdate();
  }

  /* */
  renderOption = ({ name }, i) => (
    <ListItem divider key={i}>
      <ListItemText>
        {`Option ${i + 1}`}
      </ListItemText>
      <Typography
        variant="body1"
        color="textSecondary">
        {name}
      </Typography>
    </ListItem>
  )

  /* */
  renderDropdown = (e, i) => {
    const { eventIdx } = this;
    const { classes, enter: { entrants, events } } = this.props;

    const current = entrants[i]
      && entrants[i].events
      && entrants[i].events[eventIdx];

    const value = current ? current.option.id : 0;
    const data = events[eventIdx].options.reduce((acc, opt, j) => {
      const fees = (opt.fees[i] === 0)
        ? i18n.t('terms.included')
        : i18n.l('currency', (opt.fees[i] / 100) || 0);

      acc.push({
        value: opt.id,
        label: `Option ${j + 1} (${fees})`,
      });

      return acc;
    }, [{ value: 0, label: 'Choisir une option' }]);

    return (
      <ListItem key={i} divider>
        <ListItemText>
          {`${e.firstname} ${e.lastname}`}
        </ListItemText>
        <FormControl className={classes.formControl}>
          <Select
            value={value}
            onChange={this.onChange(i)}
            classes={{ select: classes.menuItem }}>
            {data.map((item, idx) => (
              <MenuItem
                key={idx}
                value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ListItem>
    );
  }

  /* */
  renderSubtotal() {
    const { eventIdx } = this;
    const { classes, enter } = this.props;

    // calculate subtotal event
    const subtotal = enter.entrants.reduce((total, entrant) => (total
       + (entrant.events?.[eventIdx]?.amount || 0)), 0);

    if (subtotal === 0) return null;

    return (
      <ListItem className={classes.subtotal}>
        <ListItemText
          primary="Sous-total"
          primaryTypographyProps={{ color: 'inherit' }} />
        <Typography
          variant="subtitle1"
          color="inherit">
          {i18n.l('currency', subtotal / 100)}
        </Typography>
      </ListItem>
    );
  }

  /* */
  render() {
    if (this.redirect) {
      return <Redirect to={`/event/${this.id}`} />;
    }

    const { error, loading } = this.state;
    const { enter: { entrants, events }, golf } = this.props;
    const event = events[this.eventIdx];

    return (
      <Screen
        error={error}
        loading={loading}
        title={i18n.getFromOpts('event_signin.title', golf.options)}
        onBackPress={() => this.props.history.goBack()}>
        <Header event={event} />
        <Typography
          color="secondary"
          variant="body1">
          {event.name.toUpperCase()}
        </Typography>
        <br />
        <Paper>
          <List disablePadding>
            {event.options.map(this.renderOption)}
          </List>
        </Paper>
        <br /><br />
        <Typography variant="body1">
          {'Qui participera ?'}
        </Typography>
        <br />
        <Paper>
          <List disablePadding>
            {entrants.map(this.renderDropdown)}
            {this.renderSubtotal()}
          </List>
        </Paper>
        <br />
        <br />
        {this.renderNextButton()}
      </Screen>
    );
  }
}

const mapStateToProps = ({
  app: {
    account,
    golf,
  },
  eventEnter: enter,
}) => ({ account, golf, enter });

const StyledComponent = withStyles(styles)(Cart);

export default connect(
  mapStateToProps,
  null,
)(StyledComponent);
