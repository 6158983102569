import * as qs from 'query-string';
import React, { useEffect, useRef } from 'react';

import config from '_config';

/* */
const sanitize = (string, length) => (
  String(string)
    .replace(/\(.*?\)/g, '') // enlève les parenthèses et leur contenu
    .match(/\w+/g) // tous les mots
    .join(' ')
    .substring(0, length)
    .trim()
);

/* */
const formatPhone = (rawPhone) => {
  // par défaut
  let formatted = '+33600000000';
 
  // on conserve uniquement + et 0-9
  const sanitized = String(rawPhone).replace(/[^+0-9]/g, '');

  // check commençant par 06 ou 07
  if (['06', '07'].includes(sanitized.substring(0, 2)) && sanitized.length === 10) {
    formatted = `+33${sanitized.substring(1)}`;
  }

  // check commençant par +33
  if (sanitized.substring(0, 3) === '+33' && sanitized.length === 12) {
    formatted = sanitized;
  }

  return formatted;
};

/* */
const SubmitForm3DS = ({ location }) => {
  const queryParams = qs.parse(location.search);
  const { sessionId } = queryParams;

  const key = `cart_${sessionId}`;

  const data = sessionStorage.getItem(key);
  const {
    amount,
    address,
    customer,
    creditCard,
    currency = 978, // Euro (norme ISO 4217)
    totalQuantity = 1,
  } = JSON.parse(data);

  // Remove item for security
  sessionStorage.removeItem(key);

  const phone = formatPhone(customer.phone);
  const lastName = sanitize(customer.lastName, 20);
  const firstName = sanitize(customer.firstName, 20);

  const formRef = useRef(null);
  useEffect(() => {
    formRef.current.submit();
  }, []);

  return (
    <form
      ref={formRef}
      method="post"
      action={config.paybox.remoteMPI}>
      <input type="hidden" name="City" value={address.city} />
      <input type="hidden" name="Address1" value={address.line1} />
      <input type="hidden" name="ZipCode" value={address.zipCode} />
      <input type="hidden" name="CountryCode" value={address.countryCode} />
      <input type="hidden" name="NumTelephone" value={phone} />
      <input type="hidden" name="EmailPorteur" value={customer.email} />
      <input type="hidden" name="LastName" value={lastName} />
      <input type="hidden" name="FirstName" value={firstName} />
      <input type="hidden" name="Amount" value={amount} />
      <input type="hidden" name="Currency" value={currency} />
      <input type="hidden" name="IdSession" value={sessionId} />
      <input type="hidden" name="IdMerchant" value={config.paybox.id} />
      <input type="hidden" name="TotalQuantity" value={totalQuantity} />
      <input type="hidden" name="CVVCode" value={creditCard.cvv} />
      <input type="hidden" name="CCNumber" value={creditCard.number} />
      <input type="hidden" name="CCExpDate" value={creditCard.dueDate} />
      <input type="hidden" name="URLRetour" value={`${config.masterBasePath}/paybox/3ds?source=webapp`} />
      <input type="hidden" name="URLHttpDirect" value={`${config.masterBasePath}/paybox/3ds`} />
      <input type="hidden" name="ChallengeIndicator" value="04" />
    </form>
  );
};

export default SubmitForm3DS;
