import React from 'react';
import { Formik } from 'formik';
import { App } from '@aps-management/primapp-common';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {
  Link,
  Paper,
  Avatar,
  Typography,
} from '@material-ui/core';
import { Screen } from '_components/core';
import { LoginForm } from '_components/forms';
import { createValidationSchema, validators } from '_utils/validation';

/* */
const validationSchema = createValidationSchema({
  email: validators.email,
  password: validators.unsecuredPassword,
});

/* */
const styles = theme => ({
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: theme.spacing.unit * 4,
    padding: theme.spacing.unit * 3,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  avatar: {
    marginBottom: theme.spacing.unit * 2,
    backgroundColor: theme.palette.secondary.main,
  },
});

/* */
class Login extends React.Component {
  /* */
  state = {
    error: null,
    success: null,
  };

  /* */
  onSubmit = (values, { setSubmitting }) => {
    this.setState({ error: null });

    App.api.signIn(values.email, values.password)
      .catch((e) => {
        this.setState({ error: e.message });
        setSubmitting(false);
      });
  };

  /* */
  render() {
    const { classes, golf } = this.props;
    const { error, success } = this.state;

    return (
      <Screen
        noDrawer
        error={error}
        layout='fixed'
        success={success}>
        <Typography component="h1" variant="h5" align="center">
          {golf && golf.name}
        </Typography>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography gutterBottom component="h2" variant="h5">
            {'Identification'}
          </Typography>
          <br />
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            component={LoginForm}
            onSubmit={this.onSubmit}
            validationSchema={validationSchema} />
        </Paper>
        <Box textAlign="center" mb={2}>
          <Typography>
            {'Vous êtes visiteur ?'}
          </Typography>
          <Link
            to="/register"
            color="textSecondary"
            component={RouterLink}>
            {'Inscrivez-vous'}
          </Link>
        </Box>
        <Box textAlign="center">
          <Link
            to="/forgot"
            color="textSecondary"
            component={RouterLink}>
            {'Mot de passe oublié ?'}
          </Link>
        </Box>
      </Screen>
    );
  }
}

const mapStateToProps = ({ app }) => ({ golf: app.golf });

export default connect(mapStateToProps)(withStyles(styles)(Login));
