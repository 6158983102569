import _ from 'lodash';
import React from 'react';
import * as Rx from 'rxjs';
import { connect } from 'react-redux';
import { App, Directory } from '@aps-management/primapp-common';
import Autosuggest from 'react-autosuggest';
import { Redirect, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import { PhoneOutlined, EmailOutlined } from '@material-ui/icons';
import {
  Grid,
  Link,
  Paper,
  Avatar,
  MenuItem,
  TextField,
  Typography,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';

/* */
const styles = theme => ({
  suggestion: { display: 'block' },
  container: { position: 'relative' },
  icon: { marginRight: theme.spacing.unit * 1 },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  paper: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
  },
  hcp: {
    width: 56,
    height: 56,
    float: 'right',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.dark,
  },
});

/* */
class SearchMembers extends React.Component {
  /* */
  state = {
    // Screen
    error: null,
    // Data
    text: '',
    member: null,
    empty: false,
    waiting: false,
    suggestions: [],
  };

  /* */
  searchDataSource = null;

  /* */
  componentDidMount() {
    const { account, golf } = this.props;

    // Redirect if not member in the golf
    this.redirect = !(App.functions.isMember(account, golf));
    // Change route listener
    if (!this.redirect) this.props.history.listen(this.unsubscribe.bind(this));
  }

  /* */
  unsubscribe() {
    if (this.searchDataSource) this.searchDataSource.unsubscribe();
    this.searchDataSource = null;
  }

  /* */
  onSelect(member) {
    this.setState({
      member,
      error: null,
    });
  }

  /* */
  handleSuggestionsFetchRequested = _.debounce(({ value }) => {
    if (value.trim().length >= 3) {
      const { golf } = this.props;
      this.unsubscribe();
      this.setState({ error: null, waiting: true });
      this.searchDataSource = Rx
        .from(Directory.api.searchMembers(
          apolloClient,
          {
            limit: 20,
            name: value,
            golfId: golf.id,
          },
          'no-cache',
        ))
        .subscribe(
          ({ members = [] }) => {
            const empty = (members.length === 0);
            this.setState(state => ({
              empty,
              member: (empty) ? null : state.member,
              suggestions: members.sort(Directory.functions.sortMembers),
            }));
          },
          (e) => { this.setState({ error: e.message }); },
          () => { this.setState({ waiting: false }); },
        );
    }
  }, 500);

  /* */
  renderInputComponent = (inputProps) => {
    const { text, waiting, empty } = this.state;
    const {
      classes, inputRef = () => {}, ref, ...other
    } = inputProps;

    let helperText = '';
    if (waiting) {
      helperText = 'Patientez...';
    } else if (text.length < 3) {
      helperText = '3 caractères minimum';
    } else if (empty) {
      helperText = 'Aucun résultat';
    }

    return (
      <TextField
        fullWidth
        variant="outlined"
        helperText={helperText}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          inputRef: (node) => {
            ref(node);
            inputRef(node);
          },
          classes: {
            input: classes.input,
          },
          endAdornment:
            waiting ? (
              <InputAdornment position="end">
                <CircularProgress color="inherit" size={16} />
              </InputAdornment>
            ) : null,
        }}
        {...other}
      />
    );
  }

  /* */
  renderSuggestion = (suggestion, { isHighlighted }) => (
    <MenuItem selected={isHighlighted} component="div">
      <span>{`${suggestion.firstname} ${suggestion.lastname}`}</span>
    </MenuItem>
  );

  /* */
  handleSuggestionsClearRequested = () => { this.setState({ suggestions: [] }); };

  /* */
  handleChange = (event, { newValue }) => { this.setState({ text: newValue }); };

  /* */
  renderCard(member) {
    const { classes } = this.props;

    return (
      <Paper className={classes.paper}>
        {Boolean(member.index) && (
          <Avatar className={classes.hcp}>{member.index.toFixed(1)}</Avatar>
        )}
        <Typography
          color="textSecondary"
          component="p"
          variant="h6">
          {member.firstname} {member.lastname}
        </Typography>
        {member.membersince && (
          <Typography
            component="span"
            variant="caption">
            {`Membre depuis ${member.membersince}`}
          </Typography>
        )}
        {member.email && (
          <Box my={1} display="flex" alignItems="center">
            <EmailOutlined
              color="secondary"
              className={classes.icon} />
            <Link
              color="secondary"
              underline="hover"
              variant="subtitle1"
              href={`mailto:${member.email}`}>
                {member.email}
            </Link>
          </Box>
        )}
        {member.phone && (
          <Box my={1} display="flex" alignItems="center">
            <PhoneOutlined
              color="secondary"
              className={classes.icon} />
            <Link
              color="secondary"
              underline="hover"
              variant="subtitle1"
              href={`tel:${member.phone}`}>
                {member.phone}
            </Link>
          </Box>
        )}
        {member.cellphone && (
          <Box my={1} display="flex" alignItems="center">
            <PhoneOutlined
              color="secondary"
              className={classes.icon} />
            <Link
              color="secondary"
              underline="hover"
              variant="subtitle1"
              href={`tel:${member.cellphone}`}>
                {member.cellphone}
            </Link>
          </Box>
        )}
        {member.address && (
          <Typography
            component="p"
            variant="body1">
            {member.address}
          </Typography>
        )}
        {member.city && (
          <Typography
            component="p"
            variant="body1">
            {member.city}
          </Typography>
        )}
        {member.country && (
          <Typography
            component="p"
            variant="body1">
            {member.country}
          </Typography>
        )}
      </Paper>
    );
  }

  /* */
  render() {
    if (this.redirect) {
      return <Redirect to="/home" />;
    }

    const { classes, wording } = this.props;
    const {
      text,
      error,
      member,
      suggestions,
    } = this.state;

    return (
      <Screen
        error={error}
        title={i18n.t(`directory.titles.members_${wording.member}`)}>
        <Grid container spacing={40}>
          <Grid item md={5} xs={12}>
            <Autosuggest
              suggestions={suggestions}
              renderSuggestion={this.renderSuggestion}
              renderInputComponent={this.renderInputComponent}
              onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
              onSuggestionSelected={(e, { suggestion }) => this.onSelect(suggestion)}
              getSuggestionValue={suggestion => `${suggestion.firstname} ${suggestion.lastname}`}
              inputProps={{
                classes,
                value: text,
                label: 'Recherche',
                onChange: this.handleChange,
                placeholder: 'Nom ou prénom',
              }}
              theme={{
                container: classes.container,
                suggestion: classes.suggestion,
                suggestionsList: classes.suggestionsList,
                suggestionsContainerOpen: classes.suggestionsContainerOpen,
              }}
              renderSuggestionsContainer={options => (
                <Paper {...options.containerProps} square>
                  {options.children}
                </Paper>
              )}
            />
          </Grid>
          <Grid item md={7} xs={12}>
            {member && this.renderCard(member)}
          </Grid>
        </Grid>
      </Screen>
    );
  }
}

const mapStateToProps = ({ app: { account, golf } }) => ({
  golf,
  account,
  wording: golf && golf.options && golf.options.wording,
});

const StyledComponent = withStyles(styles)(withRouter(SearchMembers));

export default connect(mapStateToProps)(StyledComponent);
