import React from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
/* */
import i18n from '_utils/i18n';
import { CompleteCreditCardForm } from '_components/forms';
import { Alert, DialogPayment3DS } from '_components/elements';
import { createValidationSchema, validators } from '_utils/validation';

/* */
const validationSchema = createValidationSchema({
  cvv: validators.cvv,
  dueDate: validators.dateFourDigits,
});

/* */
const styles = theme => ({
  paper: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
  },
  panel: {
    alignItems: 'center',
    flexDirection: 'column',
  },
  radio: {
    padding: 0,
    marginRight: theme.spacing.unit * 2,
  },
  marginT: {
    marginTop: theme.spacing.unit * 2,
  },
});

/* */
class RecurringPayment extends React.Component {
  /* */
  static defaultProps = {
    currency: 'EUR',
    authorizationAmount: 5000,
    session3DS: null, // required
  };

  /* */
  constructor(props) {
    super(props);

    this.state = {
      // data 3DS
      error: null,
      sessionId: null,
      showIframe: false,
    };

    this.id3D = null;
    this.imprintRequired = true;
    this.form = React.createRef();
  }

  /* */
  componentDidMount() {
    // add listener 3ds posted response
    window.addEventListener('message', this.handleMesssage3DS);
  }

  /* */
  componentWillUnmount() {
    // clean listener
    window.removeEventListener('message', this.handleMesssage3DS);
  }

  // Catch iframe 3DS message
  handleMesssage3DS = (event) => {
    if (!event.data.type || event.data.type !== 'response3DS') return;

    const { status, id3D } = event.data;

    if (status === 'success') {
      this.id3D = id3D;
      this.setState({ showIframe: false });
      this.form.current.props.submitForm();
    } else {
      this.handleError3DS();
    }
  }

  /* */
  handleError3DS() {
    this.id3D = null;
    this.form.current.props.setSubmitting(false);
    this.setState({
      showIframe: false,
      error: "Erreur d'authentification 3D Secure.",
    });
  }

  /* */
  getCardInfos = () => this.form.current.getExtraInfos();

  /* */
  onFormSubmit = (values, actions) => {
    const {
      session3DS,
      authorizationAmount,
      onSubmit,
    } = this.props;
    const { id3D } = this;

    const newValues = {
      ...values,
      ...this.getCardInfos(),
      id3D,
    };

    if (id3D !== null) {
      onSubmit(newValues, actions);
      return;
    }

    // must be unique
    const sessionId = authorizationAmount
      + Number(moment().format('x'))
      + Math.floor(Math.random() * (10 ** 12));

    // store temporarily data
    sessionStorage.setItem(`cart_${sessionId}`, JSON.stringify({
      ...session3DS,
      id: sessionId,
      amount: authorizationAmount,
      creditCard: {
        cvv: values.cvv,
        number: values.number.replace(/\s/g, ''),
        dueDate: values.dueDate.replace(/\D/g, ''),
      },
    }));

    // load iframe 3DS
    this.setState({
      sessionId,
      error: null,
      showIframe: true,
    });
  }

  /* */
  renderCardForm() {
    const { error } = this.state;
    const { authorizationAmount } = this.props;

    const submitText = 'Enregistrer';
    const submitHintText = `Dans le cadre de la réglementation DSP2, une demande
      d'autorisation seule d'un montant de ${i18n.l('currency', authorizationAmount / 100)} sera effectué auprès de votre banque.`;

    return (
      <React.Fragment>
        {error && (
          <Alert variant="error">{error}</Alert>
        )}
        <Formik
          initialValues={{
            cvv: '',
            number: '',
            dueDate: '',
          }}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={this.onFormSubmit}
          validationSchema={validationSchema}>
          {props => (
            <CompleteCreditCardForm
              {...props}
              innerRef={this.form}
              imprintUse="required"
              submitButtonText={submitText}
              submitButtonHintText={submitHintText} />
          )}
        </Formik>
      </React.Fragment>
    );
  }

  /* */
  renderDialog3DS() {
    const { sessionId, showIframe } = this.state;

    return (
      <DialogPayment3DS
        open={showIframe}
        sessionId={sessionId}
        onClose={() => this.handleError3DS()} />
    );
  }

  /* */
  render() {
    const { currency } = this.props;

    // Seule la devise EUR est acceptée
    if (currency !== 'EUR') {
      return (
        <Alert variant="warning">
          {`Actuellement, la devise ${currency} n'est pas acceptée pour le paiement en ligne.`}
        </Alert>
      );
    }

    return (
      <React.Fragment>
        {this.renderCardForm()}
        {this.renderDialog3DS()}
      </React.Fragment>
    );
  }
}

const PaymentWithRouter = withRouter(RecurringPayment);

export default withStyles(styles)(PaymentWithRouter);
