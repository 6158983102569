import React from 'react';
import { helpers } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import i18n from '_utils/i18n';
import { Screen } from '_components/core';

/* */
const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    fontSize: 100,
    marginBottom: theme.spacing.unit * 2,
  },
});

const getError = (error) => {
  const trimed = helpers.string.handleError(error.message || error || '');
  return i18n.t(trimed);
};

/* */
const Error = (props) => (
  <Screen noDrawer>
    <div className={props.classes.wrapper}>
      <ErrorOutlineIcon
        className={props.classes.icon}
        fontSize="inherit" />
      <Typography
        component="h1"
        gutterBottom
        variant="h4">
        {'Oups !'}
      </Typography>
      <Typography
        component="p"
        gutterBottom
        variant="subtitle1">
        {getError(props.error || 'Erreur')}
      </Typography>
      {props.retry && (
        <Button onClick={props.retry}>
          {'Réessayer'}
        </Button>
      )}
    </div>
  </Screen>
);

const StyledComponent = withStyles(styles)(Error);

export default StyledComponent;
