import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import i18n from '_utils/i18n';

/* */
const styles = theme => ({
  textField: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  submitButton: {
    marginTop: theme.spacing.unit * 2,
  },
});

/* */
class ChangePasswordForm extends React.Component {
  /* */
  render() {
    const {
      errors,
      values,
      classes,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
      isSubmitting,
    } = this.props;

    return (
      <form autoComplete="off">
        <TextField
          required
          fullWidth
          name="password"
          type="password"
          label="Nouveau"
          variant="outlined"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.password}
          error={!!(errors.password)}
          className={classes.textField}
          helperText={!!(errors.password) && 'Au moins 1 chiffre, 1 majuscule et 1 minuscule.'}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          required
          fullWidth
          type="password"
          variant="outlined"
          onBlur={handleBlur}
          label="Confirmation"
          name="passwordConfirm"
          onChange={handleChange}
          className={classes.textField}
          value={values.passwordConfirm}
          error={!!(touched.passwordConfirm && errors.passwordConfirm)}
          helperText={touched.passwordConfirm && errors.passwordConfirm}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          fullWidth
          size="large"
          color="secondary"
          variant="contained"
          onClick={handleSubmit}
          disabled={isSubmitting}
          className={classes.submitButton}>
          {isSubmitting
            ? <CircularProgress color="inherit" size={24} />
            : i18n.t('default.actions.save')}
        </Button>
      </form>
    );
  }
}
export default withStyles(styles)(ChangePasswordForm);
