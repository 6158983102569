import React from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  App,
  Account,
  helpers,
  HELP_URL,
} from '@aps-management/primapp-common';
import {
  Grid,
  Paper,
  Avatar,
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from '@material-ui/core';
/* */
import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import { Alert } from '_components/elements';
import apolloClient from '_utils/apolloClient';
import { createValidationSchema, validators } from '_utils/validation';
import {
  EditProfileForm,
  EditAddressForm,
  ChangePrivacyForm,
} from '_components/forms';

/* */
const styles = theme => ({
  panel: {
    paddingTop: 0,
    display: 'inherit',
  },
  subtitle: {
    color: theme.palette.primary.main,
  },
  paper: {
    marginBottom: theme.spacing.unit * 3,
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
  },
  hcpDate: {
    color: theme.palette.grey[400],
  },
  hcp: {
    width: 56,
    height: 56,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.dark,
  },
});

/* */
const validationSchema = {
  profileForm: createValidationSchema({
    email: validators.email,
    phone: validators.required,
    lastname: validators.required,
    firstname: validators.required,
  }),
  addressForm: createValidationSchema({
    city: validators.required,
    address: validators.required,
    postcode: validators.required,
  }),
};

/* */
const compareAddress = (previous, current) => {
  const currentAddress = Object.keys(current)
    .reduce((obj, key) => ({ ...obj, [key]: current[key].trim() }), {});

  return JSON.stringify(previous) === JSON.stringify(currentAddress);
};

/* */
const formatInitialValue = value => (value === null ? '' : value);

/* */
class AccountEdit extends React.Component {
  /* */
  state = {
    // Screen
    error: null,
    success: null,
    loading: false,
    // UX
    expandedPanel: null,
    // Data
    accountPrima: null,
  };

  /* */
  componentDidMount() {
    this.load();
  }

  /* */
  resetBeforeSubmit = otherState => this.setState({
    error: null,
    success: null,
    ...otherState,
  });

  /* */
  load() {
    const { account, golf } = this.props;

    const match = App.functions.match(account, golf);
    if (!match) return this.setState({ loading: false });

    this.resetBeforeSubmit({ loading: true });

    return Account.api.getAccountPrima(apolloClient, { golfId: golf.id })
      .then(acc => this.setState({
        accountPrima: { ...acc, isMember: match.isMember },
      }))
      .catch(e => this.setState({ error: e.message }))
      .finally(() => this.setState({ loading: false }));
  }

  /* */
  handleExpand = panel => (event, expanded) => {
    this.setState({ expandedPanel: expanded ? panel : null });
  }

  /* */
  handleChangePrivacy = (event) => {
    const { golf } = this.props;
    const isPrivate = event.target.checked;
    const success = 'Confidentialité mise à jour.';

    this.resetBeforeSubmit({ privacyIsSubmitting: true });

    return Account.api.setPrivacy(apolloClient, { golfId: golf.id, isPrivate })
      .then(() => this.setState({ success, isPrivate }))
      .catch(e => this.setState({ error: e.message }))
      .finally(() => this.setState({ privacyIsSubmitting: false }));
  };

  /* */
  onSubmit = (values, { setSubmitting }) => {
    let promise;
    let success;
    const { golf } = this.props;
    const { accountPrima } = this.state;

    this.resetBeforeSubmit();

    switch (values.form) {
      case 'profile':
        success = 'Données personnelles mises à jour.';
        promise = this.props.setAccount(apolloClient, values);
        break;
      case 'address': {
        const prevAddress = helpers.object.filterByKeys(accountPrima, ['address', 'address2', 'city', 'postcode']);
        const currentAddress = helpers.object.filterByKeys(values, ['address', 'address2', 'city', 'postcode']);
        const isSame = compareAddress(prevAddress, currentAddress);

        success = 'Adresse postale mise à jour.';
        promise = isSame
          ? Promise.resolve()
          : Account.api.setAccountPrima(apolloClient, { golfId: golf.id, ...currentAddress });
        break;
      }
      case 'privacy': {
        success = 'Confidentialité mise à jour.';
        promise = Account.api.setPrivacy(apolloClient, { golfId: golf.id, ...values });
        break;
      }
      default:
        promise = Promise.reject(new Error('Erreur !'));
    }

    return promise
      .then(() => this.setState({ success }))
      .catch(e => this.setState({ error: e.message }))
      .finally(() => setSubmitting(false));
  };

  /* */
  render() {
    const { account, classes } = this.props;
    const {
      error,
      loading,
      success,
      accountPrima,
      expandedPanel,
    } = this.state;

    // check FFG data
    const defaultFfg = {
      license: null,
      handicap: null,
      handicapDate: null,
    };
    const { ffg = defaultFfg } = account;
    const { handicap, handicapDate } = ffg;

    return (
      <Screen
        error={error}
        loading={loading}
        success={success}
        title={'Mon profil'}
        helpURL={`${HELP_URL}/mon-profil`}
        onBackPress={() => this.props.history.goBack()}>
        <Grid
          container
          spacing={24}
          justify="center"
          direction='row-reverse'>
          <Grid item sm={12} md={6}>
            {handicap && (
              <Paper className={classes.paper}>
                <Box
                  flex={1}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between">
                  <Box>
                    <Typography
                      gutterBottom
                      component="h2"
                      variant="subtitle1"
                      className={classes.subtitle}>
                      {'Mon index'}
                    </Typography>
                    {handicapDate && (
                      <Typography
                        color="inherit"
                        component="span"
                        className={classes.hcpDate}>
                        {`Mis à jour le ${i18n.l('date.formats.default', moment(handicapDate, 'x').toDate())}`}
                      </Typography>
                    )}
                  </Box>
                  <Avatar className={classes.hcp}>{handicap.toFixed(1)}</Avatar>
                </Box>
              </Paper>
            )}
            {accountPrima
              && accountPrima.privacy
              && accountPrima.isMember
              && (
                <ExpansionPanel
                  expanded={expandedPanel === 'changePrivacy'}
                  onChange={this.handleExpand('changePrivacy')}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}>
                    <Typography
                      component="h2"
                      variant="subtitle1"
                      className={classes.subtitle}>
                      {'Ma confidentialité'}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.panel}>
                    <Formik
                      initialValues={{
                        form: 'privacy',
                        ...accountPrima.privacy,
                      }}
                      onSubmit={this.onSubmit}
                      component={ChangePrivacyForm} />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )}
            {accountPrima && (
              <ExpansionPanel
                expanded={expandedPanel === 'changeAddress'}
                onChange={this.handleExpand('changeAddress')}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}>
                  <Typography
                    component="h2"
                    variant="subtitle1"
                    className={classes.subtitle}>
                    {'Mon adresse postale'}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.panel}>
                  {!accountPrima.addressIsValidated && (
                    <Alert variant="info">{'Votre adresse est en cours de validation auprès du golf.'}</Alert>
                  )}
                  <Formik
                    initialValues={{
                      form: 'address',
                      ...helpers.object.filterByKeys(accountPrima, ['address', 'address2', 'city', 'postcode'], formatInitialValue),
                    }}
                    onSubmit={this.onSubmit}
                    component={EditAddressForm}
                    validationSchema={validationSchema.addressForm} />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )}
          </Grid>
          <Grid item sm={12} md={6}>
            <Paper className={classes.paper}>
              <Typography
                gutterBottom
                component="h2"
                variant="subtitle1"
                className={classes.subtitle}>
                {'Mes données personnelles'}
              </Typography>
            <Formik
              initialValues={{
                form: 'profile',
                ...helpers.object.filterByKeys(ffg, ['license'], formatInitialValue),
                ...helpers.object.filterByKeys(account, ['email', 'phone', 'lastname', 'firstname'], formatInitialValue),
              }}
              onSubmit={this.onSubmit}
              component={EditProfileForm}
              validationSchema={validationSchema.profileForm} />
            </Paper>
          </Grid>
        </Grid>
      </Screen>
    );
  }
}

const mapStateToProps = ({ app: { account, golf } }) => ({ account, golf });

const StyledComponent = withStyles(styles)(AccountEdit);

export default connect(
  mapStateToProps,
  App.actions,
)(StyledComponent);
