import React from 'react';
import { connect } from 'react-redux';
import { helpers, App } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  colors,
  Button,
  Divider,
  Popover,
  Typography,
} from '@material-ui/core';
import apolloClient from '_utils/apolloClient';
import { WeatherPreview } from '_components/elements';

/* */
const styles = theme => ({
  mr: { marginRight: 16 },
  title: { fontWeight: 'bold' },
  link: { textDecoration: 'none' },
  paper: { padding: theme.spacing.unit * 2 },
  btnIcon: { marginRight: theme.spacing.unit * 1 },
  status: {
    fontWeight: 'bold',
    marginLeft: theme.spacing.unit * 2,
  },
  separator: {
    marginTop: theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit * 1,
  },
});

/* */
class Weather extends React.PureComponent {
  state = {
    anchorEl: null,
  };

  /* */
  componentDidMount() {
    const { golf } = this.props;
    this.props.getGolfGameConditions(apolloClient, { id: golf.id });
    this.props.getGolfWeather(apolloClient, { id: golf.id });
  }

  /* */
  handleOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  /* */
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  /* */
  renderPopover = () => {
    const { anchorEl } = this.state;
    const {
      golf,
      classes,
      weather,
      gameConditions,
    } = this.props;

    const isOpen = Boolean(anchorEl);
    const types = gameConditions && Object.keys(gameConditions);

    return (
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={this.handleClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
          <Typography
            gutterBottom
            align="center"
            variant="subtitle1"
            className={classes.title}>
            {golf.name}
          </Typography>
          <Box
            mb={2}
            display="flex"
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between">
            <WeatherPreview icon={weather.icon} alt={weather.weather} />
            <Box
              ml={2}
              flex={1}
              display="flex"
              flexDirection="column">
              <Typography variant="body2" component="span">
                {`${Math.floor(weather.temp)}°C`}
              </Typography>
              <Typography variant="body2" component="span">
                {helpers.string.ucfirst(weather.weather)}
              </Typography>
            </Box>
          </Box>
          <Box
            mb={1}
            display="flex"
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between">
            <Typography variant="caption" component="span" className={classes.mr}>
              {`Vent : ${Math.round(weather.wind)} km/h`}
            </Typography>
            <Typography variant="caption" component="span">
              {`Humidité : ${Math.round(weather.humidity)}%`}
            </Typography>
          </Box>
          {types && types.length > 0
            && <Divider className={classes.separator} />}
          {types && types.map((type, i) => (
            <Box key={i}>
              {gameConditions[type].map((gc, j) => (
                <Box
                  key={j}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between">
                  <Typography variant="body2">
                    {`${gc.label} ${gc.name}`}
                  </Typography>
                  <Typography
                    variant="caption"
                    className={classes.status}
                    style={{ color: gc.status ? colors.green[500] : colors.red[500] }}>
                    {gc.statuses[gc.status].label}
                  </Typography>
                </Box>
              ))}
              {i < (types.length - 1) && <Divider className={classes.separator} />}
            </Box>
          ))}
      </Popover>
    );
  };

  /* */
  renderButton() {
    const { anchorEl } = this.state;
    const { classes, weather } = this.props;
    const isOpen = Boolean(anchorEl);

    return (
      <Button
        color="inherit"
        aria-haspopup="true"
        onClick={this.handleOpen}
        aria-owns={isOpen ? 'material-appbar' : undefined}>
        <WeatherPreview
          width={32}
          height={32}
          alt="Météo"
          icon={weather.icon}
          className={classes.btnIcon} />
        {`${Math.floor(weather.temp)}°C`}
      </Button>
    );
  }

  /* */
  render() {
    const { weather } = this.props;

    if (!weather) return null;

    return (
      <React.Fragment>
        {this.renderButton()}
        {this.renderPopover()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ app: { golf, weather, gameConditions } }) => ({
  golf,
  weather,
  gameConditions,
});

const StyledComponent = withStyles(styles)(Weather);

export default connect(
  mapStateToProps,
  App.actions,
)(StyledComponent);
