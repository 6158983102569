import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Membership } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import i18n from '_utils/i18n';
import { Screen } from '_components/core';

/* */
const styles = theme => ({
  voucher: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 1,
  },
  paperBottom: {
    display: 'flex',
    borderTop: `1px dashed ${theme.palette.grey[400]}`,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing.unit * 4,
    paddingTop: theme.spacing.unit * 2,
  },
  body1: {
    ...theme.typography.body2,
    textAlign: 'center',
  },
  table: {
    marginBottom: theme.spacing.unit * 4,
    marginTop: theme.spacing.unit * 2,
  },
  underline: {
    textDecoration: 'underline',
  },
});

/* */
const CustomTableCell = withStyles(theme => ({
  body: {
    paddingLeft: theme.spacing.unit * 1,
    paddingRight: theme.spacing.unit * 1,
  },
}))(TableCell);

/* */
class MembershipVoucher extends React.Component {
  /* */
  constructor(props) {
    super(props);

    const { data: { due } } = this.props;

    this.redirect = false;
    if (due.memberships.length === 0) {
      this.redirect = true;
    }
  }

  /* */
  renderButton() {
    const { golf, data: { due } } = this.props;

    const redirectRoute = due.redirect ? due.redirect : '/event';

    // Paris Longchamp = cfac211a-b529-11ea-b3de-0242ac130004
    if (golf.id === 'cfac211a-b529-11ea-b3de-0242ac130004') {
      return (
        <React.Fragment>
          <Typography align="center" variant="caption">
            {"Vous pouvez maintenant vous inscrire à un cours de l'EDG."}
          </Typography>
          <Button
            fullWidth
            size="large"
            color="secondary"
            variant="contained"
            onClick={() => this.props.history.push(redirectRoute)}>
            {'Continuer mon inscription'}
          </Button>
        </React.Fragment>
      );
    }

    return (
      <Button
        fullWidth
        size="large"
        color="secondary"
        variant="contained"
        onClick={() => this.props.history.push('/membership')}>
        {'Retour'}
      </Button>
    );
  }

  /* */
  render() {
    const { classes, data: { due }, wording } = this.props;

    if (this.redirect) {
      return <Redirect to="/membership" />;
    }

    return (
      <Screen layout="fixed" title="Confirmation de paiement">
        <Typography
          paragraph
          variant="h4"
          align="center">
          {'Merci !'}
        </Typography>
        <div className={classes.voucher}>
          <Paper className={classes.paper}>
            <Typography
              gutterBottom
              variant="h5"
              align="center"
              component="h2"
              color="textSecondary">
              {due.golf.name}
            </Typography>
            <Typography
              align="center"
              variant="body2"
              className={classes.underline}>
              {due.golf.email}
            </Typography>
          </Paper>
          <Paper className={classes.paper}>
            <Typography
              gutterBottom
              variant="h5"
              align="center"
              component="h2"
              color="textSecondary">
              {due.cash ? 'Paiement comptant' : `Paiement en ${due.memberships[0].transaction.schedule.length} mensualités`}
            </Typography>
            {due.apiError && (
              <Typography
                gutterBottom
                color="error"
                component="p"
                align="center"
                variant="subtitle1">
                {'La transaction a été traitée avec succès mais la communication avec votre golf n’a pu aboutir. '}
                {'Veuillez le contacter pour confirmer le paiement en conservant le numéro de transaction ci-dessous.'}
              </Typography>
            )}
            <Table className={classes.table}>
              <TableBody>
                {due.memberships.map((m, i) => {
                  const type = Membership.functions.isPrincipal(m) ? 'principal' : 'additional';
                  const scopeTitle = `membership.titles.${type}_membership_${wording.membership}`;

                  return (
                    <TableRow key={i}>
                      <CustomTableCell component="th">
                        {`${m.year} - ${i18n.t(scopeTitle)}`}
                      </CustomTableCell>
                      <CustomTableCell align="right">
                        {i18n.l('currency', m.amount / 100)}
                      </CustomTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <p className={classes.body1}>
              {i18n.t('membership.messages.sent_confirm_email')} <span className={classes.underline}>{due.owner.email}</span>
            </p>
            <div className={classes.paperBottom}>
              <div>
                <Typography gutterBottom>{'Total'}</Typography>
                <Typography color="textSecondary">
                  {i18n.l('currency', due.total / 100)}
                </Typography>
              </div>
              {due.cash && (
                <div>
                  <Typography gutterBottom>{'E-ticket'}</Typography>
                  <Typography color="textSecondary">
                    {due.memberships[0].transactionPaybox.transactionNo}
                  </Typography>
                </div>
              )}
              {!due.cash && (
                <div>
                  <Typography gutterBottom>
                    {'Prochaine échéance'}
                  </Typography>
                  <Typography color="textSecondary">
                    {i18n.l('date.formats.default', moment(due.memberships[0].transaction.schedule[0].date).toDate())}
                  </Typography>
                </div>
              )}
            </div>
          </Paper>
        </div>
        {this.renderButton()}
      </Screen>
    );
  }
}

const mapStateToProps = ({ app, membershipData }) => ({
  golf: app.golf,
  data: membershipData,
  wording: app.golf.options.wording,
});

const StyledComponent = withStyles(styles)(MembershipVoucher);

export default connect(
  mapStateToProps,
  null,
)(StyledComponent);
