import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { helpers, Primaccess } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  colors,
  Input,
  Paper,
  Table,
  Button,
  Select,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  InputLabel,
  Typography,
  TableFooter,
  TablePagination,
  CircularProgress,
} from '@material-ui/core';
/* */
import i18n from '_utils/i18n';
import apolloClient from '_utils/apolloClient';

const styles = {
  debit: { color: colors.red[600] },
  credit: { color: colors.green[600] },
  dateColumn: { width: 175, paddingRight: 0 },
};

/* */
class SearchOperations extends React.Component {
  /* */
  state = {
    page: 0,
    type: -1,
    count: null,
    loading: true,
    operations: [],
  };

  /* */
  componentDidMount() {
    this.load();
  }

  /* */
  load = () => {
    const {
      golfId,
      clientId,
      onRefresh,
      rowsPerPage,
    } = this.props;
    const { page, type } = this.state;

    const offset = page * rowsPerPage;

    this.setState({ loading: true });

    Primaccess.api.searchOperations(apolloClient, {
      golfId,
      offset,
      clientId,
      limit: rowsPerPage,
      type: (type === -1 ? null : type),
    })
      .then(({ operations, metadata, balance }) => {
        this.setState({
          operations,
          loading: false,
          count: metadata.total,
        });
        onRefresh(balance);
      });
  };

  /* */
  handleChangeType = e => this.setState({
    page: 0,
    type: parseFloat(e.target.value),
  }, this.load);

  /* */
  handleChangePage = (e, page) => this.setState({ page }, this.load);

  /* */
  renderRow = (o, k) => {
    const { classes } = this.props;

    const foreign = (o.foreign) ? (
      <Typography
        variant="caption"
        color="textSecondary">
        {o.golfName}
      </Typography>
    ) : null;
    const className = o.amount > 0 ? classes.credit : classes.debit;

    return (
      <TableRow key={k}>
        <TableCell className={classes.dateColumn}>
          {i18n.l('time.formats.default', new Date(o.createdAt))}
        </TableCell>
        <TableCell>
          {helpers.string.ucfirst(o.name.toLowerCase())}
          {foreign}
        </TableCell>
        <TableCell align="right" className={className}>
          {i18n.l('number', o.amount)}
        </TableCell>
      </TableRow>
    );
  }

  /* */
  renderEmpty() {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={3}>
            {'Aucune donnée.'}
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  /* */
  renderLabelRows = ({ from, to, count }) => `${from}-${to} sur ${count}`;

  /* */
  render() {
    const { classes, rowsPerPage } = this.props;
    const {
      page,
      type,
      count,
      loading,
      operations,
    } = this.state;

    return (
      <Fragment>
        <Box
          mt={1}
          mb={3}
          display="flex"
          alignItems="flex-end"
          flexDirection="row">
          <Box
            mr={2}
            flex={1}
            display="flex"
            flexDirection="column">
            <InputLabel shrink htmlFor="select-type">
              {'Type de transaction'}
            </InputLabel>
            <Select
              native
              value={type}
              label='Type'
              onChange={this.handleChangeType}
              input={<Input name="type" id="select-type" />}>
              <option value={-1}>{'Tous'}</option>
              <option value={0}>{'Chargement'}</option>
              <option value={1}>{'Consommation'}</option>
            </Select>
          </Box>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={this.load}>
            {'Actualiser'}
          </Button>
        </Box>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center">
            <CircularProgress size={24} color="secondary" />
          </Box>
        ) : (
          <Paper>
            <Table padding="dense">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.dateColumn}>{'Date'}</TableCell>
                  <TableCell>{'Libellé'}</TableCell>
                  <TableCell align="right">{'Montant'}</TableCell>
                </TableRow>
              </TableHead>
              {operations.length > 0 && [
                <TableBody key="tbody">
                  {operations.map(this.renderRow)}
                </TableBody>,
                <TableFooter key="tfooter">
                  <TableRow>
                    <TablePagination
                      page={page}
                      count={count}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[rowsPerPage]}
                      onChangePage={this.handleChangePage}
                      labelDisplayedRows={this.renderLabelRows} />
                  </TableRow>
                </TableFooter>,
              ]}
              {operations.length === 0 && this.renderEmpty()}
            </Table>
          </Paper>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = ({ app: { golf }, primaccess: { clientId } }) =>
  ({ golfId: golf.id, clientId });

const StyledComponent = withStyles(styles)(SearchOperations);

export default connect(mapStateToProps)(StyledComponent);
