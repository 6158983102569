import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Account, AccountWallet } from '@aps-management/primapp-common';
import { withStyles } from '@material-ui/core/styles';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { unstable_Box as Box } from '@material-ui/core/Box';
import {
  AccountBalanceWalletTwoTone as AccountWalletIcon,
} from '@material-ui/icons';
import {
  colors,
  Table,
  Paper,
  Button,
  Collapse,
  TableRow,
  TableBody,
  TableCell,
  Typography,
} from '@material-ui/core';
import i18n from '_utils/i18n';
import { Screen } from '_components/core';
import apolloClient from '_utils/apolloClient';
import masterClient from '_utils/masterClient';

import DocumentPDF from './_StatementPDF';
import ChooseProduct from '../AccountWallet/ChooseRefillModal';

/* */
const styles = theme => ({
  line: {
    color: colors.grey[500],
  },
  balance: {
    padding: theme.spacing.unit * 1,
  },
  rowDate: {
    backgroundColor: colors.grey[100],
  },
  icon: {
    fontSize: theme.spacing.unit * 20,
  },
});

/* */
class ViewStatement extends React.Component {
  /* */
  state = {
    // Screen
    error: null,
    loading: true,
    success: null,
    // Data
    balance: 0,
    receipts: {},
    activeRows: [],
    refillModalOpened: false,
    refillDisabled: false,
    wallet: null,
  };

  /* */
  componentDidMount() {
    this.loadAccountWallet()
      .then((wallet) => {
        this.setState({ wallet });
        if (wallet) {
          this.load();
        }
      })
      .catch(() => this.setState({ loading: false })); // erreur = pas de compte client
  }

  /* */
  loadAccountWallet = () => {
    const { account, golf } = this.props;
    return AccountWallet.api.get(masterClient, {
      golfId: golf.id,
      accountId: account.id,
    });
  }

  /* */
  load = () => {
    const { golf } = this.props;

    return Account.api.getStatement(apolloClient, { golfId: golf.id })
      .then(({ balance, receipts: data }) => {
        // regroup by date
        const receipts = data.reduce((acc, d) => {
          if (typeof acc[d.date] === 'undefined') {
            acc[d.date] = [];
          }
          acc[d.date].push(d);
          return acc;
        }, {});
        this.setState({
          balance,
          receipts,
          refillAmount: balance < 0 ? Math.abs(balance) / 100 : 1,
        });
        this.props.update({ balance });
      })
      .catch(e => this.setState({ error: e.message }))
      .finally(() => this.setState({ loading: false }));
  }

  handleClick = row => () => {
    this.setState((state) => {
      const { activeRows: prev } = state;
      let activeRows;

      if (prev.includes(row)) {
        activeRows = prev.filter(r => r !== row);
      } else {
        activeRows = prev;
        activeRows.push(row);
      }
      return ({ activeRows });
    });
  };

  /* */
  renderReceipts() {
    const { classes } = this.props;
    const {
      activeRows,
      balance,
      receipts,
      wallet,
    } = this.state;

    const balanceColor = balance < 0 ? colors.red[500] : colors.green[500];
    return (
      <Fragment>
        <Paper className={classes.balance}>
          <Typography
            variant="h5"
            align="center"
          >
            <span title={wallet.id}>
              {'Solde : '}
            </span>
            <span style={{ color: balanceColor }}>{i18n.l('currency', balance / 100)}</span>
          </Typography>
        </Paper>
        <br />
        {
          Object.keys(receipts).length > 0 ? (
            <Paper>
              <Table>
                <TableBody>
                  {Object.keys(receipts).map((date, i) => (
                    <Fragment key={i}>
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          variant="head"
                          className={classes.rowDate}>
                          {i18n.l('date.formats.long_y', new Date(date))}
                        </TableCell>
                      </TableRow>
                      {receipts[date].map((r, j) => {
                        const rowIdx = `${i}${j}`;
                        const totalColor = r.total > 0 ? colors.green[500] : colors.grey[900];
                        const total = `${(r.total > 0) ? '+' : ''}${i18n.l('currency', r.total / 100)}`;

                        return (
                          <TableRow key={j} onClick={this.handleClick(rowIdx)}>
                            <TableCell>
                              {r.name.toUpperCase()}
                              <Collapse in={activeRows.includes(rowIdx)}>
                                <Box py={0.5}>
                                  {r.details.map((line, k) => (
                                    <Typography
                                      key={k}
                                      variant="caption"
                                      className={classes.line}>
                                      {line.name.toUpperCase()}
                                    </Typography>
                                  ))}
                                </Box>
                              </Collapse>
                            </TableCell>
                            <TableCell align="right">
                              <span style={{ color: totalColor }}>{total}</span>
                              <Collapse in={activeRows.includes(rowIdx)}>
                                <Box py={0.5}>
                                  {r.details.map((line, k) => (
                                    <Typography
                                      key={k}
                                      variant="caption"
                                      className={classes.line}>
                                      {line.amount ? i18n.l('currency', (-1) * line.amount / 100) : '-'}
                                    </Typography>
                                  ))}
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          ) : (
            <Typography
              align="center"
              variant="subtitle1">
              {'Aucune transaction.'}
            </Typography>
          )
        }
      </Fragment >
    );
  }

  /* */
  renderHeaderButtons() {
    const { receipts, refillDisabled } = this.state;
    const { golf, account } = this.props;

    if (Object.keys(receipts).length === 0) {
      return (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end">
          <Button
            color="secondary"
            variant="contained"
            disabled={refillDisabled}
            onClick={() => this.setState({ refillModalOpened: true })}>
            {'Recharger'}
          </Button>
        </Box>
      );
    }


    return (
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between">
        <PDFDownloadLink
          document={
            <DocumentPDF
              golf={golf}
              account={account}
              receipts={receipts} />}
          fileName="releve.pdf">
          {({ loading: isLoading }) => (isLoading ? 'En chargement...' : <Button color="secondary">{'Exporter en PDF'}</Button>)}
        </PDFDownloadLink>
        <Button
          color="secondary"
          variant="contained"
          disabled={refillDisabled}
          onClick={() => this.setState({ refillModalOpened: true })}>
          {'Recharger'}
        </Button>
      </Box>
    );
  }

  /* */
  renderRefillModal() {
    const { history } = this.props;
    const { refillModalOpened } = this.state;

    return (
      <ChooseProduct
        history={history}
        open={refillModalOpened}
        handleSwitchModal={() => this.setState({ refillModalOpened: false })}
      />
    );
  }

  /* */
  renderPage() {
    const { classes } = this.props;
    const { wallet } = this.state;

    if (!wallet) {
      return (
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column">
          <AccountWalletIcon className={classes.icon} />
          <Typography variant="subtitle1">
            {"Vous n'utilisez pas ce service actuellement."}
          </Typography>
          <Typography variant="subtitle1">
            {'Veuillez vous rapprocher de votre golf.'}
          </Typography>
        </Box>
      );
    }

    return (
      <React.Fragment>
        {this.renderHeaderButtons()}
        < br />
        {this.renderReceipts()}
        {this.renderRefillModal()}
      </React.Fragment>
    );
  }

  /* */
  render() {
    const {
      error,
      loading,
      success,
    } = this.state;

    return (
      <Screen
        error={error}
        loading={loading}
        success={success}
        title="Mon relevé de compte"
        onBackPress={() => this.props.history.goBack()}>
        {this.renderPage()}
      </Screen>
    );
  }
}

const mapStateToProps = ({ app }) => ({ golf: app.golf, account: app.account });

const StyledComponent = withStyles(styles)(ViewStatement);

export default connect(mapStateToProps, AccountWallet.actions)(StyledComponent);
